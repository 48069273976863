import styled from "styled-components";

export const RootHeader = styled.div`
  width: 100%;
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 1.5rem;
  background: ${({ theme }) => theme.colors.gray};
`;

export const Box = styled.div`
  width: 30%;
  display: flex;
  height: 100%;
  justify-content: start;
`;

export const LogoutContainer = styled.div`
  width: 150px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const LogoutText = styled.div`
  color: white;
  margin-right: 20px;
  font-weight: bold;
  font-size: 16px;
`;

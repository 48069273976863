import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import React, { useCallback, useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import iconArrow from "../../../assets/option-arrow.svg";
import InputDefault from "../../PanelControl/components/InputDefault";
import {
  Button,
  ContentChart,
  FooterBar,
  HStack,
  Input,
  LabelFilter,
  Select,
  TopBar,
  VStack,
} from "./styles";
import { format } from "date-fns-tz";
import ptBR from "date-fns/locale/pt-BR";
import type { ChartData, ChartOptions } from "chart.js";

import api from "../../../../../services/api";
import IconSvg from "../../../../login/components/IconSvg";
import excelButtonIcon from "../../../assets/downloadf-icon-excel.svg";
import checkIcon from "../../../assets/icon-check.svg";
import { ExportToExcel } from "../ExportToExcel";
import { Label } from "../../PanelControl/styles";
import { ButtonCheck } from "../../FeedbackScreen/styles";
import { data } from "jquery";
import DateFromRegion from "./components/DateFrom/DateFromRegion";
import DateToRegion from "./components/DateTo/DateToRegion";

interface ChartProps {
  options: ChartOptions<"bar">;
  data: ChartData<"bar">;
}

interface QueryProps {
  initial_date: string | null;
  final_date: string | null;
  topic: string | null;
  initial_political_view: number | null;
  final_political_view: number | null;
  initial_age: number | null;
  final_age: number | null;
}

interface DataChartsProps {
  england_east_midlands_leicester_area: number;
  england_east_midlands_nottingham_area: number;
  england_east_midlands_other: number;
  england_east_of_england_cambridge_area: number;
  england_east_of_england_other: number;
  england_greater_london_east_central: number;
  england_greater_london_east_other: number;
  england_greater_london_north_central: number;
  england_greater_london_north_other: number;
  england_greater_london_north_west_central: number;
  england_greater_london_north_west_other: number;
  england_greater_london_south_east_central: number;
  england_greater_london_south_east_other: number;
  england_greater_london_south_west_central: number;
  england_greater_london_south_west_other: number;
  england_greater_london_west_central: number;
  england_greater_london_west_other: number;
  england_north_east_newcastle_tyneside_area: number;
  england_north_east_other: number;
  england_north_west_liverpool_area: number;
  england_north_west_manchester_area: number;
  england_north_west_other: number;
  england_south_east_brighton_area: number;
  england_south_east_milton_keynes_area: number;
  england_south_east_other: number;
  england_south_east_oxford_area: number;
  england_south_east_portsmouth_southampton_area: number;
  england_south_west_bournemouth_poole_area: number;
  england_south_west_bristol_area: number;
  england_south_west_other: number;
  england_west_midlands_birmingham_area: number;
  england_west_midlands_coventry_area: number;
  england_west_midlands_other: number;
  england_west_midlands_warwick_area: number;
  england_yorkshire_the_humber_bradford_area: number;
  england_yorkshire_the_humber_leeds_area: number;
  england_yorkshire_the_humber_other: number;
  england_yorkshire_the_humber_sheffield_area: number;
  north_ireland_belfast_area: number;
  north_ireland_other: number;
  other_other: number;
  scotland_edinburgh_area: number;
  scotland_glasgow_area: number;
  scotland_other: number;
  wales_cardiff_area: number;
  wales_other: number;
  uninformed: string;
}

// import { Container } from './styles';
interface ChartRegionProps {
  title: string;
}

ChartJS.defaults.font.size = 12;
ChartJS.defaults.font.family = "Open Sans";

export const options: ChartOptions<"bar"> = {
  indexAxis: "y" as const,

  responsive: true,
  maintainAspectRatio: true,

  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  plugins: {
    legend: {
      position: "chartArea" as const,
      display: false,
      labels: {
        color: "red",
      },
    },
    title: {
      display: false,
      align: "start" as const,
      position: "top" as const,
      color: "white",
    },
  },
};

const ChartRegion: React.FC<ChartRegionProps> = ({ title }) => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const [query, setQuery] = useState<QueryProps>({
    initial_date: null,
    final_date: null,
    topic: null,
    initial_political_view: null,
    final_political_view: null,
    initial_age: null,
    final_age: null,
  });

  const [dataChartArticles, setDataChartArticles] = useState<DataChartsProps>(
    {} as DataChartsProps
  );
  //state of react date component
  const [value, onChange] = useState(new Date());
  const [arrFilteredValues, setArrFilteredValues] = useState<DataChartsProps[]>(
    []
  );
  const [initialPolitical, setInitialPolitical] = useState<number>(0);
  const [finalPolitical, setFinalPolitical] = useState<number>(0);
  const [dateFrom, setDateFrom] = useState<string>("");
  const [dateTo, setDateTo] = useState<string>("");
  const [topics, setTopics] = useState<string>("");
  const [initialAge, setInitialAge] = useState<number>(0);
  const [finalAge, setFinalAge] = useState<number>(0);
  const [regionOption, setRegionOption] = useState("");
  const [areaOption, setAreaOption] = useState("");

  const [selectedDate, setSelectedDate] = React.useState<Date>();
  const [selected, setSelected] = React.useState<Date>();

  const [isDateTo, setIsDateTo] = useState(false);
  const closeDropdownDateTo = () => {
    setIsDateTo(false);
  };
  const toggleDateTo = useCallback(
    (e) => {
      e.preventDefault();
      setIsDateTo((prevState) => !prevState);
    },
    [isDateTo]
  );

  const [isDateFrom, setIsDateFrom] = useState(false);
  const closeDropdownDateFrom = () => {
    setIsDateFrom(false);
  };
  const toggleDateFrom = useCallback(
    (e) => {
      e.preventDefault();
      setIsDateFrom((prevState) => !prevState);
    },
    [isDateFrom]
  );

  const colorsBar = [
    "#48C234",
    "#8BEA7C",
    "#7FFF00",
    "#4CBB17",
    "#228B22",
    "#006300",
    "#275727",
    "#4A794A",
    "#90EE90",
    "#00FF66",
    "#005D34",
    "#8BC38B",
    "#FFE840",
    "#EFAA00",
    "#A32C67",
    "#D63384",
    "#E27AAE",
    "#0B7FC5",
    "#00A0FF",
    "#084298",
    "#1457BC",
    "#6B9EEA",
    "#64141B",
    "#81010D",
    "#9B222E",
    "#CE2131",
    "#FD5162",
    "#7B4FB4",
    "#9760F1",
    "#B283FF",
    "#A74600",
    "#DB6401",
    "#FD7E14",
    "#F6A25B",
    "#7A008E",
    "#9300AB",
    "#BA26D2",
    "#D35BE7",
    "#009C80",
    "#00B8AD",
    "#00DCC2",
    "#9C0E4E",
    "#DE006B",
    "#4E2727",
    "#643C3C",
    "#9D9D9D",
  ];

  useEffect(() => {
    setArrFilteredValues((state) => {
      let cloneState = [];
      cloneState.push(dataChartArticles);

      return cloneState;
    });
  }, [dataChartArticles]);

  useEffect(() => {
    const autoLoad = async () => {
      try {
        const responseCharts = await api.get(
          "dashboard-graphics/region-distribution",
          {
            params: query,
          }
        );
        setDataChartArticles(responseCharts.data);
      } catch (err) {
        console.log(err, "error when fetch chart of regions");
      }
    };

    autoLoad();
  }, [query]);

  const handleTopicFilter = useCallback(
    (event) => {
      setTopics(event.target.value);
    },
    [topics]
  );

  const onChangeDateFrom = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setDateFrom(event.target.value);
    },
    [dateFrom]
  );

  const onChangeDateTo = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setDateTo(event.target.value);
    },
    [dateTo]
  );

  const onChangeInitialPolitical = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setInitialPolitical(Number(event.target.value));
    },
    [initialPolitical]
  );

  const handleChangeFinalPolitical = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setFinalPolitical(Number(event.target.value));
    },
    [finalPolitical]
  );

  const onChangeInitialAge = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setInitialAge(Number(event.target.value));
    },
    [initialAge]
  );

  const onChangeFinalAge = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setFinalAge(Number(event.target.value));
    },
    [finalAge]
  );

  const handleChangeRegion = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      setRegionOption(event.target.value);
    },
    [regionOption]
  );

  const changeAreaOption = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      setAreaOption(event.target.value);
    },
    [areaOption]
  );

  const handleButtonSubmitChart = useCallback(() => {
    setQuery((state: QueryProps) => {
      let cloneState = {
        ...state,
        topic: topics === "" ? null : topics,
        initial_date: dateFrom === "" ? null : dateFrom,
        final_date: dateTo === "" ? null : dateTo,
        initial_political_view:
          initialPolitical === 0 ? null : initialPolitical,
        final_political_view: finalPolitical === 0 ? null : finalPolitical,
        initial_age: initialAge === 0 ? null : initialAge,
        final_age: finalAge === 0 ? null : finalAge,
      };

      return cloneState;
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    query,
    topics,
    dateFrom,
    dateTo,
    initialPolitical,
    finalPolitical,
    initialAge,
    finalAge,
    regionOption,
    areaOption,
  ]);

  const labels = [
    "England East Midlands - Leicester Area",
    "England East Midlands - Nottingham Area",
    "England East Midlands - Other",
    "England East Of England - Cambridge Area",
    "England East Of England - Other",
    "England Greater London - East Central",
    "England Greater London - East Other",
    "England Greater London - North central",
    "England Greater London - North Other",
    "England Greater London - North West Central",
    "England Greater London - North West Other",
    "England Greater London - South East Central",
    "England Greater London - South East Other",
    "England Greater London - South West Central",
    "England Greater London - South West Other",
    "England Greater London - West Central",
    "England Greater London - West Other",
    "England North East Newcastle - Tyneside Area",
    "England North East - Other",
    "England North West - Liverpool Area",
    "England North West - Manchester Area",
    "England North West - Other",
    "England South East - Brighton Area",
    "England South East - Milton Keynes Area",
    "England South East - Other",
    "England South East - Oxford Area",
    "England South East - Portsmouth Southampton Area",
    "England South West - Bournemouth Poole Area",
    "England South West - Bristol Area",
    "England South West - Other",
    "England West Midlands - Birmingham Area",
    "England West Midlands - Coventry Area",
    "England West Midlands - Other",
    "England West Midlands - Warwick Area",
    "England Yorkshire The Humber - Bradford Area",
    "England Yorkshire The Humber - leeds Area",
    "England Yorkshire The Humber - Other",
    "England Yorkshire The Humber - Sheffield Area",
    "Northern Ireland - Belfast Area",
    "Northern Ireland - Other",
    "Other - Other",
    "Scotland - Edinburgh Area",
    "Scotland - Glasgow Area",
    "Scotland - Other",
    "Wales - Cardiff Area",
    "Wales - Other",
    "Uninformed",
  ];

  //           {/* {Object.keys(data).map(
  //             (keyName, keyIndex) => (

  //             )
  //             // use keyName to get current key's name
  //             // and a[keyName] to get its value
  //           )} */}

  const data = {
    labels,
    datasets: [
      {
        data: [
          dataChartArticles.england_east_midlands_leicester_area,
          dataChartArticles.england_east_midlands_nottingham_area,
          dataChartArticles.england_east_midlands_other,
          dataChartArticles.england_east_of_england_cambridge_area,
          dataChartArticles.england_east_of_england_other,
          dataChartArticles.england_greater_london_east_central,
          dataChartArticles.england_greater_london_east_other,
          dataChartArticles.england_greater_london_north_central,
          dataChartArticles.england_greater_london_north_other,
          dataChartArticles.england_greater_london_north_west_central,
          dataChartArticles.england_greater_london_north_west_other,
          dataChartArticles.england_greater_london_south_east_central,
          dataChartArticles.england_greater_london_south_east_other,
          dataChartArticles.england_greater_london_south_west_central,
          dataChartArticles.england_greater_london_south_west_other,
          dataChartArticles.england_greater_london_west_central,
          dataChartArticles.england_greater_london_west_other,
          dataChartArticles.england_north_east_newcastle_tyneside_area,
          dataChartArticles.england_north_east_other,
          dataChartArticles.england_north_west_liverpool_area,
          dataChartArticles.england_north_west_manchester_area,
          dataChartArticles.england_north_west_other,
          dataChartArticles.england_south_east_brighton_area,
          dataChartArticles.england_south_east_milton_keynes_area,
          dataChartArticles.england_south_east_other,
          dataChartArticles.england_south_east_oxford_area,
          dataChartArticles.england_south_east_portsmouth_southampton_area,
          dataChartArticles.england_south_west_bournemouth_poole_area,
          dataChartArticles.england_south_west_bristol_area,
          dataChartArticles.england_south_west_other,
          dataChartArticles.england_west_midlands_birmingham_area,
          dataChartArticles.england_west_midlands_coventry_area,
          dataChartArticles.england_west_midlands_other,
          dataChartArticles.england_west_midlands_warwick_area,
          dataChartArticles.england_yorkshire_the_humber_bradford_area,
          dataChartArticles.england_yorkshire_the_humber_leeds_area,
          dataChartArticles.england_yorkshire_the_humber_other,
          dataChartArticles.england_yorkshire_the_humber_sheffield_area,
          dataChartArticles.north_ireland_belfast_area,
          dataChartArticles.north_ireland_other,
          dataChartArticles.other_other,
          dataChartArticles.scotland_edinburgh_area,
          dataChartArticles.scotland_glasgow_area,
          dataChartArticles.scotland_other,
          dataChartArticles.wales_cardiff_area,
          dataChartArticles.wales_other,
          dataChartArticles.uninformed,
        ],
        borderColor: colorsBar,
        backgroundColor: colorsBar,
        barThickness: 23,
      },
    ],
  };

  const [productData, setProductData] = useState([]);
  // const [finalData, setFinalData] = useState([]);
  // const [finalDataSend, setFinalDataSend] = useState([]);
  const [currentFilters, setCurrentFilters] = useState<QueryProps[]>([]);

  useEffect(() => {
    //Array of query filters for export excel
    setCurrentFilters((state) => {
      let cloneState = [];
      cloneState.push(query);
      return cloneState;
    });
  }, [query]);

  useEffect(() => {
    if (selectedDate) {
      setDateFrom(format(selectedDate, "yyyy/MM/dd").replaceAll("/", "-"));
    }

    if (selected) {
      setDateTo(format(selected, "yyyy/MM/dd").replaceAll("/", "-"));
    }
  }, [selectedDate, selected]);

  return (
    <>
      <TopBar>
        <span> {title} </span>
        <ExportToExcel
          dataFilters={currentFilters}
          valuesFiltered={arrFilteredValues}
          nameFile="total-articles-read-per-user"
        >
          <IconSvg image={excelButtonIcon} width={"40"} height={"40"} />
        </ExportToExcel>
      </TopBar>
      <ContentChart>
        <Bar options={options} data={data} height={"600px"} />
      </ContentChart>
      <FooterBar>
        <VStack>
          <LabelFilter>Date</LabelFilter>
          <div className="wrapper-date-from">
            <Input
              placeholder="from"
              type="text"
              value={dateFrom}
              onChange={onChangeDateFrom}
              onClick={toggleDateFrom}
            />

            {isDateFrom && (
              <DateFromRegion
                closeToggle={closeDropdownDateFrom}
                selected={selectedDate}
                setSelected={setSelectedDate}
              />
            )}
          </div>
        </VStack>
        <VStack margin="0 1rem">
          <LabelFilter style={{ color: "transparent" }}> . </LabelFilter>
          <div className="wrapper-date-from">
            <Input
              type="text"
              placeholder="to"
              value={dateTo}
              onChange={onChangeDateTo}
              onClick={toggleDateTo}
            />

            {isDateTo && (
              <DateToRegion
                closeToggle={closeDropdownDateTo}
                selected={selected}
                setSelected={setSelected}
              />
            )}
          </div>
        </VStack>

        <VStack margin="0 2rem">
          <div className="arrow-wrapper">
            <Select
              value={topics}
              onChange={handleTopicFilter}
              className={"select-topic"}
            >
              <option value={""} hidden>
                {"Topic"}
              </option>
              <option value="Global Affairs">Global Affairs</option>
              <option value="Business">Business</option>
              <option value="Opinion">Opinion</option>
              <option value="Sport">Sport</option>
              <option value="Entertainment">Entertainment</option>
              <option value="Wellness">Wellness</option>
              <option value="Politics">Politics</option>
              <option value="Science">Science</option>
              <option value="Tech">Tech</option>
              <option value="UK Affairs">Uk Affairs</option>
            </Select>
            {topics === "" && (
              <img src={iconArrow} className="image-position" alt="" />
            )}
          </div>
        </VStack>

        <VStack margin="0 0rem 0 1rem">
          <LabelFilter> Political position </LabelFilter>
          <Input
            type="text"
            value={initialPolitical}
            onChange={onChangeInitialPolitical}
            placeholder="from"
          />
        </VStack>

        <VStack margin="0 2rem 0 1rem">
          <LabelFilter style={{ color: "transparent" }}>.</LabelFilter>
          <Input
            value={finalPolitical}
            onChange={handleChangeFinalPolitical}
            type="text"
            placeholder="to"
          />
        </VStack>
        <VStack margin="0 1rem">
          <LabelFilter> Age </LabelFilter>
          <Input
            type="text"
            placeholder="from"
            value={initialAge}
            onChange={onChangeInitialAge}
          />
        </VStack>

        <VStack margin="0 1rem">
          <LabelFilter style={{ color: "transparent" }}> . </LabelFilter>
          <Input
            type="text"
            placeholder="to"
            value={finalAge}
            onChange={onChangeFinalAge}
          />
        </VStack>
        <HStack>
          <Button className="button-excel" onClick={handleButtonSubmitChart}>
            <img src={checkIcon} />
          </Button>
        </HStack>
      </FooterBar>
    </>
  );
};

export default ChartRegion;

import React from "react";
import LoginPage from "./components/LoginPage";

// import { Container } from './styles';

const Login: React.FC = () => {
  return <LoginPage />;
};

export default Login;

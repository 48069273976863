import React from "react";
import { DayPicker } from "react-day-picker";

import { useDetectClickOutside } from "react-detect-click-outside";

import { BoxDate } from "./styles";

interface DateComponentProps {
  closeToggle: () => void;
  selected: Date;
  setSelected: React.Dispatch<React.SetStateAction<Date>>;
}

const DateComponentFromNews: React.FC<DateComponentProps> = ({
  closeToggle,
  selected,
  setSelected,
}) => {
  const ref = useDetectClickOutside({ onTriggered: closeToggle });
  return (
    <BoxDate className="boxDates" ref={ref}>
      <DayPicker
        mode="single"
        selected={selected}
        onSelect={setSelected}
        // footer={footer}
      />
    </BoxDate>
  );
};

export default DateComponentFromNews;

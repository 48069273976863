/*
here we are listing and filtering the news and
you can navigate to the edit articles
*/

import { css } from "@emotion/react";
import { format, utcToZonedTime } from "date-fns-tz";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MoonLoader from "react-spinners/MoonLoader";
import api from "../../../../../../services/api";
import seachIcon from "../../../../../dash/assets/icon-search-md.svg";
import checkIcon from "../../../../assets/icon-check.svg";
import penIcon from "../../../../assets/pen-icon.svg";
import Pagination from "../../../../components/Pagination";
import InputDefault from "../../../PanelControl/components/InputDefault";
import DateComponentNews from "../DateComponentNews";
import {
  BoxList,
  ButtonCheck,
  ButtonEditNews,
  Container,
  ContentPag,
  EmptyField,
  FlexButton,
  FlexControl,
  FlexDateFrom,
  FlexDateTo,
  FlexIcon,
  FlexList,
  FlexMain,
  FlexNewspapper,
  FlexPagination,
  FlexPoliticalViewsFrom,
  FlexPoliticalViewsTo,
  FlexSearch,
  FlexTopic,
  Main,
  Select,
  TableNews,
  TBodyNews,
  TdNews,
  TextTable,
  THeadNews,
  ThNews,
  TrNews,
} from "./styles";

import arrowIcon from "../../../../assets/option-arrow.svg";
import DateComponentFromNews from "../DateComponentFromNews";

//set up interfaces
interface ObjectNewsProps {
  category: string;
  date: string;
  id: string;
  newspaper: string;
  rating: null | string;
  title: string;
  political_position?: string | number;
  countread?: string;
}

interface ObjectNewsStateProps {
  initial_date: null | string;
  final_date: null | string;
  initial_political_view: null | string;
  final_political_view: null | string;
  topic: null | string;
  newspaper: null | string;
  filter: "simple-list" | "list-read" | "list-vote" | "";
  offset: number;
  search: null | string;
}

const NewsContainer: React.FC = () => {
  const navigator = useNavigate();
  //set up main object for news
  const [dataNews, setDataNews] = useState<ObjectNewsStateProps>({
    initial_date: null,
    final_date: null,
    initial_political_view: null,
    final_political_view: null,
    topic: null,
    newspaper: null,
    filter: "list-vote",
    offset: 0,
    search: null,
  });
  //set up all states
  const [resultNews, setResultNews] = useState<ObjectNewsProps[]>([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [readActive, setReadActive] = useState(false);
  const [newspaper, setNewspaper] = useState("");
  const [list, setList] = useState<
    "simple-list" | "list-read" | "list-vote" | ""
  >("");
  const [politicalPosInitial, setPoliticalPosInitial] = useState("");
  const [politicalPosFinal, setPoliticalPosFinal] = useState("");
  const [category, setCategory] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [color, setColor] = useState("#FFC600");
  const [activeDateTo, setActiveDateTo] = useState(false);
  const [activeDateFrom, setActiveDateFrom] = useState(false);
  const [rangeDateTo, setRangeDateTo] = useState("");
  const [rangeDateFrom, setRangeDateFrom] = useState("");
  const [selectedDate, setSelectedDate] = React.useState<Date>();
  const [selectedDateFrom, setSelectedDateFrom] = React.useState<Date>();

  /*
  here we control the date range through
  a component we take the start date with the end
  */
  const toggleDateTo = useCallback(
    (e) => {
      e.preventDefault();
      setActiveDateTo((prevState) => !prevState);
    },
    [activeDateTo]
  );

  /*
  here we control the date range through
  a component we take the start date with the end
  */
  const toggleDateToFrom = useCallback(
    (e) => {
      e.preventDefault();
      setActiveDateFrom((prevState) => !prevState);
    },
    [activeDateFrom]
  );

  // load initial list to news
  // list vote because we have 3
  // types of news coming from the api
  useEffect(() => {
    setList("list-vote");
  }, []);

  // loading the news coming from the backend
  // and preparing searches with pagination
  useEffect(() => {
    const autoLoadNews = async () => {
      try {
        const response = await api.get("news-dashboard", {
          params: dataNews,
        });
        setResultNews(response?.data?.list_news);
        setTotalPages(response?.data?.pagination);
        setLoading(false);
      } catch (err) {
        console.log(err, "erro when fetch data news dashboard");
        setLoading(false);
      }
    };

    autoLoadNews();
  }, [dataNews]);

  // performing the fetch event to update in state
  // when user search any word
  const handleSearchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(event.target.value);
    },
    [search]
  );

  //manipulating specifically when this is the search because
  // we will display a slightly different format in the
  // interface and news columns, there are small differences
  // for the other 2 types
  const handleSubmitCheckButton = useCallback(() => {
    if (list === "list-read") {
      setReadActive(true);
    } else {
      setReadActive(false);
    }
    //update our main state of list news
    setDataNews((state: ObjectNewsStateProps) => {
      let cloneState = { ...state };

      cloneState = {
        ...cloneState,
        search: search === "" ? null : search,
        newspaper: newspaper === "" ? null : newspaper,
        topic: category === "" ? null : category,
        filter: list === "" ? null : list,
        initial_political_view:
          politicalPosInitial === "" ? null : politicalPosInitial,
        final_political_view:
          politicalPosFinal === "" ? null : politicalPosFinal,
        offset: 0,
        initial_date: rangeDateFrom === "" ? null : rangeDateFrom,
        final_date: rangeDateTo === "" ? null : rangeDateTo,
      };

      return cloneState;
    });
  }, [
    dataNews,
    search,
    newspaper,
    category,
    list,
    politicalPosFinal,
    politicalPosInitial,
    rangeDateFrom,
    rangeDateTo,
  ]);

  //this function close component of initial date
  const closeDropdownDateTo = useCallback(() => {
    setActiveDateTo(false);
  }, [activeDateTo]);

  //this function close component of final date
  const closeDropdownDateFrom = useCallback(() => {
    setActiveDateFrom(false);
  }, [activeDateFrom]);

  //this function responsible for get value of select
  //of newspaper
  const handleOptionNewspaper = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      setSearch("");
      setNewspaper((state) => {
        let cloneState = state;
        cloneState = event.target.value;
        return cloneState;
      });
    },
    [newspaper]
  );

  //this function responsible for fetch value
  //of select about Category
  const handleOptionsCategory = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      setSearch("");
      setCategory((state) => {
        let cloneState = state;
        cloneState = event.target.value;
        return cloneState;
      });
    },
    [category, search]
  );

  //this function responsible for fetch value
  //of select about which list (kind of news)
  const handleChangeList = useCallback(
    (event: React.ChangeEvent<any>) => {
      setSearch("");
      setList((state) => {
        let cloneState = state;
        cloneState = event.target.value;
        return cloneState;
      });
    },
    [list, search]
  );

  // just adjusts of css
  // for loading component
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

  //formatting dates...
  useEffect(() => {
    if (selectedDate) {
      setSearch("");
      setRangeDateTo(format(selectedDate, "yyyy/MM/dd").replaceAll("/", "-"));
    }

    if (selectedDateFrom) {
      setSearch("");
      setRangeDateFrom(
        format(selectedDateFrom, "yyyy/MM/dd").replaceAll("/", "-")
      );
    }
  }, [selectedDate, selectedDateFrom]);

  //transform our object date to UTC zone and format him
  // resolving date issues, including utc always zero,
  // same thing with beehive api backend servers

  const formatDate = (date: string) => {
    let myObject = date;
    const nyTimeZone = "UTC";
    const myDate = utcToZonedTime(myObject, nyTimeZone);
    const niceDate = format(myDate, "yyyy/MM/dd - HH:mm", {
      timeZone: "UTC",
    });

    return `${niceDate}`;
  };

  return (
    <Container>
      {loading ? (
        <div
          style={{
            height: "80vh",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <MoonLoader
            color={color}
            loading={loading}
            css={override}
            size={65}
            speedMultiplier={1.2}
          />
        </div>
      ) : (
        <Main>
          <FlexSearch>
            <FlexMain>
              <input
                value={search}
                onChange={handleSearchChange}
                type="text"
                placeholder="Search"
              ></input>
            </FlexMain>
            <FlexIcon>
              <img src={seachIcon} />
            </FlexIcon>
          </FlexSearch>

          <FlexControl>
            <FlexList>
              <Select
                className="input-default-list-news"
                onChange={handleChangeList}
                value={list}
              >
                <option value={"simple-list"} hidden>
                  Simple-list
                </option>
                <option value="list-read" hidden>
                  Most read
                </option>
                <option value="list-vote" hidden>
                  Best rated
                </option>
              </Select>
              <img src={arrowIcon} className="image-position" />
            </FlexList>
            <FlexDateFrom>
              <div className="wrapper-date-from">
                <InputDefault
                  className="input-default-list-news"
                  placeholder="Date from"
                  type="text"
                  id={"forcemargin"}
                  onClick={toggleDateToFrom}
                  onFocus={(e) => {
                    setTimeout(() => {
                      setActiveDateFrom(true);
                    }, 100);
                  }}
                  value={rangeDateFrom}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setRangeDateFrom(e.target.value)
                  }
                />

                {activeDateFrom && (
                  <DateComponentFromNews
                    closeToggle={closeDropdownDateFrom}
                    selected={selectedDateFrom}
                    setSelected={setSelectedDateFrom}
                  />
                )}
              </div>
            </FlexDateFrom>
            <FlexDateTo>
              <div className="wrapper-date-from">
                <InputDefault
                  className="input-default-list-news"
                  placeholder="Date to"
                  id={"forcemargin"}
                  type="text"
                  onClick={toggleDateTo}
                  onFocus={(e) => {
                    setTimeout(() => {
                      setActiveDateTo(true);
                    }, 100);
                  }}
                  value={rangeDateTo}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setRangeDateTo(e.target.value)
                  }
                />

                {activeDateTo && (
                  <DateComponentNews
                    closeToggle={closeDropdownDateTo}
                    selected={selectedDate}
                    setSelected={setSelectedDate}
                  />
                )}
              </div>
            </FlexDateTo>

            <FlexTopic>
              <Select
                value={category}
                onChange={handleOptionsCategory}
                className="input-default-list-news"
              >
                <option value="" hidden>
                  Topic / Category
                </option>
                <option value="UK Affairs">UK Affairs</option>
                <option value="Global Affairs">Global Affairs</option>
                <option value="Business">Business</option>
                <option value="Sport">Sport</option>
                <option value="Opinion">Opinion</option>
                <option value="Entertainment">Entertainment</option>
                <option value="Wellness">Wellness</option>
                <option value="Politics">Politics</option>
                <option value="Science">Science</option>
                <option value="Tech">Tech</option>
              </Select>
              <img src={arrowIcon} className="image-position" />
            </FlexTopic>
            <FlexPoliticalViewsFrom>
              <InputDefault
                className="input-default-list-news"
                placeholder="Political position"
                type="text"
                value={politicalPosInitial}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setSearch("");
                  setPoliticalPosInitial((state) => (state = e.target.value));
                }}
              />
            </FlexPoliticalViewsFrom>
            <FlexPoliticalViewsTo>
              <InputDefault
                className="input-default-list-news"
                placeholder="Political position"
                type="text"
                value={politicalPosFinal}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setSearch("");
                  setPoliticalPosFinal((state) => (state = e.target.value));
                }}
              />
            </FlexPoliticalViewsTo>
            <FlexNewspapper>
              <Select
                value={newspaper}
                onChange={handleOptionNewspaper}
                className="input-default-list-news"
              >
                <option value="" hidden>
                  Newspaper
                </option>
                <option value="BBC">BBC</option>
                <option value="The Guardian">The Guardian</option>
                <option value="Aljazeera">Aljazeera</option>
                <option value="Independent">Independent</option>
                <option value="Haaretz">Haaretz</option>
                <option value="Sixth Tone">Sixth Tone</option>
                <option value="Sky News">Sky News</option>
                <option value="The Hindu">The Hindu</option>
                <option value="Washington Post">Washington Post</option>
                <option value="Santiago Times">Santiago Times</option>
              </Select>
              <img src={arrowIcon} className="image-position" />
            </FlexNewspapper>
            <FlexButton>
              <ButtonCheck
                className="button-excel"
                onClick={handleSubmitCheckButton}
              >
                <img src={checkIcon} className="check-icon" alt="" />
              </ButtonCheck>
            </FlexButton>
          </FlexControl>
          <BoxList>
            <TableNews>
              <THeadNews>
                <TrNews style={{ borderBottom: "none", marginBottom: "2rem" }}>
                  <ThNews style={{ width: "16.6%" }}>Newspapper</ThNews>
                  <ThNews style={{ width: "16.6%" }}>Category/topic</ThNews>
                  <ThNews
                    className="force-transform-title"
                    style={{ width: "16.6%", marginRight: "3rem" }}
                  >
                    Title
                  </ThNews>

                  {readActive ? (
                    <ThNews
                      className="date-transform"
                      style={{
                        width: "16.6%",
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      Date
                    </ThNews>
                  ) : (
                    <ThNews
                      className="date-transform2"
                      style={{
                        width: "16.6%",
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      Date
                    </ThNews>
                  )}

                  {readActive ? (
                    <ThNews className="field-political-position2">
                      Political Pos
                    </ThNews>
                  ) : (
                    <ThNews className="field-political-position">
                      Political Pos
                    </ThNews>
                  )}

                  {readActive ? (
                    <ThNews
                      className="force-transform-rating2"
                      style={{
                        width: "16.6%",
                        display: "flex",
                        justifyContent: "flex-end",
                        marginRight: "auto",
                      }}
                    >
                      Rating
                    </ThNews>
                  ) : (
                    <ThNews
                      className="force-transform-rating"
                      style={{
                        width: "16.6%",
                        display: "flex",
                        justifyContent: "flex-end",
                        marginRight: "auto",
                      }}
                    >
                      Rating
                    </ThNews>
                  )}

                  {readActive && (
                    <ThNews
                      style={{
                        width: "20.6%",
                        display: "flex",
                        justifyContent: "flex-end",
                        marginRight: "auto",
                        transform: "translateX(50px)",
                      }}
                    >
                      Reads
                    </ThNews>
                  )}
                  <ThNews style={{ width: "16.6%" }}></ThNews>
                </TrNews>
              </THeadNews>
              <TBodyNews>
                {resultNews.length >= 1 ? (
                  resultNews.map((itemNews, index) => (
                    <TrNews>
                      <TdNews style={{ width: "16.6%" }}>
                        <TextTable>{itemNews.newspaper} </TextTable>
                      </TdNews>
                      <TdNews style={{ width: "16.6%" }}>
                        <TextTable>{itemNews.category}</TextTable>
                      </TdNews>
                      <TdNews style={{ width: "220px", marginRight: "5rem" }}>
                        <TextTable> {itemNews.title}</TextTable>
                      </TdNews>
                      <TdNews
                        className="column-date"
                        style={{ width: "16.6%" }}
                      >
                        <TextTable>
                          {/* {format(
                            Date.parse(itemNews.date),
                            " dd/MM/yyyy' at ' HH':'mm",
                            {
                              timeZone: "America/New_York",
                            }
                          )} */}
                          {formatDate(itemNews.date)}
                        </TextTable>
                      </TdNews>
                      <TdNews className="column-political-position">
                        <TextTable>
                          {itemNews.political_position
                            ? itemNews.political_position
                            : "0"}
                        </TextTable>
                      </TdNews>
                      <TdNews
                        style={{ justifyContent: "flex-end", width: "10%" }}
                      >
                        <TextTable>
                          {itemNews.rating ? itemNews.rating : "-"}
                        </TextTable>
                      </TdNews>

                      {readActive && (
                        <TdNews
                          style={{ justifyContent: "flex-end", width: "10%" }}
                        >
                          <TextTable>
                            {itemNews.countread ? itemNews.countread : "0"}
                          </TextTable>
                        </TdNews>
                      )}
                      <TdNews
                        style={{ justifyContent: "flex-end", width: "10%" }}
                      >
                        <ButtonEditNews
                          onClick={() => navigator(`/news/list/${itemNews.id}`)}
                        >
                          <img src={penIcon} className="check-icon" alt="" />
                        </ButtonEditNews>
                      </TdNews>
                    </TrNews>
                  ))
                ) : (
                  <EmptyField>
                    <span>Empty data for your search.</span>
                  </EmptyField>
                )}
              </TBodyNews>
            </TableNews>
          </BoxList>

          <FlexPagination style={{ marginTop: "0rem" }}>
            <ContentPag>
              {resultNews.length >= 1 ? (
                <Pagination
                  limit={10}
                  total={totalPages}
                  offset={dataNews?.offset}
                  setDataNews={setDataNews}
                  dataNews={dataNews}
                />
              ) : (
                <div style={{ height: "20px" }}></div>
              )}
            </ContentPag>
          </FlexPagination>
        </Main>
      )}
    </Container>
  );
};

export default NewsContainer;

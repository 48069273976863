import styled from "styled-components";

interface VStackProps {
  margin?: string;
}

export const TopBar = styled.div`
  width: 100%;
  height: 10%;
  display: flex;
  background: #f8f9fa;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border: 1px solid #dadada;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;

  span {
    font-size: 1.5rem;
    font-weight: bold;
    font-family: "Open Sans", sans-serif;

    @media (max-width: 1366px) {
      font-size: 1.4rem;
    }
  }
`;

export const ContentChart = styled.div`
  width: 100%;
  height: 75%;
  background-color: white;
  overflow: scroll;
  border: 1px solid #dadada;
  border-top: none;
  overflow-x: hidden;

  div {
    height: 1000px;
    width: 100%;
    background: white;
    border: 1px solid #dadada;
    border-top: none;
  }
`;

export const FooterBar = styled.div`
  width: 100%;
  height: 15%;
  background-color: green;
  border: 1px solid #dadada;
  display: flex;
  align-items: center;
  border-radius: 0px 0px 5px 5px;
  background-color: #f8f9fa;
  padding: 0rem 1rem;

  .wrapper-date-from {
    position: relative;
  }
`;

export const VStack = styled.div<VStackProps>`
  width: 100%;
  max-width: 152px;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: ${({ margin }) => (margin ? margin : "")};
  justify-content: flex-end;
  padding-bottom: 1rem;

  .arrow-wrapper {
    position: relative;
    width: 100%;
  }

  .image-position {
    position: absolute;
    right: 12px;
    top: 16px;
  }

  @media (max-width: 1366px) {
    .image-position {
      width: 12px;
      height: auto;
      right: 10px;
      top: 18px;
    }
  }
`;

export const Input = styled.input`
  width: 100%;
  height: 40px;
  border: 1px solid black;
  border-radius: 5px;
  padding: 0 1rem;
  border-color: #9d9d9d;
  font-size: 1.2rem;

  ::placeholder {
    font-size: 1.2rem;
  }
`;

export const LabelFilter = styled.span`
  font-size: 1.2rem;
  font-weight: bold;
  margin: 0.3rem 0;
`;

export const Button = styled.button`
  width: 45px;
  height: 45px;
  background-color: ${({ theme }) => theme.colors.honey};
  margin-top: 1.75rem;
  border-radius: 5px;
  margin-left: auto;

  margin-bottom: auto;
  transition: all 120ms linear;

  @media (max-width: 1366px) {
    height: 40px;
    width: 40px;
  }
`;

export const HStack = styled.div`
  width: 60px;
  height: 100%;

  display: flex;
  margin-left: auto;
`;

export const Select = styled.select`
  width: 100%;
  height: 40px;
  border: 1px solid #9d9d9d;
  color: gray;
  border-radius: 5px;
  font-size: 1.2rem;
  height: 40px;
  border: 1px solid black;
  border-radius: 5px;
  padding: 0 1rem;
  border-color: #9d9d9d;

  option {
    color: black;
    background: white;
    display: flex;
    white-space: pre;
  }

  @media (max-width: 1366px) {
    height: 39px;
  }
`;

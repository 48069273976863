/*
 *this file is responsible for displaying each
 *graph on the screen and downloading some data
 *and metrics to excel
 */

import React, { useEffect, useState } from "react";

import arrowDownload from "../../assets/arrow-download-icon.svg";
import ChartArticlesRead from "../components/ChartArticlesRead";
import ChartTotalPoliticalPosition from "../components/ChartTotalPoliticalPosition";
import ModelChart from "../components/ModelChart";
import PieChartArticlesRead from "../components/PieChartArticlesRead";
import { format } from "date-fns-tz";
import api from "../../../../services/api";
import IconSvg from "../../../login/components/IconSvg";
import ChartAverageReadTime from "../components/ChartAverageReadTime";
import ChartClickOnRead from "../components/ChartClickOnRead";
import ChartCompleteness from "../components/ChartCompleteness";
import ChartNumberOfRatings from "../components/ChartNumberOfRatings";
import ChartOverall from "../components/ChartOverall";
import ChartQualityOfWriting from "../components/ChartQualityOfWriting";
import ChartTrustworthiness from "../components/ChartTrustworthiness";
import ChartViewsHomepage from "../components/ChartViewsHomepage";
import {
  ButtonRatingExcel,
  ButtonsExcelContainer,
  ButtonUserExcel,
  Container,
  FullChart,
  GridContainer,
  GridItem,
  WrapperRight,
} from "./styles";
import ChartRegion from "../components/ChartRegion";
import PieAge from "../components/PieAge";
import PiePolitical from "../components/PiePolitical";
import ptBR from "date-fns/locale/pt-BR";

import { ExportToExcelUnique } from "../components/ExportToExcelUnique";

interface ExcelApiProps {
  average_reading_time: null | {
    seconds: number;
    miliseconds: number;
  };
  born_at: string;
  email: string;
  first_name: string;
  id: string;
  last_name: string;
  political_economic: string;
  political_position: string;
  political_social: string;
  political_vote: string;
  region: string;
  total_news_read: string;
  total_news_read_aljazeera: string;
  total_news_read_bbc: string;
  total_news_read_haaretz: string;
  total_news_read_independent: string;
  total_news_read_sixthtone: string;
  total_news_read_sky: string;
  total_news_read_the_guardian: string;
  total_news_read_the_hindu: string;
  total_news_read_washington_post: string;
  total_number_of_rating: string;
  total_viewing: string;
}

interface ExcelApiRatingsProps {
  id: string;
  trustworthiness: string;
  weight_trustworthiness: string;
  quality_writing: string;
  weight_quality_writing: string;
  completeness: string;
  weight_completeness: string;
  political_position: string;
  weight_political_position: string;
  name_user: string;
  title_news: string;
  newspaper_news: string;
}

const DashScreen: React.FC = () => {
  const [dataExcelApi, setDataExcelApi] = useState<ExcelApiProps[]>([]);
  const [dataAvailable, setDataAvailable] = useState({
    userData: false,
    ratingsData: false,
  });
  const [dataExcelApiRatings, setDataExcelApiRatings] = useState<
    ExcelApiRatingsProps[]
  >([]);

  // taking the data from the api and
  // signaling if they are ready to
  // activate the excel download buttons
  // of user values

  useEffect(() => {
    const autoLoad = async () => {
      const responseApi = await api.get("generate-excel/user");

      setDataExcelApi(responseApi.data);
      setDataAvailable((state) => {
        return {
          ...state,
          userData: true,
        };
      });
    };

    autoLoad();
  }, []);

  // taking the data from the api and
  // signaling if they are ready to
  // activate the excel download buttons
  // of rating values

  useEffect(() => {
    const autoLoad = async () => {
      const responseApi = await api.get("generate-excel/rating");
      setDataExcelApiRatings(responseApi.data);
      setDataAvailable((state) => {
        return {
          ...state,
          ratingsData: true,
        };
      });
    };

    autoLoad();
  }, []);

  // building excel file headers
  const headers = [
    { label: "first_name", key: "first_name" },
    { label: "last_name", key: "last_name" },
    { label: "email", key: "email" },
    { label: "political_position", key: "political_position" },
    { label: "political_vote", key: "political_vote" },
    { label: "political_economic", key: "political_economic" },
    { label: "political_social", key: "political_social" },
    { label: "born_at", key: "born_at" },
    { label: "region", key: "region" },
    { label: "total_news_read", key: "total_news_read" },
    { label: "total_news_read_aljazeera", key: "total_news_read_aljazeera" },
    { label: "total_news_read_bbc", key: "total_news_read_bbc" },
    { label: "total_news_read_haaretz", key: "total_news_read_haaretz" },
    {
      label: "total_news_read_independent",
      key: "total_news_read_independent",
    },
    {
      label: "total_news_read_sixthtone",
      key: "total_news_read_sixthtone",
    },
    {
      label: "total_news_read_sky",
      key: "total_news_read_sky",
    },
    {
      label: "total_news_read_the_guardian",
      key: "total_news_read_the_guardian",
    },
    {
      label: "total_news_read_the_hindu",
      key: "total_news_read_the_hindu",
    },
    {
      label: "total_news_read_washington_post",
      key: "total_news_read_washington_post",
    },
    {
      label: "average_reading_time",
      key: "average_reading_time",
    },
    {
      label: "total_viewing",
      key: "total_viewing",
    },
    {
      label: "total_number_of_rating",
      key: "total_number_of_rating",
    },
  ];

  //building the body of the respective
  // header fields to then generate
  // columns proportional to the header
  // for this new object with fields formatted
  const data = dataExcelApi.map((object) => {
    return {
      // id: object.id,
      first_name: object.first_name,
      last_name: object.last_name,
      email: object.email,
      political_position:
        object.political_position &&
        Number(object.political_position).toFixed(2),
      political_vote: object.political_vote,
      political_economic: object.political_economic,
      political_social: object.political_social,
      born_at:
        object?.born_at &&
        format(Date.parse(object?.born_at), "dd/MM/yyyy", {
          locale: ptBR,
          timeZone: "America/Sao_Paulo",
        }),
      region: object?.region?.replace("#@@#", "-"),
      total_news_read: object.total_news_read,
      total_news_read_aljazeera: object.total_news_read_aljazeera,
      total_news_read_bbc: object.total_news_read_bbc,
      total_news_read_haaretz: object.total_news_read_haaretz,
      total_news_read_independent: object.total_news_read_independent,
      total_news_read_sixthtone: object.total_news_read_sixthtone,
      total_news_read_sky: object.total_news_read_sky,
      total_news_read_the_guardian: object.total_news_read_the_guardian,
      total_news_read_the_hindu: object.total_news_read_the_hindu,
      total_news_read_washington_post: object.total_news_read_washington_post,
      // average_reading_time: 0,
      total_viewing: object.total_viewing,
      total_number_of_rating: object.total_number_of_rating,
    };
  });

  // below are tabs for generate
  // excel of ratings
  const headersRating = [
    { label: "name_user", key: "name_user" },
    { label: "newspaper_news", key: "newspaper_news" },
    { label: "title_news", key: "title_news" },
    { label: "political_position", key: "political_position" },
    { label: "quality_writing", key: "quality_writing" },
    { label: "completeness", key: "completeness" },
    { label: "trustworthiness", key: "trustworthiness" },
    { label: "weight_completeness", key: "weight_completeness" },
    { label: "weight_political_position", key: "weight_political_position" },
    { label: "weight_quality_writing", key: "weight_quality_writing" },
    { label: "weight_trustworthiness", key: "weight_trustworthiness" },
  ];

  //profit

  const dataRating = dataExcelApiRatings.map((column) => {
    return {
      // id: column.id,
      name_user: column.name_user,
      newspaper_news: column.newspaper_news,
      title_news: column.title_news,
      political_position: column.political_position,
      quality_writing: column.quality_writing,
      completeness: column.completeness,
      trustworthiness: column.trustworthiness,
      weight_completeness: column.weight_completeness,
      weight_political_position: column.weight_political_position,
      weight_quality_writing: column.weight_quality_writing,
      weight_trustworthiness: column.weight_trustworthiness,
    };
  });

  return (
    <div
      style={{
        display: "flex",
        width: "80vw",
        height: "auto",
        flexDirection: "column",
      }}
    >
      <ButtonsExcelContainer>
        <WrapperRight>
          <ExportToExcelUnique nameFile="ExcelUser" dataFilters={data}>
            <ButtonRatingExcel disabled={dataAvailable.userData ? false : true}>
              Excel user
              <IconSvg
                image={arrowDownload}
                width={"13.13px"}
                height={"15px"}
              />
            </ButtonRatingExcel>
          </ExportToExcelUnique>
          <ExportToExcelUnique nameFile="ExcelRating" dataFilters={dataRating}>
            <ButtonUserExcel
              disabled={dataAvailable.ratingsData ? false : true}
            >
              Excel rating
              <IconSvg
                image={arrowDownload}
                width={"13.13px"}
                height={"15px"}
              />
            </ButtonUserExcel>
          </ExportToExcelUnique>
        </WrapperRight>
      </ButtonsExcelContainer>
      <Container>
        <GridContainer>
          <GridItem>
            <ModelChart title="Total articles read"></ModelChart>
          </GridItem>
          <GridItem>
            <PieChartArticlesRead title="Average number of articles read per user" />
          </GridItem>
          <GridItem style={{ height: "500px" }}>
            <ChartArticlesRead title="Average number of articles read per user"></ChartArticlesRead>
          </GridItem>
          <GridItem>
            <ChartTotalPoliticalPosition title="Political position of articles"></ChartTotalPoliticalPosition>
          </GridItem>
          <GridItem style={{ height: "500px" }}>
            <ChartOverall title="Rating: Overall"></ChartOverall>
          </GridItem>
          <GridItem>
            <ChartCompleteness title="Rating: Completeness"></ChartCompleteness>
          </GridItem>
          <GridItem style={{ height: "500px" }}>
            <ChartTrustworthiness title="Rating: Trustworthiness"></ChartTrustworthiness>
          </GridItem>
          <GridItem>
            <ChartQualityOfWriting title="Rating: Quality of writing"></ChartQualityOfWriting>
          </GridItem>
          <GridItem>
            <PieAge title="Age" />
          </GridItem>
          <GridItem>
            <PiePolitical title="Political positioning of users" />
          </GridItem>
          <GridItem style={{ height: "500px" }}>
            <ChartAverageReadTime title="Average Read time"></ChartAverageReadTime>
          </GridItem>
          <GridItem>
            <ChartClickOnRead title="Clicks on 'read' "></ChartClickOnRead>
          </GridItem>
          <GridItem style={{ height: "500px" }}>
            <ChartViewsHomepage title="Article views on homepage"></ChartViewsHomepage>
          </GridItem>
          <GridItem>
            <ChartNumberOfRatings title="Number of ratings"></ChartNumberOfRatings>
          </GridItem>
          <GridItem maxWidth="1100px" className="last-grid">
            <FullChart className="last-child">
              <ChartRegion title="Region" />
            </FullChart>
          </GridItem>
        </GridContainer>
      </Container>
    </div>
  );
};

export default DashScreen;

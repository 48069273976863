import styled from "styled-components";

interface BoxProps {
  marginTop?: string;
}

interface ContainerImageProps {
  srcImg?: string;
  previousImg?: string;
}

export const ContainerFull = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  padding: 2rem;
  background: ${({ theme }) => theme.colors.gray200};
  overflow-y: auto;
  flex-direction: column;
`;

export const FlexFull = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  /* -webkit-box-shadow: -2px -1px 11px -2px rgba(0, 0, 0, 0.08);
  box-shadow: -2px -1px 11px -2px rgba(0, 0, 0, 0.08); */
`;

export const NewsInfoMain = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  padding: 0 0rem;
  border-radius: 15px;
  flex-direction: column;
  -webkit-box-shadow: -2px -1px 11px -2px rgba(0, 0, 0, 0.08);
  box-shadow: -2px -1px 11px -2px rgba(0, 0, 0, 0.08);
`;

export const WrapperHeading = styled.div`
  display: flex;
  width: 100%;
  min-height: 4.2rem;
  background: ${({ theme }) => theme.colors.gray100};
  /* border: 0.1px solid ${({ theme }) => theme.colors.gray200}; */
  align-items: center;
  padding: 0 1rem;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  /* -webkit-box-shadow: -2px -1px 11px -2px rgba(0, 0, 0, 0.08);
  box-shadow: -2px -1px 11px -2px rgba(0, 0, 0, 0.08); */
  border: 1px solid #dadada;
  /* border: 1px solid ${({ theme }) => theme.colors.gray200}; */
`;

export const Heading = styled.p`
  font-size: 1.5rem;
  font-weight: bold;
  font-size: "Open Sans", sans-serif;
  margin: 0.5rem 0;

  @media (max-width: 1366px) {
    font-size: 1.4rem;
  }
`;

export const ControlMain = styled.form`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.white};
  border-end-start-radius: 5px;
  border-end-end-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  border: 1px solid #dadada;
  border-top: none;
  background-color: white;
`;

export const HStack = styled.div`
  display: flex;

  .input-mask {
    width: 100%;
    height: 45px;
    background: ${({ theme }) => theme.colors.white};
    border: 1px solid #9d9d9d;
    border-radius: 5px;
    padding-left: 1rem;
    color: black;
    padding: 0 1rem;
    font-size: 1.25rem;
    &::placeholder {
      font-size: 1.25rem;
      color: ${({ theme }) => theme.colors.gray300};
    }

    @media (max-width: 1366px) {
      height: 42px;

      &::placeholder {
        font-size: 1.2rem;
        color: ${({ theme }) => theme.colors.gray300};
      }
    }
  }
`;

export const Box = styled.div<BoxProps>`
  width: 50%;
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : "4rem")};
  height: 45px;
  position: relative;
  padding: 0 1rem;

  .image-position {
    position: absolute;
    right: 20px;
    top: 20px;
  }

  @media (max-width: 1366px) {
    height: 42px;

    .image-position {
      top: 18px;
    }
  }
`;

export const BoxFullwidth = styled.div`
  margin-top: 1rem;
  height: 45px;
  width: 100%;
  padding: 0 1rem;
`;

export const HorizontalFlex = styled.div`
  width: 100%;
  height: 260px;
  display: flex;
  margin-top: 4rem;
  align-items: center;
  padding: 1rem 2rem;
  justify-content: flex-start;
`;

export const ContainerImage = styled.div<ContainerImageProps>`
  width: 352px;
  height: 256px;
  background: ${({ theme }) => theme.colors.gray200};
  background-image: ${({ srcImg, previousImg }) =>
    previousImg ? `url('${previousImg}')` : `url('${srcImg}')`};
  background-size: cover;
  background-repeat: no-repeat;
`;

export const ButtonChooseImage = styled.button`
  background: ${({ theme }) => theme.colors.honey};
  width: 185px;
  height: 47px;
  color: black;
  margin-left: 4rem;
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  border-radius: 5px;
  transition: all 120ms linear;

  @media (max-width: 1366px) {
    width: 170px;
    height: 42px;
    font-size: 1.3rem;
  }
`;

export const NewsCounterMain = styled.div`
  display: flex;
  width: 100%;
  border-radius: 5px;
  margin-top: 2rem;
  height: auto;
  background: white;
  flex-direction: column;
`;

export const NewsRelatedMain = styled.div`
  display: flex;
  width: 100%;
  border-radius: 5px;
  margin-top: 0rem;
  height: auto;
  background: white;
  flex-direction: column;
`;

export const FlexButtons = styled.div`
  display: flex;
  width: 100%;
  margin-top: 2rem;
  justify-content: flex-end;
`;

export const ButtonSave = styled.button`
  width: 112px;
  height: 47px;
  color: black;
  background: ${({ theme }) => theme.colors.honey};
  border-radius: 5px;
  font-weight: bold;
  transition: all 120ms linear;

  @media (max-width: 1366px) {
    width: 100px;
    height: 42px;
    font-size: 1.4rem;
  }
`;

export const ButtonDelete = styled.button`
  width: 112px;
  height: 47px;
  color: black;
  margin-left: 2rem;
  background: #b1b1b1;
  border-radius: 5px;
  font-weight: bold;
  transition: all 120ms linear;

  :hover {
    background-color: red;
    -webkit-box-shadow: -2px -1px 11px -2px rgba(0, 0, 0, 0.08);
    box-shadow: -2px -1px 11px -2px rgba(0, 0, 0, 0.08);
    opacity: 0.6;
    color: white;
  }

  @media (max-width: 1366px) {
    width: 100px;
    height: 42px;
    font-size: 1.4rem;
  }
`;

export const WrapperForm = styled.div`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.white};
  padding: 1.5rem;
  border: 1px solid #d4d4d4;
  border-top: none;
`;

export const Table = styled.table`
  width: 100%;
  padding: 0 1rem;
  border: none;
`;

export const TableRow = styled.tr`
  text-align: left;
  width: 100%;
  height: 40px;

  border-bottom: 1px solid ${({ theme }) => theme.colors.gray200};
`;

export const TableHead = styled.th`
  font-size: 1.5rem;
  font-weight: bold;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray200};
`;

export const TableData = styled.td`
  height: 40px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray200};

  :first-child {
    border-bottom: none;
  }

  :last-child {
    border-bottom: none;
  }
`;

export const Text = styled.p`
  font-size: 1.3rem;
  max-width: 500px;
  font-weight: bold;
  text-overflow: ellipsis;
  width: 100%;
  padding: 0rem 0rem;
  font-size: "Open Sans", sans-serif;
  display: flex;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: normal;
`;

export const Select = styled.select`
  width: 100%;
  height: 100%;
  border: 1px solid #9d9d9d;
  color: gray;
  border-radius: 5px;
  font-size: 14px;
  padding: 0 1rem;

  option {
    color: black;
    background: white;
    display: flex;
    white-space: pre;
    min-height: 20px;
    padding: 0px 2px 1px;
  }

  @media (max-width: 1366px) {
    font-size: 1.3rem;
  }
`;

import React, { useState } from "react";
import logoIcon from "./assets/logo-dash.svg";
import MoonLoader from "react-spinners/MoonLoader";
import { css } from "@emotion/react";
import { Container, Text } from "./styles";

interface LoadingProps {
  loading?: boolean;
}

const LoadingScreen: React.FC<LoadingProps> = ({ loading }) => {
  const [color, setColor] = useState("#FFC600");

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;
  return (
    <Container>
      <MoonLoader
        color={color}
        loading={loading}
        css={override}
        size={65}
        speedMultiplier={1.2}
      />
    </Container>
  );
};

export default LoadingScreen;

import styled from "styled-components";

export const WrapperInput = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 1rem;

  .text-span {
    font-size: 1.4rem;
    font-weight: 400;
    margin-top: 1rem;
  }

  .input-base {
    height: 40px;
    margin-top: 1rem;
  }
`;

export const Heading = styled.span`
  color: #333333;
  font-size: 1.4rem;
  font-weight: bold;
`;

import React from "react";
import * as Dialog from "@radix-ui/react-dialog";
import {
  Content,
  Overlay,
  CloseButton,
  HorizontalStack,
  CloseButtonOut,
  Title,
} from "./styles";
import { X } from "phosphor-react";
import { useNavigate } from "react-router-dom";

export type IRedirectModalProps = {
  open: boolean;
  onClose: () => void;
};

const RedirectModal: React.FC<IRedirectModalProps> = ({ open, onClose }) => {
  const navigate = useNavigate();

  return (
    <Dialog.Root open={open}>
      <Dialog.Trigger />
      <Dialog.Portal>
        <Overlay />
        <Content>
          <Title>Import news</Title>
          <CloseButton>
            <X size={24} />
          </CloseButton>
          <span className="text">
            How would you like to import new articles?
          </span>
          <HorizontalStack>
            <button
              className="manually"
              onClick={() => {
                onClose();
                navigate("/news/import/manually");
              }}
            >
              Manually
            </button>
            <CloseButtonOut>
              <button
                className="file"
                onClick={() => {
                  onClose();
                  navigate("/news/import/csv");
                }}
              >
                From file
              </button>
            </CloseButtonOut>
          </HorizontalStack>
        </Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export { RedirectModal };

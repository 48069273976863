/*
here contains the functions that send a new object to
the beehive api with the intention of creating a new article
We also uploaded another image request for the article.
we also compress the image because endpoint have specific limit
*/

import React, { useCallback, useState } from "react";
import api from "../../../../../../../services/api";
import ImageUploading from "react-images-uploading";
import InputDefault from "../../../../PanelControl/components/InputDefault";
import InputMask from "react-input-mask";
import {
  ControlMain,
  Heading,
  WrapperHeading,
  Text,
} from "../EditNewsMain/styles";

import { GrayBox } from "../../../../PanelControl/components/NotificationArea/styles";
import { LoadingContainer } from "../../../../FeedbackEditScreen/styles";
import { LoadingSpinner } from "../../../../../../../shared/components/LoadingSpinner";
import { toast } from "react-toastify";
import Compress from "react-image-file-resizer";

//set up styles
import {
  WhiteContainer,
  ContainerFull,
  FlexFull,
  HorizontalStack,
  Box,
  HorizontalFull,
  WrapperFull,
  ContainerImage,
  FlexContainer,
  ButtonChooseImage,
  ButtonSave,
  WrapperButton,
  Select,
} from "./styles";

import iconArrow from "../../../../../assets/option-arrow.svg";
import { useNavigate } from "react-router-dom";

// set up interfaces
interface DataApiObject {
  newspaper: string;
  category: string;
  date: string;
  title: string;
  description: string;
  link: string;
  key_words: string;
}

interface ImageListType {
  dataURL?: string;
  file?: File;
  [key: string]: any;
}

const AddNewsMain: React.FC = () => {
  // set up the main object to generate new article
  //and set up all states
  const [dataApi, setDataApi] = useState<DataApiObject>({
    newspaper: "",
    category: "",
    date: "",
    title: "",
    description: "",
    link: "",
    key_words: "",
  });
  const [images, setImages] = React.useState<ImageListType[]>([]);
  const [fileImg, setFileImg] = useState<any>(null);
  const [idNewsCreated, setIdNewsCreated] = useState("");
  const [previousImg, setPreviousImg] = useState("");
  const [idNews, setIdNews] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  // this number is relate to react uploading images
  // which number max of images for uploading variable
  const maxNumber = 69;

  //onchange for upload images:
  //a callback that detects and processes
  // the image that the user wants to change from the article

  /*Warning: the backend has a size limit, so we need to
  compress the image to ensure that up to a certain size,
  I can reduce it enough for the api to accept the file */
  const onChange = (
    imageList: ImageListType[],
    addUpdateIndex: number[] | undefined
  ) => {
    imageList.forEach(async (imageObject: ImageListType) => {
      //add image url to one state
      //this state has the function of showing the image preview
      setPreviousImg(imageObject.dataURL);
      // now let's try to reduce this image
      Compress.imageFileResizer(
        imageList[0].file, // the file from input
        680, // width
        680, // height
        "WEBP", // compress format WEBP, JPEG, PNG
        70, // quality
        0, // rotation
        (uri: any) => {
          const fileCompressed = new File(
            [uri],
            `${String(Math.random())}.webp`
          );
          setFileImg(fileCompressed);
        },
        "blob" // blob or base64 default base64
      );
    });
    //update the state with images
    setImages(imageList as ImageListType[]);
  };

  // we need to work with a new request so that
  // with the id of the news created by the endpoint,
  // we add the image in this function below
  //parameter is id only
  const loadImage = useCallback(
    async (id?: string) => {
      try {
        let formData = new FormData();
        formData.append("image", fileImg);
        const uploadImage = await api.patch(
          `news-dashboard/upload-image/${id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        console.log("deu bom file patch image", uploadImage.status);
      } catch (err) {
        console.log(err, "deu ruim file patch image");
        toast.error("Bad request! Image too large!");
      }
    },
    [dataApi, fileImg]
  );

  /*
    here we will create the news first to generate an id
    send the object 
    and after request to create article we use the function
    to load image when we have the id in our hands
  */
  const handleClickSaveAddNews = useCallback(
    async (data: any) => {
      let id: string | null = null;
      try {
        setLoading((state) => !state);
        const responseAddNews = await api.post("news-dashboard", data);
        id = responseAddNews.data.id;
        setIdNews((state) => (state = id));
        await loadImage(id);
        setLoading((state) => !state);
        toast.success("News successfully added");
        navigate("/news/list/");
      } catch (err) {
        console.log(err, "Error when create news");
        toast.error("Bad request! Image too large!");
        setLoading((state) => !state);
      }
    },
    [dataApi, idNews, fileImg, loading]
  );

  //handler event of select for choose newspaper
  const handleChangeOptionNewspaper = useCallback(
    (event: any) => {
      setDataApi({
        ...dataApi,
        newspaper: event.target.value,
      });
    },

    [dataApi]
  );

  //handler event of select for choose category/topic
  const handleChangeOptionCategory = useCallback(
    (event: any) => {
      setDataApi({
        ...dataApi,
        category: event.target.value,
      });
    },

    [dataApi]
  );

  //handler event of select for choose title of article
  const handleChangeTitle = useCallback(
    (event) => {
      setDataApi({
        ...dataApi,
        title: event.target.value,
      });
    },
    [dataApi]
  );

  //handler event of select for choose description of article
  const handleChangeDescription = useCallback(
    (event) => {
      setDataApi({
        ...dataApi,
        description: event.target.value,
      });
    },
    [dataApi]
  );

  //handler event of select for choose date of article
  const handleChangeDate = useCallback(
    (event) => {
      setDataApi({
        ...dataApi,
        date: event.target.value,
      });
    },
    [dataApi]
  );

  //handler event of select for choose link of article
  const handleChangeLink = useCallback(
    (event) => {
      setDataApi({
        ...dataApi,
        link: event.target.value,
      });
    },
    [dataApi]
  );

  //handler event of select for choose keywords of article
  const handleChangeKeywords = useCallback(
    (event) => {
      setDataApi({
        ...dataApi,
        key_words: event.target.value,
      });
    },
    [dataApi]
  );

  return (
    <ContainerFull>
      <FlexFull>
        <WhiteContainer>
          <WrapperHeading>
            <Heading>Add articles</Heading>
          </WrapperHeading>
          <ControlMain>
            <GrayBox>
              <HorizontalStack>
                <Box marginTop="1rem">
                  <Text style={{ fontWeight: "bold" }}>Newspaper</Text>
                  <Select
                    style={{ paddingLeft: "1rem" }}
                    value={dataApi.newspaper}
                    onChange={handleChangeOptionNewspaper}
                  >
                    <option value="">Newspaper</option>
                    <option value="bbc">BBC</option>
                    <option value="independent">Independent</option>
                    <option value="aljazeera">Aljazeera</option>
                    <option value="theGuardian">The Guardian</option>
                    <option value="sixthtone">Sixth Tone</option>
                    <option value="haaretz">Haaretz</option>
                    <option value="sky">Sky News</option>
                    <option value="theHindu">The Hindu</option>
                    <option value="whashingtonpost">Washington Post</option>
                    <option value="Santiago Times">Santiago Times</option>
                  </Select>

                  <img src={iconArrow} className="image-position" />
                </Box>
                <Box marginTop="1rem">
                  <Text style={{ fontWeight: "bold" }}>Category/Topics</Text>
                  <Select
                    value={dataApi.category}
                    style={{ paddingLeft: "1rem" }}
                    onChange={handleChangeOptionCategory}
                  >
                    <option value={""} hidden>
                      Category/Topics
                    </option>
                    <option value="globalAffairs">Global Affairs</option>
                    <option value="business">Business</option>
                    <option value="opinion">Opinion</option>
                    <option value="sport">Sport</option>
                    <option value="entertainment">Entertainment</option>
                    <option value="wellness">Wellness</option>
                    <option value="politics">Politics</option>
                    <option value="science">Science</option>
                    <option value="tech">Tech</option>
                    <option value="UkAffairs">UK Affairs</option>
                  </Select>
                  <img src={iconArrow} className="image-position" />
                </Box>
              </HorizontalStack>
              <HorizontalFull>
                <WrapperFull>
                  <Text style={{ fontWeight: "bold" }}>Title</Text>
                  <InputDefault
                    type="text"
                    placeholder="Title"
                    onChange={handleChangeTitle}
                  />
                </WrapperFull>
              </HorizontalFull>

              <HorizontalFull>
                <WrapperFull>
                  <Text style={{ fontWeight: "bold" }}>Description</Text>
                  <InputDefault
                    type="text"
                    placeholder="Description"
                    onChange={handleChangeDescription}
                  />
                </WrapperFull>
              </HorizontalFull>
              <HorizontalStack>
                <Box marginTop="1rem">
                  <Text style={{ fontWeight: "bold" }}>Date (UTC)</Text>
                  <InputMask
                    type="text"
                    placeholder="Date (e.g. 2022-05-12 16:00)"
                    onChange={handleChangeDate}
                    mask={"9999/99/99 99:99"}
                    style={{}}
                    className="input-mask"
                  ></InputMask>
                </Box>
                <Box marginTop="1rem">
                  <Text style={{ fontWeight: "bold" }}>Link</Text>
                  <InputDefault
                    type="text"
                    placeholder="Link"
                    onChange={handleChangeLink}
                  />
                </Box>
              </HorizontalStack>
              <HorizontalFull>
                <WrapperFull>
                  <Text style={{ fontWeight: "bold" }}>Keywords</Text>
                  <InputDefault
                    type="text"
                    placeholder="Keywords"
                    onChange={handleChangeKeywords}
                  />
                </WrapperFull>
              </HorizontalFull>

              <FlexContainer>
                <ContainerImage src={previousImg} />
                <ImageUploading
                  multiple={false}
                  value={images}
                  onChange={onChange}
                  maxNumber={maxNumber}
                >
                  {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps,
                  }) => (
                    // write your building UI
                    <div className="upload__image-wrapper">
                      <ButtonChooseImage
                        type="button"
                        style={isDragging ? { color: "purple" } : undefined}
                        onClick={onImageUpload}
                        className="button-excel"
                        {...dragProps}
                      >
                        Choose photo
                      </ButtonChooseImage>
                    </div>
                  )}
                </ImageUploading>
              </FlexContainer>
            </GrayBox>
          </ControlMain>
        </WhiteContainer>
      </FlexFull>

      <WrapperButton>
        <ButtonSave
          type="button"
          className="button-excel"
          onClick={() => {
            handleClickSaveAddNews(dataApi);
          }}
        >
          {loading ? (
            <LoadingContainer>
              <LoadingSpinner />
            </LoadingContainer>
          ) : (
            "Save"
          )}
        </ButtonSave>
      </WrapperButton>
    </ContainerFull>
  );
};

export default AddNewsMain;

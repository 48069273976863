import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  background: #ebedef;
  width: 100%;
  height: auto;
  flex-direction: column;
  padding: 0 2rem;
  overflow-y: auto;

  .adjust-pagination-container {
    padding-right: 0rem;
    margin-top: 3.3rem;
  }

  @media (max-width: 1366px) {
    .adjust-pagination-container {
      margin-top: 4.2rem;
    }
  }
`;

export const ButtonBox = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1.6rem;
`;

// export const WrapperDate = styled(DatePicker)`
//   border-radius: 4px;
//   border: 1px solid ${colors("grey")};
// `;

export const Select = styled.select`
  width: 100%;
  height: 100%;
  max-height: 45px;
  margin: 0 0.5rem;
  border: 1px solid #9d9d9d;
  border-radius: 5px;
  padding: 0 1em;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
  font-size: 1.25rem;
  color: ${({ theme }) => theme.colors.gray300};
  font-weight: normal;
  -webkit-appearance: none;
  appearance: none;

  @media (max-width: 1366px) {
    height: 42px;
  }

  option {
    color: black;
    background: white;
    display: flex;
    white-space: pre;
    min-height: 20px;
    padding: 0px 2px 1px;
  }
`;

export const BoxDate = styled.div`
  width: 310px;
  height: 360px;
  position: absolute;
  background: white;
  left: 0;
  top: 80px;
  display: flex;
  border-radius: 5px;
  -webkit-box-shadow: 1px 1px 15px -1px rgba(0, 0, 0, 0.17);
  box-shadow: 1px 1px 15px -1px rgba(0, 0, 0, 0.17);
`;

export const Button = styled.button`
  width: 100%;
  max-width: 178px;
  height: 45px;
  background: ${({ theme }) => theme.colors.honey};
  font-size: 1.4rem;
  font-weight: 700;
  margin-top: 1rem;
  margin-left: auto;
  border-radius: 5px;
  transition: all 120ms linear;

  @media (max-width: 1366px) {
    font-size: 1.3rem;
    width: 160px;
  }
`;

export const Main = styled.div`
  width: 100%;
  height: auto;
  min-height: 700px;
  display: flex;
  background: white;
  display: flex;
  flex-direction: column;
  padding: 0 1.5rem 3rem 1.5rem;
  margin-bottom: 5rem;
  border-radius: 5px;
  border: 1px solid #dadada;

  .wrapper-date-from {
    display: flex;
    width: 100%;
    min-width: 160px;
    position: relative;
    height: 100%;
    align-items: center;

    @media (max-width: 1600px) {
      min-width: 115px;
    }
  }

  @media (max-width: 1366px) {
    padding: 0 1.5rem 4rem 1.5rem;
  }
`;

export const HeaderContent = styled.div`
  width: 100%;
  height: 20%;
  display: flex;
  align-items: center;
`;

export const FormBox = styled.div`
  width: 100%;
  min-height: 80%;
  background: ${({ theme }) => theme.colors.gray100};
  display: flex;
  flex-direction: column;
  padding: 0 2rem;
  padding-bottom: 1rem;
  margin-top: 1rem;
`;

export const PaginationContainer = styled.div`
  width: 100%;
  height: 8%;
  display: flex;
`;

export const InputsWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  margin: 2rem 0 1rem -0.2rem;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  width: 6%;
  height: 100%;
  align-items: center;
  justify-content: flex-end;
`;

export const ButtonCheck = styled.button`
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.honey};
  border-radius: 5px;
  margin-right: 0.5rem;
  transition: all 120 linear;
  margin-top: 1rem;

  @media (max-width: 1366px) {
    width: 42px;
    height: 42px;
  }
`;

export const StyledTable = styled.table`
  width: 100%;
  padding: 0 2rem;
`;

export const TableRow = styled.tr`
  display: flex;
  width: 100%;
  margin-top: 1rem;
  justify-content: space-between;
  height: 37px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray200};
  align-items: center;

  &:last-child {
    border-bottom: none;
  }
`;

export const THead = styled.th`
  display: flex;
  width: 100%;
  min-width: 100px;
  align-items: center;
  justify-content: space-between;
  height: 37px;
  font-size: 1.5rem;

  font-family: "Open Sans", sans-serif;

  @media (max-width: 1366px) {
    font-size: 1.4rem;
  }
`;

export const TableItem = styled.td`
  font-size: 1.4rem;
  min-width: 100px;
  max-width: 150px;
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  align-items: center;
  padding-bottom: 0.6rem;

  @media (max-width: 1366px) {
    font-size: 1.3rem;
  }
`;

export const ButtonViewFeedback = styled.button`
  background: transparent;
  cursor: pointer;
`;

import styled from "styled-components";

interface VerticalProps {
  PaddingTop?: string;
}

export const Flex = styled.div`
  width: 100%;
  height: 100%;
  background: #ebedef;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 5rem;
`;

export const Container = styled.div`
  width: 100%;
  min-height: 130px;
  display: flex;
  border: 1px solid ${({ theme }) => theme.colors.gray200};
  -webkit-box-shadow: 0px 0px 40px 1px rgba(0, 0, 0, 0.017);
  box-shadow: 0px 0px 40px 1px rgba(0, 0, 0, 0.03);
  flex-direction: column;
  border: 1px solid #d4d4d4;
  border-radius: 5px;
`;

export const Text = styled.span`
  font-size: 1.6rem;
  font-family: "Arial";
  font-weight: bold;
  margin-left: 1rem;

  @media (max-width: 1600px) {
    font-size: 1.5rem;
    margin-left: 1rem;
  }

  @media (max-width: 1366px) {
    font-size: 1.4rem;
    margin-left: 1rem;
  }
`;

export const Box = styled.div`
  display: flex;
  width: 100%;
  padding-left: 1rem;
  height: 51px;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray200};
  background: #f8f9fa;
  border-radius: 5px 5px 0 0;
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: 0px 0px 5px 5px;
  flex-direction: column;
  background: white;
  padding: 2rem 2rem;
  align-items: center;
  justify-content: center;

  .margin-top-input {
    margin-top: 0.4rem;
  }

  span {
    margin-bottom: 0.3rem;
  }
`;

export const Background = styled.div`
  width: 100%;
  height: 100%;
  background: #f9fafa;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0 2rem;
  padding-bottom: 0.8rem;
  border-radius: 5px;
`;

export const VerticalStack = styled.div<VerticalProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: ${({ PaddingTop }) => (PaddingTop ? PaddingTop : "2rem")};
`;

export const HorizontalStack = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
`;

export const Button = styled.button`
  width: 112px;
  height: 47px;
  background: ${({ theme }) => theme.colors.honey};
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 2rem;
  margin-left: auto;
  border-radius: 5px;
  transition: all 120ms linear;

  @media (max-width: 1366px) {
    width: 100;
    height: 42px;
    font-size: 1.3rem;
  }
`;

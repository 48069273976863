/*
*this file contains the chart as named by
"Number of ratings" chart and loading all data values,
also exporting to excel data values and user be filter it
*/
//import library chart js components
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import React, { useCallback, useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import InputDefault from "../../PanelControl/components/InputDefault";

import { format } from "date-fns-tz";
import api from "../../../../../services/api";
import { ExportToExcel } from "../ExportToExcel";
import DateFromNumberRatings from "./components/DateFrom/DateFromNumberRatings";
import DateToNumberRatings from "./components/DateTo/DateFromNumberRatings";

//icons
import IconSvg from "../../../../login/components/IconSvg";
import excelButtonIcon from "../../../assets/downloadf-icon-excel.svg";
import checkIcon from "../../../assets/icon-check.svg";
import iconArrow from "../../../assets/option-arrow.svg";

//set up styles
import {
  Button,
  Flex,
  FlexChart,
  FlexFilters,
  FlexHeader,
  Select,
  TextHead,
  TextInput,
  VerticalStackAge,
  VerticalStackButton,
  VerticalStackDate,
  VerticalStackPolitical,
  VerticalStackRegion,
  VerticalStackTopic,
} from "./styles";

//set up interfaces
interface QueryProps {
  initial_date: string | null;
  final_date: string | null;
  topic: string | null;
  initial_political_view: number | null;
  final_political_view: number | null;
  initial_age: number | null;
  final_age: number | null;
  region: string | null;
}

interface DataChartsProps {
  all: number | null;
  all_jazeera: number | null;
  bbc: number | null;
  haaretz: number | null;
  independent: number | null;
  sixth_tone: number | null;
  sky_news: number | null;
  the_guardian: number | null;
  the_hindu: number | null;
  whashington_post: number | null;
}

interface ModelChartProps {
  title: string;
}

// const newsPaper = [
//   { news: "all" },
//   { news: "BBC" },
//   { news: "Alljazeera" },
//   { news: "Haaretz" },
//   { news: "Independent" },
//   { news: "Sixth tone" },
//   { news: "Sky news" },
//   { news: "The Guardian" },
//   { news: "The hindu" },
//   { news: "whashington Post" },
// ];

// here are options for
// react chart js 2 by definition in docs:
// Options are resolved from top to bottom,
// using a context dependent route.
// read more on https://react-chartjs-2.js.org/components/
export const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "chartArea" as const,
      display: false,
      labels: {
        color: "red",
      },
    },
    title: {
      display: false,
      align: "start" as const,
      position: "top" as const,
      color: "white",
    },
  },
};

const ChartNumberOfRatings: React.FC<ModelChartProps> = ({ title }) => {
  //registers for react chart js 2
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  // here configuration to query params
  // for api beehive and filter this chart
  // for the following props below
  const [query, setQuery] = useState<QueryProps>({
    initial_date: null,
    final_date: null,
    topic: null,
    initial_political_view: null,
    final_political_view: null,
    initial_age: null,
    final_age: null,
    region: null,
  });

  //set up states
  const [dataChartArticles, setDataChartArticles] = useState<DataChartsProps>(
    {} as DataChartsProps
  );
  //state of react date component
  const [value, onChange] = useState(new Date());
  const [initialPolitical, setInitialPolitical] = useState<number>(0);
  const [finalPolitical, setFinalPolitical] = useState<number>(0);
  const [dateFrom, setDateFrom] = useState<string>("");
  const [dateTo, setDateTo] = useState<string>("");
  const [topics, setTopics] = useState<string>("");
  const [initialAge, setInitialAge] = useState<number>(0);
  const [finalAge, setFinalAge] = useState<number>(0);
  const [regionOption, setRegionOption] = useState("");
  const [areaOption, setAreaOption] = useState("");
  const [counterUsersApi, setCounterUsersApi] = useState(0);
  const [currentFilters, setCurrentFilters] = useState<QueryProps[]>([]);
  const [arrFilteredValues, setArrFilteredValues] = useState<DataChartsProps[]>(
    []
  );
  const [isDateFrom, setIsDateFrom] = useState(false);
  const [selectedDate, setSelectedDate] = React.useState<Date>();
  const [selected, setSelected] = React.useState<Date>();
  const [isDateTo, setIsDateTo] = useState(false);

  // RANGE DATE FINAL
  //below function will be close component of final date
  const closeDropdownDateTo = () => {
    setIsDateTo(false);
  };

  //below function will be toggle for show
  //date component about final date
  const toggleDateTo = useCallback(
    (e) => {
      e.preventDefault();
      setIsDateTo((prevState) => !prevState);
    },
    [isDateTo]
  );

  // RANGE DATE INITIAL
  //below function will be close dropdown initial date
  const closeDropdownDateFrom = () => {
    setIsDateFrom(false);
  };

  //below function will be toggle for show
  //date component about initial date
  const toggleDateFrom = useCallback(
    (e) => {
      e.preventDefault();
      setIsDateFrom((prevState) => !prevState);
    },
    [isDateFrom]
  );

  //this useEffect fetch data for the api beehive
  //and set up in our state main
  useEffect(() => {
    const autoLoad = async () => {
      try {
        const responseCharts = await api.get(
          "dashboard-graphics/number-votes-newspaper",
          {
            params: query,
          }
        );
        setDataChartArticles(responseCharts.data);
      } catch (err) {
        console.log(err, "erro?");
      }
    };

    autoLoad();
  }, [query]);

  // this useEffect leave it ready
  //the data for know which filters are selected
  useEffect(() => {
    setArrFilteredValues((state) => {
      let cloneState = [];
      cloneState.push(dataChartArticles);

      return cloneState;
    });
  }, [dataChartArticles]);

  useEffect(() => {
    //Array of query filters for export excel
    setCurrentFilters((state) => {
      let cloneState = [];
      cloneState.push({
        ...query,
        region: query?.region?.replace("#@@#", " - "),
      });

      return cloneState;
    });
  }, [query]);

  const handleTopicFilter = useCallback(
    (event) => {
      setTopics(event.target.value);
    },
    [topics]
  );

  const onChangeDateFrom = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setDateFrom(event.target.value);
    },
    [dateFrom]
  );

  const onChangeDateTo = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setDateTo(event.target.value);
    },
    [dateTo]
  );

  const onChangeInitialPolitical = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setInitialPolitical(Number(event.target.value));
    },
    [initialPolitical]
  );

  const handleChangeFinalPolitical = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setFinalPolitical(Number(event.target.value));
    },
    [finalPolitical]
  );

  const onChangeInitialAge = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setInitialAge(Number(event.target.value));
    },
    [initialAge]
  );

  const onChangeFinalAge = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setFinalAge(Number(event.target.value));
    },
    [finalAge]
  );

  const handleChangeRegion = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      setRegionOption(event.target.value);
    },
    [regionOption]
  );

  const changeAreaOption = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      setAreaOption(event.target.value);
    },
    [areaOption]
  );

  const handleButtonSubmitChart = useCallback(() => {
    setQuery((state: QueryProps) => {
      let cloneState = {
        ...state,
        topic: topics === "" ? null : topics,
        initial_date: dateFrom === "" ? null : dateFrom,
        final_date: dateTo === "" ? null : dateTo,
        initial_political_view:
          initialPolitical === 0 ? null : initialPolitical,
        final_political_view: finalPolitical === 0 ? null : finalPolitical,
        initial_age: initialAge === 0 ? null : initialAge,
        final_age: finalAge === 0 ? null : finalAge,
        region: !regionOption
          ? null
          : regionOption !== "" && areaOption === ""
          ? `${regionOption}#@@#all`
          : `${regionOption}#@@#${areaOption}`,
      };

      return cloneState;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    query,
    topics,
    dateFrom,
    dateTo,
    initialPolitical,
    finalPolitical,
    initialAge,
    finalAge,
    regionOption,
    areaOption,
  ]);

  //this useEffect for reset state of area
  //when region is null
  useEffect(() => {
    if (!regionOption) {
      setAreaOption("");
    }
  }, [regionOption]);

  //datas

  const data = {
    labels: [
      "Average",
      "BBC",
      "Alljazera",
      "Haaretz",
      "Independent",
      "Sixth Tone",
      "Sky News",
      "The Guardian",
      "The Hindu",
      "Washington Post",
    ],
    // datasets is an array of objects where each object represents a set of data to display corresponding to the labels above. for brevity, we'll keep it at one object
    datasets: [
      {
        label: "Average Position",
        data: [
          dataChartArticles.all,
          dataChartArticles.bbc,
          dataChartArticles.all_jazeera,
          dataChartArticles.haaretz,
          dataChartArticles.independent,
          dataChartArticles.sixth_tone,
          dataChartArticles.sky_news,
          dataChartArticles.the_guardian,
          dataChartArticles.the_hindu,
          dataChartArticles.whashington_post,
        ],
        // you can set indiviual colors for each bar
        backgroundColor: [
          "#333333",
          "#DC3545",
          "#0D6EFD",
          "#198754",
          "#D63384",
          "#6F42C1",
          "#FFC107",
          "#6610F2",
          "#FD7E14",
          "#0DCAF0",
        ],
        borderWidth: 1,
        // borderRadius: 40,
      },
    ],
  };

  /*
  I'm changing the way of displaying the data for UI,
  that is, just formatting this data to a format that talks to the api
  replacing "/"" to "-"" when necessary send data to api
  */
  useEffect(() => {
    if (selectedDate) {
      setDateFrom(format(selectedDate, "yyyy/MM/dd").replaceAll("/", "-"));
    }

    if (selected) {
      setDateTo(format(selected, "yyyy/MM/dd").replaceAll("/", "-"));
    }
  }, [selectedDate, selected]);

  return (
    <Flex>
      <FlexHeader>
        <TextHead> {title} </TextHead>
        <ExportToExcel
          nameFile="number-of-ratings"
          dataFilters={currentFilters}
          valuesFiltered={arrFilteredValues}
        >
          <IconSvg image={excelButtonIcon} width={"40"} height={"40"} />
        </ExportToExcel>
      </FlexHeader>
      <FlexChart>
        <Bar options={options} data={data} />
      </FlexChart>
      <FlexFilters>
        <VerticalStackDate>
          <TextInput>Date</TextInput>
          <div className="wrapper-date-from">
            <InputDefault
              placeholder="from"
              type="text"
              value={dateFrom}
              onChange={onChangeDateFrom}
              onClick={toggleDateFrom}
            />

            {isDateFrom && (
              <DateFromNumberRatings
                closeToggle={closeDropdownDateFrom}
                selected={selectedDate}
                setSelected={setSelectedDate}
              />
            )}
          </div>
          <div className="wrapper-date-from">
            <InputDefault
              placeholder="to"
              type="text"
              value={dateTo}
              onChange={onChangeDateTo}
              onClick={toggleDateTo}
            />

            {isDateTo && (
              <DateToNumberRatings
                closeToggle={closeDropdownDateTo}
                selected={selected}
                setSelected={setSelected}
              />
            )}
          </div>
        </VerticalStackDate>
        <VerticalStackTopic>
          <TextInput style={{ marginBottom: "1rem" }}>Topic</TextInput>
          <div className="arrow-wrapper">
            <Select
              value={topics}
              onChange={handleTopicFilter}
              style={{ height: "42px" }}
              className="force-margin-select"
            >
              <option value={""} hidden>
                {"Topic"}
              </option>
              <option value="Global Affairs">Global Affairs</option>
              <option value="Business">Business</option>
              <option value="Opinion">Opinion</option>
              <option value="Sport">Sport</option>
              <option value="Entertainment">Entertainment</option>
              <option value="Wellness">Wellness</option>
              <option value="Politics">Politics</option>
              <option value="Science">Science</option>
              <option value="Tech">Tech</option>
              <option value="UK Affairs">Uk Affairs</option>
            </Select>
            {topics === "" && (
              <img src={iconArrow} className="image-position" alt="" />
            )}
          </div>
        </VerticalStackTopic>
        <VerticalStackPolitical>
          <TextInput>Political...</TextInput>
          <InputDefault
            placeholder="from"
            type="number"
            value={initialPolitical}
            onChange={onChangeInitialPolitical}
          />
          <InputDefault
            placeholder="to"
            type="number"
            value={finalPolitical}
            onChange={handleChangeFinalPolitical}
          />
        </VerticalStackPolitical>
        <VerticalStackAge>
          <TextInput>Age</TextInput>
          <InputDefault
            placeholder="from"
            type="number"
            value={initialAge}
            onChange={onChangeInitialAge}
          />
          <InputDefault
            placeholder="to"
            type="number"
            value={finalAge}
            onChange={onChangeFinalAge}
          />
        </VerticalStackAge>
        <VerticalStackRegion>
          <TextInput>Region</TextInput>
          <div className="arrow-wrapper">
            <Select
              value={regionOption}
              onChange={handleChangeRegion}
              style={{ height: "45px" }}
            >
              <option value={""} hidden>
                {"Region"}
              </option>
              <option value="England - Greater London">Greater London</option>
              <option value="East of England">East of England</option>
              <option value="East Midlands">East Midlands</option>
              <option value="North East">North East</option>
              <option value="North West">North West</option>
              <option value="South East">South East</option>
              <option value="South West">South West</option>
              <option value="West Midlands">West Midlands</option>
              <option value="Yorkshire & The Humber">
                Yorkshire The Humber
              </option>
              <option value="Scotland">Scotland</option>
              <option value="Wales">Wales</option>
              <option value="North Ireland">Northern Ireland</option>
              <option value="Other">Other</option>
            </Select>
            {regionOption === "" && (
              <img src={iconArrow} className="image-position" />
            )}
          </div>

          <div className="arrow-wrapper">
            <Select
              value={areaOption}
              onChange={changeAreaOption}
              style={{ height: "45px" }}
            >
              <option value={""} hidden>
                {"Area"}
              </option>
              {regionOption === "" ? (
                // <option value="">Select region</option>
                <></>
              ) : regionOption === "England - Greater London" ? (
                <>
                  <option value="East Central (EC1, EC2, E1-E20)">
                    East Central (EC1, EC2, E1-E20)
                  </option>
                  <option value="East Other">East Other</option>
                  <option value="North Central (N1-N22)">
                    North Central (N1-N22)
                  </option>
                  <option value="North Other">North Other</option>
                  <option value="North West Central (NW1-NW11)">
                    North West Central (NW1-NW11)
                  </option>
                  <option value="North West Other">North West Other</option>
                  <option value="South East Central (SE1-SE28)">
                    {" "}
                    South East Central (SE1-SE28)
                  </option>
                  <option value="South East Other">South East Other</option>
                  <option value="South West Central (SW1-SW20)">
                    South West Central (SW1-SW20)
                  </option>
                  <option value="South West Other">South West Other</option>
                  <option value="West Central (WC1, WC2, W1-W14)">
                    {" "}
                    West Central (WC1, WC2, W1-W14)
                  </option>
                  <option value="West Other">West Other</option>
                </>
              ) : regionOption === "East of England" ? (
                <>
                  <option value="Cambridge area">Cambridge area</option>
                  <option value="Other">Other</option>
                </>
              ) : regionOption === "East Midlands" ? (
                <>
                  <option value="Leicester area">Leicester area</option>
                  <option value="Nottingham area">Nottingham area</option>
                  <option value="Other">Other</option>
                </>
              ) : regionOption === "North East" ? (
                <>
                  <option value="Newcastle / Tyneside area">
                    Newcastle / Tyneside area
                  </option>
                  <option value="Other">Other</option>
                </>
              ) : regionOption === "North West" ? (
                <>
                  <option value="Liverpool area">Liverpool area</option>
                  <option value="Manchester area">Manchester area</option>
                  <option value="Other">Other</option>
                </>
              ) : regionOption === "South East" ? (
                <>
                  <option value="Brighton area">Brighton area</option>
                  <option value="Oxford area">Oxford area</option>
                  <option value="Portsmouth / Southampton area">
                    Portsmouth / Southampton area
                  </option>
                  <option value="Milton Keynes area">Milton Keynes area</option>
                  <option value="Other">Other</option>
                </>
              ) : regionOption === "South West" ? (
                <>
                  <option value="Bournemouth /Poole area">
                    Bournemouth /Poole area
                  </option>
                  <option value="Bristol area">Bristol area</option>
                  <option value="Other">Other</option>
                </>
              ) : regionOption === "West Midlands" ? (
                <>
                  <option value="Birmingham area">Birmingham area</option>
                  <option value="Coventry area">Coventry area</option>
                  <option value="Warwick area">Warwick area</option>
                  <option value="Other">Other</option>
                </>
              ) : regionOption === "Yorkshire & The Humber" ? (
                <>
                  <option value="Leeds area">Leeds area</option>
                  <option value="Sheffield area">Sheffield area</option>
                  <option value="Bradford area">Bradford area</option>
                  <option value="Other">Other</option>
                </>
              ) : regionOption === "Scotland" ? (
                <>
                  <option value="Glasgow area">Glasgow area</option>
                  <option value="Edinburgh area">Edinburgh area</option>
                  <option value="Other">Other</option>
                </>
              ) : regionOption === "Wales" ? (
                <>
                  <option value="Cardiff area">Cardiff area</option>
                  <option value="Other">Other</option>
                </>
              ) : regionOption === "North Ireland" ? (
                <>
                  <option value="Cardiff area">Cardiff area</option>
                  <option value="Other">Other</option>
                </>
              ) : (
                regionOption === "Other" && (
                  <>
                    <option value="Other">Other</option>
                  </>
                )
              )}
            </Select>
            {areaOption === "" && (
              <img src={iconArrow} className="image-position" alt="" />
            )}
          </div>
        </VerticalStackRegion>
        <VerticalStackButton>
          <Button className="button-excel" onClick={handleButtonSubmitChart}>
            <img src={checkIcon} />
          </Button>
        </VerticalStackButton>
      </FlexFilters>
    </Flex>
  );
};

export default ChartNumberOfRatings;

/*
on this screen we will show more information about
the profile of the user clicked on
*/
import React, { useCallback, useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../../../../../hooks/auth";
import api from "../../../../../../../services/api";
import ButtonNavigator from "../../../../../components/ButtonNavigator";
import CollapseControlPanel from "../../../../../components/CollapseControlPanel";
import CollapseNews from "../../../../../components/CollapseNews";
import DotComponent from "../../../../../components/DotComponent";
import ViewMain from "../../../../UserScreen/screens/ViewMain";

//icons
import IconSvg from "../../../../../../login/components/IconSvg";
import feedbackIcon from "../../../../../assets/icon-dash-feedback.svg";
import subsIcon from "../../../../../assets/icon-dash-subs.svg";
import usersIcon from "../../../../../assets/icon-dash-users.svg";
import dashIcon from "../../../../../assets/icon-dash.svg";
import iconExit from "../../../../../assets/icon-exit.svg";
import logoBeehive from "../../../../../assets/logo-dash.svg";

//set up styles
import {
  AsideControls,
  Box,
  Header,
  MasterContainer,
  NavLeft,
  NavRight,
  TextHeader,
} from "../../../../../Master/styles";

//set up interfaces
interface DataUserViewProps {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  avatar: string;
  political_position: string;
  political_vote: string;
  political_economic_views: string;
  political_social_views: string;
  born_at: string;
  region: string;
  isAdmin: boolean;
  is_blocked: boolean;
  notification_token: string;
  which_social_login?: string;
  sign_up_first_time_with_social_media?: string;
  payment_id?: string;
  created_at: string;
  updated_at: string;
  avatar_url: string;
}

//buttons for show it to user on sidebar
const buttonsVector = [
  { name: "Feedback", icon: feedbackIcon, type: "Feedback" },
  { name: "Subscription", icon: subsIcon, type: "Subs" },
  { name: "Subscribers", icon: usersIcon, type: "Users" },
];
const ViewUserScreen: React.FC = () => {
  //set up states
  const navigator = useNavigate();
  const [indexMenu, setIndexMenu] = useState<any>(0);
  const [isLoading, setIsLoading] = useState(false);
  const [dataUserView, setDataUserView] = useState({} as DataUserViewProps);
  const [screen, setScreen] = useState<
    "Dashboard" | "Panel" | "Feedback" | "Subs" | "Users" | "IrrelevantWords"
  >("Dashboard");
  const { iduser } = useParams();
  const { signOut } = useAuth();

  /*
  force state here:
  since the user is navigating through the navigator,
  update my state by forcing it
  */
  const handleType = useCallback(
    (value: any, index: number) => {
      switch (value) {
        case "Dashboard":
          setScreen("Dashboard");
          break;
        case "Panel":
          setScreen("Panel");
          break;
        case "Feedback":
          setScreen("Feedback");
          break;
        case "Subs":
          setScreen("Subs");
          break;
        case "Users":
          setScreen("Users");
          break;

        case "IrrelevantWords":
          setScreen("IrrelevantWords");
          break;

        default:
          break;
      }

      setIndexMenu(index);
      active.visible = true;
    },
    [screen]
  );

  //searching the user in the api through the id by
  // the navigation params and updating the state
  // to list the information
  useEffect(() => {
    const autoLoadUserView = async () => {
      try {
        setIsLoading(true);
        const responseUserId = await api.get(`users/${iduser}`);
        setDataUserView(responseUserId?.data);
        setIsLoading(false);
      } catch (err) {
        console.log(err);
      }
    };

    autoLoadUserView();
  }, []);

  const [active, setActive] = useState({
    index: 0,
    visible: false,
  });

  const navigate = useNavigate();

  return (
    <MasterContainer>
      <AsideControls>
        <NavLink to={"/"}>
          <Box>
            <IconSvg
              className="image-testing"
              image={logoBeehive}
              width="208px"
              height="43px"
            />
          </Box>
        </NavLink>

        <NavLink
          className={({ isActive }) =>
            isActive ? "active-link" : "inactive-link"
          }
          to={`/dashboard`}
          end
        >
          <ButtonNavigator
            onClick={() => handleType("Dashboard", 0)}
            name={"Dashboard"}
            icon={dashIcon}
            index={0}
            indexMenu={indexMenu}
            isActive={active.visible}
          />
        </NavLink>

        <CollapseControlPanel />
        <CollapseNews />
        {buttonsVector.map((button, index) => (
          <NavLink
            className={({ isActive }) =>
              isActive ? "active-link" : "inactive-link"
            }
            to={`/${button.type.toLocaleLowerCase()}`}
            end
          >
            <ButtonNavigator
              key={button.name}
              onClick={() => handleType(button.type, index + 1)}
              name={button.name}
              icon={button.icon}
              index={index + 1}
              indexMenu={indexMenu}
              isActive={active.visible}
            />
          </NavLink>
        ))}
        <div
          className="hover-exit-button"
          style={{
            width: "100%",
            height: "60px",
            paddingLeft: "2rem",
          }}
        >
          <ButtonNavigator
            onClick={() => signOut()}
            name={"Exit"}
            icon={iconExit}
            indexMenu={indexMenu}
            isActive={active.visible}
          />
        </div>
      </AsideControls>
      <Header>
        <NavLeft>
          <TextHeader>
            {" "}
            <span
              className="blue-link"
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/dashboard")}
            >
              {" "}
              Home{" "}
            </span>{" "}
            /{" "}
          </TextHeader>
          <TextHeader style={{ marginLeft: "0.6rem" }} color="#666666">
            <span
              className="blue-link"
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/users")}
            >
              {" "}
              Subscribers{" "}
            </span>{" "}
            / <span className="blue-link"></span>Visualize
          </TextHeader>
        </NavLeft>
        <NavRight>
          <DotComponent />
        </NavRight>
      </Header>

      <ViewMain isLoading={isLoading} data={dataUserView} />
    </MasterContainer>
  );
};

export default ViewUserScreen;

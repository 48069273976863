import styled from "styled-components";
import { Avatar } from "@material-ui/core";

interface Props {
  alt?: string;
}

export const MasterContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-template-rows: 9vh 91vh;
  width: 100vw;
  height: 100vh;

  grid-template-areas:
    "aside header"
    "aside main";
`;

export const DotAvatar = styled.span`
  height: 50px;
  width: 50px;
  background: ${({ theme }) => theme.colors.honey};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 2rem;

  span {
    font-family: "Open Sans", sans-serif;
    font-size: 1.6rem;
    color: white;
    font-weight: normal;
    margin-top: 0.2rem;
  }
`;

export const AsideControls = styled.aside`
  grid-area: aside;
  background: ${({ theme }) => theme.colors.gray};
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  padding: 2rem 0;
  overflow-y: auto;
`;

export const MainContent = styled.div`
  grid-area: main;
  background: blue;
  overflow-y: scroll;
`;

export const Header = styled.div`
  grid-area: header;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 1.5rem;

  @media (max-width: 1366px) {
    padding-left: 1rem;
    padding-right: 1.3rem;
  }
`;

export const GridContainerTemplates = styled.div`
  height: 220vh;
  flex-wrap: wrap;
  overflow-y: auto;
  display: grid;
  background: #ebedef;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;

  ::-webkit-scrollbar {
  }
`;

export const Box = styled.div`
  width: 100%;
  margin-bottom: 4.8rem;
  display: flex;
  cursor: pointer;
`;

export const NavLeft = styled.div`
  display: flex;
  max-width: 400px;
  padding-left: 1.5rem;
  height: 100%;
  align-items: center;
`;

export const NavRight = styled.div`
  display: flex;
  max-width: 100px;
  height: 100%;
  align-items: center;
`;

export const TextHeader = styled.p<{ color?: string }>`
  font-family: "Open Sans", sans-serif;
  font-size: 1.4rem;
  color: ${({ color }) => (color ? color : "blue")};
  cursor: "pointer !important";

  .blue-link {
    color: blue;
    text-decoration: underline;
    font-style: italic;
    font-weight: bold;
  }

  @media (max-width: 1366px) {
    font-size: 1.3rem;
    margin-top: 0.12rem;
  }
`;

export const AvatarEditor = styled(Avatar)<Props>`
  width: 90px;
  height: 90px;
  margin-right: 1rem;
  background-color: ${({ theme }) => theme.colors.honey};
  background-color: ${({ theme }) => theme.colors.honey} !important;
`;

/*
 *this one needs to create, list and exclude words
 *that the user perceives to be irrelevant to the
 *backend search business rule when searching for news
 */

import React, { useCallback, useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import IconSvg from "../../../../../login/components/IconSvg";
import ButtonNavigator from "../../../../components/ButtonNavigator";
import CollapseNews from "../../../../components/CollapseNews";
import {
  AsideControls,
  Box,
  Header,
  MasterContainer,
  NavLeft,
  NavRight,
  TextHeader,
} from "../../../../Master/styles";

//logo
import logoBeehive from "../../../../assets/logo-dash.svg";

//icons ----------------------------------------------------- //
import dashIcon from "../../../../assets/icon-dash.svg";
import feedbackIcon from "../../../../assets/icon-dash-feedback.svg";
import subsIcon from "../../../../assets/icon-dash-subs.svg";
import usersIcon from "../../../../assets/icon-dash-users.svg";
import iconExit from "../../../../assets/icon-exit.svg";
import IrrelevantPage from "../../../components/IrrelevantPage";
import { useAuth } from "../../../../../../hooks/auth";
import CollapseControlPanel from "../../../../components/CollapseControlPanel";
import DotComponent from "../../../../components/DotComponent";
//icons ----------------------------------------------------- //

const buttonsVector = [
  { name: "Feedback", icon: feedbackIcon, type: "Feedback" },
  { name: "Subscription", icon: subsIcon, type: "Subs" },
  { name: "Subscribers", icon: usersIcon, type: "Users" },
];

const IrrelevantWords: React.FC = () => {
  const { signOut } = useAuth();
  const navigator = useNavigate();
  const [indexMenu, setIndexMenu] = useState<any>(0);
  const [screen, setScreen] = useState<
    "Dashboard" | "Panel" | "Feedback" | "Subs" | "Users" | "IrrelevantWords"
  >("Dashboard");

  const [active, setActive] = useState({
    index: 0,
    visible: false,
  });

  const navigate = useNavigate();

  useEffect(() => {
    setScreen("Subs");
  }, []);

  const handleType = useCallback(
    (value: any, index: number) => {
      switch (value) {
        case "Dashboard":
          setScreen("Dashboard");
          break;
        case "Panel":
          setScreen("Panel");
          break;
        case "Feedback":
          setScreen("Feedback");
          break;
        case "Subs":
          setScreen("Subs");
          break;
        case "Users":
          setScreen("Users");
          break;
        case "IrrelevantWords":
          setScreen("IrrelevantWords");
          break;

        default:
          break;
      }

      setIndexMenu(index);
      active.visible = true;
    },
    [screen]
  );

  return (
    <>
      <MasterContainer>
        <AsideControls>
          <NavLink to={"/"}>
            <Box>
              <IconSvg
                className="image-testing"
                image={logoBeehive}
                width="208px"
                height="43px"
              />
            </Box>
          </NavLink>

          <NavLink
            className={({ isActive }) =>
              isActive ? "active-link" : "inactive-link"
            }
            to={`/dashboard`}
            end
          >
            <ButtonNavigator
              onClick={() => handleType("Dashboard", 0)}
              name={"Dashboard"}
              icon={dashIcon}
              index={0}
              indexMenu={indexMenu}
              isActive={active.visible}
            />
          </NavLink>

          <CollapseControlPanel />
          <CollapseNews />
          {buttonsVector.map((button, index) => (
            <NavLink
              className={({ isActive }) =>
                isActive ? "active-link" : "inactive-link"
              }
              to={`/${button.type.toLocaleLowerCase()}`}
              end
            >
              <ButtonNavigator
                key={button.name}
                onClick={() => handleType(button.type, index + 1)}
                name={button.name}
                icon={button.icon}
                index={index + 1}
                indexMenu={indexMenu}
                isActive={active.visible}
              />
            </NavLink>
          ))}
          <div
            className="hover-exit-button"
            style={{
              width: "100%",
              height: "60px",
              paddingLeft: "2rem",
            }}
          >
            <ButtonNavigator
              onClick={() => signOut()}
              name={"Exit"}
              icon={iconExit}
              indexMenu={indexMenu}
              isActive={active.visible}
            />
          </div>
        </AsideControls>
        <Header>
          <NavLeft>
            <TextHeader
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/dashboard")}
            >
              <strong>
                <u>
                  <i>{`Home \n`}</i>
                </u>
              </strong>
              /
            </TextHeader>
            <TextHeader style={{ marginLeft: "0.6rem" }} color="#666666">
              {screen === "Panel"
                ? `Painel de Controle`
                : screen === "Subs"
                ? `Irrelevant words`
                : screen === "Users"
                ? `Usuários`
                : screen}
            </TextHeader>
          </NavLeft>
          <NavRight>
            <DotComponent />
          </NavRight>
        </Header>

        {/* here the main component for this page */}
        <IrrelevantPage />
      </MasterContainer>
    </>
  );
};

export default IrrelevantWords;

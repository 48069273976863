import styled from "styled-components";

export const TextAreaBase = styled.textarea`
  width: 100%;
  min-height: 45px;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid #9d9d9d;
  border-radius: 5px;
  padding: 1rem;
  color: black;
  font-size: 14px;
  resize: none;
  position: relative;

  &::placeholder {
    font-size: 14px;
    color: ${({ theme }) => theme.colors.gray300};
  }

  @media (max-width: 1366px) {
    min-height: 80px; /* Adjust the minimum height for smaller screens */
  }
`;

/*
 * this one manages the routes, being an authorized user or not
  only the administrator can view the information and features of
  the dashboard
 */

import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import { useAuth } from "../hooks/auth";
import { Authorization, ROLES } from "../hooks/useAuthorization";
import ChangeCreditCardScreen from "../modules/checkout/screens/ChangeCreditCardScreen";
import CheckoutScreen from "../modules/checkout/screens/CheckoutScreen";
import { NewsCsvImport } from "../modules/dash/screen/NewsCsvImport/NewsCsvImport";
import { NewsManualmport } from "../modules/dash/screen/NewsManualmport/NewsManualmport";
import FeedbackEditScreen from "../modules/dash/screen/FeedbackEditScreen";
import FeedbackScreen from "../modules/dash/screen/FeedbackScreen";
import FeedbackUserDetails from "../modules/dash/screen/FeedbackUserDetails";
import LoadingHome from "../modules/dash/screen/LoadingHome";
import ListNews from "../modules/dash/screen/NewsScreen/ListNews";
import AddNewsScreen from "../modules/dash/screen/NewsScreen/ListNews/screens/AddNewsScreen";
import EditNewsScreen from "../modules/dash/screen/NewsScreen/ListNews/screens/EditNewsScreen";
import RelatedNewsScreen from "../modules/dash/screen/NewsScreen/ListNews/screens/RelatedNewsScreen";
import ViewUserScreen from "../modules/dash/screen/NewsScreen/ListNews/screens/ViewUserScreen";
import PanelControl from "../modules/dash/screen/PanelControl";
import IrrelevantWords from "../modules/dash/screen/PanelControl/components/IrrelevantWords";
import SubscribeScreen from "../modules/dash/screen/SubscribeScreen";
import UploadImagesScreen from "../modules/dash/screen/UploadImagesScreen";
import UserScreen from "../modules/dash/screen/UserScreen";
import DashboardPage from "../modules/dash/screens/DashboardPage";
import Login from "../modules/login";
import MobileScreen from "../shared/components/MobileScreen";

const RouteApp: React.FC = () => {
  const { isAuthenticated, authorized, setAuthorized } = useAuth();
  // kind of user resolution
  const [screenMode, setScreenMode] = useState<"Desktop" | "Mobile">("Desktop");
  // loading token by local storage
  useEffect(() => {
    const token = localStorage.getItem("@BEEHIVE:token");
    if (token) {
      setAuthorized(true);
    } else {
      setAuthorized(false);
    }
  }, [authorized]);

  //utils for update window width
  const [size, setSize] = useState(0);
  const resize = () => {
    setSize(window.innerWidth);
  };

  //get breakpoint view of user
  useEffect(() => {
    window.addEventListener("resize", resize);

    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);

  //choose for current screen only do
  //so when size was updated
  //and define size of screen
  useEffect(() => {
    if (size > 0) {
      if (size <= 1200) {
        setScreenMode("Mobile");
      } else {
        setScreenMode("Desktop");
      }
    }
  }, [size]);

  //if authorized then show user admin routes
  //else public routes because unknown user
  //and current screen be mobile then show message only
  //to go for desktop version
  if (screenMode !== "Desktop") {
    return <MobileScreen />;
  }

  if (!authorized) {
    return (
      <Routes>
        <Route
          path="/"
          element={
            <Authorization>
              <Login />
            </Authorization>
          }
        />
        <Route
          path="/login"
          element={
            <Authorization>
              <Login />
            </Authorization>
          }
        />
        <Route path="*" element={<h1></h1>} />
        <Route
          path="/checkoutda39a3ee5e6b4b0d3255bfef95601890afd80709"
          element={
            <Authorization>
              <CheckoutScreen />
            </Authorization>
          }
        />
        <Route
          path="/changecardda39a3ee5e6b4b0d3255bfef95601890afd80709"
          element={
            <Authorization>
              <ChangeCreditCardScreen />
            </Authorization>
          }
        />
      </Routes>
    );
  }

  return (
    <Routes>
      <Route
        path="/"
        element={
          <Authorization allowedRoles={[ROLES.ADMIN, ROLES.CONTENT_CREATOR]}>
            <LoadingHome />
          </Authorization>
        }
      />
      <Route
        path="/dashboard"
        element={
          <Authorization allowedRoles={[ROLES.ADMIN, ROLES.CONTENT_CREATOR]}>
            <DashboardPage />
          </Authorization>
        }
      />
      <Route
        path="/panel"
        element={
          <Authorization allowedRoles={[ROLES.ADMIN]}>
            <PanelControl />
          </Authorization>
        }
      />
      <Route
        path="/feedback"
        element={
          <Authorization allowedRoles={[ROLES.ADMIN]}>
            <FeedbackScreen />
          </Authorization>
        }
      />
      <Route
        path="/feedback/edit"
        element={
          <Authorization allowedRoles={[ROLES.ADMIN]}>
            <FeedbackEditScreen />
          </Authorization>
        }
      />
      <Route
        path="/feedback/:idFeedback"
        element={
          <Authorization allowedRoles={[ROLES.ADMIN]}>
            <FeedbackUserDetails />
          </Authorization>
        }
      />
      <Route
        path="/subs"
        element={
          <Authorization allowedRoles={[ROLES.ADMIN]}>
            <SubscribeScreen />
          </Authorization>
        }
      />
      <Route
        path="/users"
        element={
          <Authorization allowedRoles={[ROLES.ADMIN]}>
            <UserScreen />
          </Authorization>
        }
      />
      <Route
        path="/users/users/:iduser"
        element={
          <Authorization allowedRoles={[ROLES.ADMIN]}>
            <ViewUserScreen />
          </Authorization>
        }
      />
      <Route path="*" element={<h1></h1>} />
      <Route
        path="/news/list/"
        element={
          <Authorization allowedRoles={[ROLES.ADMIN]}>
            <ListNews />
          </Authorization>
        }
      />
      <Route
        path="/news/list/:idnews"
        element={
          <Authorization allowedRoles={[ROLES.ADMIN]}>
            <EditNewsScreen />
          </Authorization>
        }
      />
      <Route
        path="/news/add"
        element={
          <Authorization allowedRoles={[ROLES.ADMIN]}>
            <AddNewsScreen />
          </Authorization>
        }
      />
      <Route
        path="/news/relate"
        element={
          <Authorization allowedRoles={[ROLES.ADMIN]}>
            <RelatedNewsScreen />
          </Authorization>
        }
      />
      <Route
        path="/news/images"
        element={
          <Authorization allowedRoles={[ROLES.ADMIN]}>
            <UploadImagesScreen />
          </Authorization>
        }
      />
      <Route
        path="/irrelevantwords"
        element={
          <Authorization allowedRoles={[ROLES.ADMIN]}>
            <IrrelevantWords />
          </Authorization>
        }
      />
      <Route
        path="/news/import/csv"
        element={
          <Authorization allowedRoles={[ROLES.CONTENT_CREATOR]}>
            <NewsCsvImport />
          </Authorization>
        }
      />
      <Route
        path="/news/import/manually"
        element={
          <Authorization allowedRoles={[ROLES.CONTENT_CREATOR]}>
            <NewsManualmport />
          </Authorization>
        }
      />
    </Routes>
  );
};

export default RouteApp;

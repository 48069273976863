/* this hook it for to do auth provider
 here the user be authenticated when
 login

* we only have one type of authentication
for admin */

import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import { useNavigate } from "react-router-dom";
import api from "../services/api";
import { AxiosError } from "axios";
import { ROLES } from "./useAuthorization";

interface AuthContextData {
  data?: any;
  loading?: boolean;
  signIn(email: string, password: string): Promise<void>;
  signOut(): Promise<void> | void;
}

export interface IRole {
  name: ROLES;
  id?: string; //remove ? later
}

const AuthContext = createContext<any>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<any>();
  let isAuthenticated = !!user;
  const navigate = useNavigate();
  const [authorized, setAuthorized] = useState(false);

  /* there is an interceptor here to catch when the
  // refresh token expires and then refresh the
  // token with the backend */

  useEffect(() => {
    let isRefreshing = false;
    let failedRequestQueue: Array<{
      onSuccess: (token: string) => void;
      onFailure: (err: AxiosError) => void;
    }> = [];

    api.interceptors.response.use(
      (response) => {
        return response;
      },
      (error: AxiosError) => {
        if (error.response?.status === 401) {
          if (error.response.data?.message === "Invalid JWT token") {
            //refresh token
            const originalConfig = error.config;

            const oldToken = localStorage.getItem("@BEEHIVE:token");

            if (!isRefreshing) {
              isRefreshing = true;

              api
                .put("/sessions/refresh-token", { token: oldToken })
                .then((response) => {
                  const { refresh_token } = response.data;
                  localStorage.setItem("@BEEHIVE:token", refresh_token);
                  api.defaults.headers.common[
                    "Authorization"
                  ] = `Bearer ${refresh_token}`;
                  failedRequestQueue.forEach((request) =>
                    request.onSuccess(refresh_token)
                  );
                  failedRequestQueue = [];
                })
                .catch((err) => {
                  failedRequestQueue.forEach((request) =>
                    request.onFailure(err)
                  );
                  failedRequestQueue = [];
                })
                .finally(() => {
                  isRefreshing = false;
                });
            }
            return new Promise((resolve, reject) => {
              failedRequestQueue.push({
                onSuccess: (token: string) => {
                  originalConfig.headers["Authorization"] = `Bearer ${token}`;
                  resolve(api(originalConfig));
                },
                onFailure: (err: AxiosError) => {
                  //if failure the request
                  //then remove it
                  reject(err);
                  signOut();
                },
              });
            });
          } else {
            //throw any other errors
            throw error;
          }
        } else {
          //if there is any other kind of error throw it
          throw error;
        }
      }
    );
  }, []);

  /* get user in localStorage
    if user haved authenticated */

  useEffect(() => {
    const autoLoad = async () => {
      const token = localStorage.getItem("@BEEHIVE:token");
      const data = localStorage.getItem("@BEEHIVE:data");

      if (token && data) {
        api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        setUser(JSON.parse(data));
      }
    };

    autoLoad();
  }, []);

  /* sign in */

  const signIn = useCallback(async (email, password) => {
    try {
      const response = await api.post("sessions", {
        email,
        password,
      });

      const { token, user } = response.data as any;

      if (email !== "er623@bath.ac.uk" && !user.isAdmin) {
        // we need to add to this user with this email:er623@bath.ac.uk, isAdmin:true in database
        // curently there is no user with given email
        throw "User is not admin";
      }

      user.role =
        email === "er623@bath.ac.uk"
          ? { name: ROLES.CONTENT_CREATOR, id: 2 }
          : { name: ROLES.ADMIN, id: 1 };

      localStorage.setItem("@BEEHIVE:token", token);
      localStorage.setItem("@BEEHIVE:data", JSON.stringify(user));

      setUser(user);

      api.defaults.headers.common["Authorization"] = `Bearer ${token}`;

      setAuthorized(true);

      navigate("/dashboard");
    } catch (error: any) {
      throw error;
    }
  }, []);

  /* sign out */

  const signOut = useCallback(() => {
    localStorage.removeItem("@BEEHIVE:token");
    localStorage.removeItem("@BEEHIVE:data");
    navigate("/login");
    setAuthorized(false);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        signIn,
        user,
        isAuthenticated,
        authorized,
        setAuthorized,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

// creating hook

function useAuth(): any {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be used with an MainHookProvider");
  }

  return context;
}

export { AuthProvider, useAuth };

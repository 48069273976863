/*
 * this file manages which screen the user is inside the dash
 */

import React, { useCallback, useEffect, useState } from "react";
import {
  AsideControls,
  Box,
  Header,
  MasterContainer,
  NavLeft,
  NavRight,
  TextHeader,
} from "./styles";

import { NavLink, useNavigate } from "react-router-dom";
import IconSvg from "../../login/components/IconSvg";
import feedbackIcon from "../assets/icon-dash-feedback.svg";
import subsIcon from "../assets/icon-dash-subs.svg";
import usersIcon from "../assets/icon-dash-users.svg";
import dashIcon from "../assets/icon-dash.svg";
import logoBeehive from "../assets/logo-dash.svg";
import ButtonNavigator from "../components/ButtonNavigator";
import CollapseNews from "../components/CollapseNews";
import DashScreen from "../screen/DashScreen";
import FeedbackScreen from "../screen/FeedbackScreen";
import PanelControl from "../screen/PanelControl";
import SubscribeScreen from "../screen/SubscribeScreen";
import UserScreen from "../screen/UserScreen";

import { useAuth } from "../../../hooks/auth";
import iconExit from "../assets/icon-exit.svg";
import CollapseControlPanel from "../components/CollapseControlPanel";
import DotComponent from "../components/DotComponent";

const buttonsVector = [
  { name: "Feedback", icon: feedbackIcon, type: "Feedback" },
  { name: "Subscription", icon: subsIcon, type: "Subs" },
  { name: "Subscribers", icon: usersIcon, type: "Users" },
];

const Master: React.FC = () => {
  const navigator = useNavigate();
  const [indexMenu, setIndexMenu] = useState(0);
  const [screen, setScreen] = useState<
    "Dashboard" | "Panel" | "Feedback" | "Subs" | "Users" | "IrrelevantWords"
  >("Dashboard");

  const { signOut } = useAuth();

  const [active, setActive] = useState({
    index: 0,
    visible: false,
  });

  const navigate = useNavigate();

  useEffect(() => {
    handleType("Dashboard", 0);
    navigator("/dashboard");
  }, []);

  const handleType = useCallback(
    (value: string, index: number) => {
      switch (value) {
        case "Dashboard":
          navigate("/dashboard");
          break;
        case "Panel":
          setScreen("Panel");
          break;
        case "Feedback":
          setScreen("Feedback");
          break;
        case "Subs":
          setScreen("Subs");
          break;
        case "Users":
          setScreen("Users");
          break;
        case "Irrelevant":
          setScreen("Users");
          break;

        default:
          break;
      }

      setIndexMenu(index);
      active.visible = true;
    },
    [screen]
  );

  return (
    <MasterContainer>
      <AsideControls>
        <NavLink to={"/"}>
          <Box>
            <IconSvg
              className="image-testing"
              image={logoBeehive}
              width="208px"
              height="43px"
            />
          </Box>
        </NavLink>

        <NavLink
          className={({ isActive }) =>
            isActive ? "active-link" : "inactive-link"
          }
          to={`/dashboard`}
          end
        >
          <ButtonNavigator
            onClick={() => handleType("Dashboard", 0)}
            name={"Dashboard"}
            icon={dashIcon}
            index={0}
            indexMenu={indexMenu}
            isActive={active.visible}
          />
        </NavLink>

        <CollapseControlPanel />
        <CollapseNews />
        {buttonsVector.map((button, index) => (
          <NavLink
            className={({ isActive }) =>
              isActive ? "active-link" : "inactive-link"
            }
            to={`/${button.type.toLocaleLowerCase()}`}
            end
          >
            <ButtonNavigator
              key={button.name}
              onClick={() => handleType(button.type, index + 1)}
              name={button.name}
              icon={button.icon}
              index={index + 1}
              indexMenu={indexMenu}
              isActive={active.visible}
            />
          </NavLink>
        ))}
        <div
          className="hover-exit-button"
          style={{
            width: "100%",
            height: "60px",
            paddingLeft: "2rem",
          }}
        >
          <ButtonNavigator
            onClick={() => signOut()}
            name={"Exit"}
            icon={iconExit}
            indexMenu={indexMenu}
            isActive={active.visible}
          />
        </div>
      </AsideControls>
      <Header>
        <NavLeft>
          <TextHeader
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/dashboard")}
          >
            <strong>
              <i>
                <u> Home </u>
              </i>
            </strong>
            /
          </TextHeader>
          <TextHeader style={{ marginLeft: "0.6rem" }} color="#666666">
            {screen === "Panel"
              ? `Painel de Controle`
              : screen === "Subs"
              ? `Assinatura`
              : screen === "Users"
              ? `Usuários`
              : screen}
          </TextHeader>
        </NavLeft>
        <NavRight>
          <DotComponent />
        </NavRight>
      </Header>

      {/* 
        what screen is the user on
        then change if necessary!
      */}

      {screen === "Dashboard" && <DashScreen />}
      {screen === "Panel" && <PanelControl />}
      {screen === "Subs" && <SubscribeScreen />}
      {screen === "Feedback" && <FeedbackScreen />}
      {screen === "Users" && <UserScreen />}
    </MasterContainer>
  );
};

export default Master;

/* 
This screen represents the functionality of listing the application's users,
in addition to viewing the list, you can also search for a user in the list,
this list has pagination, and you can navigate to the user's view screen to
have more information about him, in addition to being able to also block this
user if so the administrator wants
*/

import { css } from "@emotion/react";
import React, { useCallback, useEffect, useState } from "react";
import MoonLoader from "react-spinners/MoonLoader";
import {
  BoxList,
  Button,
  ButtonSearch,
  Container,
  FlexBox,
  Heading,
  HorizontalStack,
  HStack,
  InputSearch,
  List,
  ListItem,
  Main,
  Text,
  WrapperButton,
  WrapperForm,
} from "./styles";

import logoBeehive from "../../assets/logo-dash.svg";

//icons ----------------------------------------------------- //
import checkIcon from "../../assets/icon-check.svg";
import feedbackIcon from "../../assets/icon-dash-feedback.svg";
import subsIcon from "../../assets/icon-dash-subs.svg";
import usersIcon from "../../assets/icon-dash-users.svg";
import dashIcon from "../../assets/icon-dash.svg";
import searchIcon from "../../assets/icon-search-md.svg";
import unlockIcon from "../../assets/unblock-icon.svg";
import iconExit from "../../../dash/assets/icon-exit.svg";
import IconSvg from "../../../login/components/IconSvg";
import blockIcon from "../../assets/block-icon.svg";
//icons ----------------------------------------------------- //

import { NavLink, useNavigate } from "react-router-dom";
import { useAuth } from "../../../../hooks/auth";
import api from "../../../../services/api";
import viewIcon from "../../assets/icon-view.svg";
import ButtonNavigator from "../../components/ButtonNavigator";
import CollapseNews from "../../components/CollapseNews";
import Pagination from "../../components/Pagination";
import { FlexPagination } from "../NewsScreen/components/NewsContainer/styles";
import CollapseControlPanel from "../../components/CollapseControlPanel";
import DotComponent from "../../components/DotComponent";
import { toast } from "react-toastify";

//set up styles
import {
  AsideControls,
  Box,
  Header,
  MasterContainer,
  NavLeft,
  NavRight,
  TextHeader,
} from "../../Master/styles";

//set up interfaces
interface DataUsersListApiProps {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  avatar: string;
  political_position: string;
  political_vote: string;
  political_economic_views: string;
  political_social_views: string;
  born_at: string;
  region: string;
  isAdmin: boolean;
  is_blocked: boolean;
  notification_token: string;
  which_social_login?: string;
  sign_up_first_time_with_social_media?: string;
  payment_id?: string;
  created_at: string;
  updated_at: string;
  avatar_url: string;
}

//buttons for show it to user on sidebar
const buttonsVector = [
  { name: "Feedback", icon: feedbackIcon, type: "Feedback" },
  { name: "Subscription", icon: subsIcon, type: "Subs" },
  { name: "Subscribers", icon: usersIcon, type: "Users" },
];

const UserScreen: React.FC = () => {
  const { signOut } = useAuth();
  const navigate = useNavigate();
  //set up states
  const [indexMenu, setIndexMenu] = useState(0);
  const [screen, setScreen] = useState<
    "Dashboard" | "Panel" | "Feedback" | "Subs" | "Users" | "IrrelevantWords"
  >("Dashboard");
  const [isLoading, setIsLoading] = useState(true);
  const [usersList, setUsersList] = useState<DataUsersListApiProps[]>([]);
  const [isBlock, setIsBlock] = useState(false);
  const [color, setColor] = useState("#FFC600");
  const [isSearch, setIsSearch] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState("");
  const [totalUsers, setTotalUsers] = useState("");
  const [active, setActive] = useState({
    index: 0,
    visible: false,
  });
  const [objectName, setObjectName] = useState({
    offset: 0,
    is_search: search ? true : false,
    search: search === "" ? null : search,
  });

  //just some adjusts for styles of loading component
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

  /*
  force state here:
  since the user is navigating through the navigator,
  update my state by forcing it
  */
  useEffect(() => {
    setScreen("Users");
  }, []);

  //then handle which screen user will be
  //but it was already set anyway
  // via useEffect forcing it
  const handleType = useCallback(
    (value: any, index: number) => {
      switch (value) {
        case "Dashboard":
          setScreen("Dashboard");
          break;
        case "Panel":
          setScreen("Panel");
          break;
        case "Feedback":
          setScreen("Feedback");
          break;
        case "Subs":
          setScreen("Subs");
          break;
        case "Users":
          setScreen("Users");
          break;

        case "IrrelevantWords":
          setScreen("IrrelevantWords");
          break;

        default:
          break;
      }

      setIndexMenu(index);
      active.visible = true;
    },
    [screen]
  );

  //just navigate to specfic url
  const handleOnClickViewUser = (url: string) => {
    navigate(url);
  };

  /*
  this function we change the state and api for the users that
  the administrator wants to block from the application
  */
  const handleClickBlockUser = useCallback(
    async (id: string, isBlock: boolean, index: number) => {
      if (!isBlock) {
        try {
          const responseBlockUser = await api.put("users/update-is-blocked", {
            user_id: id,
            is_blocked: true,
          });

          try {
            const responseUpdateList = await api.get("/users/list-all", {
              params: objectName,
            });
            // setUsersList(responseUpdateList?.data?.user);
            setUsersList((prevState: DataUsersListApiProps[]) => {
              let cloneState = [...prevState];
              cloneState[index].is_blocked = !isBlock;
              return cloneState;
            });
          } catch (err) {
            console.log("error when post api update list users", err);
          }
        } catch (err) {
          console.log(err, "error when block user");
        }

        if (isBlock) {
          toast.success("User is unlocked");
        } else {
          toast.success("User is blocked");
        }
      } else {
        try {
          const responseBlockUser = await api.put("users/update-is-blocked", {
            user_id: id,
            is_blocked: false,
          });
          try {
            const responseUpdateList = await api.get("/users/list-all", {
              params: objectName,
            });
            setUsersList((prevState: DataUsersListApiProps[]) => {
              let cloneState = [...prevState];
              cloneState[index].is_blocked = !isBlock;
              return cloneState;
            });
          } catch (err) {
            console.log("error when post api update list users", err);
          }
        } catch (err) {
          console.log(err, "error when block user");
        }

        if (isBlock) {
          toast.success("User is unlocked");
        } else {
          toast.success("User is blocked");
        }
      }
    },
    [isBlock, usersList]
  );

  /*
  here we fetch the list of users from the api, update the state, and also the
  total number of users that comes from the api also to show the administrator,
  and the total number of pages for paging this screen
  */
  useEffect(() => {
    const autoLoadUsers = async () => {
      try {
        const responseUsers = await api.get("/users/list-all", {
          params: objectName,
        });
        setUsersList(responseUsers?.data?.user);
        setTotalUsers(responseUsers?.data?.count_user);
        setTotalPages(responseUsers?.data?.pagination);
        setIsLoading(false);
      } catch (err) {
        console.log(err, "erro de servidor");
        setIsLoading(false);
      }
    };
    autoLoadUsers();
  }, [objectName, totalPages]);

  //handle event to search feature
  const handleSearchUser = useCallback(
    (event) => {
      setSearch(event.target.value);
    },
    [search]
  );

  //handle submit to new list with user for search
  const handleSearchData = useCallback(async () => {
    setObjectName((state) => {
      let cloneState = {
        ...state,
        search: search === "" ? null : search,
        is_search: search !== "" ? true : false,
      };

      return cloneState;
    });
  }, [search, objectName]);

  // fetch users active from bluesnap
  const [amoutUsersAreActive, setAmountUsersAreActive] = useState(0);

  //searching the list of users who are active in the plan
  useEffect(() => {
    const autoLoadActiveUsers = async () => {
      try {
        const responseUsersActive = await api.get(
          "bluesnap/list-all-subscription-active"
        );

        setAmountUsersAreActive(responseUsersActive.data.total_subscription);
      } catch (err) {
        console.log(err, "error when fetch users active");
      }
    };

    autoLoadActiveUsers();
  }, []);

  return (
    <>
      <MasterContainer>
        <AsideControls>
          <NavLink to={"/"}>
            <Box>
              <IconSvg
                className="image-testing"
                image={logoBeehive}
                width="208px"
                height="43px"
              />
            </Box>
          </NavLink>

          <NavLink
            className={({ isActive }) =>
              isActive ? "active-link" : "inactive-link"
            }
            to={`/dashboard`}
            end
          >
            <ButtonNavigator
              onClick={() => handleType("Dashboard", 0)}
              name={"Dashboard"}
              icon={dashIcon}
              index={0}
              indexMenu={indexMenu}
              isActive={active.visible}
            />
          </NavLink>

          <CollapseControlPanel />
          <CollapseNews />
          {buttonsVector.map((button, index) => (
            <NavLink
              className={({ isActive }) =>
                isActive ? "active-link" : "inactive-link"
              }
              to={`/${button.type.toLocaleLowerCase()}`}
              end
            >
              <ButtonNavigator
                key={button.name}
                onClick={() => handleType(button.type, index + 1)}
                name={button.name}
                icon={button.icon}
                index={index + 1}
                indexMenu={indexMenu}
                isActive={active.visible}
              />
            </NavLink>
          ))}
          <div
            className="hover-exit-button"
            style={{
              width: "100%",
              height: "60px",
              paddingLeft: "2rem",
            }}
          >
            <ButtonNavigator
              onClick={() => signOut()}
              name={"Exit"}
              icon={iconExit}
              indexMenu={indexMenu}
              isActive={active.visible}
            />
          </div>
        </AsideControls>
        <Header>
          <NavLeft>
            <TextHeader>
              <i>
                <u>
                  <strong
                    style={{ cursor: "pointer" }}
                    onClick={() => navigate("/dashboard")}
                  >
                    {" "}
                    Home{" "}
                  </strong>
                </u>
              </i>
              /
            </TextHeader>
            <TextHeader style={{ marginLeft: "0.6rem" }} color="#666666">
              {screen === "Panel"
                ? `Painel de Controle`
                : screen === "Subs"
                ? `Assinatura`
                : screen === "Users"
                ? `Subscribers`
                : screen}
            </TextHeader>
          </NavLeft>
          <NavRight>
            <DotComponent />
          </NavRight>
        </Header>

        <Container>
          {isLoading ? (
            <div
              style={{
                height: "80vh",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <MoonLoader
                color={color}
                loading={isLoading}
                css={override}
                size={65}
                speedMultiplier={1.2}
              />
            </div>
          ) : (
            <Main>
              <HStack>
                <WrapperForm>
                  <InputSearch
                    type="text"
                    placeholder="Search"
                    value={search}
                    onChange={handleSearchUser}
                  />
                  <img src={searchIcon} />
                </WrapperForm>
                <WrapperButton>
                  <ButtonSearch
                    className="button-excel"
                    onClick={handleSearchData}
                  >
                    <img src={checkIcon} alt="" />{" "}
                  </ButtonSearch>
                </WrapperButton>
              </HStack>
              <span className="users-label">
                Total users: {totalUsers} - Active users: {amoutUsersAreActive}
              </span>
              <BoxList>
                <List>
                  <Heading>Name</Heading>
                  <ListItem>
                    {usersList.length > 0 ? (
                      usersList.map((user, index) => (
                        <HorizontalStack key={user.id}>
                          <Text>
                            {user.first_name} {user.last_name}
                          </Text>
                          <FlexBox>
                            <Button
                              onClick={() =>
                                handleClickBlockUser(
                                  user.id,
                                  user.is_blocked,
                                  index
                                )
                              }
                            >
                              <img
                                className="img-icon"
                                src={
                                  user
                                    ? user.is_blocked
                                      ? blockIcon
                                      : unlockIcon
                                    : ""
                                }
                                alt="Icon block"
                              />
                            </Button>
                            <Button>
                              <img
                                onClick={() =>
                                  handleOnClickViewUser(`users/${user.id}`)
                                }
                                src={viewIcon}
                                className="img-icon"
                                alt="Icon View"
                              />
                            </Button>
                          </FlexBox>
                        </HorizontalStack>
                      ))
                    ) : (
                      <div
                        style={{
                          width: "100%",
                          minHeight: "300px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        Empty data for your search.
                      </div>
                    )}
                  </ListItem>
                </List>
              </BoxList>

              <FlexPagination style={{ marginTop: "0.1rem" }}>
                {usersList.length >= 1 && (
                  <Pagination
                    limit={10}
                    total={totalPages}
                    offset={objectName?.offset}
                    setDataNews={setObjectName}
                    dataNews={objectName}
                  />
                )}
              </FlexPagination>
            </Main>
          )}
        </Container>
      </MasterContainer>
    </>
  );
};

export default UserScreen;

/*
  here it takes to edit articles where the user loads the
  chosen article and from there he will be able to see the
  opposite and related news in addition to being able to delete
  only related news or edit information and the photo of the article
*/

import React, { useCallback, useState } from "react";
import IconSvg from "../../../../../../login/components/IconSvg";
import CollapseNews from "../../../../../components/CollapseNews";
import {
  AsideControls,
  Box,
  Header,
  MasterContainer,
  NavLeft,
  NavRight,
  TextHeader,
} from "../../../../../Master/styles";
import logoBeehive from "../../../../../assets/logo-dash.svg";
import dashIcon from "../../../../../assets/icon-dash.svg";
import feedbackIcon from "../../../../../assets/icon-dash-feedback.svg";
import subsIcon from "../../../../../assets/icon-dash-subs.svg";
import usersIcon from "../../../../../assets/icon-dash-users.svg";
import ButtonNavigator from "../../../../../components/ButtonNavigator";
import { NavLink, useNavigate } from "react-router-dom";
import EditNewsMain from "../EditNewsMain";
import { useAuth } from "../../../../../../../hooks/auth";
import iconExit from "../../../../../assets/icon-exit.svg";
import CollapseControlPanel from "../../../../../components/CollapseControlPanel";
import DotComponent from "../../../../../components/DotComponent";

const buttonsVector = [
  { name: "Feedback", icon: feedbackIcon, type: "Feedback" },
  { name: "Subscription", icon: subsIcon, type: "Subs" },
  { name: "Subscribers", icon: usersIcon, type: "Users" },
];

const EditNewsScreen: React.FC = () => {
  const navigator = useNavigate();
  const [indexMenu, setIndexMenu] = useState<any>(0);
  const [screen, setScreen] = useState<
    "Dashboard" | "Panel" | "Feedback" | "Subs" | "Users" | "IrrelevantWords"
  >("Dashboard");

  const [active, setActive] = useState({
    index: 0,
    visible: false,
  });

  const navigate = useNavigate();
  const { signOut } = useAuth();

  const handleType = useCallback(
    (value: any, index: number) => {
      switch (value) {
        case "Dashboard":
          setScreen("Dashboard");
          break;
        case "Panel":
          setScreen("Panel");
          break;
        case "Feedback":
          setScreen("Feedback");
          break;
        case "Subs":
          setScreen("Subs");
          break;
        case "Users":
          setScreen("Users");
          break;

        case "IrrelevantWords":
          setScreen("IrrelevantWords");
          break;

        default:
          break;
      }

      setIndexMenu(index);
      active.visible = true;
    },
    [screen]
  );

  return (
    <MasterContainer>
      <AsideControls>
        <NavLink to={"/"}>
          <Box>
            <IconSvg
              className="image-testing"
              image={logoBeehive}
              width="208px"
              height="43px"
            />
          </Box>
        </NavLink>

        <NavLink
          className={({ isActive }) =>
            isActive ? "active-link" : "inactive-link"
          }
          to={`/dashboard`}
          end
        >
          <ButtonNavigator
            onClick={() => handleType("Dashboard", 0)}
            name={"Dashboard"}
            icon={dashIcon}
            index={0}
            indexMenu={indexMenu}
            isActive={active.visible}
          />
        </NavLink>

        <CollapseControlPanel />
        <CollapseNews />
        {buttonsVector.map((button, index) => (
          <NavLink
            className={({ isActive }) =>
              isActive ? "active-link" : "inactive-link"
            }
            to={`/${button.type.toLocaleLowerCase()}`}
            end
          >
            <ButtonNavigator
              key={button.name}
              onClick={() => handleType(button.type, index + 1)}
              name={button.name}
              icon={button.icon}
              index={index + 1}
              indexMenu={indexMenu}
              isActive={active.visible}
            />
          </NavLink>
        ))}
        <div
          className="hover-exit-button"
          style={{
            width: "100%",
            height: "60px",
            paddingLeft: "2rem",
          }}
        >
          <ButtonNavigator
            onClick={() => signOut()}
            name={"Exit"}
            icon={iconExit}
            indexMenu={indexMenu}
            isActive={active.visible}
          />
        </div>
      </AsideControls>
      <Header>
        <NavLeft>
          <TextHeader
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/dashboard")}
          >
            <span className="blue-link"> Home </span> /
          </TextHeader>
          <TextHeader style={{ marginLeft: "0.6rem" }} color="#666666">
            <span
              className="blue-link"
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/news/list")}
            >
              Articles
            </span>{" "}
            / Edit
          </TextHeader>
        </NavLeft>
        <NavRight>
          <DotComponent />
        </NavRight>
      </Header>
      {/* below then the component that contains the functionality */}
      <EditNewsMain />
    </MasterContainer>
  );
};

export default EditNewsScreen;

import styled from "styled-components";

export const Flex = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  background: #dadada;
  flex-direction: column;
  border-radius: 5px;
`;

export const FlexHeader = styled.div`
  display: flex;
  width: 100%;
  height: 10%;
  background: #f8f9fa;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border: 1px solid #dadada;
  align-items: center;
  padding: 0 1rem;
  justify-content: space-between;

  & .container-space {
    width: 180px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      color: black;
      font-size: 1.4rem;
    }
  }
`;

export const FlexChart = styled.div`
  display: flex;
  width: 100%;
  height: 60%;
  background: white;
  border-left: 1px solid #dadada;
  border-right: 1px solid #dadada;
  padding: 1rem 1rem;
`;

export const FlexFilters = styled.div`
  display: flex;
  width: 100%;
  height: 30%;
  background: #f8f9fa;
  border-end-start-radius: 5px;
  border-end-end-radius: 5px;
  border: 1px solid #dadada;
  padding: 0 1rem;
`;

export const TextHead = styled.span`
  font-size: 1.5rem;
  font-weight: bold;

  @media (max-width: 1366px) {
    font-size: 1.4rem;
  }
`;

export const VerticalStackDate = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 18%;
  padding: 1rem 0.5rem;
  justify-content: space-between;

  .wrapper-date-from {
    position: relative;
  }
`;

export const VerticalStackTopic = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 18%;
  padding: 1rem 0.5rem;
  justify-content: flex-start;

  .arrow-wrapper {
    position: relative;
    width: 100%;
  }

  .image-position {
    position: absolute;
    right: 12px;
    top: 20px;
  }

  @media (max-width: 1366px) {
    .image-position {
      width: 12px;
      height: auto;
      right: 10px;
      top: 20px;
    }
  }
`;

export const VerticalStackPolitical = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 18%;
  padding: 1rem 0.5rem;
  justify-content: space-between;
`;
export const VerticalStackAge = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 18%;
  padding: 1rem 0.5rem;
  justify-content: space-between;
`;
export const VerticalStackRegion = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 18%;
  padding: 1rem 0.5rem;
  justify-content: space-between;
  position: relative;

  .arrow-wrapper {
    position: relative;
    width: 100%;
  }

  .image-position {
    position: absolute;
    right: 12px;
    top: 20px;
  }

  @media (max-width: 1366px) {
    .image-position {
      width: 12px;
      height: auto;
      right: 10px;
      top: 20px;
    }
  }
`;
export const VerticalStackButton = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 10%;
  align-items: center;
  justify-content: center;
  padding: 3rem 0.5rem;
  justify-content: space-between;
`;

export const TextInput = styled.span`
  font-size: 1.3rem;
  color: black;
  margin-left: 0.5rem;
  font-weight: bold;
`;

export const Select = styled.select`
  width: 100%;
  height: 100%;
  border: 1px solid #9d9d9d;
  color: gray;
  border-radius: 5px;
  font-size: 1.4rem;
  padding: 0 1rem;
  font-size: 1.2rem;

  option {
    color: black;
    background: white;
    display: flex;
    white-space: pre;
    min-height: 20px;
    padding: 0px 2px 1px;
    font-size: 1.2rem;
  }

  @media (max-width: 1366px) {
    height: 42px !important;
  }
`;

export const Button = styled.div`
  width: 45px;
  height: 45px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.honey};
  margin-top: 0.7rem;
  transition: all 120ms linear;

  @media (max-width: 1366px) {
    width: 40px;
    height: 40px;
    margin-top: 1rem;
  }
`;

import styled from "styled-components";

interface InputBaseProps extends React.HTMLAttributes<HTMLInputElement> {}

export const InputBase = styled.input<InputBaseProps>`
  width: 100%;
  min-height: 45px;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid #9d9d9d;
  border-radius: 5px;
  color: black;
  padding: 1rem;
  font-size: 14px;
  resize: vertical;
  &::placeholder {
    font-size: 14px;
    color: ${({ theme }) => theme.colors.gray300};
  }

  @media (max-width: 1366px) {
    height: 42px;

    &::placeholder {
      font-size: 14px;
      color: ${({ theme }) => theme.colors.gray300};
    }
  }
`;

import styled from "styled-components";

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  top: 10%;
`;

export const Text = styled.div`
  color: black;
  font-size: 16px;
  margin-bottom: 20px;
  font-weight: bold;
`;

export const CloseButton = styled.button`
  position: absolute;
  background: transparent;
  border: 0;
  top: 1.5rem;
  right: 2rem;
  cursor: pointer;
`;

import React from "react";
import IconSvg from "../../../login/components/IconSvg";
import {
  CollapseList,
  CollapseListItem,
  Container,
  FlexDiv,
  Text,
} from "./styles";
import useCollapse from "react-collapsed";
import newsIcon from "../../assets/icon-dash-news.svg";
import arrowIcon from "../../assets/icon-dash-arrow.svg";
import arrowIconDown from "../../assets/icon-arrowdown-dash.svg";
import { NavLink, useNavigate } from "react-router-dom";
import iconControlPanel from "../../assets/Frame.svg";

// import { Container } from './styles';

interface ICollapseNewsProps {
  currentPath?: string;
}

const CollapseControlPanel: React.FC<ICollapseNewsProps> = ({
  currentPath,
}) => {
  const navigator = useNavigate();
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();

  return (
    // <ButtonNav>
    //   <IconSvg width={"20"} height={"20"} />
    //   <TextNav>test </TextNav>
    // </ButtonNav>

    // {isExpanded ? "Collapse" : "Expand"}
    <Container isExpanded={isExpanded}>
      <div className="button-navigator">
        <div
          style={{ cursor: "pointer" }}
          className="heade222r"
          {...getToggleProps()}
        >
          <FlexDiv>
            <IconSvg
              className="icon-collapse-sidebar"
              image={iconControlPanel}
              width="31px"
              height="22px"
            />
            <Text>Control panel</Text>
          </FlexDiv>
          {isExpanded ? (
            <IconSvg image={arrowIcon} width="12px" height="15px" />
          ) : (
            <IconSvg image={arrowIconDown} width="12px" height="15px" />
          )}
        </div>
        <div className="content-collapse" {...getCollapseProps()}>
          <CollapseList>
            <NavLink
              className={({ isActive }) =>
                isActive ? "active-link" : "inactive-link"
              }
              to="/panel"
              end
            >
              <span className="menu-aside-main">Settings</span>
            </NavLink>
            <NavLink
              className={({ isActive }) =>
                isActive ? "active-link" : "inactive-link"
              }
              to="/irrelevantwords"
              end
            >
              <span className="menu-aside-main"> Irrelevant words</span>
            </NavLink>
          </CollapseList>
        </div>
      </div>
    </Container>
  );
};

export default CollapseControlPanel;

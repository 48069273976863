import styled from "styled-components";

export const Flex = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
`;

export const Text = styled.span`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.gray300};
  font-weight: bold;
`;

export const CheckBoxWrapper = styled.div`
  position: relative;
  margin-right: 1rem;
  display: flex;
  cursor: pointer;
`;
export const CheckBoxLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 42px;
  height: 26px;
  border-radius: 15px;
  background: #bebebe;
  cursor: pointer;
  &::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    margin: 3px;
    background: #ffffff;
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
  }
`;
export const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 42px;
  height: 26px;
  cursor: pointer;
  &:checked + ${CheckBoxLabel} {
    background: #4fbe79;
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 18px;
      height: 18px;
      margin-left: 21px;
      transition: 0.2s;
    }
  }
`;

export const ContainerMain = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
`;

export const GrayBox = styled.div`
  background-color: #f9fafa;
  padding: 2rem;

  @media (max-width: 1366px) {
    padding: 2rem;
  }
`;

/*
On this screen, the administrator will be able to see feedback from each user
and their respective responses to the feedback questions that appear in the
application for users.
*/

import React, { useCallback, useEffect, useState } from "react";
import useCollapse from "react-collapsed";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import {
  AsideControls,
  Box,
  Header,
  MasterContainer,
  NavLeft,
  NavRight,
  TextHeader,
} from "../../Master/styles";
import IconSvg from "../../../login/components/IconSvg";
import CollapseNews from "../../components/CollapseNews";
import ButtonNavigator from "../../components/ButtonNavigator";
import api from "../../../../services/api";
import { useAuth } from "../../../../hooks/auth";
import CollapseControlPanel from "../../components/CollapseControlPanel";
import { GrayBox } from "../PanelControl/components/NotificationArea/styles";
import MoonLoader from "react-spinners/MoonLoader";
import { css } from "@emotion/react";
import DotComponent from "../../components/DotComponent";

//icons
import subsIcon from "../../assets/icon-dash-subs.svg";
import usersIcon from "../../assets/icon-dash-users.svg";
import logoBeehive from "../../assets/logo-dash.svg";
import CollapseBox from "./components/CollapseBox";
import iconExit from "../../../dash/assets/icon-exit.svg";
import dashIcon from "../../assets/icon-dash.svg";
import feedbackIcon from "../../assets/icon-dash-feedback.svg";

//setup styles
import {
  Container,
  Heading,
  LightGrayContainer,
  WhiteContainer,
  WrapperHeading,
  Text,
  WrapperFull,
} from "./styles";

// set up interfaces
interface ObjectQuestionsProps {
  created_at: string;
  id: string;
  question1: string;
  question2: string;
  question3: string;
  question4: string;
  question5: string;
  question6: string;
  question7: string;
  updated_at: string;
}

interface ResponseAnswersProps {
  articles_grouped: string;
  created_at: string;
  diversity_outlets: number;
  id: string;
  missing_any_newspaper: string;
  missing_any_newspaper_yes: string;
  missing_any_topics: string;
  missing_any_topics_yes: string;
  other_comments: string;
  other_comments_yes: string;
  political_underrepresented: string;
  quality_news_beehive: number;
  updated_at: string;
  user_id: string;
}

//buttons for show it to user on sidebar
const buttonsVector = [
  { name: "Feedback", icon: feedbackIcon, type: "Feedback" },
  { name: "Subscription", icon: subsIcon, type: "Subs" },
  { name: "Subscribers", icon: usersIcon, type: "Users" },
];

const FeedbackUserDetails: React.FC = () => {
  //catching through params navigator whick id of
  // feedback admin will be
  const { idFeedback } = useParams();

  //set up states
  const [color, setColor] = useState("#FFC600");
  const [loadingFirst, setLoadingFirst] = useState(true);
  const navigator = useNavigate();
  const [indexMenu, setIndexMenu] = useState<any>(0);
  const [screen, setScreen] = useState<
    "Dashboard" | "Panel" | "Feedback" | "Subs" | "Users" | "IrrelevantWords"
  >("Dashboard");
  const [active, setActive] = useState({
    index: 0,
    visible: false,
  });
  const [questions, setQuestions] = useState<ObjectQuestionsProps>({
    created_at: "",
    id: "",
    question1: "",
    question2: "",
    question3: "",
    question4: "",
    question5: "",
    question6: "",
    question7: "",
    updated_at: "",
  });

  const [answers, setAnswers] = useState<ResponseAnswersProps>({
    articles_grouped: "",
    created_at: "",
    diversity_outlets: 0,
    id: "",
    missing_any_newspaper: "",
    missing_any_newspaper_yes: "",
    missing_any_topics: "",
    missing_any_topics_yes: "",
    other_comments: "",
    other_comments_yes: "",
    political_underrepresented: "",
    quality_news_beehive: 0,
    updated_at: "",
    user_id: "",
  });

  const [author, setAuthor] = useState("");
  const [lastNameAuthor, setLastNameAuthor] = useState("");

  //just some styles for loading component
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

  const navigate = useNavigate();
  const { signOut } = useAuth();

  /*
  force state here:
  since the user is navigating through the navigator,
  update my state by forcing it
  */
  useEffect(() => {
    setScreen("Feedback");
  }, []);

  //then handle which screen user will be
  //but it was already set anyway
  // via useEffect forcing it
  const handleType = useCallback(
    (value: any, index: number) => {
      switch (value) {
        case "Dashboard":
          setScreen("Dashboard");
          break;
        case "Panel":
          setScreen("Panel");
          break;
        case "Feedback":
          setScreen("Feedback");
          break;
        case "Subs":
          setScreen("Subs");
          break;
        case "Users":
          setScreen("Users");
          break;

        case "IrrelevantWords":
          setScreen("IrrelevantWords");
          break;

        default:
          break;
      }

      setIndexMenu(index);
      active.visible = true;
    },
    [screen]
  );

  //loading feedback questions via the beehive api
  //and update the state of questions for list in UI
  useEffect(() => {
    const autoLoad = async () => {
      try {
        const responseDefaultQuestions = await api.get(`feedback-question`);
        setQuestions(responseDefaultQuestions.data);
        setLoadingFirst(false);
      } catch (err) {
        console.log("error when get feedback question", err);
      }
    };

    autoLoad();
  }, []);

  //getting username and last name and loading
  // the answers in the respective state
  useEffect(() => {
    const autoLoad = async () => {
      let getUserId = null;

      try {
        const responseFeedbackById = await api.get(`feedback/${idFeedback}`);
        setAnswers(responseFeedbackById.data);
        getUserId = responseFeedbackById.data.user_id;
      } catch (err) {
        console.log("erro", err);
      }

      try {
        const responseUserInfo = await api.get(`/users/${getUserId}`);
        setAuthor(responseUserInfo.data.first_name);
        setLastNameAuthor(responseUserInfo.data.last_name);
      } catch (err) {
        console.log(err, "erro");
      }
    };
    autoLoad();
  }, []);

  return (
    <>
      <MasterContainer>
        <AsideControls>
          <NavLink to={"/"}>
            <Box>
              <IconSvg
                className="image-testing"
                image={logoBeehive}
                width="208px"
                height="43px"
              />
            </Box>
          </NavLink>

          <NavLink
            className={({ isActive }) =>
              isActive ? "active-link" : "inactive-link"
            }
            to={`/dashboard`}
            end
          >
            <ButtonNavigator
              onClick={() => handleType("Dashboard", 0)}
              name={"Dashboard"}
              icon={dashIcon}
              index={0}
              indexMenu={indexMenu}
              isActive={active.visible}
            />
          </NavLink>

          <CollapseControlPanel />
          <CollapseNews />
          {buttonsVector.map((button, index) => (
            <NavLink
              className={({ isActive }) =>
                isActive ? "active-link" : "inactive-link"
              }
              to={`/${button.type.toLocaleLowerCase()}`}
              end
            >
              <ButtonNavigator
                key={button.name}
                onClick={() => handleType(button.type, index + 1)}
                name={button.name}
                icon={button.icon}
                index={index + 1}
                indexMenu={indexMenu}
                isActive={active.visible}
              />
            </NavLink>
          ))}
          <div
            className="hover-exit-button"
            style={{
              width: "100%",
              height: "60px",
              paddingLeft: "2rem",
            }}
          >
            <ButtonNavigator
              onClick={() => signOut()}
              name={"Exit"}
              icon={iconExit}
              indexMenu={indexMenu}
              isActive={active.visible}
            />
          </div>
        </AsideControls>
        <Header>
          <NavLeft>
            <TextHeader style={{ cursor: "pointer" }}>
              <strong>
                <i>
                  <u onClick={() => navigate("/")}>Home </u>
                </i>
              </strong>
              /
            </TextHeader>
            <button style={{ background: "transparent" }}>
              <TextHeader
                onClick={() => navigate("/feedback")}
                style={{ marginLeft: "0.6rem", cursor: "pointer !important" }}
                color="#666666"
              >
                {screen === "Panel"
                  ? `Painel de Controle`
                  : screen === "Subs"
                  ? `Assinatura`
                  : screen === "Users"
                  ? `Usuários`
                  : screen === "Feedback"
                  ? `Feedback / Visualize`
                  : screen}
              </TextHeader>
            </button>
          </NavLeft>
          <NavRight>
            <DotComponent />
          </NavRight>
        </Header>

        <Container>
          {!loadingFirst ? (
            <>
              <div className="border-outside">
                <WhiteContainer>
                  <WrapperHeading>
                    <Heading>
                      {author} {lastNameAuthor}
                    </Heading>
                  </WrapperHeading>
                  <LightGrayContainer>
                    <GrayBox>
                      <WrapperFull>
                        <CollapseBox answer={questions.question1}>
                          <Text> {answers.quality_news_beehive} </Text>
                        </CollapseBox>
                        <CollapseBox answer={questions.question2}>
                          <Text> {answers.diversity_outlets} </Text>
                        </CollapseBox>
                        <CollapseBox answer={questions.question3}>
                          <Text> {answers.political_underrepresented} </Text>
                        </CollapseBox>
                        <CollapseBox answer={questions.question4}>
                          <Text> {answers.articles_grouped} </Text>
                        </CollapseBox>
                        <CollapseBox answer={questions.question5}>
                          <Text>{answers.missing_any_newspaper}</Text>
                        </CollapseBox>
                        <CollapseBox answer={questions.question6}>
                          <Text>{answers.missing_any_topics}</Text>
                        </CollapseBox>
                        <CollapseBox answer={questions.question7}>
                          <Text>{answers.other_comments}</Text>
                        </CollapseBox>
                      </WrapperFull>
                    </GrayBox>
                  </LightGrayContainer>
                </WhiteContainer>
              </div>
              <div
                style={{
                  width: "100%",
                  height: "100px",
                  background: "transparent",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <button
                  className="button-back"
                  onClick={() => navigator("/feedback")}
                >
                  Back
                </button>
              </div>
            </>
          ) : (
            <div
              style={{
                height: "80vh",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <MoonLoader
                color={color}
                loading={loadingFirst}
                css={override}
                size={65}
                speedMultiplier={1.2}
              />
            </div>
          )}
        </Container>
      </MasterContainer>
    </>
  );
};

export default FeedbackUserDetails;

import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  margin: 1rem auto;
  flex-direction: column;
`;

export const Heading = styled.span`
  font-size: 1.4rem;
  font-weight: bold;
  color: #333333;
  margin: 0 0 2rem 0;
`;

export const WrapperInputs = styled.div`
  display: flex;
  width: 100%;
`;

export const LegendInput = styled.div`
  display: flex;
  width: 20%;
  height: 48px;
  align-items: center;
`;

export const Text = styled.span`
  font-size: 1.4rem;
  font-weight: 200;
`;

import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  background: #ebedef;
  width: 100%;
  height: auto;
  flex-direction: column;
  padding: 0 2rem;
  overflow-y: scroll;
`;

export const Main = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  background: white;
  flex-direction: column;
  padding: 1rem 1.7rem 1rem 1.7rem;
  margin-top: 3rem;
  margin-bottom: 5rem;
  border: 1px solid #dadada;
  border-radius: 5px;

  .users-label {
    margin-left: auto;
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-right: 1rem;
  }

  @media (max-width: 1366px) {
    .users-label {
      margin-top: 0;
    }
  }
`;

export const BoxList = styled.div`
  width: 100%;
  height: 100%;
  max-height: 550px;
  padding: 0 1rem 1rem 1rem;
  margin-top: 0.5rem;
  background: ${({ theme }) => theme.colors.gray100};
  margin-bottom: 1.4rem;

  @media (max-width: 1366px) {
    margin-bottom: 1rem;
  }
`;

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 1.5rem;
`;

export const ListItem = styled.li`
  font-size: 1.5rem;
  padding-bottom: 0rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 420px;
`;

export const HorizontalStack = styled.div`
  display: flex;
  width: 100%;
  margin-top: 1.5rem;
  align-items: center;
  padding: 0 0.5rem;

  border-bottom: 1px solid ${({ theme }) => theme.colors.gray200};

  :last-child {
    border-bottom: none;
  }
`;

export const Heading = styled.span`
  font-size: 1.5rem;
  font-weight: bold;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray200};
  display: flex;
  flex-direction: column;
  height: 30px;

  @media (max-width: 1366px) {
    font-size: 1.4rem;
  }
`;

export const Text = styled.p`
  font-size: 1.5rem;
  margin-top: 0rem;
  font-weight: normal;
  width: 100%;
  padding-bottom: 10px;
  justify-content: center;
  display: flex;
  flex-direction: column;

  @media (max-width: 1600px) {
    font-size: 1.4rem;
  }

  @media (max-width: 1366px) {
    font-size: 1.3rem;
  }
`;

export const FlexBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  max-width: 60px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;

  @media (max-width: 1366px) {
    .img-icon {
      width: 18px;
      height: 18px;
    }
  }
`;

export const Button = styled.button`
  display: flex;
  background: transparent;
  align-items: center;
`;

export const HStack = styled.div`
  width: 100%;
  height: 47px;
  display: flex;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 2rem;
`;

export const WrapperForm = styled.div`
  width: 93%;
  height: 100%;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #9d9d9d;
  border-radius: 5px;
`;

export const WrapperButton = styled.div`
  width: 7%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const ButtonSearch = styled.button`
  width: 45px;
  height: 45px;
  display: flex;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colors.honey};
  transition: all 120ms linear;

  @media (max-width: 1366px) {
    width: 42px;
    height: 42px;
  }
`;

export const InputSearch = styled.input`
  width: 95%;
  border: none;
  height: 100%;

  @media (max-width: 1366px) {
    font-size: 1.2rem;
  }
`;

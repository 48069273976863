import React from "react";
import MoonLoader from "react-spinners/MoonLoader";
import { CloseButton, Container, Text } from "./styles";
import { css } from "@emotion/react";
import { X } from "phosphor-react";

export type ILoadingOverlayProps = {
  color?: string;
  text: string;
  onClose: () => void;
};

const LoadingOverlay: React.FC<ILoadingOverlayProps> = ({
  color = "#FFC600",
  text,
  onClose,
}) => {
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

  return (
    <Container>
      <Text>{text}</Text>
      <MoonLoader
        color={color}
        loading={true}
        css={override}
        size={65}
        speedMultiplier={1.2}
      />
      <CloseButton onClick={onClose}>
        <X size={24} />
      </CloseButton>
    </Container>
  );
};

export { LoadingOverlay };

import React, { HTMLProps } from "react";
import IconSvg from "../../../login/components/IconSvg";
import { ButtonNav, TextNav } from "./styles";

// import { Container } from './styles';
interface ButtonNavigatorProps extends HTMLProps<ButtonNavigatorProps> {
  name: string;
  icon: string;
  isActive?: boolean;
  onClick?: () => void;
  index?: number;
  indexMenu?: number;
}

const ButtonNavigator: React.FC<ButtonNavigatorProps> = ({
  name,
  icon,
  isActive,
  onClick,
  index,
  indexMenu,
}) => {
  return (
    <ButtonNav
      onClick={onClick}
      index={index}
      isActive={isActive}
      indexMenu={indexMenu}
    >
      <IconSvg
        image={icon}
        className="image-sidebar-menu"
        width={"20"}
        height={"20"}
      />
      <TextNav> {name} </TextNav>
    </ButtonNav>
  );
};

export default ButtonNavigator;

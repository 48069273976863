import styled from "styled-components";
import InputMask from "react-input-mask";

export const RootContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: #aaaaaa;
  border-radius: 10px;
  padding: 5px;
  margin-top: 20px;
`;

export const TextContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 5px;
`;

export const Text = styled.p`
  font-size: 16px;
  max-width: 500px;
  font-weight: bold;
  text-overflow: ellipsis;
  width: 100%;
  font-size: "Open Sans", sans-serif;
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: normal;
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const RightContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
`;

export const KeywordsDateContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  position: absolute;
  bottom: 5px;
  width: 100%;
  padding: 0 5px;
`;

export const Box = styled.div`
  width: 50%;
  height: 100%;
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  justify-content: space-evenly;
  position: relative;

  .image-position {
    position: absolute;
    right: 25px;
    top: 62px;
  }
`;

export const DateInput = styled(InputMask)`
  width: 100%;
  min-height: 80px;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid #9d9d9d;
  border-radius: 5px;
  padding: 1rem;
  color: black;
  font-size: 14px;
  resize: none;
  position: relative;
  &::placeholder {
    font-size: 14px;
    color: ${({ theme }) => theme.colors.gray300};
  }
`;

export const ErrorMessage = styled.p`
  color: red;
`;

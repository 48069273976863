import { createGlobalStyle } from "styled-components";
import { lighten } from "polished";

const GlobalStyle = createGlobalStyle`
   * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
    font-family: "Open Sans", sans-serif;
    :link {
      text-decoration: none !important;
    }

 

    p {
      margin: 0
    }
  }

  html {
    font-size: 10px;
  }

  ul, li {
    list-style: none;
  }
  button {
    cursor: pointer;
    border: none;
  }
  body {
    overflow: hidden;
    background: ${({ theme }) => theme.colors.white};
    -webkit-font-smoothing: antialiased;

    /* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}



select {
  -webkit-appearance: none;
  appearance: none;
}

.error-validation {
  color: red;
  margin-top: 1rem;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
  }


  .collapsible .content {
    padding: 6px;
    background-color: #eeeeee;
}

.collapsible .header {
    background-color: #dddddd;
    padding: 6px;
    cursor: pointer;
}

@media (max-width: 1366px) {
    .force-margin-select {
      transform: translateY(3px);
    }
}

.menu-aside-main {
  font-size: 1.4rem !important;
  font-weight: normal !important;
    margin-left: 4rem !important;

  @media (max-width: 1366px) {
    font-size: 1.3rem !important;
    margin-left: 3rem !important;
  }
} 

.active-link {
  color: white;
  display: flex;
  background: rgba(218,218,218,0.05);
  text-decoration: none;
  width: 100%;
  height: 60px;
  align-items: center;
  justify-content: flex-start;
  font-size: 1.8rem;
  color: #9d9d9d;
  padding-left: 2rem;

  &:hover {
  
  }
}

.hover-exit-button {
  &:hover {
    background: #252626;
  }
}

.inactive-link {
  color: white;
  display: flex;
  background: transparent;
  text-decoration: none;
  width: 100%;
  height: 60px;
  align-items: center;
  justify-content: flex-start;
  font-size: 1.8rem;
  color: #9d9d9d;
  padding-left: 2rem;

  &:hover {
    background: #252626;
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

:root {
  --rdp-cell-size: 40px;
  --rdp-accent-color: #0000ff;
  --rdp-background-color: ${({ theme }) => theme.colors.honey};
  /* Switch to dark colors for dark themes */
  --rdp-accent-color-dark: #3003e1;
  --rdp-background-color-dark: #180270;
  /* Outline border for focused elements */
  --rdp-outline: 2px solid var(--rdp-accent-color);
  /* Outline border for focused and selected elements */
  --rdp-outline-selected: 2px solid rgba(0, 0, 0, 0.75);
}

.loading-spinner {
  width: 20px;
  height: 20px;
  border: 2.5px solid #000; 
  border-top: 2.5px solid ${({ theme }) => theme.colors.honey}; 
  border-radius: 50%;
  animation: spinner 260ms linear infinite;
}

.active-link-final {
  color: white;
  display: flex;
  width: 100%;
  height: 60px;
  background: rgba(255, 255, 255, 0.15);
  align-items: center;
  justify-content: flex-start;
  font-size: 1.8rem;
  color: #9d9d9d;
  text-decoration: none; 
  padding-left: 2rem;

  &:hover {
    background: #252626;
  }
}

.inactive-link-final {
  color: white;
  display: flex;
  width: 100%;
  height: 60px;
  align-items: center;
  justify-content: flex-start;
  font-size: 1.8rem;
  text-decoration: none; 
  color: #9d9d9d;
  padding-left: 2rem;

  &:hover {
    background: #252626;
  }
}

.rdp-day_selected:not([aria-disabled='true']), .rdp-day_selected:focus:not([aria-disabled='true']), .rdp-day_selected:active:not([aria-disabled='true']), .rdp-day_selected:hover:not([aria-disabled='true']) {
  background-color: ${({ theme }) => theme.colors.honey} !important;
  span {
    color: black !important
  }
}

.button-excel {

cursor: pointer;
   :hover {
    background-color: ${({ theme }) => lighten(0.1, theme.colors.honey)};
    -webkit-box-shadow: 0px 0px 8px -5px #000000;
    box-shadow: 0px 0px 8px -5px #000000;
    
  }
}

.image-testing {
  margin: 0;
  margin-left: 4rem;
  margin-bottom: -0.5rem !important;
  width: 237px;
  height: auto;
  transform: translateX(20px);

  @media (max-width: 1720px) {
  width: 218px;
  transform: translateX(10px);
  }
}

@media (max-width: 1600px) {
 .image-testing {
  width: 200px;
  transform: translateX(4px);
 }
}



@media (max-width: 1366px) {
  .image-testing {
  margin: 0 auto;
  width: 190px;
  height: auto;
  margin-left: auto;
  margin-bottom: -1.5rem !important;
  transform: translateX(-3px);
}

.login-logo {
  width: 220px;
}

.image-sidebar-menu {
  width: 17px !important;
  height: auto !important;;
}

.icon-collapse-sidebar2 {
  width: 17px !important;
  height: auto;
}

.icon-collapse-sidebar {
width: 17px !important;
height: auto;
margin-right: -1rem;
}

}

`;

export default GlobalStyle;

import styled from "styled-components";

export const Box = styled.form`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Text = styled.span`
  font-size: 1.7rem;
  font-weight: normal;
  font-family: "Arial";
  margin: 1rem 0.8rem;
`;

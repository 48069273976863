import React from "react";
import {
  ButtonPag,
  ButtonPagActive,
  PaginationList,
  PaginationListItem,
} from "./styles";

import arrowLeft from "../../../dash/assets/arrow-left-pagination.svg";
import arrowRight from "../../../dash/assets/arrow-right.svg";

import arrowRightActive from "../../assets/arrow-right-active.svg";
import arrowLeftActive from "../../assets/arrow-left-pagination-active.svg";

interface PaginationProps {
  limit: number;
  total: number;
  offset: number;
  setDataNews: any;
  dataNews: any;
}

// import { Container } from './styles';
const MAX_ITEMS = 3;
const MAX_LEFT = (MAX_ITEMS + 1) / 2;

const Pagination: React.FC<PaginationProps> = ({
  limit,
  total,
  offset,
  setDataNews,
  dataNews,
}) => {
  const currentPage = offset + 1;
  const pages = total + 1;
  const first = Math.max(currentPage - MAX_LEFT, 1);

  const onPageChange = (page: any) => {
    setDataNews({ ...dataNews, offset: page - 1 });
  };

  return (
    <>
      {pages > 0 && (
        <PaginationList>
          <ButtonPag
            disabled={currentPage === 1}
            onClick={() => onPageChange(currentPage - 1)}
          >
            {currentPage === 1 ? (
              <img src={arrowLeft} alt="" />
            ) : (
              <img src={arrowLeftActive} alt="" />
            )}
          </ButtonPag>
          {Array.from({ length: Math.min(MAX_ITEMS, pages) })
            .map((_, index) => index + first)
            .map((page) => (
              <PaginationListItem key={String(Math.random())}>
                {page === currentPage ? (
                  <ButtonPagActive
                    onClick={() => onPageChange(page)}
                    className={"active-button"}
                  >
                    {page}
                  </ButtonPagActive>
                ) : page > total + 1 ? (
                  <></>
                ) : (
                  <ButtonPag
                    onClick={() =>
                      setDataNews({ ...dataNews, offset: page - 1 })
                    }
                    className={"active-button"}
                  >
                    {page}
                  </ButtonPag>
                )}
              </PaginationListItem>
            ))}
          <ButtonPag
            onClick={() => onPageChange(currentPage + 1)}
            style={{ borderRight: "none", borderLeft: "none" }}
            disabled={currentPage === total + 1}
          >
            {currentPage === total + 1 ? (
              <img src={arrowRight} alt="" />
            ) : (
              <img src={arrowRightActive} alt="" />
            )}
          </ButtonPag>
        </PaginationList>
      )}
    </>
  );
};

export default Pagination;

import styled from "styled-components";

export const FlexContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  padding: 0 2rem;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.gray200};
`;

export const WhiteBox = styled.div`
  width: 100%;
  min-height: 396px;
  margin-top: 2.5rem;
  background: white;
  display: flex;
  padding: 2rem;
  border-radius: 5px;
  border: 1px solid #dadada;
`;

export const FormContent = styled.div`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.gray100};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 4rem;

  span {
    font-weight: normal;
    margin-left: 1rem;
  }
`;

export const Text = styled.p`
  font-size: 1.6rem;
  color: black;
  margin: 1.5rem 0;
  font-weight: bold;

  @media (max-width: 1366px) {
    font-size: 1.4rem;
  }
`;

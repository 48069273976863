import React, { ReactNode, useState } from "react";
import useCollapse from "react-collapsed";
import IconSvg from "../../../../../login/components/IconSvg";
import { AccordionWrapper, ContentAccordion } from "./styles";

import arrowIcon from "../../../../assets/arrow-white.svg";

interface CollapseProps {
  collapseHeight?: string;
  children?: ReactNode;
  title: string;
}

// import { Container } from './styles';

const CollapseMenu: React.FC<CollapseProps> = ({
  collapseHeight,
  children,
  title,
}) => {
  const [isExpanded, setExpanded] = useState(false);
  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded });

  return (
    <AccordionWrapper collapsedHeight={collapseHeight} isExpanded={isExpanded}>
      <div
        className="button-header-collapsed"
        {...getToggleProps({
          onClick: () => setExpanded((prevExpanded) => !prevExpanded),
        })}
      >
        <div className="title-head">
          <span> {title.toLocaleUpperCase()} </span>
        </div>

        <div className="button-wrapper">
          {isExpanded ? (
            <IconSvg image={arrowIcon} width="12px" height="15px" />
          ) : (
            <IconSvg
              className="reflect-icon"
              image={arrowIcon}
              width="12px"
              height="15px"
            />
          )}
        </div>
      </div>
      <div className="content-accordion" {...getCollapseProps()}>
        <ContentAccordion collapsedHeight={collapseHeight}>
          {children}
        </ContentAccordion>
      </div>
    </AccordionWrapper>
  );
};

export default CollapseMenu;

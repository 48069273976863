import React, { useState } from "react";
import { Dropzone } from "../../../../shared/components/Dropzone/Dropzone";
import { Body, RootContainer } from "./styles";
import readXlsxFile from "read-excel-file";
import { toast } from "react-toastify";
import axios from "axios";
import { ContentCreatorHeader } from "../components/ContentCreatorHeader/ContentCreatorHeader";
import { LoadingOverlay } from "../../../../shared/components/LoadingOverlay/LoadingOverlay";
import * as yup from "yup";

export type INewsCsvImportProps = {};

export interface IArticle {
  title: string;
  description: string;
  image: string;
  date: string;
  link: string;
  article_text?: string;
  key_words: string;
  newspaper: Newspaper;
}

export enum Newspaper {
  Aljazeera = "Aljazeera",
  BBC = "BBC",
  Haaretz = "Haaretz",
  Independent = "Independent",
  SixthTone = "Sixthtone",
  Sky = "Sky News",
  TheGuardian = "The Guardian",
  TheHindu = "The Hindu",
  WashingtonPost = "Washington Post",
  Perspective = "Perspectives (University of Warwick)",
  BathMagazine = "Bath Magazine (University of Bath)",
}

const articleSchema = {
  Title: {
    prop: "title",
    type: String,
  },
  Description: {
    prop: "description",
    type: String,
  },
  "Image link": {
    prop: "image",
    type: String,
  },
  "Date of publication": {
    prop: "date",
    type: String,
  },
  "Article link": {
    prop: "link",
    type: String,
  },
  "Article text": {
    prop: "article_text",
    type: String,
  },
  "Key words": {
    prop: "key_words",
    type: String,
  },
};

const csvValidationSchema = yup.object({
  title: yup.string().required(`Title is required`),
  description: yup.string().required(`Description is required`),
  image: yup.string().required(`Image link is required`),
  link: yup.string().required(`Article link is required`),
  date: yup.string(),
  key_words: yup.string(),
  article_text: yup.string(),
  newspaper: yup.string(),
});

const arrayValidationSchema = yup
  .array()
  .of(csvValidationSchema)
  .min(1, "At least one item is required");

const NewsCsvImport: React.FC<INewsCsvImportProps> = ({}) => {
  const [isUploading, setIsUploading] = useState<boolean>(false);

  const onUploadPress = (files: File[]) => {
    setIsUploading(true);

    readXlsxFile(files[0], {
      sheet: "Articles",
      schema: articleSchema,
    }).then(({ rows, errors }) => {
      if (errors.length > 0) {
        setIsUploading(false);
        return toast.error("Something went wrong while parsing given file");
      }

      const rowsWithNewspaper = rows.map((article: IArticle) => ({
        ...article,
        newspaper: Newspaper.BathMagazine,
        date: article.date ?? new Date().toLocaleDateString(),
      })) as IArticle[];

      try {
        arrayValidationSchema.validateSync(rowsWithNewspaper, {
          abortEarly: false,
        });
      } catch (e) {
        if (Array.isArray(e.inner) && e.inner.length > 0) {
          const errorDetails = e.inner.map((error) => {
            const path = error.path || "unknown";
            return `Row ${path}: ${error.message}`;
          });

          toast.error(`File validation error: ${errorDetails.join(", ")}`);
        } else {
          toast.error("File validation error: Unknown error");
        }
      }

      uploadNews(rowsWithNewspaper);
    });
  };

  const uploadNews = async (data: IArticle[]) => {
    try {
      const cronApi = axios.create({
        baseURL: process.env.REACT_APP_CRON_API, //"cron job address",
      });

      let response = await cronApi.post("news/upload-multiple", data);
      if (response.status === 200) {
        toast.success("Articles successfully imported!");
      }
    } catch (e) {
      toast.error("Something went wrong while uploading news");
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <RootContainer>
      <ContentCreatorHeader />
      <Body>
        {isUploading ? (
          <LoadingOverlay
            text={
              "Importing your articles to our database. It will take some time,but you can close this window!"
            }
            onClose={() => setIsUploading(false)}
          />
        ) : (
          <Dropzone onUploadPress={onUploadPress} />
        )}
      </Body>
    </RootContainer>
  );
};

export { NewsCsvImport };

import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  background-color: #333333;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Text = styled.p`
  font-size: 1.4rem;
  color: #9d9d9d;
  cursor: "pointer";
  margin-top: 2rem;

  @media (max-width: 1366px) {
    font-size: 1.3rem;
  }
`;

import React, { useRef, useState } from "react";
import {
  CheckBox,
  CheckBoxLabel,
  CheckBoxWrapper,
  ContainerMain,
  Flex,
  GrayBox,
  Text,
} from "./styles";
import { Switch } from "@chakra-ui/switch";
import { Box } from "../ModelForms/styles";
import InputDefault from "../InputDefault";
import { useEffect } from "react";

// import { Container } from './styles';
interface IDataNotificationsApi {
  dataNotifications?: IObjectNotificationData;
  checkBoxRef?: any;
  isActiveNotification?: any;
  handleActiveNotification?: any;
  title?: any;
  setTitle?: any;
  text?: any;
  setText?: any;
  hour?: any;
  setHour?: any;
  minute?: any;
  setMinute?: any;
  setFormData?: any;
  formData?: any;
}

interface IObjectNotificationData {
  created_at: string;
  hour: number;
  id: string;
  is_active: boolean;
  minute: number;
  text: string;
  title: string;
  updated_at: string;
}

interface IHourProps {}

const NotificationArea: React.FC<IDataNotificationsApi> = ({
  dataNotifications,
  checkBoxRef,
  isActiveNotification,
  handleActiveNotification,
  title,
  text,
  hour,
  minute,
  setTitle,
  setMinute,
  setHour,
  setText,
  setFormData,
  formData,
}) => {
  // const [isActiveNotification, setIsActiveNotification] = useState(false);
  // const [notificationId, setNotificationId] = useState("");
  // const [title, setTitle] = useState("");
  // const [text, setText] = useState("");
  // const [hour, setHour] = useState(0);
  // const [minute, setMinute] = useState(0);

  // const checkBoxRef = useRef<any>(null);

  let obj = {
    notification_control_panel_id: "d1dec0fe-f9f4-4454-804c-4b4dac3e5c92",
    title: "Beehive News",
    text: "Good morning, there's news in the app!!",
    hour: 7,
    minute: 0,
    is_active: true,
  };

  // useEffect(() => {
  //   if (dataNotifications) {
  //     setNotificationId(dataNotifications?.id);
  //     setIsActiveNotification(dataNotifications.is_active);
  //     setTitle(dataNotifications?.title);
  //     setText(dataNotifications?.text);
  //     setHour(dataNotifications?.hour);
  //     setMinute(dataNotifications?.minute);
  //   }
  // }, [dataNotifications]);

  return (
    <ContainerMain>
      <GrayBox>
        <Flex>
          <CheckBoxWrapper>
            <CheckBox
              ref={checkBoxRef}
              checked={isActiveNotification}
              id="checkbox"
              type="checkbox"
              onClick={handleActiveNotification}
            />
            <CheckBoxLabel htmlFor="checkbox" />
          </CheckBoxWrapper>

          <Text>Send notifications</Text>
        </Flex>

        <Box style={{ marginTop: "1rem" }}>
          {/* <Text style={{ marginBottom: "1rem", marginTop: "2rem" }}>
            text in Message
          </Text> */}
          {/* <InputDefault
            placeholder={title}
            className="input-panel"
            type="text"
            value={formData.title}
            onChange={(e: any) =>
              setFormData({ ...formData, title: e.target.value })
            }
          /> */}

          <Text style={{ marginBottom: "1rem", marginTop: "2rem" }}>
            TEXT IN MESSAGE
          </Text>
          <InputDefault
            placeholder={text}
            type="text"
            className="input-panel"
            value={formData.text}
            onChange={(e: any) =>
              setFormData({ ...formData, text: e.target.value })
            }
          />

          <Text style={{ marginBottom: "1rem", marginTop: "2rem" }}>
            {"Sending time - hour (0-23) UTC".toUpperCase()}
          </Text>
          <InputDefault
            placeholder={hour}
            type="text"
            value={formData.hour}
            className="input-panel"
            onChange={(e: any) =>
              setFormData({ ...formData, hour: e.target.value })
            }
          />

          <Text style={{ marginBottom: "1rem", marginTop: "2rem" }}>
            {"Sending time - minute (0-59) UTC".toUpperCase()}
          </Text>
          <InputDefault
            placeholder={minute}
            type="text"
            className="input-panel"
            value={formData.minute}
            onChange={(e: any) =>
              setFormData({ ...formData, minute: e.target.value })
            }
          />
        </Box>
      </GrayBox>
    </ContainerMain>
  );
};

export default NotificationArea;

import React from "react";
import { IArticle } from "../../../../modules/dash/screen/NewsCsvImport/NewsCsvImport";
import { TextArea } from "../../TextArea/TextArea";
import { CustomButton } from "../styles";
import {
  TextContainer,
  Text,
  RootContainer,
  LeftContainer,
  RightContainer,
  KeywordsDateContainer,
  ButtonContainer,
  DateInput,
  ErrorMessage,
} from "./styles";

export type IImportListItemProps = {
  data: IArticle;
  index: number;
  remove: (index: number) => void;
  setFieldValue: any;
  setFieldTouched: any;
  errors?: any;
  touched?: any;
};

const ImportListItem: React.FC<IImportListItemProps> = ({
  data,
  index,
  remove,
  setFieldValue,
  setFieldTouched,
  errors,
  touched,
}) => {
  return (
    <RootContainer>
      <LeftContainer>
        <TextContainer>
          <Text style={{ fontWeight: "bold" }}>Title</Text>
          <TextArea
            placeholder="Title"
            value={data.title}
            onChange={(e: any) =>
              setFieldValue(`articles[${index}.title]`, e.target.value)
            }
            onBlur={() => setFieldTouched(`articles[${index}].title`)}
            rows={1}
          />
          {touched?.title && errors?.title && (
            <ErrorMessage>{errors.title}</ErrorMessage>
          )}
        </TextContainer>
        <TextContainer>
          <Text style={{ fontWeight: "bold" }}>Article link</Text>
          <TextArea
            value={data.link}
            placeholder="Article link"
            rows={1}
            onChange={(e: any) =>
              setFieldValue(`articles[${index}.link]`, e.target.value)
            }
            onBlur={() => setFieldTouched(`articles[${index}].link`)}
          />
          {touched?.link && errors?.link && (
            <ErrorMessage>Article link is required</ErrorMessage>
          )}
        </TextContainer>
        <TextContainer>
          <Text style={{ fontWeight: "bold" }}>Image link</Text>
          <TextArea
            value={data.image}
            placeholder="Image link"
            rows={1}
            onChange={(e: any) =>
              setFieldValue(`articles[${index}.image]`, e.target.value)
            }
            onBlur={() => setFieldTouched(`articles[${index}].image`)}
          />
          {touched?.image && errors?.image && (
            <ErrorMessage>Image link is required</ErrorMessage>
          )}
        </TextContainer>
        <KeywordsDateContainer>
          <LeftContainer>
            <TextContainer>
              <Text style={{ fontWeight: "bold" }}>Keywords (optional)</Text>
              <TextArea
                value={data.key_words}
                placeholder="Keywords"
                rows={1}
                onChange={(e: any) =>
                  setFieldValue(`articles[${index}.key_words]`, e.target.value)
                }
              />
            </TextContainer>
          </LeftContainer>
          <RightContainer>
            <TextContainer>
              <Text style={{ fontWeight: "bold" }}>
                Publication date (optional)
              </Text>
              <DateInput
                type="text"
                value={data.date}
                placeholder="Date (e.g. 2023-12-13 16:02)"
                mask={"9999/99/99 99:99"}
                className="input-mask"
                onChange={(e: any) =>
                  setFieldValue(`articles[${index}.date]`, e.target.value)
                }
              />
            </TextContainer>
          </RightContainer>
        </KeywordsDateContainer>

        <TextContainer>
          <Text style={{ fontWeight: "bold" }}>Description</Text>
          <TextArea
            value={data.description}
            placeholder="Description"
            rows={3}
            onChange={(e: any) =>
              setFieldValue(`articles[${index}.description]`, e.target.value)
            }
            onBlur={() => setFieldTouched(`articles[${index}].description`)}
          />
          {touched?.description && errors?.description && (
            <ErrorMessage>Description is required</ErrorMessage>
          )}
        </TextContainer>
      </LeftContainer>
      <RightContainer>
        <TextContainer>
          <Text style={{ fontWeight: "bold" }}>Article text (optional)</Text>
          <TextArea
            value={data.article_text}
            placeholder="Article text"
            rows={15}
            onChange={(e: any) =>
              setFieldValue(`articles[${index}.article_text]`, e.target.value)
            }
          />
        </TextContainer>
        <ButtonContainer>
          <CustomButton
            disabled={index === 0}
            onClick={() => {
              remove(index);
            }}
            color={"#EB655E"}
          >
            Remove
          </CustomButton>
        </ButtonContainer>
      </RightContainer>
    </RootContainer>
  );
};

export { ImportListItem };

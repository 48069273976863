import React from "react";
import { ImageSvg } from "./styles";

// import { Container } from './styles';

interface IconSvgProps {
  className?: string;
  image?: string;
  width?: string;
  height?: string;
}

const IconSvg: React.FC<IconSvgProps> = ({
  className,
  image,
  width,
  height,
}) => {
  return (
    <ImageSvg width={width} height={height} src={image} className={className} />
  );
};

export default IconSvg;

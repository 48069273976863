import React, { ChangeEvent } from "react";
import InputDefault from "../InputDefault";

import { Container, Heading, LegendInput, WrapperInputs, Text } from "./styles";

interface WeightOfUserTableProps {
  title?: string;
  legend?: string;
  type?: string;
  value?: string | number;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

const WeightOfUserTable: React.FC<WeightOfUserTableProps> = ({
  title,
  legend,
  type,
  value,
  onChange,
}) => {
  return (
    <Container>
      {title !== "" && <Heading> {title} </Heading>}
      <WrapperInputs>
        <LegendInput>
          <Text> {legend} </Text>
        </LegendInput>

        <InputDefault type={type} value={value} onChange={onChange} />
      </WrapperInputs>
    </Container>
  );
};

export default WeightOfUserTable;

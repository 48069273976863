import styled from "styled-components";

export const BoxDate = styled.div`
  width: 310px;
  height: 310px;
  position: absolute;
  background: white;
  left: 0;
  top: 60px;
  display: flex;
  border-radius: 5px;
  -webkit-box-shadow: 1px 1px 15px -1px rgba(0, 0, 0, 0.17);
  box-shadow: 1px 1px 15px -1px rgba(0, 0, 0, 0.17);
  z-index: 99;
`;

import styled from "styled-components";

interface BoxProps {
  marginTop?: string;
}

interface PropsRound {
  active?: boolean;
}

export const ContainerFull = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  padding: 2rem;
  background: #ebedef;
  overflow-y: auto;
  flex-direction: column;
`;

export const FlexFull = styled.div`
  width: 100%;
  min-height: auto;
  display: flex;
  flex-direction: column;
`;

export const ContainerWhite = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
`;

export const WrapperMain = styled.div`
  width: 100%;
  height: auto;
  background: white;
  display: flex;
  padding: 0rem;
  margin: 1rem 0;
  border-radius: 5px;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.colors.gray200};

  @media (max-width: 1600px) {
    .adjust-pagination-container {
      padding-right: 1rem;
      margin-top: 0rem;
    }
  }

  .adjust-pagination-container {
    padding-right: 1rem;
    margin-top: 1rem;
  }
`;

export const WrapperHeading = styled.div`
  display: flex;
  width: 100%;
  height: 5.1rem;
  background: ${({ theme }) => theme.colors.gray100};
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray200};
  align-items: center;
  padding: 0 1rem;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`;

export const Heading = styled.p`
  font-size: 1.6rem;
  font-weight: bold;

  @media (max-width: 1600px) {
    font-size: 1.4rem;
  }
`;

export const ControlMain = styled.div`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.gray100};
  border-end-start-radius: 5px;
  border-end-end-radius: 5px;
  display: flex;
  flex-direction: column;
`;

export const HStack = styled.div`
  display: flex;
`;

export const Box = styled.div<BoxProps>`
  width: 50%;
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : "4rem")};
  height: 47px;

  padding: 0 1rem;
`;

export const BoxFullwidth = styled.div`
  margin-top: 1rem;
  height: 47px;
  width: 100%;
  padding: 0 1rem;
`;

export const HorizontalFlex = styled.div`
  width: 100%;
  height: 260px;
  display: flex;
  margin-top: 4rem;
  align-items: center;
  padding: 1rem 2rem;
  justify-content: flex-start;
`;

export const ContainerImage = styled.div`
  width: 352px;
  height: 256px;
  background: ${({ theme }) => theme.colors.gray200};
`;

export const ButtonChooseImage = styled.button`
  background: ${({ theme }) => theme.colors.honey};
  width: 185px;
  height: 47px;
  color: black;
  margin-left: 4rem;
`;

export const NewsCounterMain = styled.div`
  display: flex;
  width: 100%;
  border-radius: 5px;
  margin-top: 2rem;
  min-height: 219px;
  background: white;
  flex-direction: column;
  padding: 1.5rem;
`;

export const NewsRelatedMain = styled.div`
  display: flex;
  width: 100%;
  border-radius: 5px;
  margin-top: 0rem;
  min-height: 219px;
  background: white;
  flex-direction: column;
`;

export const FlexButtons = styled.div`
  display: flex;
  width: 100%;
  margin-top: 2rem;
  justify-content: flex-end;
`;

export const ButtonSave = styled.button`
  width: 112px;
  height: 47px;
  color: black;
  background: ${({ theme }) => theme.colors.honey};
  border-radius: 5px;
  font-weight: bold;
  margin-top: 1rem;
  transition: all 120ms linear;
`;

export const ButtonDelete = styled.button`
  width: 112px;
  height: 47px;
  color: black;
  margin-left: 2rem;
  background: #b1b1b1;
`;

export const WrapperForm = styled.div`
  width: 100%;
  height: 100%;
  background: #f9fafa;

  padding: 1rem;
`;

export const WrapperRelatedNews = styled.div`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.gray100};
  margin-top: 2rem;
  padding: 1rem 2rem;

  @media (max-width: 1366px) {
    margin-top: 1rem;
  }
`;

export const Table = styled.table`
  width: 100%;
  padding: 0 1rem;
  border: none;
  align-items: center;

  .transform-to-main {
    transform: translateX(8px);
  }

  .transform-to-relate {
    transform: translateX(8px);
  }

  @media (max-width: 1366px) {
    .transform-to-main {
      transform: translateX(0px);
    }

    .transform-to-relate {
      transform: translateX(0px);
    }
  }

  .translate-to-text-center {
    transform: translateX(480px);
  }

  @media (max-width: 1780px) {
    .translate-to-text-center {
      transform: translateX(390px);
    }
  }

  @media (max-width: 1600px) {
    .translate-to-text-center {
      transform: translateX(330px);
    }
  }

  @media (max-width: 1400px) {
    .translate-to-text-center {
      transform: translateX(380px);
    }
  }

  @media (max-width: 1366px) {
    .translate-to-text-center {
      transform: translateX(280px);
    }
  }
`;

export const TableRow = styled.tr`
  text-align: left;
  width: 100%;
  height: 40px;
  justify-content: center;
  align-items: center;

  /* border-bottom: 1px solid ${({ theme }) => theme.colors.gray200}; */

  .force-tranform-title {
    transform: translateX(10px);
  }
`;

export const TableHead = styled.th`
  font-size: 1.5rem;
  font-weight: bold;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray200};

  @media (max-width: 1366px) {
    font-size: 1.4rem;
  }
`;

export const TableData = styled.td`
  height: 40px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray200};

  #transform-force-main-checkbox {
    transform: translateX(-10px);
  }

  #transform-force-related-checkbox {
    transform: translateX(10px);
  }

  & input[type="checkbox"] {
    width: 19px !important;
    height: 19px !important;
    border-radius: 20px !important;
    z-index: 9;
  }

  & input[type="checkbox"]:checked {
    width: 19px !important;
    height: 19px !important;
    border: 1px solid #9d9d9d;
    border-radius: 10px !important;
    color: white;
    accent-color: ${({ theme }) => theme.colors.honey} !important;
    display: flex;
    align-items: center;
    content: "✔";
    color: white !important;
  }
`;

export const Text = styled.td`
  font-size: 1.5rem;
  font-weight: normal;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 380px;

  @media (max-width: 1600px) {
    font-size: 1.4rem;
  }

  @media (max-width: 1366px) {
    font-size: 1.3rem;
  }
`;

export const HorizontalStack = styled.div`
  display: flex;
  width: 100%;
  height: 47px;
`;

export const ControlLeft = styled.div`
  display: flex;
  width: 65%;
  height: 100%;

  padding: 0 1rem;
`;

export const ControlCenter = styled.div`
  display: flex;
  width: 28%;
  height: 100%;
  padding: 0 1rem;
  margin-top: 1rem;
  position: relative;

  .image-position {
    position: absolute;
    right: 20px;
    top: 20px;
  }
`;

export const ControlRight = styled.div`
  display: flex;
  width: 7%;
  height: 100%;
  align-items: center;
  justify-content: flex-end;
  padding: 0 1rem;
  margin-top: 1rem;
`;

export const SearchBox = styled.div`
  width: 100%;
  height: 100%;
  border: 1px solid #9d9d9d;
  border-radius: 5px;
  display: flex;
  margin-top: 1rem;

  @media (max-width: 1366px) {
    max-height: 42px;
  }
`;

export const LeftBox = styled.div`
  width: 10%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 1rem;
`;

export const RightBox = styled.div`
  width: 90%;
  height: 100%;
  display: flex;
`;

export const InputSearchBox = styled.input`
  border: none;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  padding: 0 1rem;

  @media (max-width: 1600px) {
    font-size: 1.4rem;
  }
`;

export const ButtonMain = styled.button`
  width: 45px;
  height: 45px;
  background: ${({ theme }) => theme.colors.honey};
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 120ms linear;

  @media (max-width: 1366px) {
    width: 42px;
    height: 42px;
    transform: translateY(-2px);
  }
`;

export const Select = styled.select`
  width: 100%;
  height: 97.5%;
  border: 1px solid #9d9d9d;
  color: gray;
  border-radius: 5px;
  font-size: 14px;
  margin-top: 0.1rem;
  padding: 0 1.5rem;

  option {
    color: black;
    background: white;
    display: flex;
    white-space: pre;
    min-height: 20px;
    padding: 0px 2px 1px;
    display: flex !important;
    align-items: center;
  }

  @media (max-width: 1600px) {
    font-size: 1.4rem;
  }

  @media (max-width: 1366px) {
    max-height: 42px;
  }
`;

export const ButtonRemoveNewsRelated = styled.button`
  background: transparent;
  cursor: pointer;
`;

export const RoundCheck = styled.div`
  position: relative;

  label {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    height: 20px;
    left: 20px;
    position: absolute;
    top: 0;
    width: 20px;

    &:after {
      border: 1.5px solid #fff;
      border-top: none;
      border-right: none;
      content: "";
      height: 5px;
      left: 5px;
      opacity: 0;
      position: absolute;
      top: 5px;
      transform: rotate(-45deg);
      width: 9px;
    }
  }

  @media (max-width: 1702px) {
    label {
      left: 13px;
    }
  }

  @media (max-width: 1366px) {
    label {
      left: 6px;
    }
  }

  & input[type="checkbox"] {
    z-index: 20;
    opacity: 0;
  }

  & input[type="checkbox"]:checked + label {
    background-color: ${({ theme }) => theme.colors.honey} !important;
    border-color: ${({ theme }) => theme.colors.honey} !important;
  }

  & input[type="checkbox"] + label::after {
    opacity: 1 !important;
  }
`;

export const RoundCheckRelate = styled.div<PropsRound>`
  position: relative;
  cursor: pointer !important;
  label {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    height: 20px;
    left: 20px;
    position: absolute;
    top: 10px;
    width: 20px;
    z-index: 1;
    cursor: pointer !important;

    &:after {
      border: 1.5px solid #fff;
      border-top: none;
      border-right: none;
      content: "";
      height: 5px;
      left: 5px;
      opacity: 0;
      position: absolute;
      top: 5px;
      transform: rotate(-45deg);
      width: 9px;
    }
  }

  @media (max-width: 1702px) {
    label {
      left: 13px;
      top: 10px;
    }
  }

  @media (max-width: 1366px) {
    label {
      left: 19px;
      top: 10px;
    }
  }

  & input[type="checkbox"] {
    opacity: 0;
    position: relative;
    z-index: 20 !important;
  }

  & input[type="checkbox"]:checked {
    opacity: 0;
    z-index: 99;
    position: relative;
  }

  & input[type="checkbox"]:checked + label {
    background-color: ${({ theme }) => theme.colors.honey} !important;
    border-color: ${({ theme }) => theme.colors.honey} !important;
    margin-top: -1rem;
    cursor: pointer !important;
  }

  & input[type="checkbox"] + label::after {
    opacity: 1 !important;
  }

  z-index: 1;
`;

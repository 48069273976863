import React from "react";
import logoIcon from "./assets/logo-dash.svg";

import { Container, Text } from "./styles";

const MobileScreen: React.FC = () => {
  return (
    <Container>
      <img src={logoIcon} />
      <Text margin->Mobile version is not supported, go to a desktop.</Text>
    </Container>
  );
};

export default MobileScreen;

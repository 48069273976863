import React from "react";
import { TextAreaBase } from "./styles";

export type ITextAreaProps = React.TextareaHTMLAttributes<HTMLTextAreaElement>;

const TextArea: React.FC<ITextAreaProps> = ({ ...rest }) => {
  return <TextAreaBase {...rest} />;
};

export { TextArea };

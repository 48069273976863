import styled from "styled-components";
import * as Dialog from "@radix-ui/react-dialog";
import { darken, lighten } from "polished";

export const Overlay = styled(Dialog.Overlay)`
  position: fixed;
  width: 100vw;
  height: 100vh;
  inset: 0;
  background: rgba(0, 0, 0, 0.75);
`;

export const Title = styled(Dialog.Title)`
  margin-left: 1.5rem;
`;

export const Content = styled(Dialog.Content)`
  min-width: 30vw;
  border-radius: 6px;
  padding: 2.5rem 3rem;
  background: white;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-38%, -50%);

  span {
    margin-left: 1.5rem;
    font-family: "Open Sans", sans-serif;
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }
`;

export const CloseButton = styled(Dialog.Close)`
  position: absolute;
  background: transparent;
  border: 0;
  top: 1.5rem;
  right: 2rem;
  cursor: pointer;
`;

export const CloseButtonOut = styled(Dialog.Close)``;

export const HorizontalStack = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  margin-top: 2rem;

  button {
    width: 190px;
    height: 50px;
    border-radius: 5px;
    background-color: #c4c4c4;
    font-size: 1.4rem;
    font-weight: bold;

    @media (max-width: 1366px) {
      font-size: 1.3rem;
      width: 150px;
    }
  }

  .yes-button {
    background-color: ${({ theme }) => theme.colors.honey};

    :hover {
      background-color: ${({ theme }) => lighten(0.1, theme.colors.honey)};
      -webkit-box-shadow: 0px 0px 8px -5px #000000;
      box-shadow: 0px 0px 8px -5px #000000;
    }
  }

  .no-button {
    background-color: red;
    color: white;
    opacity: 0.6;

    :hover {
      background-color: ${({ theme }) => darken(0.1, "red")};
      -webkit-box-shadow: 0px 0px 8px -5px #000000;
      box-shadow: 0px 0px 8px -5px #000000;
    }
  }
`;

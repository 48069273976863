import React, { useEffect } from "react";
import Master from "../../Master";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../hooks/auth";
import { ROLES } from "../../../../hooks/useAuthorization";
import { ContentCreatorDashboard } from "../../screen/ContentCreatorDashboard/ContentCreatorDashboard";

// import { Container } from './styles';

const DashboardPage: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    const token = localStorage.getItem("@BEEHIVE:token");
    if (token) {
      navigate("/dashboard");
    } else {
      navigate("/login");
    }
  }, []);

  return (
    <>
      {user.role.name === ROLES.CONTENT_CREATOR ? (
        <ContentCreatorDashboard />
      ) : (
        <Master />
      )}
    </>
  );
};

export default DashboardPage;

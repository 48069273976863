import styled from "styled-components";

export const PaginationList = styled.ul`
  margin: 1rem 0;
  display: flex;
  align-items: center;
  border: 1px solid #9d9d9d;
  border-radius: 5px;
`;

export const PaginationListItem = styled.li`
  height: 45px;
  display: flex;
  align-items: center;

  .disable-page {
    background-color: #c4c4c4 !important;
    opacity: 0.1;
    color: black;
    cursor: not-allowed;
  }

  @media (max-width: 1366px) {
    height: 38px;
  }
`;

export const ButtonPag = styled.button`
  width: 41px;
  height: 45px;
  margin: 0 0rem;
  font-weight: bold;
  border-left: 1px solid #9d9d9d;
  border-right: 1px solid #9d9d9d;
  color: ${({ theme }) => theme.colors.honey};
  background: transparent;

  :first-child {
    border-left: none;
  }

  :last-child {
  }

  @media (max-width: 1366px) {
    height: 38px;
    width: 36px;
    font-size: 1.2rem;
  }
`;

export const ButtonPagActive = styled.button`
  width: 41px;
  height: 45px;
  margin: 0 0rem;
  font-weight: bold;
  border-left: 1px solid #9d9d9d;
  border-right: 1px solid #9d9d9d;
  color: ${({ theme }) => theme.colors.black};

  background: ${({ theme }) => theme.colors.honey};

  :first-child {
    border-left: none;
  }

  :last-child {
  }

  @media (max-width: 1366px) {
    height: 38px;
    width: 36px;
    font-size: 1.2rem;
  }
`;

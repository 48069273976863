import { HTMLProps } from "react";
import styled from "styled-components";

interface ButtonProps extends HTMLProps<HTMLButtonElement> {}

interface ContainerProps {
  isExpanded: boolean;
  collapsedHeight: number;
}

export const Container = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 2rem;
  overflow-y: scroll;
  background: #ebedef;

  .input-panel {
    width: 100%;
    height: 40px;
  }
`;

export const MainConfig = styled.div`
  width: 100%;
  background: white;
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
`;

export const ColumnFlex = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`;

export const BoxLabel = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 2rem;
  flex: 0.02;
  background: white;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray200};
`;

export const Label = styled.p`
  font-weight: normal;
  font-size: 1.6rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

export const Configs = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  background: white;
  padding: 1rem;
  flex-direction: column;
`;

export const Box = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const MainContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  background: #f9fafa;
  padding: 1rem 1rem;
`;

export const FlexMain = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Button = styled.button<ButtonProps>`
  width: 112px;
  height: 47px;
  margin-left: auto;
  margin-left: 1rem;
  background: ${({ theme }) => theme.colors.honey};
  font-weight: bold;
  border-radius: 5px;
  transition: all 120ms linear;
`;

export const LargeBox = styled.div`
  display: flex;
  width: 100%;

  align-items: center;
  justify-content: flex-end;
  margin-top: 1rem;
`;

// export const AccordionWrapper = styled.div<ContainerProps>`
//   width: 100%;
//   height: auto;
//   background: white;
//   display: flex;
//   flex-direction: column;

//   .button-header-collapsed {
//     width: 100%;
//     height: 51px;
//     background-color: #666666;
//     color: white;
//     display: flex;
//     align-items: center;
//     padding: 0 1.5rem;
//     justify-content: space-between;
//   }

//   .title-head {
//     display: flex;
//   }

//   .content-accordion {
//     width: 100%;
//     height: auto;
//   }

//   .reflect-icon {
//     transition: transform 0 ease;
//     transform: scaleY(-1);
//   }
// `;

export const ContentAccordion = styled.div`
  display: flex;
  width: 100%;
  height: 850px;
  background: red;
`;

export const HeaderBar = styled.div`
  width: 100%;
  height: 5%;
  background: red;
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    width: 25px;
    height: 25px;
    background-color: green;
    align-items: center;
    display: flex;
  }
`;

export const ContentBar = styled.div`
  width: 100%;
  height: 95%;
  background: green;
  display: flex;
`;

export const GrayWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: #f9fafa;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem;
`;

export const VerticalBox = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: column;
  margin-bottom: 2.4rem;

  .input-panel {
    width: 100%;
    height: 40px;
  }

  span {
    font-weight: bold;
    margin-bottom: 1.6rem;
    font-size: 1.4rem;
  }

  @media (max-width: 1366px) {
    margin-bottom: 0.5rem;
  }
`;

export const HorizontalBox = styled.div`
  width: 100%;
  height: 271px;
  display: flex;
  flex-direction: column;
  margin-top: 2rem;

  span {
    font-weight: bold;
    margin-bottom: 1.6rem;
    font-size: 1.4rem;
  }
`;

export const HorizontalWeights = styled.div`
  width: 100%;
  height: 331px;
  display: flex;
  margin-top: 6.4rem;
  flex-direction: column;

  span {
    font-weight: bold;
    margin-bottom: 1.6rem;
    font-size: 1.4rem;
  }
`;

export const InputHorizontal = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  margin: 1rem 0;
`;

export const LegendInput = styled.div`
  width: 20%;
  display: flex;
  height: 100%;
  align-items: center;

  span {
    font-weight: bold;
  }

  .legend-input {
    font-weight: 400;
    transform: translateY(7px);
  }
`;

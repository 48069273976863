import React, { ChangeEvent } from "react";
import InputDefault from "../InputDefault";

import { Heading, WrapperInput } from "./styles";

const OthersCPanel: React.FC<any> = ({ data, setData }) => {
  return (
    <>
      <WrapperInput>
        <Heading>Minimum number of ratings for weights to be activated</Heading>
        <InputDefault
          type="text"
          value={data?.minimum_number_votes_weight_rating}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setData({
              ...data,
              minimum_number_votes_weight_rating: Number(e.target.value),
            })
          }
          className="input-base"
        />
      </WrapperInput>

      <WrapperInput>
        <Heading>
          Word overlap index for related articles (0.XX) is equal or higher than
        </Heading>
        <InputDefault
          className="input-base"
          type="number"
          value={data?.news_related_percentage_equal_words}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setData({
              ...data,
              news_related_percentage_equal_words: Number(e.target.value),
            })
          }
        />
      </WrapperInput>

      <WrapperInput>
        <Heading style={{ marginTop: "1rem" }}>
          Criteria for selection of opposing article
        </Heading>
        <span className="text-span">
          1. Word overlap index for related articles (0.XX) is equal or higher
          than
        </span>

        <span className="text-span">
          2. Has different positioning (left x right)
        </span>
        <span className="text-span">
          3. Word overlap index for opposing articles (0.XX)
        </span>
        <InputDefault
          className="input-base"
          type="number"
          value={data?.opposing_view_equal_number_words}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setData({
              ...data,
              opposing_view_equal_number_words: Number(e.target.value),
            })
          }
        />
        <span className="text-span">
          4. Has a higher number of ratings, if difference in number of ratings
          is higher than (X%):
        </span>
        <InputDefault
          className="input-base"
          type="text"
          value={data?.minimum_percentage_among_votes}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setData({
              ...data,
              minimum_percentage_among_votes: Number(e.target.value),
            })
          }
        />
        <span className="text-span">
          5. If difference is smaller than the above, the one that has the most
          distant political position
        </span>
      </WrapperInput>

      <WrapperInput>
        <Heading>Rules for articles deletion</Heading>
        <span className="text-span">
          Delete if read count equal or less than
        </span>
        <InputDefault
          className="input-base"
          type="text"
          value={data?.delete_news_number_times_read}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setData({
              ...data,
              delete_news_number_times_read: Number(e.target.value),
            })
          }
        />

        <span className="text-span">AND</span>

        <span className="text-span">
          Delete if number of related articles less than
        </span>

        <InputDefault
          className="input-base"
          type="text"
          value={data?.delete_news_number_related}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setData({
              ...data,
              delete_news_number_related: Number(e.target.value),
            })
          }
        />

        <span className="text-span">AND</span>
        <span className="text-span">
          Delete if overall article score is less than
        </span>

        <InputDefault
          className="input-base"
          type="text"
          value={data?.delete_news_raitng}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setData({
              ...data,
              delete_news_number_related: Number(e.target.value),
            })
          }
        />

        <span className="text-span">AND</span>
        <span className="text-span">
          Delete if article publication date is equal or older than (in days)
        </span>

        <InputDefault
          className="input-base"
          type="text"
          value={data?.delete_news_day}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setData({
              ...data,
              delete_news_day: Number(e.target.value),
            })
          }
        />
      </WrapperInput>
    </>
  );
};

export default OthersCPanel;

import styled from "styled-components";

interface BoxProps {
  marginTop?: string;
}

interface ContainerImageProps {
  src?: string;
}

export const ContainerFull = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  padding: 2rem;
  background: ${({ theme }) => theme.colors.gray200};
  overflow-y: auto;
  flex-direction: column;
`;

export const FlexFull = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const WhiteContainer = styled.div`
  width: 100%;
  min-height: 732px;
  background: transparent;
  display: flex;
  padding: 1rem;
  border-radius: 5px;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.colors.gray200};
`;

export const HorizontalStack = styled.div`
  display: flex;
  height: 100px;
  align-items: center;

  .input-mask {
    width: 100%;
    height: 45px;
    background: ${({ theme }) => theme.colors.white};
    border: 1px solid #9d9d9d;
    border-radius: 5px;
    padding-left: 1rem;
    color: black;
    padding: 0 1rem;
    font-size: 1.25rem;
    &::placeholder {
      font-size: 1.25rem;
      color: ${({ theme }) => theme.colors.gray300};
    }

    @media (max-width: 1366px) {
      height: 42px;

      &::placeholder {
        font-size: 1.2rem;
        color: ${({ theme }) => theme.colors.gray300};
      }
    }
  }
`;

export const Box = styled.div<BoxProps>`
  width: 50%;
  height: 100%;
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : "4rem")};
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  justify-content: space-evenly;
  position: relative;

  .image-position {
    position: absolute;
    right: 25px;
    top: 62px;
  }
`;

export const Text = styled.span`
  font-size: 1.5rem;
  font-weight: normal;
  margin-bottom: 2rem;
`;

export const HorizontalFull = styled.div`
  width: 100%;
  display: flex;
  height: 95px;
  align-items: center;
`;

export const WrapperFull = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  justify-content: space-evenly;
`;

export const FlexContainer = styled.div`
  width: 100%;
  display: flex;
  padding: 1rem;
  margin: 2rem 0;
  align-items: center;
`;

export const ContainerImage = styled.div<ContainerImageProps>`
  width: 352px;
  height: 256px;
  background: ${({ theme }) => theme.colors.gray200};
  background-image: ${({ src }) => (src ? `url('${src}')` : "")};
  background-size: cover;
  background-repeat: no-repeat;
`;

export const ButtonChooseImage = styled.button`
  background: ${({ theme }) => theme.colors.honey};
  width: 185px;
  height: 45px;
  color: black;
  margin-left: 4rem;
  font-weight: bold;
  font-family: "Open Sans", sans-serif;
  border-radius: 5px;
  transition: all 120ms linear;

  @media (max-width: 1366px) {
    font-size: 1.4rem;
  }
`;

export const ButtonSave = styled.button`
  width: 112px;
  height: 45px;
  display: flex;
  color: black;
  background: ${({ theme }) => theme.colors.honey};
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  font-weight: bold;
  border-radius: 5px;
  transition: all 120ms linear;
  margin-left: auto;
  margin-right: 1rem;

  @media (max-width: 1366px) {
    font-size: 1.4rem;
  }
`;

export const WrapperButton = styled.div`
  width: 100%;
  height: 150px;
  padding: 2rem 0;
  display: flex;
  justify-content: flex-end;
`;

export const Select = styled.select`
  width: 100%;
  height: 45px;
  border: 1px solid #9d9d9d;
  color: gray;
  border-radius: 5px;
  font-size: 14px;

  option {
    color: black;
    background: white;
    display: flex;
    white-space: pre;
    min-height: 20px;
    padding: 0px 2px 1px;
  }

  @media (max-width: 1366px) {
    height: 42px !important;
  }
`;

import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  background-color: #f8f9fa;

   .labels{
    font-weight: 700;
  }

  .input-style{
    background-color: #FFF !important;
    font-size: 14px !important;
    height: 45px !important;
  }

  .placeholder-style{
    display: none !important;
  }
`;

export const TopGap = styled.div`
  height: 25px;
  width: 100%;
`;

export const BoxInput = styled.div`
  border: 1px solid orange;
  min-height: 20pt;
  `;

export const PriceBox = styled.div`
display: flex;
width: 100%;
justify-content: space-between;
margin-top: 20px;
transform: translateY(-25px);

`;

export const BoxLinks = styled.div`
margin-top: auto;
display: flex;
flex-direction: column;
padding: 0;
`;

export const ButtonLabel = styled.label`
padding: 0;
text-transform: lowercase !important;
font-weight: 800;
transform: translateY(2px);

::first-letter {
  text-transform: uppercase;
}
`;

export const LabelLink = styled.a`
`;
export const LabelLinkLabel = styled.label`

color: #333;
font-weight: 400;
text-align: left;
margin-bottom: 10px;
font-style: italic !important;
text-decoration: underline !important;
`;

import React from "react";
import { DotAvatar } from "./styles";

// import { Container } from './styles';

const DotComponent: React.FC = () => {
  return (
    <DotAvatar>
      <span>BN</span>
    </DotAvatar>
  );
};

export default DotComponent;

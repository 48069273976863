import React from "react";
import IconSvg from "../../../login/components/IconSvg";
import {
  CollapseList,
  CollapseListItem,
  Container,
  FlexDiv,
  Text,
} from "./styles";
import useCollapse from "react-collapsed";
import newsIcon from "../../assets/icon-dash-news.svg";
import arrowIcon from "../../assets/icon-dash-arrow.svg";
import arrowIconDown from "../../assets/icon-arrowdown-dash.svg";
import { NavLink, useNavigate } from "react-router-dom";

// import { Container } from './styles';

interface ICollapseNewsProps {
  currentPath?: string;
}

const CollapseNews: React.FC<ICollapseNewsProps> = ({ currentPath }) => {
  const navigator = useNavigate();
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();

  return (
    // <ButtonNav>
    //   <IconSvg width={"20"} height={"20"} />
    //   <TextNav> </TextNav>
    // </ButtonNav>

    // {isExpanded ? "Collapse" : "Expand"}
    <Container isExpanded={isExpanded}>
      <div className="button-navigator">
        <div
          style={{ cursor: "pointer" }}
          className="heade222r"
          {...getToggleProps()}
        >
          <FlexDiv>
            <IconSvg
              image={newsIcon}
              width="21px"
              height="16px"
              className="icon-collapse-sidebar2"
            />
            <Text>Articles</Text>
          </FlexDiv>
          {isExpanded ? (
            <IconSvg image={arrowIcon} width="12px" height="15px" />
          ) : (
            <IconSvg image={arrowIconDown} width="12px" height="15px" />
          )}
        </div>
        <div className="content-collapse" {...getCollapseProps()}>
          <CollapseList>
            <NavLink
              className={({ isActive }) =>
                isActive ? "active-link" : "inactive-link"
              }
              to="/news/list/"
              end
            >
              <span className="menu-aside-main">Edit list of articles</span>
            </NavLink>
            <NavLink
              className={({ isActive }) =>
                isActive ? "active-link" : "inactive-link"
              }
              to="/news/add/"
              end
            >
              <span className="menu-aside-main">Add articles</span>
            </NavLink>
            <NavLink
              className={({ isActive }) =>
                isActive ? "active-link" : "inactive-link"
              }
              to="/news/relate"
              end
            >
              <span className="menu-aside-main">Relate articles</span>
            </NavLink>
            <NavLink
              to="/news/images"
              className={({ isActive }) =>
                isActive ? "active-link-final" : "inactive-link-final"
              }
            >
              <span className="menu-aside-main">Edit image catalogue</span>
            </NavLink>
          </CollapseList>
        </div>
      </div>
    </Container>
  );
};

export default CollapseNews;

import { lighten } from "polished";
import styled from "styled-components";

const getColor = (props) => {
  if (props.isDragAccept) {
    return "#00e676";
  }
  if (props.isDragReject) {
    return "#ff1744";
  }
  if (props.isFocused) {
    return "#2196f3";
  }
  return "#eeeeee";
};

export const RootContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  padding: 30px;
  height: 100%;
`;

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: black;
  font-size: 20px;
  outline: none;
  transition: border 0.24s ease-in-out;
  height: 100%;
`;

export const UploadCsvFile = styled.button`
  width: 150px;
  height: 45px;
  display: flex;
  background-color: ${({ theme }) => theme.colors.honey};
  color: black;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  border-radius: 5px;
  font-weight: bold;
  position: absolute;
  right: 50px;
  text-align: center;

  transition: background 120ms linear;

  :hover {
    background: ${({ theme }) => lighten(0.1, theme.colors.honey)};
    -webkit-box-shadow: 0px 0px 8px -5px #000000;
    box-shadow: 0px 0px 8px -5px #000000;
  }

  :disabled {
    cursor: "not-allowed";
    opacity: 0.2;
  }

  @media (max-width: 1366px) {
    font-size: 1.3rem;
    width: 133px;
    height: 42px;
  }
`;

import styled from "styled-components";

export const RootContainer = styled.div`
  width: 100vw;
  height: 100vh;
`;

export const Body = styled.div`
  height: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
`;

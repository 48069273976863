import { HTMLProps } from "react";
import styled from "styled-components";

interface ContainerProps {
  isExpanded: boolean;
}

export const Container = styled.div<ContainerProps>`
  .heade222r {
    display: flex;
    width: 100%;
    height: 60px;
    background: ${({ isExpanded }) => (isExpanded ? "black" : "")};
    align-items: center;
    padding-left: 0.5rem;
    justify-content: space-between;
    padding-right: 3rem;

    .content-collapse {
      width: 100%;
      height: 300px;
      background-color: red;
    }

    &:hover {
      background: ${({ isExpanded }) => (isExpanded ? "black" : "#252626")};
    }
  }
`;

export const CollapseList = styled.ul`
  width: 100%;
  height: 240px;
  background: black;

  .activeLink {
    width: 100%;
    height: 100%;
    color: red;
  }

  span {
    color: black;
    color: #666666;
    font-size: 1.4rem;
    margin-left: 1rem;
  }
`;

export const CollapseListItem = styled.li`
  color: white;
  display: flex;
  width: 100%;
  height: 60px;
  align-items: center;
  justify-content: flex-start;
  font-size: 1.8rem;
  color: #9d9d9d;
  padding-left: 2rem;

  @media (max-width: 1366px) {
    font-size: 1.2rem;
  }

  &:hover {
    background: #252626;
  }
`;

export const FlexDiv = styled.div`
  width: 30%;
  padding-left: 2rem;
  height: 100%;
  display: flex;
  align-items: center;
`;

export const Text = styled.div`
  font-size: 1.4rem;
  color: #9d9d9d;
  margin-left: 2.5rem;
  font-weight: normal;

  @media (max-width: 1366px) {
    font-size: 1.3rem;
  }
`;

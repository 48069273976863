/*
 * page is
 stopword update feature
 */

import React, { useCallback, useState } from "react";
import InputDefault from "../../PanelControl/components/InputDefault";
import { useEffect } from "react";
import api from "../../../../../services/api";
import Pagination from "../../../components/Pagination";
import MoonLoader from "react-spinners/MoonLoader";
import { css } from "@emotion/react";
import { toast } from "react-toastify";

//icons
import checkIcon from "../../../../dash/assets/icon-check.svg";
import spamIcon from "../../../../dash/assets/spam-icon.svg";

//styles

import {
  Button,
  ButtonSendWords,
  FlexBody,
  FlexContainerFull,
  FlexHeader,
  Heading,
  StackWords,
  TitleWords,
  WhiteBox,
  WriteWords,
  Text,
  ButtonSpam,
  FlexPagination,
} from "./styles";

//set up interfaces
interface IrrelevantWordsApiResponse {
  id: string;
  word: string;
  created_at: string;
  updated_at: string;
}

const IrrelevantPage: React.FC = () => {
  //this main object for params
  //control and set up pagination offset utils
  const [params, setParams] = useState({
    offset: 0,
  });

  //and set up all necessary states
  const [term, setTerm] = useState("");
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(0);
  const [color, setColor] = useState("#FFC600");
  const [dataWords, setDataWords] = useState<IrrelevantWordsApiResponse[]>([]);
  const [addNewWord, setAddNewWord] = useState(false);

  //here we just send to api the
  // term that the user wants to
  // make irrelevant for api
  const handleButtonClickWords = useCallback(async () => {
    let ObjectForApi = {
      array_word: [
        {
          word: term,
        },
      ],
    };
    // here just verify if have term for send api
    // to prevent any api error
    if (term !== "") {
      try {
        const responseCreateWord = await api.post(
          "irrelevant-words",
          ObjectForApi
        );

        toast.success("Word added with success!");
        setAddNewWord((prevState) => !prevState);
      } catch (err) {
        console.log(err, "deu erro");
      }
    }
  }, [term]);

  //here the purpose is to remove the selected term
  const handleRemoveTerms = useCallback(async (id: string) => {
    try {
      const responseRemoveTerm = await api.delete(`irrelevant-words/${id}`);
      //check it if o backend accept and show success message
      if (responseRemoveTerm.data.response === "Successfully deleted") {
        toast.success("Word removed with success!");
      } else {
        //if error show message bad request
        toast.error("Something went wrong!");
      }
      //update our state of words
      setAddNewWord((prevState) => !prevState);
    } catch (err) {
      //reject
      console.log(err, "erro when remove term");
    }
  }, []);

  // loading existing words
  useEffect(() => {
    const autoLoad = async () => {
      const responseApi = await api.get("irrelevant-words", {
        params: params,
      });
      setDataWords(responseApi.data.listWords);
      setTotalPages(responseApi.data.pagination);
      setLoading(false);
    };

    autoLoad();
  }, [params, addNewWord]);

  //just styles for loading
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

  return (
    <FlexContainerFull>
      {loading ? (
        <div
          style={{
            height: "80vh",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <MoonLoader
            color={color}
            loading={loading}
            css={override}
            size={65}
            speedMultiplier={1.2}
          />
        </div>
      ) : (
        <WhiteBox>
          <FlexHeader>
            <WriteWords>
              <InputDefault
                value={term}
                onChange={(event: any) => setTerm(event.target.value)}
                type="text"
                placeholder="Write the word"
              />
            </WriteWords>
            <ButtonSendWords>
              <Button className="button-excel" onClick={handleButtonClickWords}>
                <img src={checkIcon} />
              </Button>
            </ButtonSendWords>
          </FlexHeader>
          <FlexBody>
            <StackWords>
              <TitleWords>
                <Heading>Words</Heading>
              </TitleWords>
              {dataWords.length > 0 &&
                dataWords.map((word) => (
                  <TitleWords key={word.id}>
                    <Text> {word.word} </Text>
                    <ButtonSpam onClick={() => handleRemoveTerms(word.id)}>
                      <img src={spamIcon} />
                    </ButtonSpam>
                  </TitleWords>
                ))}

              {dataWords.length === 0 && (
                <Text style={{ margin: "auto auto" }}>
                  To start enter a word
                </Text>
              )}
            </StackWords>
          </FlexBody>
          <FlexPagination>
            <Pagination
              limit={10}
              total={totalPages}
              offset={params?.offset}
              setDataNews={setParams}
              dataNews={params}
            />
          </FlexPagination>
        </WhiteBox>
      )}
    </FlexContainerFull>
  );
};

export default IrrelevantPage;

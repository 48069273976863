/*this screen contains responsability of show details
of user selected by id params */

import { css } from "@emotion/react";
import { format, utcToZonedTime, getTimezoneOffset } from "date-fns-tz";

// import ptBR from "date-fns/locale/pt-BR";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import MoonLoader from "react-spinners/MoonLoader";
import api from "../../../../../../services/api";

import { FlexContainer, FormContent, Text, WhiteBox } from "./styles";
//set up interfaces
interface DataUserViewProps {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  avatar: string;
  political_position: string;
  political_vote: string;
  political_economic_views: string;
  political_social_views: string;
  born_at: string;
  region: string;
  isAdmin: boolean;
  is_blocked: boolean;
  notification_token: string;
  which_social_login?: string;
  sign_up_first_time_with_social_media?: string;
  payment_id?: string;
  created_at: string;
  updated_at: string;
  avatar_url: string;
}

interface DataMainProps {
  data?: DataUserViewProps;
  isLoading?: boolean;
}

const ViewMain: React.FC<DataMainProps> = ({ data, isLoading }) => {
  //set up states
  const [color, setColor] = React.useState("#FFC600");
  const [userProfile, setUserProfile] = useState({} as DataUserViewProps);
  const [loading, setLoading] = useState(true);
  const [regionProfile, setRegionProfile] = useState("");
  const [areaProfile, setAreaProfile] = useState("");
  const { iduser } = useParams();
  //styles for loading component
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

  //this effect for fetch data and update on our state main
  //for show details of specific user by id
  useEffect(() => {
    const autoLoadUserView = async () => {
      try {
        const responseUserId = await api.get(`users/${iduser}`);
        setUserProfile(responseUserId.data);
        setLoading(false);
        const [region, area] = responseUserId.data.region!.split("#@@#");
        setRegionProfile(region);
        setAreaProfile(area);
      } catch (err) {
        console.log(err, "ERROR when fetch profile user");
      }
    };
    autoLoadUserView();
  }, []);

  //just formatted zone time for zero UTC and format date for UI
  const formatDate = (date: string) => {
    let myObject = date;

    if (!myObject) {
      return;
    }

    const nyTimeZone = "UTC";
    const myDate = utcToZonedTime(myObject, nyTimeZone);
    const niceDate = format(myDate, "yyyy/MM/dd - HH:mm", {
      timeZone: "UTC",
    });

    return `${niceDate}`;
  };

  return (
    <div>
      <FlexContainer>
        {loading ? (
          <div
            style={{
              height: "80vh",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <MoonLoader
              color={color}
              loading={loading}
              css={override}
              size={65}
              speedMultiplier={1.2}
            />
          </div>
        ) : (
          <WhiteBox>
            <FormContent>
              <Text>
                Name:
                <span>{`${userProfile?.first_name} ${userProfile?.last_name}`}</span>
              </Text>
              <Text>
                Email:
                <span>{`${userProfile?.email} `}</span>
              </Text>
              <Text>
                Date of birth:
                <span>
                  {userProfile.born_at && formatDate(userProfile?.born_at)}
                </span>
              </Text>
              <Text>
                City: <span>{regionProfile} </span>
              </Text>
              <Text>
                Country: <span> {areaProfile}</span>
              </Text>
              <Text>
                Political Positioning:
                <span>
                  {`${Number(userProfile?.political_position).toFixed(1)}`}{" "}
                </span>
              </Text>
              <Text>
                Registration date:
                <span>
                  {userProfile.created_at &&
                    formatDate(userProfile?.created_at)}
                </span>
              </Text>
            </FormContent>
          </WhiteBox>
        )}
      </FlexContainer>
    </div>
  );
};

export default ViewMain;

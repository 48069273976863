import { HTMLProps } from "react";
import styled from "styled-components";

interface ButtonNavProps extends HTMLProps<HTMLButtonElement> {
  isActive?: boolean;
  index?: number;
  indexMenu?: number;
}

export const ButtonNav = styled.button<ButtonNavProps>`
  display: flex;
  width: 100%;
  min-height: 60px;
  align-items: center;
  padding-left: 0.5rem;
  background: ${({ isActive, index, indexMenu }) =>
    isActive && index === indexMenu ? "transparent" : "transparent"};

  /* &:hover {
    background: rgb(157, 157, 157, 0.03);
  } */

  @media (max-width: 1366px) {
  }
`;

export const TextNav = styled.span`
  font-size: 1.4rem;
  margin-left: 2.5rem;
  color: #9d9d9d;
  font-weight: normal;

  @media (max-width: 1366px) {
    font-size: 1.3rem;
  }
`;

/*
  This screen has the functionality to change the questions that are displayed
  in the feedback area of the beehive application
*/

import React, { useCallback, useEffect, useState } from "react";

import {
  Button,
  Container,
  Heading,
  LightGrayContainer,
  WhiteContainer,
  WrapperHeading,
  InputFeedback,
  LoadingContainer,
} from "./styles";

import { NavLink, useNavigate } from "react-router-dom";
import {
  AsideControls,
  Box,
  Header,
  MasterContainer,
  NavLeft,
  NavRight,
  TextHeader,
} from "../../Master/styles";
import IconSvg from "../../../login/components/IconSvg";
import CollapseNews from "../../components/CollapseNews";
import ButtonNavigator from "../../components/ButtonNavigator";

import dashIcon from "../../assets/icon-dash.svg";
import feedbackIcon from "../../assets/icon-dash-feedback.svg";
import subsIcon from "../../assets/icon-dash-subs.svg";
import usersIcon from "../../assets/icon-dash-users.svg";

import logoBeehive from "../../assets/logo-dash.svg";
import iconExit from "../../../dash/assets/icon-exit.svg";
import api from "../../../../services/api";
import { useAuth } from "../../../../hooks/auth";
import CollapseControlPanel from "../../components/CollapseControlPanel";
import { GrayBox } from "../PanelControl/components/NotificationArea/styles";
import { toast } from "react-toastify";
import MoonLoader from "react-spinners/MoonLoader";
import { css } from "@emotion/react";
import DotComponent from "../../components/DotComponent";

// set up interfaces
interface ObjectQuestionsProps {
  created_at: string;
  id: string;
  question1: string;
  question2: string;
  question3: string;
  question4: string;
  question5: string;
  question6: string;
  question7: string;
  updated_at: string;
}

interface ObjectQuestionsApiProps {
  feedback_question_id: string;
  question1: string;
  question2: string;
  question3: string;
  question4: string;
  question5: string;
  question6: string;
  question7: string;
}

interface ResponseAnswersProps {
  articles_grouped: string;
  created_at: string;
  diversity_outlets: number;
  id: string;
  missing_any_newspaper: string;
  missing_any_newspaper_yes: string;
  missing_any_topics: string;
  missing_any_topics_yes: string;
  other_comments: string;
  other_comments_yes: string;
  political_underrepresented: string;
  quality_news_beehive: number;
  updated_at: string;
  user_id: string;
}

//buttons for display on UI sidebar
const buttonsVector = [
  { name: "Feedback", icon: feedbackIcon, type: "Feedback" },
  { name: "Subscription", icon: subsIcon, type: "Subs" },
  { name: "Subscribers", icon: usersIcon, type: "Users" },
];

const FeedbackEditScreen: React.FC = () => {
  const navigate = useNavigate();
  const { signOut } = useAuth();

  //set up states
  const [color, setColor] = useState("#FFC600");
  const [indexMenu, setIndexMenu] = useState(0);
  const [screen, setScreen] = useState<
    "Dashboard" | "Panel" | "Feedback" | "Subs" | "Users" | "IrrelevantWords"
  >("Dashboard");
  const [active, setActive] = useState({
    index: 0,
    visible: false,
  });
  const [answers, setAnswers] = useState<ResponseAnswersProps>({
    articles_grouped: "",
    created_at: "",
    diversity_outlets: 0,
    id: "",
    missing_any_newspaper: "",
    missing_any_newspaper_yes: "",
    missing_any_topics: "",
    missing_any_topics_yes: "",
    other_comments: "",
    other_comments_yes: "",
    political_underrepresented: "",
    quality_news_beehive: 0,
    updated_at: "",
    user_id: "",
  });
  const [questions, setQuestions] = useState<ObjectQuestionsProps>({
    created_at: "",
    id: "",
    question1: "",
    question2: "",
    question3: "",
    question4: "",
    question5: "",
    question6: "",
    question7: "",
    updated_at: "",
  });
  const [questionsApi, setQuestionsApi] = useState<ObjectQuestionsApiProps>({
    feedback_question_id: "",
    question1: "",
    question2: "",
    question3: "",
    question4: "",
    question5: "",
    question6: "",
    question7: "",
  });
  const [loading, setLoading] = useState(false);
  const [loadingFirst, setLoadingFirst] = useState(true);

  /*
  force state here:
  since the user is navigating through the navigator,
  update my state by forcing it
  */
  useEffect(() => {
    setScreen("Feedback");
  }, []);

  //then handle which screen user will be
  //but it was already set anyway
  // via useEffect forcing it
  const handleType = useCallback(
    (value: any, index: number) => {
      switch (value) {
        case "Dashboard":
          setScreen("Dashboard");
          break;
        case "Panel":
          setScreen("Panel");
          break;
        case "Feedback":
          setScreen("Feedback");
          break;
        case "Subs":
          setScreen("Subs");
          break;
        case "Users":
          setScreen("Users");
          break;

        case "IrrelevantWords":
          setScreen("IrrelevantWords");
          break;

        default:
          break;
      }

      setIndexMenu(index);
      active.visible = true;
    },
    [screen]
  );

  /*
  when entering the screen, pull from the api the questions that are in effect
  in the application and click on the state to list them in the inputs that
  can be edited these questions
  */
  useEffect(() => {
    const autoLoad = async () => {
      try {
        const responseDefaultQuestions = await api.get(`feedback-question`);
        setQuestions(responseDefaultQuestions.data);

        setQuestionsApi((state) => {
          let cloneState = { ...state };

          cloneState = {
            ...state,
            feedback_question_id: responseDefaultQuestions.data.id,
            question1: responseDefaultQuestions.data.question1,
            question2: responseDefaultQuestions.data.question2,
            question3: responseDefaultQuestions.data.question3,
            question4: responseDefaultQuestions.data.question4,
            question5: responseDefaultQuestions.data.question5,
            question6: responseDefaultQuestions.data.question6,
            question7: responseDefaultQuestions.data.question7,
          };

          return cloneState;
        });

        setLoadingFirst(false);
      } catch (err) {
        console.log("erro", err);
      }
    };

    autoLoad();
  }, []);

  /*
  function to detect the changes and when clicking on the button it will trigger
  and send the new information to the api and thus modifying the questions in
  the application
  */
  const handleSubmitQuestions = useCallback(async () => {
    try {
      setLoading(true);
      const responseUpdateQuestions = await api.put(
        "feedback-question",
        questionsApi
      );
      toast.success("Save succesfully!");
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error("Something is wrong!");
    }
  }, [questionsApi]);

  //component of loading spinner
  const LoadingSpinner = () => {
    return (
      <div className="spinner-container">
        <div className="loading-spinner"></div>
      </div>
    );
  };

  //some styles for props of lib to loading
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

  return (
    <>
      <MasterContainer>
        <AsideControls>
          <NavLink to={"/"}>
            <Box>
              <IconSvg
                className="image-testing"
                image={logoBeehive}
                width="208px"
                height="43px"
              />
            </Box>
          </NavLink>

          <NavLink
            className={({ isActive }) =>
              isActive ? "active-link" : "inactive-link"
            }
            to={`/dashboard`}
            end
          >
            <ButtonNavigator
              onClick={() => handleType("Dashboard", 0)}
              name={"Dashboard"}
              icon={dashIcon}
              index={0}
              indexMenu={indexMenu}
              isActive={active.visible}
            />
          </NavLink>

          <CollapseControlPanel />
          <CollapseNews />
          {buttonsVector.map((button, index) => (
            <NavLink
              className={({ isActive }) =>
                isActive ? "active-link" : "inactive-link"
              }
              to={`/${button.type.toLocaleLowerCase()}`}
              end
            >
              <ButtonNavigator
                key={button.name}
                onClick={() => handleType(button.type, index + 1)}
                name={button.name}
                icon={button.icon}
                index={index + 1}
                indexMenu={indexMenu}
                isActive={active.visible}
              />
            </NavLink>
          ))}
          <div
            className="hover-exit-button"
            style={{
              width: "100%",
              height: "60px",
              paddingLeft: "2rem",
            }}
          >
            <ButtonNavigator
              onClick={() => signOut()}
              name={"Exit"}
              icon={iconExit}
              indexMenu={indexMenu}
              isActive={active.visible}
            />
          </div>
        </AsideControls>
        <Header>
          <NavLeft>
            <TextHeader
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/dashboard")}
            >
              <u>
                <i> Home </i>{" "}
              </u>
              /{" "}
            </TextHeader>
            <button style={{ background: "transparent" }}>
              <TextHeader
                onClick={() => navigate("/feedback")}
                style={{ marginLeft: "0.6rem", cursor: "pointer !important" }}
                color="#666666"
              >
                {screen === "Panel"
                  ? `Painel de Controle`
                  : screen === "Subs"
                  ? `Assinatura`
                  : screen === "Users"
                  ? `Usuários`
                  : screen === "Feedback"
                  ? `Feedback / Change questions`
                  : screen}
              </TextHeader>
            </button>
          </NavLeft>
          <NavRight>
            <DotComponent />
          </NavRight>
        </Header>

        <Container>
          {loadingFirst ? (
            <div
              style={{
                height: "80vh",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <MoonLoader
                color={color}
                loading={loadingFirst}
                css={override}
                size={65}
                speedMultiplier={1.2}
              />
            </div>
          ) : (
            <>
              <WhiteContainer>
                <WrapperHeading>
                  <Heading>Change descriptions</Heading>
                </WrapperHeading>
                <LightGrayContainer>
                  <GrayBox>
                    <InputFeedback
                      type="text"
                      placeholder={`${questionsApi.question1}`}
                      value={questionsApi.question1}
                      onChange={(e) =>
                        setQuestionsApi({
                          ...questionsApi,
                          question1: e.target.value,
                        })
                      }
                    />
                    <InputFeedback
                      type="text"
                      placeholder={`${questionsApi.question2}`}
                      value={`${questionsApi.question2}`}
                      onChange={(e) =>
                        setQuestionsApi({
                          ...questionsApi,
                          question2: e.target.value,
                        })
                      }
                    />
                    <InputFeedback
                      type="text"
                      placeholder={`${questionsApi.question3}`}
                      value={`${questionsApi.question3}`}
                      onChange={(e) =>
                        setQuestionsApi({
                          ...questionsApi,
                          question3: e.target.value,
                        })
                      }
                    />
                    <InputFeedback
                      type="text"
                      placeholder={`${questionsApi.question4}`}
                      value={`${questionsApi.question4}`}
                      onChange={(e) =>
                        setQuestionsApi({
                          ...questionsApi,
                          question4: e.target.value,
                        })
                      }
                    />
                    <InputFeedback
                      type="text"
                      placeholder={`${questionsApi.question5}`}
                      value={`${questionsApi.question5}`}
                      onChange={(e) =>
                        setQuestionsApi({
                          ...questionsApi,
                          question5: e.target.value,
                        })
                      }
                    />
                    <InputFeedback
                      type="text"
                      placeholder={`${questionsApi.question6}`}
                      value={`${questionsApi.question6}`}
                      onChange={(e) =>
                        setQuestionsApi({
                          ...questionsApi,
                          question6: e.target.value,
                        })
                      }
                    />
                    <InputFeedback
                      type="text"
                      placeholder={`${questionsApi.question7}`}
                      value={`${questionsApi.question7}`}
                      onChange={(e) =>
                        setQuestionsApi({
                          ...questionsApi,
                          question7: e.target.value,
                        })
                      }
                    />
                  </GrayBox>
                </LightGrayContainer>
              </WhiteContainer>
              <Button
                onClick={handleSubmitQuestions}
                style={{ width: "100px", marginTop: "2.5rem" }}
                className="button-excel"
              >
                {loading ? (
                  <LoadingContainer>
                    <LoadingSpinner />
                  </LoadingContainer>
                ) : (
                  "Save"
                )}
              </Button>
            </>
          )}
        </Container>
      </MasterContainer>
    </>
  );
};

export default FeedbackEditScreen;

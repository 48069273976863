/*
here is the login screen for the user to authenticate through the administrator
account to access the metrics and functionalities that the dashboard offers
*/
import React, { useRef, useState } from "react";
import {
  Container,
  Heading,
  LoginBox,
  Text,
  Input,
  Flex,
  Box,
  FlexButtons,
  ButtonLogin,
} from "./styles";
import logoBeehive from "../../assets/logo.svg";
import IconSvg from "../IconSvg";
import personIcon from "../../assets/icon-person-login.svg";
import passwordIcon from "../../assets/icon-password.svg";
import { useNavigate } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import { useAuth } from "../../../../hooks/auth";
import * as yup from "yup";

import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingSpinner } from "../../../../shared/components/LoadingSpinner";

interface SubmitProps {
  email: string;
  password: string;
}

const LoginPage: React.FC = () => {
  const schema = yup.object({
    email: yup.string().email("mail is invalid!").required(`required field`),
    password: yup.string().min(6).required(`required field`),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SubmitProps>({
    resolver: yupResolver(schema),
  });

  const { signIn } = useAuth();
  const [loading, setLoading] = useState(false);
  const inputRef = useRef<any>(null);

  // callback that calls our authentication hook to make
  // requests with the authentication endpoint
  const onSubmit: SubmitHandler<SubmitProps> = async (data) => {
    try {
      setLoading(true);

      // if (data.email !== "info@beehive.news") {
      //   toast.error("Email or password is invalid!");
      //   setLoading(false);
      //   return;
      // }

      await signIn(data.email, data.password);
    } catch (error: any) {
      toast.error("Email or password is invalid!");
      setLoading(false);
    }
  };

  //let focus on input to accessibility
  const handleUsernameClick = () => {
    inputRef.current.focus();
  };
  let navigate = useNavigate();

  return (
    <Container>
      <LoginBox onSubmit={handleSubmit(onSubmit)}>
        <IconSvg image={logoBeehive} className="login-logo" />
        <Heading>Login</Heading>
        <Text>Sign in to your account</Text>
        <Flex ref={inputRef}>
          <Box>
            <IconSvg image={personIcon} width="16px" height="20px" />
          </Box>
          <Input
            type="email"
            placeholder="Username"
            onClick={handleUsernameClick}
            {...register("email")}
          />
        </Flex>
        {errors?.email?.message && (
          <p className="error-validation">
            {errors?.email?.message === "Email invalido"
              ? "Field required"
              : errors?.email?.message}
          </p>
        )}

        <Flex>
          <Box>
            <IconSvg image={passwordIcon} width="16px" height="20px" />
          </Box>
          <Input
            type="password"
            placeholder="Password"
            {...register("password")}
          />
        </Flex>
        {errors?.password?.message && (
          <p className="error-validation">
            {errors?.email?.message === "email is a required field"
              ? "Field required"
              : errors?.email?.message}
          </p>
        )}
        <FlexButtons>
          <ButtonLogin className="button-excel">
            {loading ? <LoadingSpinner /> : "Login"}
          </ButtonLogin>
        </FlexButtons>
      </LoginBox>
    </Container>
  );
};

export default LoginPage;

import * as React from "react";
import { useCallback } from "react";
import { useAuth } from "./auth";

export enum ROLES {
  ADMIN = "admin",
  CONTENT_CREATOR = "content_creator",
}

type RoleTypes = ROLES;

export const useAuthorization = () => {
  const { user } = useAuth();

  const isAccessible = useCallback(
    ({ allowedRoles }: { allowedRoles: RoleTypes[] }) => {
      if (!user) {
        // if user is undefined, allow access if allowedRoles are undefined or empty
        return !allowedRoles || allowedRoles.length === 0;
      }
      if (allowedRoles && allowedRoles.length > 0) {
        return allowedRoles?.includes(user.role.name);
      }

      return true;
    },
    [user]
  );

  return { isAccessible, role: user ? user.role : undefined };
};

type AuthorizationProps = {
  forbiddenFallback?: React.ReactNode;
  children: React.ReactNode;
  allowedRoles?: RoleTypes[];
};

export const Authorization = ({
  allowedRoles,
  forbiddenFallback = null,
  children,
}: AuthorizationProps) => {
  const { isAccessible } = useAuthorization();

  return (
    <>
      {isAccessible({ allowedRoles: allowedRoles })
        ? children
        : forbiddenFallback}
    </>
  );
};

/*
  * here the admin can change the article.
  We load the information and change

  * Below is the function that uploads images
*/

import React, { useEffect, useState, useCallback } from "react";
import InputDefault from "../../../../PanelControl/components/InputDefault";
import Compress from "react-image-file-resizer";
import { format, utcToZonedTime, getTimezoneOffset } from "date-fns-tz";
import * as Dialog from "@radix-ui/react-dialog";
import spamIcon from "../../../../../assets/spam-icon.svg";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../../../../../../services/api";
import ImageUploading from "react-images-uploading";
import { GrayBox } from "../../../../PanelControl/components/NotificationArea/styles";
import { toast } from "react-toastify";
import { LoadingSpinner } from "../../../../../../../shared/components/LoadingSpinner";
import { LoadingContainer } from "../../../../FeedbackEditScreen/styles";
import iconArrow from "../../../../../assets/option-arrow.svg";
import NewModal from "../../../../../../../shared/components/NewModal";
import InputMask from "react-input-mask";

// set up styles
import {
  Box,
  BoxFullwidth,
  ButtonChooseImage,
  ButtonDelete,
  ButtonSave,
  ContainerFull,
  ContainerImage,
  ControlMain,
  FlexButtons,
  FlexFull,
  Heading,
  HorizontalFlex,
  HStack,
  NewsCounterMain,
  NewsInfoMain,
  NewsRelatedMain,
  Table,
  TableData,
  TableHead,
  TableRow,
  WrapperHeading,
  Text,
  WrapperForm,
  Select,
} from "./styles";

//set up interfaces of objects
interface NewsEditProps {
  id?: string;
}

interface NewsSpecificProps {
  news: {
    id: string;
    newspaper: string;
    category: string;
    date: string;
    image: string;
    title: string;
    description: string;
    link: string;
    opposing_view: null;
    key_words: string;
    trustworthiness: null;
    quality_writing: null;
    completeness: null;
    political_position: null;
    rating: null;
    created_at: string;
    updated_at: string;
    is_political_position: true;
  };
  opposing_view: OpposingViewProps;
  related: OpposingViewProps[];
}

interface OpposingViewProps {
  id: string;
  newspaper: string;
  category: string;
  date: string;
  image: string;
  title: string;
  description: string;
  link: string;
  opposing_view: null;
  key_words: string;
  trustworthiness: null;
  quality_writing: null;
  completeness: null;
  political_position: null;
  rating: null;
  created_at: string;
  updated_at: string;
  is_political_position: true;
}

interface DataForApiUpdateNews {
  news_id: string;
  newspaper: string;
  category: string;
  date: string;
  title: string;
  description: string;
  link: string;
  key_words: string;
}

interface ImageListType {
  dataURL?: string;
  file?: File;
  [key: string]: any;
}

const EditNewsMain: React.FC<NewsEditProps> = ({ id }) => {
  //capure if of news through params
  const { idnews } = useParams();
  const navigator = useNavigate();

  //set up states
  const [dataNewsSpecific, setDataNewsSpecific] = useState(
    {} as NewsSpecificProps
  );

  //the main state object
  const [newsUpdate, setNewsUpdate] = useState({
    news_id: "",
    newspaper: "",
    category: "",
    date: "",
    title: "",
    description: "",
    link: "",
    key_words: "",
  } as DataForApiUpdateNews);

  const [newsId, setNewsId] = useState("");
  const [dateNews, setDateNews] = useState("");
  const [images, setImages] = React.useState<any[]>([]);
  const [previousImg, setPreviousImg] = useState<any>("");
  const [compressedFile, setCompressedFile] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState("");
  const [date, setDate] = useState("");
  const [loadingButton, setLoadingButton] = useState(false);
  const [file, setFile] = useState<any>({} as any);
  const [existsUpload, setExistsUpload] = useState(false);
  const [refresh, setRefresh] = useState(false);

  // this number is relate to react uploading images
  // which number max of images for uploading variable
  const maxNumber = 69;

  //here fetch data and update to main state
  //and one array of dependency is refresh
  //for trigger when reset to get new infos of articles
  useEffect(() => {
    const loadNewsEditById = async () => {
      try {
        setLoading(true);
        const response = await api.get(`news-dashboard/${idnews}`);
        setDataNewsSpecific(response.data);
        setLoading(false);
      } catch (err) {
        console.log(err, "erro");
      }
    };

    loadNewsEditById();
  }, [refresh]);

  //just ensure this variable is string of value
  useEffect(() => {
    setNewsId(String(idnews));
  }, []);

  //handler when user was updated the article
  //formatted values to send a api beehive
  //these newsUpdate are main value to api
  useEffect(() => {
    setNewsUpdate({
      ...newsUpdate,
      news_id: dataNewsSpecific?.news?.id,
      newspaper: dataNewsSpecific?.news?.newspaper,
      category: dataNewsSpecific?.news?.category,
      title: dataNewsSpecific?.news?.title?.trim(),
      description: dataNewsSpecific?.news?.description?.trim(),
      date: dataNewsSpecific?.news?.date,
      link: dataNewsSpecific?.news?.link,
      key_words: dataNewsSpecific?.news?.key_words,
    });
  }, [dataNewsSpecific]);

  //a callback to identify which news item he wants to delete article and send
  // it to the api to do the removed
  const handleDeleteNews = useCallback(async (idOfNews?: string) => {
    try {
      const responseDelete = await api.delete(`news-dashboard/${idOfNews}`);
      navigator("/news/list/");
      toast.success("News deleted with success");
    } catch (err) {
      console.log(err, "erro ao excluir");
      alert("algum erro ocorreu");
    }
  }, []);

  //onchange for upload images:
  //a callback that detects and processes
  // the image that the user wants to change from the article

  /*Warning: the backend has a size limit, so we need to
  compress the image to ensure that up to a certain size,
  I can reduce it enough for the api to accept the file */

  const onChange = async (
    imageList: ImageListType[],
    addUpdateIndex: number[] | undefined
  ) => {
    //add image url to one state
    //this state has the function of showing the image preview
    setPreviousImg(imageList[0].dataURL);
    // now let's try to reduce this image
    Compress.imageFileResizer(
      imageList[0].file, // the file from input
      680, // width
      680, // height
      "WEBP", // compress format WEBP, JPEG, PNG
      70, // quality
      0, // rotation
      (uri: any) => {
        const fileCompressed = new File([uri], `${String(Math.random())}.webp`);
        setCompressedFile(fileCompressed);
      },
      "blob" // blob or base64 default base64
    );
  };

  // So let's send the object with
  // the changes to the beehive endpoint
  const handleSaveNews = useCallback(async () => {
    try {
      setLoadingButton(true);
      let myNews = {
        news_id: newsId,
        newspaper: newsUpdate.newspaper,
        category: newsUpdate.category,
        date: date === "" ? dataNewsSpecific.news.date : date,
        title: newsUpdate.title,
        description: newsUpdate.description,
        link: newsUpdate.link,
        key_words: newsUpdate.key_words,
      };
      const responseEditNews = await api.put("news-dashboard", myNews);
      toast.success("News edited with success!");
    } catch (err) {
      console.log(err, "ERROR when trying edit news in UPDATE");
      toast.error("All fields are required.");
      setLoadingButton(false);
    }

    //now update new image
    //was compressed and send to other endpoint
    //with id of article to then load new image
    if (compressedFile) {
      let formData = new FormData();
      formData.append("image", compressedFile);

      try {
        setLoadingButton(true);
        const fetchUploadPicture = await api.patch(
          `news-dashboard/upload-image/${newsId}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        setExistsUpload(false);
        setLoadingButton(false);
      } catch (err) {
        console.log(err, "ERROR when update patch image file");
        setLoadingButton(false);
        toast.error("ERROR: Image with problem!");
      }
    } else {
      return setLoadingButton(false);
    }
  }, [newsUpdate, newsId, date, file, loadingButton, compressedFile]);

  //delete related news
  /* the news related to the administrator/user
     can delete if you want */
  const DeleteRelatedNews = useCallback(
    async (id: string) => {
      try {
        const responseDelete = await api.delete("related-news", {
          data: {
            main_news_id: idnews,
            array_related_news: [{ related_news_id: id }],
          },
        });
        setRefresh(!refresh);
        toast.success("Relate News deleted with success!");
      } catch (err) {
        console.log(err, "error when delete related news");
        toast.error("Error!");
      }
    },
    [refresh]
  );

  /*
  just doing the time zone alignment
  with the server, so we will work in
  zero UTC always
  */
  const formatDate = (date: string) => {
    let myObject = date;
    const nyTimeZone = "UTC";
    const myDate = utcToZonedTime(myObject, nyTimeZone);
    const niceDate = format(myDate, "yyyy/MM/dd - HH:mm", {
      timeZone: "UTC",
    });
    return `${niceDate}`;
  };

  return (
    <ContainerFull>
      <FlexFull>
        <NewsInfoMain>
          <WrapperHeading>
            <Heading>Articles</Heading>
          </WrapperHeading>

          <ControlMain>
            <GrayBox>
              <HStack>
                <Box>
                  {/* newspappers here */}

                  <Select
                    value={newsUpdate.newspaper}
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                      setNewsUpdate((state) => {
                        return {
                          ...state,
                          newspaper: e.target.value,
                        };
                      })
                    }
                  >
                    <option value={newsUpdate?.newspaper} hidden>
                      {newsUpdate?.newspaper}
                    </option>
                    {newsUpdate?.newspaper !== "BBC" && (
                      <option value="BBC">BBC</option>
                    )}
                    {newsUpdate?.newspaper !== "Independent" && (
                      <option value="Independent">Independent</option>
                    )}
                    {newsUpdate?.newspaper !== "Aljazeera" && (
                      <option value="Aljazeera">Aljazeera</option>
                    )}
                    {newsUpdate?.newspaper !== "The Guardian" && (
                      <option value="The Guardian">The Guardian</option>
                    )}
                    {newsUpdate?.newspaper !== "Haaretz" && (
                      <option value="Haaretz">Haaretz</option>
                    )}
                    {newsUpdate?.newspaper !== "Sky News" && (
                      <option value="Sky News">Sky News</option>
                    )}
                    {newsUpdate?.newspaper !== "The Hindu" && (
                      <option value="The Hindu">The Hindu</option>
                    )}

                    {newsUpdate?.newspaper !== "Whashington Post" && (
                      <option value="Whashington Post">Whashington Post</option>
                    )}

                    {newsUpdate?.newspaper !== "Whashington Post" && (
                      <option value="Sixth tone">Sixth tone</option>
                    )}

                    {newsUpdate?.newspaper !== "Santiago Times" && (
                      <option value="Santiago Times">Santiago Times</option>
                    )}
                  </Select>

                  <img src={iconArrow} className="image-position" />
                </Box>
                <Box>
                  <Select
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                      setNewsUpdate((state) => {
                        return {
                          ...state,
                          category: e.target.value,
                        };
                      })
                    }
                  >
                    <option value={newsUpdate?.category} hidden>
                      {newsUpdate?.category}
                    </option>
                    {newsUpdate?.category !== "Global Affairs" && (
                      <option value="Global Affairs">Global Affairs</option>
                    )}
                    {newsUpdate?.category !== "Business" && (
                      <option value="Business">Business</option>
                    )}
                    {newsUpdate?.category !== "Opinion" && (
                      <option value="Opinion">Opinion</option>
                    )}
                    {newsUpdate?.category !== "Sport" && (
                      <option value="Sport">Sport</option>
                    )}
                    {newsUpdate?.category !== "Entertainment" && (
                      <option value="Entertainment">Entertainment</option>
                    )}
                    {newsUpdate?.category !== "Wellness" && (
                      <option value="Wellness">Wellness</option>
                    )}
                    {newsUpdate?.category !== "Politics" && (
                      <option value="Politics">Politics</option>
                    )}

                    {newsUpdate?.category !== "Science" && (
                      <option value="Science">Science</option>
                    )}

                    {newsUpdate?.category !== "Tech" && (
                      <option value="Tech">Tech</option>
                    )}

                    {newsUpdate?.category !== "UK Affairs" && (
                      <option value="UK Affairs">UK Affairs</option>
                    )}
                  </Select>

                  <img src={iconArrow} className="image-position" />
                </Box>
              </HStack>
              <BoxFullwidth>
                <InputDefault
                  type="text"
                  value={newsUpdate?.title}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setNewsUpdate((state) => {
                      return {
                        ...state,
                        title: e.target.value,
                      };
                    })
                  }
                />
              </BoxFullwidth>
              <BoxFullwidth>
                <InputDefault
                  type="text"
                  value={newsUpdate?.description}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setNewsUpdate((state) => {
                      return {
                        ...state,
                        description: e.target.value,
                      };
                    })
                  }
                />
              </BoxFullwidth>
              <HStack>
                <Box marginTop="1rem">
                  <InputMask
                    type="text"
                    className="input-mask"
                    mask={"9999/99/99 99:99"}
                    value={date}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setDate(e.target.value)
                    }
                    placeholder={
                      newsUpdate?.date && formatDate(newsUpdate.date)
                    }
                  ></InputMask>
                </Box>
                <Box marginTop="1rem">
                  <InputDefault
                    type="text"
                    value={newsUpdate?.link}
                    placeholder={newsUpdate?.link ? newsUpdate?.link : ""}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setNewsUpdate((state) => {
                        return {
                          ...state,
                          link: e.target.value,
                        };
                      })
                    }
                  />
                </Box>
              </HStack>
              <BoxFullwidth>
                <InputDefault
                  type="text"
                  value={newsUpdate?.key_words}
                  placeholder={
                    newsUpdate?.key_words ? newsUpdate?.key_words : ""
                  }
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setNewsUpdate((state) => {
                      return {
                        ...state,
                        key_words: e.target.value,
                      };
                    })
                  }
                />
              </BoxFullwidth>

              <HorizontalFlex>
                <ContainerImage
                  previousImg={previousImg}
                  srcImg={dataNewsSpecific?.news?.image}
                />
                <ImageUploading
                  multiple={false}
                  value={images}
                  onChange={onChange}
                  maxNumber={maxNumber}
                >
                  {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps,
                  }) => (
                    // write your building UI
                    <div className="upload__image-wrapper">
                      <ButtonChooseImage
                        onClick={onImageUpload}
                        type="button"
                        className="button-excel"
                        style={isDragging ? { color: "purple" } : undefined}
                        {...dragProps}
                      >
                        Choose photo
                      </ButtonChooseImage>
                    </div>
                  )}
                </ImageUploading>
              </HorizontalFlex>
            </GrayBox>
          </ControlMain>
        </NewsInfoMain>

        {dataNewsSpecific?.opposing_view && (
          <NewsCounterMain>
            <WrapperHeading>
              <Heading>Opposing view</Heading>
            </WrapperHeading>

            <WrapperForm>
              {dataNewsSpecific.opposing_view.id && (
                <Table>
                  <TableRow>
                    <TableHead>Newspapper</TableHead>
                    <TableHead>Category/Topic</TableHead>
                    <TableHead>Title</TableHead>
                    <TableHead>Date</TableHead>
                    <TableHead></TableHead>
                  </TableRow>

                  {!loading && (
                    <TableRow>
                      <TableData>
                        <Text>
                          {dataNewsSpecific?.opposing_view?.newspaper}
                        </Text>
                      </TableData>
                      <TableData>
                        <Text>{dataNewsSpecific?.opposing_view?.category}</Text>
                      </TableData>
                      <TableData>
                        <Text>{dataNewsSpecific?.opposing_view?.title}</Text>
                      </TableData>
                      <TableData>
                        <Text>
                          {formatDate(dataNewsSpecific?.opposing_view?.date)}
                        </Text>
                      </TableData>

                      <TableData style={{ textAlign: "right" }}></TableData>
                    </TableRow>
                  )}
                </Table>
              )}
            </WrapperForm>
          </NewsCounterMain>
        )}

        <NewsCounterMain>
          <NewsRelatedMain>
            <WrapperHeading>
              <Heading>Related articles</Heading>
            </WrapperHeading>
            <WrapperForm>
              {loading ? (
                <LoadingContainer>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      minHeight: "300px",
                    }}
                  >
                    <LoadingSpinner />
                  </div>
                </LoadingContainer>
              ) : (
                dataNewsSpecific?.related?.length !== 0 && (
                  <GrayBox>
                    <Table>
                      <TableRow>
                        <TableHead>Newspapper</TableHead>
                        <TableHead>Category/Topic</TableHead>
                        <TableHead>Title</TableHead>
                        <TableHead>Date</TableHead>
                        <TableHead></TableHead>
                      </TableRow>
                      {!loading &&
                        dataNewsSpecific?.related?.map((itemNews, index) => (
                          <TableRow>
                            <TableData>
                              <Text> {itemNews?.newspaper} </Text>
                            </TableData>
                            <TableData>
                              <Text> {itemNews?.category} </Text>
                            </TableData>
                            <TableData>
                              <Text>{itemNews?.title}</Text>
                            </TableData>

                            <TableData>
                              {/* <Text>{itemNews?.date}</Text> */}

                              <Text>{formatDate(itemNews.date)}</Text>
                            </TableData>

                            <TableData style={{ textAlign: "right" }}>
                              <button
                                onClick={() => DeleteRelatedNews(itemNews.id)}
                                style={{ background: "transparent" }}
                              >
                                {
                                  <img
                                    width="16px"
                                    height="16px"
                                    src={spamIcon}
                                  />
                                }
                              </button>
                            </TableData>
                          </TableRow>
                        ))}

                      {loading && (
                        <LoadingContainer>
                          <LoadingSpinner />
                        </LoadingContainer>
                      )}
                    </Table>
                  </GrayBox>
                )
              )}
            </WrapperForm>
          </NewsRelatedMain>
        </NewsCounterMain>
        <FlexButtons>
          <ButtonSave className="button-excel" onClick={handleSaveNews}>
            {loadingButton ? (
              <LoadingContainer>
                <LoadingSpinner />
              </LoadingContainer>
            ) : (
              "Save"
            )}
          </ButtonSave>
          {/* onClick={() => handleDeleteNews(newsId)} */}
          <Dialog.Root>
            <Dialog.Trigger asChild>
              <ButtonDelete>Delete</ButtonDelete>
            </Dialog.Trigger>

            <NewModal newsId={newsId} callback={handleDeleteNews} />
          </Dialog.Root>
        </FlexButtons>
      </FlexFull>
    </ContainerFull>
  );
};

export default EditNewsMain;

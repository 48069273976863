import React from "react";
import IconSvg from "../../../../login/components/IconSvg";
import { Box, LogoutContainer, LogoutText, RootHeader } from "./styles";
import iconExit from "../../../assets/icon-exit.svg";
import logoBeehive from "../../../assets/logo-dash.svg";
import { useAuth } from "../../../../../hooks/auth";

export type IHeaderProps = {};

const ContentCreatorHeader: React.FC<IHeaderProps> = ({}) => {
  const { signOut } = useAuth();
  return (
    <RootHeader>
      <Box>
        <IconSvg
          className="image-testing"
          image={logoBeehive}
          width="208px"
          height="43px"
        />
      </Box>
      <LogoutContainer
        onClick={() => {
          signOut();
        }}
      >
        <LogoutText>Logout</LogoutText>
        <IconSvg
          image={iconExit}
          className="image-sidebar-menu"
          width={"20"}
          height={"20"}
        />
      </LogoutContainer>
    </RootHeader>
  );
};

export { ContentCreatorHeader };

import React from "react";
import * as Dialog from "@radix-ui/react-dialog";
import {
  Content,
  Overlay,
  CloseButton,
  HorizontalStack,
  CloseButtonOut,
  Title,
} from "./styles";
import { X } from "phosphor-react";

// import { Container } from './styles';

interface ModalProps {
  callback: (idNews: string) => void;
  newsId: string;
}

const NewModal: React.FC<ModalProps> = ({ callback, newsId }) => {
  return (
    <Dialog.Portal>
      <Overlay />
      <Content>
        <Title>Delete news</Title>
        <CloseButton>
          <X size={24} />
        </CloseButton>
        <span className="text">Do you really want to delete this news?</span>
        <HorizontalStack>
          <button className="yes-button" onClick={() => callback(newsId)}>
            Yes
          </button>
          <CloseButtonOut>
            <button className="no-button">No</button>
          </CloseButtonOut>
        </HorizontalStack>
      </Content>
    </Dialog.Portal>
  );
};

export default NewModal;

import React, { useState } from "react";
import { ContentCreatorHeader } from "../components/ContentCreatorHeader/ContentCreatorHeader";
import { RootContainer } from "../NewsCsvImport/styles";
import { RedirectModal } from "../components/RedirectModal/RedirectModal";

export type IContentCreatorDashboardProps = {};

const ContentCreatorDashboard: React.FC<
  IContentCreatorDashboardProps
> = ({}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(true);
  return (
    <RootContainer>
      <ContentCreatorHeader />
      <RedirectModal open={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </RootContainer>
  );
};

export { ContentCreatorDashboard };

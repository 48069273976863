import React, { useEffect, useRef } from "react";
//import { useHistory } from 'react-router'

import {
  BoxInput,
  BoxLinks,
  ButtonLabel,
  Container,
  LabelLink,
  LabelLinkLabel,
  PriceBox,
  TopGap,
} from "./styles";

const ChangeCreditCardScreen: React.FC = () => {
  //const history = useHistory();
  const refSayhelloButton = useRef<HTMLButtonElement>(null);

  const refCreditCardNumberHelp = useRef<HTMLSpanElement>();

  useEffect(() => {
    //window.ReactNativeWebView?.postMessage("Say Helooou");

    const myWindow = window as any;

    myWindow.ReactNativeWebView?.postMessage("Say Helooou") as any;
    refSayhelloButton.current.click();
  }, []);

  document.addEventListener("keydown", (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  });

  // this useeffect observers if the span credicarhelp will change with dirt code, if yes replaces it for empty string
  useEffect(() => {
    const observer = new MutationObserver(function (mutationlist, observer) {
      if (
        refCreditCardNumberHelp.current.innerHTML !== `10 - invalidCcNumber`
      ) {
        refCreditCardNumberHelp.current.innerHTML = ``;
      }
    });
    observer.observe(refCreditCardNumberHelp.current, {
      characterData: false,
      childList: true,
      attributes: false,
    });
  }, []);

  return (
    <Container>
      <div className="panel panel-default bootstrap-basic">
        <form className="panel-body" id="checkout-form" action="#">
          <div className="row">
            <div className="form-group col-md-12">
              <TopGap />
              <label htmlFor="cardholder-name" className="labels">
                Name on card
              </label>
              <input
                type="text"
                className="form-control my-input input-style"
                id="cardholder-name"
                placeholder="Name on card"
              />
              <span className="helper-text"></span>
            </div>
            {/* <!--Hosted Payment Field for CC number--> */}
            <div className="form-group col-xs-9">
              <label htmlFor="card-number" className="labels">
                Card Number
              </label>
              <div className="input-group">
                <div
                  className="form-control input-style"
                  id="card-number"
                  data-bluesnap="ccn"
                ></div>

                <div id="card-logo" className="input-group-addon">
                  <img
                    alt=""
                    src="https://files.readme.io/d1a25b4-generic-card.png"
                    height="20px"
                  ></img>
                </div>
              </div>
              {/* HERE IS WHERE SHOW CREDITCARD */}
              <span
                className="helper-text"
                id="ccn-help"
                ref={refCreditCardNumberHelp}
              ></span>
            </div>
            {/* <!--Hosted Payment Field for CC CVV--> */}
            <div className="form-group col-xs-3">
              <label htmlFor="cvv" className="labels">
                CVV
              </label>
              <div
                className="form-control input-style"
                id="cvv"
                data-bluesnap="cvv"
              ></div>
              <span className="helper-text" id="cvv-help"></span>
            </div>

            {/* <!--Hosted Payment Field for CC EXP--> */}
            <div className="form-group col-xs-6">
              <label htmlFor="exp-date" className="labels">
                Expiration date
              </label>
              <div
                className="form-control input-style"
                id="exp-date"
                data-bluesnap="exp"
              ></div>
              <span className="helper-text" id="exp-help"></span>
            </div>
            <div className="form-group col-xs-6">
              <label htmlFor="cardholder-name" className="labels">
                Postcode
              </label>
              <input
                type="text"
                className="form-control my-input input-style"
                id="cardholder-name"
                placeholder="Postcode"
              />
              <span className="helper-text"></span>
            </div>
            {/* <PriceBox className="col-xs-12">
              <label htmlFor="exp-date" >Total</label>
              <label htmlFor="exp-date" >£ 00.99</label>
            </PriceBox> */}
          </div>

          <button
            style={{
              backgroundColor: "#ffc600",
              color: "#000000",
              fontWeight: "bold",
              transform: "translateY(-15px)",
              padding: 0,
              borderRadius: 10,
              height: 45,
              marginTop: 20,
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            data-bluesnap="submitButton"
            className="btn btn-raised btn-info btn-lg col-md-4 honey-bg"
            type="submit"
            id="submit-button"
            onClick={window["do_when_clicking_submit_button"]}
          >
            <ButtonLabel>Change credit card</ButtonLabel>
          </button>
          <button
            ref={refSayhelloButton}
            className="btn btn-raised btn-info btn-lg col-md-4"
            onClick={window["reloadingCheckout"]}
            style={{
              opacity: 0,
              cursor: "default",
            }}
          >
            Reload
          </button>
          {/* <BoxLinks className="col-xs-6">
            <LabelLink href="https://beehive.news/terms-of-use/">
              <LabelLinkLabel>
                Terms of use
              </LabelLinkLabel>
            </LabelLink>
            <LabelLink href="https://beehive.news/data-policy/">
              <LabelLinkLabel>
                Data privacy policy
              </LabelLinkLabel>
            </LabelLink>
          </BoxLinks> */}
        </form>
      </div>
    </Container>
  );
};

export default ChangeCreditCardScreen;

import styled from "styled-components";

interface ContainerCollapseProps {
  isExpanded?: boolean;
}

export const Container = styled.div`
  display: flex;
  background: #ebedef;
  width: 100%;
  height: auto;
  flex-direction: column;
  padding: 0 2rem 5rem 2rem;
  overflow-y: auto;

  @media (max-width: 1366px) {
    padding: 0 2rem 1rem 2rem;
  }
`;

export const ButtonBox = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1.6rem;
`;

export const Button = styled.button`
  width: 100%;
  max-width: 178px;
  height: 45px;
  background: ${({ theme }) => theme.colors.honey};
  font-size: 1.6rem;
  font-weight: bold;
  margin-top: 1rem;
  margin-left: auto;
  border-radius: 5px;
  transition: all 120ms linear;

  @media (max-width: 1366px) {
    max-width: 160px;
    height: 42px;
    font-size: 1.3rem;
    margin-top: 0.5rem;
  }
`;

export const Main = styled.div`
  width: 100%;
  height: 80vh;
  display: flex;
  background: white;
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
`;

export const HeaderContent = styled.div`
  width: 100%;
  height: 10%;
  display: flex;
  align-items: center;
`;

export const FormBox = styled.div`
  width: 100%;
  height: 82%;
  background: ${({ theme }) => theme.colors.gray100};
  display: flex;
  flex-direction: column;
`;

export const PaginationContainer = styled.div`
  width: 100%;
  height: 8%;
  display: flex;
`;

export const InputsWrapper = styled.div`
  display: flex;
  width: 94%;
  height: 100%;
  align-items: center;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  width: 6%;
  height: 100%;
  align-items: center;
  justify-content: flex-end;
`;

export const ButtonCheck = styled.button`
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.honey};
`;

export const StyledTable = styled.table`
  width: 100%;
  padding: 0 2rem;
`;

export const TableRow = styled.tr`
  display: flex;
  width: 100%;
  margin-top: 1rem;
  justify-content: space-between;
  height: 37px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray200};
  align-items: center;
`;

export const THead = styled.th`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  height: 37px;
  font-size: 1.5rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray200};
`;

export const TableItem = styled.td`
  font-size: 1.5rem;
`;

export const WhiteContainer = styled.div`
  width: 100%;
  height: auto;
  background: white;
  display: flex;

  margin-top: 2rem;
  border-radius: 5px;
  flex-direction: column;
  border: 1px solid #dadada;
`;

export const WrapperHeading = styled.div`
  display: flex;
  width: 100%;
  height: 4.1rem;
  background: ${({ theme }) => theme.colors.gray100};

  align-items: center;
  padding: 0 1rem;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: 1.5px solid #dadada;
`;

export const Heading = styled.p`
  font-size: 1.4rem;
  font-weight: bold;

  @media (max-width: 1366px) {
    font-size: 1.3rem;
  }
`;

export const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LightGrayContainer = styled.div`
  width: 100%;
  height: auto;
  background: white;
  margin-top: 1rem;
  padding: 1rem 2rem 2rem 2rem;
  border-radius: 10px;

  @media (max-width: 1366px) {
  }
`;

export const CollapseWrapper = styled.div<ContainerCollapseProps>`
  width: 100%;
  border: 1px solid #9d9d9d;
  background: white;
  border-radius: 5px;

  height: 70px;

  & .collapse-wrapper {
    width: 100%;
    height: 100%;
  }

  & .collapse-content {
    width: 100%;
    height: 800px;
  }
`;

export const CollapseList = styled.div`
  width: 100%;
  height: 290px;
  background: red;
`;

export const CollapseItem = styled.div`
  width: 100%;
  height: 200px;
`;

export const Text = styled.p`
  font-size: 1.5rem;
  color: #9d9d9d;
`;

export const InputFeedback = styled.input`
  width: 100%;
  height: 42px;
  border: 1px solid #9d9d9d;
  margin: 1rem 0;
  border-radius: 5px;
  padding: 0 2rem;
  font-size: 1.4rem;

  ::placeholder {
    color: #333333;
  }

  @media (max-width: 1366px) {
    font-size: 1.3rem;

    ::placeholder {
      font-size: 1.3rem;
    }
  }
`;

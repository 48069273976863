import React from "react";
import { DayPicker } from "react-day-picker";
import { BoxDate } from "../../styles";
import { useDetectClickOutside } from "react-detect-click-outside";

// import { Container } from './styles';

interface DateComponentProps {
  closeToggle: () => void;
  selected: Date;
  setSelected: React.Dispatch<React.SetStateAction<Date>>;
}

const DateComponent: React.FC<DateComponentProps> = ({
  closeToggle,
  selected,
  setSelected,
}) => {
  const ref = useDetectClickOutside({ onTriggered: closeToggle });
  return (
    <BoxDate className="boxDates" ref={ref}>
      <DayPicker
        mode="single"
        selected={selected}
        onSelect={setSelected}
        // footer={footer}
      />
    </BoxDate>
  );
};

export default DateComponent;

/* 
  Here we will create the functionality to relate
  the news from a mandatory news so that I can
  choose other news and add it to the array and
  send it to the beehive api
*/

import { format } from "date-fns-tz";
import ptBR from "date-fns/locale/pt-BR";
import cloneDeep from "lodash/cloneDeep";
import React, { useCallback, useRef, useState } from "react";
import IconSvg from "../../../../../login/components/IconSvg";
import iconCheck from "../../../../assets/icon-check.svg";
import spamIcon from "../../../../assets/spam-icon.svg";
import iconArrow from "../../../../assets/option-arrow.svg";
import { useEffect } from "react";
import api from "../../../../../../services/api";
import searchIcon from "../../../../assets/icon-search-md.svg";
import Pagination from "../../../../components/Pagination";
import { FlexPagination } from "../../../NewsScreen/components/NewsContainer/styles";
import { toast } from "react-toastify";
import MoonLoader from "react-spinners/MoonLoader";
import { css } from "@emotion/react";
import { LoadingSpinner } from "../../../../../../shared/components/LoadingSpinner";
import { LoadingContainer } from "../../../FeedbackEditScreen/styles";

// set up styles
import {
  ButtonMain,
  ButtonRemoveNewsRelated,
  ButtonSave,
  ContainerFull,
  ContainerWhite,
  ControlCenter,
  ControlLeft,
  ControlRight,
  FlexFull,
  Heading,
  HorizontalStack,
  InputSearchBox,
  LeftBox,
  RightBox,
  RoundCheck,
  RoundCheckRelate,
  SearchBox,
  Select,
  Table,
  TableData,
  TableHead,
  TableRow,
  Text,
  WrapperForm,
  WrapperHeading,
  WrapperMain,
  WrapperRelatedNews,
} from "./styles";

//set up interfaces
interface NewsListProps {
  id: string;
  newspaper: string;
  category: string;
  date: string;
  title: string;
  rating?: any;
  checked?: boolean;
  checkedMain?: boolean;
}

interface RelatedNewsPropsForApi {
  main_news_id?: string;
  array_related_news?: RelatedPropsObject[];
}

interface RelatedPropsObject {
  related_news_id: string;
}

interface RelatedProps {
  category: string;
  date: string;
  id: string;
  newspaper: string;
  rating?: string;
  title: string;
  checked?: boolean;
}

interface ResponseApiProps {
  category: string;
  date: string;
  id: string;
  newspaper: string;
  rating?: string;
  title: string;
  checked?: boolean;
  checkedMain?: boolean;
}

const RelatedNewsMain: React.FC = () => {
  //set up states and refs
  const [newsList, setNewsList] = useState<NewsListProps[]>([]);
  const [objectMain, setObjectMain] = useState<ResponseApiProps>({
    category: "",
    date: "",
    id: "",
    newspaper: "",
    rating: null,
    title: "",
    checked: false,
    checkedMain: false,
  });
  const [indexObjectChecked, setIndexObjectChecked] = useState(-1);
  const [dataNews, setDataNews] = useState({
    initial_date: null,
    final_date: null,
    initial_political_view: null,
    final_political_view: null,
    topic: null,
    newspaper: null,
    filter: "simple-list",
    offset: 0,
    search: null,
  });

  // the state this would be the ideal format
  // that the backend receives to send related news
  const [objectNewsProps, setObjectNewsProps] =
    useState<RelatedNewsPropsForApi>({
      main_news_id: "",
      array_related_news: [
        {
          related_news_id: "",
        },
      ],
    });

  const refCheck = useRef<HTMLInputElement | null>(null);
  const [relatedNews, setRelatedNews] = useState<RelatedProps[]>([]);
  const [totalPages, setTotalPages] = useState(0);
  const [searchListNews, setSearchListNews] = useState("");
  const [topicParams, setTopicParams] = useState("");
  const [loadingPage, setLoadingPage] = useState(true);
  const [color, setColor] = useState("#FFC600");
  const [loadingSave, setLoadingSave] = useState(false);
  const [actionForApi, setActionForApi] = useState(false);
  const [forceUpdate, setForceUpdate] = useState(false);

  //just adjust of styles to
  // loading component
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

  /*
    here we have a useEffect event to send when everything is ready,
    after having a main news obviously, we can send the array of
    related news, the variable actionForApi control re-render effect
  */
  useEffect(() => {
    const autoLoadObjectNewsProps = async () => {
      if (objectNewsProps.main_news_id !== "") {
        try {
          setLoadingSave(true);
          const responseApi = await api.post("related-news", objectNewsProps);
          toast.success("Success!");
          setLoadingSave(false);
        } catch (err) {
          toast.error("FAILURE request. Something went wrong.");
        }
      } else {
        return;
      }
    };

    autoLoadObjectNewsProps();
  }, [actionForApi]);

  /*
  this is the callback generated by the send button,
  to update the data with the information specified
  by the user or administrator
  */
  const handleSaveRelatedNews = useCallback(async () => {
    setLoadingSave(true);
    setObjectNewsProps((prevState) => {
      let cloneState = { ...prevState };
      const relatedNewsIds = relatedNews.map((object, index) => {
        return {
          related_news_id: object.id,
        };
      });
      cloneState.main_news_id = objectMain.id;
      cloneState.array_related_news = relatedNewsIds;
      return cloneState;
    });
    setActionForApi(!actionForApi);
    setLoadingSave(false);
  }, [relatedNews, objectMain, objectNewsProps, loadingSave]);

  // handler event to get the params for api
  //that the user is using in filters in this case what he search
  //when user click on button
  const handleSearchSubmit = useCallback(
    (event) => {
      setDataNews({
        ...dataNews,
        search: searchListNews === "" ? null : searchListNews,
        topic: topicParams === "" ? null : topicParams,
      });
    },
    [searchListNews, dataNews, topicParams]
  );

  //handler change event of input search to
  //update our state of search
  const handleChangeSearch = useCallback(
    (event) => {
      setSearchListNews(event.target.value);
    },
    [searchListNews]
  );

  /* 
    we need to work on whether the buttons should be selected or not,
    because we have pagination and we need to have a unique news
    identification and even to be able to remark them if these
    identifiers are included in our list of previously selected news
  */
  useEffect(() => {
    const autoLoadNewsList = async () => {
      try {
        const responseApi = await api.get("news-dashboard", {
          params: dataNews,
        });
        /*
          we added new properties forcing them, to have control of which would
          already be selected or not, obviously starting all with false
        */
        const resNews = responseApi.data.list_news.map(
          (data: ResponseApiProps, index: number) => ({
            ...data,
            checked: false,
            checkedMain: false,
          })
        );
        //update our states
        setNewsList(resNews);
        setTotalPages(responseApi.data.pagination);
        //fixing issues
        setRelatedNews([...relatedNews]);
        setLoadingPage(false);
      } catch (err) {
        console.log(err, "error when get news related");
      }
    };

    autoLoadNewsList();
  }, [dataNews, forceUpdate]);

  /* 
    This function here checks if the target of our button is already selected,
    to generate the toggle effect of true or false
    to add or remove from our main news
  */
  const handleChangeMainNews = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, objectNews: RelatedProps) => {
      if (event.target.checked === true) {
        setNewsList((state) => {
          let cloneState = cloneDeep(state);
          const newArray: NewsListProps[] = cloneState.map((item) => {
            return {
              ...item,
              checkedMain: false,
            };
          });
          let findMyMainNews = newArray.find(
            (itemNews) => itemNews.id === objectNews.id
          );
          findMyMainNews.checkedMain = true;
          return newArray;
        });
        setObjectMain((state) => {
          let cloneState = cloneDeep(state);
          cloneState = objectNews;
          return cloneState;
        });
      } else {
        setNewsList((state) => {
          let cloneState = cloneDeep(state);
          let findMyMainNews = cloneState.find(
            (itemNews) => itemNews.id === objectNews.id
          );
          findMyMainNews.checkedMain = false;
          return cloneState;
        });
        setObjectMain((state) => {
          let cloneState = cloneDeep(state);
          cloneState = {
            category: "",
            date: "",
            id: "",
            newspaper: "",
            rating: null,
            title: "",
            checked: false,
            checkedMain: false,
          };
          return cloneState;
        });
      }
    },
    [objectNewsProps]
  );

  /* 
    This function here checks if the target of our button is already selected,
    to generate the toggle effect of true or false
    to add or remove from our related news

    and also checks if the news has already been previously selected,
    it's just in a different pagination, so we need to remark this article
  */
  const handleChangeRelatedNews = useCallback(
    (event, relatedNewsObject: RelatedProps) => {
      if (event.target.checked === true) {
        const newsAlreadyExists = relatedNewsObject.id;
        const duplicateNews = relatedNews.find(
          (news) => news.id == newsAlreadyExists
        );
        if (duplicateNews) return;
        setNewsList((state) => {
          let cloneState = [...state];
          let getIndex = cloneState.findIndex(
            (object) => object.id == newsAlreadyExists
          );
          cloneState[getIndex].checked = true;
          return cloneState;
        });

        setRelatedNews((state) => {
          let cloneState = [...state];
          cloneState = [
            ...state,
            { ...relatedNewsObject, checked: event.target.checked },
          ];
          return cloneState;
        });
      } else {
        const newsAlreadyExists = relatedNewsObject.id;
        setNewsList((state) => {
          let cloneState = [...state];
          let getIndex = cloneState.findIndex(
            (object) => object.id == newsAlreadyExists
          );
          cloneState[getIndex].checked = event.target.checked;
          return cloneState;
        });
        setRelatedNews((state) => {
          let cloneState = [...state];
          cloneState = [
            ...state,
            { ...relatedNewsObject, checked: event.target.checked },
          ];
          const getOutItem = cloneState.find(
            (object) => object.checked === false
          );
          const idForExclude = getOutItem?.id;
          const newArray = relatedNews.filter(
            (item) => item.id !== idForExclude
          );

          return newArray;
        });
      }
    },
    [relatedNews, newsList]
  );

  /*
    this function will be called whenever the admin or user needs to remove
    something from the list, so we update the states to false
  */
  const handleRemoveRelatedNews = useCallback(
    (id: string) => {
      const filterNewsForExclude = relatedNews.filter(
        (object) => object.id !== id
      );

      setRelatedNews((state) => {
        let cloneState = [...state];
        cloneState = [...filterNewsForExclude];
        return cloneState;
      });

      setNewsList((state) => {
        let cloneState = [...state];
        let numberIndex = cloneState.findIndex((object) => object.id === id);

        if (numberIndex === -1) {
          return cloneState;
        } else {
          cloneState[numberIndex].checked = false;
        }

        return cloneState;
      });
    },
    [relatedNews, newsList]
  );

  /*
    this useEffect event relates whether a news id should be marked,
    as it was reset only due to pagination for related articles
  */
  useEffect(() => {
    if (relatedNews.length > 0) {
      let arrayOfNewsRelated = relatedNews?.map((object, index) => {
        return {
          id: object.id,
          checked: object.checked,
          category: object.category,
          newspaper: object.newspaper,
          rating: object.rating,
          title: object.title,
          date: object.date,
        };
      });

      let arrayOfListNews = newsList.map((object, index) => {
        return {
          id: object.id,
          checked: object.checked,
          category: object.category,
          newspaper: object.newspaper,
          rating: object.rating,
          title: object.title,
          date: object.date,
          checkedMain: object.checkedMain,
        };
      });

      const result: RelatedProps[] = [];

      arrayOfNewsRelated.forEach((arr1Obj) => {
        const matchedObject = arrayOfListNews.find(
          (arr2Obj) => arr2Obj.id == arr1Obj.id
        );
        if (matchedObject) {
          result.push({ ...arr1Obj, ...matchedObject });
          setNewsList((prevState) => {
            let cloneState = [...prevState];
            const objectsIndex = newsList.findIndex(
              (object) => object.id === matchedObject.id
            );
            cloneState[objectsIndex].checked = true;
            return cloneState;
          });
        }
      });
    }
  }, [relatedNews]);

  /*
    this useEffect event relates whether a news id should be marked,
    as it was reset only due to pagination for main article was choosed
  */
  useEffect(() => {
    let arrayOfListNews = newsList.map((object, index) => {
      return {
        id: object.id,
        checked: object.checked,
        category: object.category,
        newspaper: object.newspaper,
        rating: object.rating,
        title: object.title,
        date: object.date,
        checkedMain: object.checkedMain,
      };
    });

    if (objectMain.id !== "") {
      let indexObject = arrayOfListNews.findIndex(
        (item) => item.id === objectMain.id
      );

      if (indexObject !== -1) {
        setIndexObjectChecked(indexObject);
      } else {
        return setIndexObjectChecked(-1);
      }
    }
  }, [newsList, dataNews, objectMain, forceUpdate]);

  /*
  This useEffect event is doing the work of monitoring object indices to mark
  them as true if they are already in the main news selection and then mark
  them as true, it work to pagination for example and search or filtered values
  */
  useEffect(() => {
    if (newsList && indexObjectChecked !== -1) {
      setNewsList((state) => {
        let cloneState = [...state];
        cloneState[indexObjectChecked].checkedMain = true;
        return cloneState;
      });
    }
  }, [indexObjectChecked]);

  return (
    <ContainerFull>
      {loadingPage && (
        <div
          style={{
            height: "80vh",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <MoonLoader
            color={color}
            loading={loadingPage}
            css={override}
            size={65}
            speedMultiplier={1.2}
          />
        </div>
      )}

      {!loadingPage && (
        <FlexFull>
          <WrapperMain>
            <WrapperHeading>
              <Heading>Main article</Heading>
            </WrapperHeading>
            <ContainerWhite>
              <WrapperForm>
                {objectMain.id !== "" ? (
                  <Table>
                    <TableRow>
                      <TableHead>Newspapper</TableHead>
                      <TableHead>Category/Topic</TableHead>
                      <TableHead>Title</TableHead>
                      <TableHead>Date</TableHead>
                      <TableHead></TableHead>
                    </TableRow>
                    <TableRow>
                      <TableData>
                        <Text> {objectMain?.newspaper} </Text>
                      </TableData>
                      <TableData>
                        <Text> {objectMain?.category} </Text>
                      </TableData>
                      <TableData>
                        <Text> {objectMain?.title} </Text>
                      </TableData>
                      <TableData>
                        <Text>
                          {format(Date.parse(objectMain?.date), " dd/MM/yyyy", {
                            locale: ptBR,
                            timeZone: "America/Sao_Paulo",
                          })}
                        </Text>
                      </TableData>

                      <TableData style={{ textAlign: "right" }}>
                        <ButtonRemoveNewsRelated
                          onClick={() => {
                            setObjectMain((state) => {
                              return {
                                ...state,
                                category: "",
                                date: "",
                                id: "",
                                newspaper: "",
                                rating: null,
                                title: "",
                                checked: false,
                                checkedMain: false,
                              };
                            });
                            setForceUpdate(!forceUpdate);
                          }}
                        >
                          {<img width="16px" height="16px" src={spamIcon} />}
                        </ButtonRemoveNewsRelated>
                      </TableData>
                    </TableRow>
                  </Table>
                ) : (
                  <div> {/* render nothing for empty object main news */} </div>
                )}
              </WrapperForm>
            </ContainerWhite>
          </WrapperMain>

          <WrapperMain>
            <WrapperHeading>
              <Heading>Relate articles</Heading>
            </WrapperHeading>
            <ContainerWhite>
              <WrapperForm>
                <Table>
                  {relatedNews.length >= 1 && (
                    <TableRow>
                      <TableHead>Newspapper</TableHead>
                      <TableHead>Category/Topic</TableHead>
                      <TableHead>Title</TableHead>
                      <TableHead>Date</TableHead>
                      <TableHead></TableHead>
                    </TableRow>
                  )}
                  {relatedNews &&
                    relatedNews.length >= 1 &&
                    relatedNews.map((newsObject, index) => (
                      <>
                        <TableRow key={newsObject.id}>
                          <TableData>
                            <Text> {newsObject?.newspaper} </Text>
                          </TableData>
                          <TableData>
                            <Text> {newsObject?.category} </Text>
                          </TableData>
                          <TableData>
                            <Text>{newsObject?.title}</Text>
                          </TableData>
                          <TableData>
                            <Text>
                              {format(
                                Date.parse(newsObject?.date),
                                " dd/MM/yyyy",
                                {
                                  locale: ptBR,
                                  timeZone: "America/Sao_Paulo",
                                }
                              )}
                            </Text>
                          </TableData>

                          <TableData style={{ textAlign: "right" }}>
                            <ButtonRemoveNewsRelated
                              onClick={() =>
                                handleRemoveRelatedNews(newsObject.id)
                              }
                            >
                              {
                                <img
                                  width="16px"
                                  height="16px"
                                  src={spamIcon}
                                />
                              }
                            </ButtonRemoveNewsRelated>
                          </TableData>
                        </TableRow>
                      </>
                    ))}
                </Table>
              </WrapperForm>
            </ContainerWhite>
          </WrapperMain>

          {/* control main */}

          <WrapperMain style={{ padding: "1rem" }}>
            <HorizontalStack>
              <ControlLeft>
                <SearchBox>
                  <RightBox>
                    <InputSearchBox
                      type="text"
                      placeholder="Search"
                      value={searchListNews}
                      onChange={handleChangeSearch}
                    />
                  </RightBox>
                  <LeftBox>
                    <IconSvg width="20px" height="20px" image={searchIcon} />
                  </LeftBox>
                </SearchBox>
              </ControlLeft>
              <ControlCenter>
                <Select
                  // value={category}
                  value={topicParams}
                  onChange={(e) => {
                    setSearchListNews("");
                    setTopicParams(e.target.value);
                  }}
                  // onChange={handleOptionsCategory}
                  className="input-default-list-news"
                >
                  <option value="" hidden>
                    Topic / Category
                  </option>
                  <option value="UK Affairs">UK Affairs</option>
                  <option value="Global Affairs">Global Affairs</option>
                  <option value="Business">Business</option>
                  <option value="Sport">Sport</option>
                  <option value="Tech">Tech</option>
                  <option value="Science">Science</option>
                  <option value="Politics">Politics</option>
                  <option value="Wellness">Wellness</option>
                  <option value="Entertainment">Entertainment</option>
                  <option value="Opinion">Opinion</option>
                </Select>

                <img src={iconArrow} className="image-position" />
              </ControlCenter>
              <ControlRight>
                <ButtonMain
                  className="button-excel"
                  onClick={handleSearchSubmit}
                >
                  <IconSvg image={iconCheck} width="20px" height="20px" />
                </ButtonMain>
              </ControlRight>
            </HorizontalStack>
            <ContainerWhite style={{ marginTop: "1rem" }}>
              <WrapperRelatedNews>
                <Table>
                  <TableRow>
                    <TableHead>Newspapper</TableHead>
                    <TableHead>Category/Topic</TableHead>
                    <TableHead className="force-tranform-title">
                      Title
                    </TableHead>
                    <TableHead>Date</TableHead>
                    <TableHead className="transform-to-main">Main</TableHead>
                    <TableHead className="transform-to-relate">
                      Relate
                    </TableHead>
                  </TableRow>
                  {newsList.length > 0 ? (
                    newsList?.map((newsObject, index) => (
                      <TableRow key={newsObject.id}>
                        <TableData>
                          <Text> {newsObject.newspaper} </Text>
                        </TableData>
                        <TableData>
                          <Text> {newsObject.category} </Text>
                        </TableData>
                        <TableData
                          style={{ maxWidth: "300px", padding: "0 1rem" }}
                        >
                          <Text>{newsObject.title}</Text>
                        </TableData>
                        <TableData>
                          <Text>
                            {format(
                              Date.parse(newsObject?.date),
                              " dd/MM/yyyy",
                              {
                                locale: ptBR,
                                timeZone: "America/Sao_Paulo",
                              }
                            )}
                          </Text>
                        </TableData>
                        {/* main news */}
                        <TableData>
                          <RoundCheck
                            onClick={(event: any) =>
                              handleChangeMainNews(event, newsObject)
                            }
                          >
                            <input
                              key={newsObject.id}
                              style={{ margin: "auto auto", cursor: "pointer" }}
                              type="checkbox"
                              checked={newsObject.checkedMain}
                              className="option-input radio"
                              name="active"
                              id={"transform-force-main-checkbox"}
                              onChange={(event) =>
                                handleChangeMainNews(event, newsObject)
                              }
                            />

                            <label htmlFor="checkbox"></label>
                          </RoundCheck>

                          {/* <CheckButton
                            key={newsObject.id}
                            isChecked={newsObject.checkedMain}
                            newsObject={newsObject}
                            callback={handleChangeMainNews}
                          /> */}
                        </TableData>
                        {/* related news */}
                        <TableData>
                          <RoundCheckRelate>
                            <input
                              ref={refCheck}
                              key={newsObject.id}
                              id={"transform-force-related-checkbox"}
                              style={{
                                margin: "1rem auto 1rem 1rem",
                                cursor: "pointer",
                              }}
                              type="checkbox"
                              checked={newsObject.checked}
                              onChange={(event) =>
                                handleChangeRelatedNews(event, newsObject)
                              }
                            />

                            <label htmlFor="checkbox"></label>
                          </RoundCheckRelate>
                        </TableData>
                      </TableRow>
                    ))
                  ) : (
                    <div
                      style={{
                        minHeight: "200px",
                        display: "flex",
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <span
                        className="translate-to-text-center"
                        // style={{ transform: "translateX(280px)" }}
                      >
                        Empty data for your search.
                      </span>
                    </div>
                  )}
                </Table>
              </WrapperRelatedNews>
            </ContainerWhite>
            <div className="adjust-pagination-container">
              <FlexPagination>
                {newsList && newsList.length >= 1 && (
                  <Pagination
                    limit={10}
                    total={totalPages}
                    offset={dataNews?.offset}
                    setDataNews={setDataNews}
                    dataNews={dataNews}
                  />
                )}
              </FlexPagination>
            </div>
          </WrapperMain>
          <ButtonSave
            onClick={handleSaveRelatedNews}
            style={{ marginLeft: "auto" }}
            className="button-excel"
          >
            {loadingSave ? (
              <>
                <LoadingContainer>
                  <LoadingSpinner />
                </LoadingContainer>
              </>
            ) : (
              "Save"
            )}
          </ButtonSave>
        </FlexFull>
      )}
    </ContainerFull>
  );
};

export default RelatedNewsMain;

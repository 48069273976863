import React, { useEffect } from "react";
import { string } from "yup";
import { InputBase } from "./styles";

// import { Container } from './styles';

interface InputDefaultProps extends React.HTMLAttributes<HTMLInputElement> {
  placeholder?: any;
  type: string;
  value?: any;
  ref?: any;
  name?: string;
  className?: string;
}

const InputDefault: React.FC<InputDefaultProps> = ({
  placeholder,
  type,
  value,
  ref,
  className,
  name,
  ...rest
}) => {
  return (
    <InputBase
      name={name}
      placeholder={placeholder}
      type={type}
      value={value}
      className={className}
      ref={ref}
      {...rest}
    />
  );
};

export default InputDefault;

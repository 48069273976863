import axios from "axios";
import React, { useState } from "react";
import { SubmitHandler } from "react-hook-form";
import { toast } from "react-toastify";
import { ImportList } from "../../../../shared/components/ImportList/ImportList";
import { LoadingOverlay } from "../../../../shared/components/LoadingOverlay/LoadingOverlay";
import { ContentCreatorHeader } from "../components/ContentCreatorHeader/ContentCreatorHeader";
import { IArticle } from "../NewsCsvImport/NewsCsvImport";
import { RootContainer } from "./styles";

export type INewsManualmportProps = {};

const NewsManualmport: React.FC<INewsManualmportProps> = ({}) => {
  const [isUploading, setIsUploading] = useState<boolean>(false);

  const onSubmit: SubmitHandler<IArticle[]> = async (data) => {
    try {
      setIsUploading(true);
      const cronApi = axios.create({
        baseURL: process.env.REACT_APP_CRON_API, //"cron job address",
      });

      let response = await cronApi.post("news/upload-multiple", data);
      if (response.status === 200) {
        toast.success("Articles successfully imported!");
      }
    } catch (e) {
      toast.error("Something went wrong while uploading news");
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <RootContainer>
      <ContentCreatorHeader />
      {isUploading ? (
        <LoadingOverlay
          text={
            "Importing your articles to our database. It will take some time,but you can close this window!"
          }
          onClose={() => setIsUploading(false)}
        />
      ) : (
        <ImportList onSubmit={onSubmit} />
      )}
    </RootContainer>
  );
};

export { NewsManualmport };

import styled from "styled-components";

interface CollapseContentProps {
  isOpen: boolean;
}

interface HorizontalStackProps {
  isOpen: boolean;
}

export const CollapseContainer = styled.div`
  width: 100%;
  background: white;
  cursor: pointer !important;
  margin: 0rem 0;
  align-items: center;
  display: flex;
  flex-direction: column;
  border: 0.5px solid #9d9d9d;
`;

export const ButtonCollapse = styled.div`
  width: 100%;
`;

export const CollapseContent = styled.div<CollapseContentProps>`
  width: 100%;
  height: ${({ isOpen }) => (isOpen ? "70px" : "0")};
  overflow: hidden;
  background: white;
  display: flex;
  font-size: 0.8rem;
  align-items: center;
  padding: 0 2rem;
  border-radius: 0px 0px 5px 5px;
  border-top: none;
  transition: height ease 0.3s;
`;

export const HorizontalStack = styled.div<HorizontalStackProps>`
  display: flex;
  width: 100%;
  height: 100%;
  border: 1px solid ${({ theme }) => theme.colors.gray200};
  padding: 0 2rem;
  justify-content: space-between;
  align-items: center;
  transition: all 2s linear;

  & .arrow-rotate {
    transition: all 0.3s linear;
    transform: ${({ isOpen }) => isOpen && "rotate(180deg)"};
  }
`;

export const Text = styled.p`
  font-size: 1.4rem;
  color: black;
  margin: 2rem 2rem 2rem 2rem;
  font-weight: bold;
`;

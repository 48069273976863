import styled from "styled-components";

interface GridItemProps {
  src?: string;
}

export const Flex = styled.div`
  width: 100%;
  height: 100%;
  background: #ebedef;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 5rem;
`;

export const Container = styled.div`
  width: 100%;
  height: 302px;
  display: flex;
  border: 1px solid ${({ theme }) => theme.colors.gray200};
  -webkit-box-shadow: 0px 0px 40px 1px rgba(0, 0, 0, 0.017);
  box-shadow: 0px 0px 40px 1px rgba(0, 0, 0, 0.03);
  flex-direction: column;
`;

export const FlexItems = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`;

export const Text = styled.span`
  font-size: 1.7rem;
  font-family: "Arial";
  font-weight: bold;
`;

export const Box = styled.div`
  display: flex;
  width: 100%;
  padding-left: 1rem;
  height: 51px;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray200};
  background: #ebedef;
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  height: calc(302px - 51px);
  flex-direction: column;
  background: white;
  padding: 1rem 1rem;
  align-items: center;
  justify-content: center;
`;

export const Background = styled.div`
  width: 100%;
  height: 100%;
  background: #ebedef;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
`;

export const VerticalStack = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  margin-top: 3rem;
  flex-direction: column;
  justify-content: space-evenly;
`;

export const HorizontalStack = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
`;

export const Button = styled.button`
  width: 100%;
  max-width: 47px;
  height: 47px;
  background: ${({ theme }) => theme.colors.honey};
  font-size: 1.5rem;
  font-weight: bold;
  display: flex;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  transition: all 120ms linear;

  @media (max-width: 1600px) {
    width: 42px;
    height: 42px;
  }

  @media (max-width: 1366px) {
    width: 40px;
    height: 40px;
    border-radius: 5px;
  }
`;

export const BoxGray = styled.div`
  width: 100%;
  min-height: 100%;
  background: ${({ theme }) => theme.colors.gray200};
  display: flex;
  justify-content: center;
  padding: 0rem 2rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  overflow-y: auto;
  flex-direction: column;
  flex: 1;
`;

export const WhiteMainBox = styled.div`
  width: 100%;
  height: 100%;
  background: white;
  display: flex;
  flex-direction: column;
  padding: 0 1rem 2rem 1rem;
`;

export const FlexContent = styled.div`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.gray100};
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const GrayBoxContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: ${({ theme }) => theme.colors.gray100};
`;

export const InputBox = styled.div`
  width: 100%;
  display: flex;
  min-height: 80px;
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

export const FlexRight = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
`;

export const FlexLeft = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
`;

export const CenterHorizontalStack = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem auto;
`;

export const ButtonAddNewPhoto = styled.button`
  width: 180px;
  height: 47px;
  background: ${({ theme }) => theme.colors.honey};
  color: black;
  margin: 0 auto;
  font-weight: bold;
  border-radius: 5px;
  transition: all 120ms linear;

  @media (max-width: 1366px) {
    width: 120px;
    height: 40px;
    font-size: 1.3rem;
  }
`;

export const ButtonAddNewPhotoDisable = styled.button`
  width: 180px;
  height: 47px;
  background: ${({ theme }) => theme.colors.honey};
  color: black;
  margin: 0 auto;
  font-weight: bold;
  border-radius: 5px;
  transition: all 120ms linear;
  opacity: 0.3;
  cursor: not-allowed;

  @media (max-width: 1366px) {
    width: 120px;
    height: 40px;
    font-size: 1.3rem;
  }
`;

export const WrapperButton = styled.div`
  width: 100%;
  display: flex;
  margin: 1rem auto;
`;

export const GridContainer = styled.div`
  display: flex;
  width: 100%;
  height: 1000px;
  background: red;
`;

// news components below

export const ContainerImages = styled.div`
  background-color: white;
  padding: 1.5rem;
  border-radius: 5px;
`;

export const UploadsMainContainer = styled.div`
  display: flex;
  width: 100%;
  background: ${({ theme }) => theme.colors.gray200};
  padding: 2rem 2rem;
  overflow-y: auto;
  flex-direction: column;
`;

export const MainBox = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  flex-direction: column;
  padding: 0 0rem 0rem 0rem;
  border-radius: 5px;
  border: 1px solid #dadada;
  -webkit-box-shadow: -2px -1px 11px -2px rgba(0, 0, 0, 0.08);
  box-shadow: -2px -1px 11px -2px rgba(0, 0, 0, 0.08);
`;

export const WrapperFlex = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`;

export const FormBox = styled.div`
  display: flex;
  width: 100%;
  height: 80px;
  padding: 2rem 0rem 2rem 0.6rem;
  background: white;
  align-items: center;
  justify-content: center;
  background: white;
  border-radius: 5px 5px 0px 0px;
`;

export const FormLeft = styled.div`
  display: flex;
  width: 46%;
  height: 100%;
  padding: 0 0.5rem;
  align-items: center;
  position: relative;

  .image-position {
    position: absolute;
    right: 15px;
    top: 18px;
  }
`;

export const FormRight = styled.div`
  display: flex;
  width: 46%;
  height: 100%;
  padding: 0 0.5rem;
  align-items: center;
  margin-left: 1rem;
  position: relative;

  .image-position {
    position: absolute;
    right: 15px;
    top: 18px;
  }
`;

export const ButtonSubmit = styled.div`
  display: flex;
  width: 5%;
  height: 100%;
  padding: 0 0rem;
  margin-right: 1rem;
  justify-content: flex-end;
  align-items: center;
`;

export const UploadsArea = styled.div`
  display: flex;
  width: 100%;
  padding: 0rem 1.5rem 1.5rem 1.5rem;
  margin-top: -1.2rem;
  flex-direction: column;
  background: #f9fafa;
  border-radius: 0 0 5px 5px;
  padding-top: 3rem;

  & .upload__image-wrapper {
    margin: 0 auto;
  }
`;

export const Grid = styled.div`
  display: grid;
  width: 100%;
  height: 100%;
  align-items: center;
  padding: 2rem;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  grid-row-gap: 3.8rem;
  margin: 0 auto;

  .image-close {
  }

  @media (max-width: 1700px) {
    grid-row-gap: 3rem;
  }

  @media (max-width: 1366px) {
    grid-template-columns: repeat(3, 1fr);
    margin-top: 0rem;
    grid-row-gap: 2rem;
  }
`;

export const GridItem = styled.div<GridItemProps>`
  width: auto;
  height: 330px;
  max-width: 420px;
  background-image: url(${({ src }) => src && src});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
  position: relative;
  border-radius: 5px;
  -webkit-box-shadow: -1px 0px 15px -5px rgba(0, 0, 0, 0.58);
  box-shadow: -1px 0px 15px -5px rgba(0, 0, 0, 0.15);

  & img {
    position: absolute;
    right: 5%;
    top: 5%;
  }

  @media (max-width: 1700px) {
    height: 290px;
    max-width: 370px;
  }

  @media (max-width: 1366px) {
    height: 220px;
    max-width: 300px;
  }
`;

export const ButtonRemoveImg = styled.button`
  cursor: pointer;
  -webkit-box-shadow: 5px 5px 14px 5px #ffffff;
  box-shadow: 5px 5px 14px 5px #ffffff;
`;

export const ButtonSeeMore = styled.button`
  width: 112px;
  height: 47px;
  margin: 0 auto;
  background: ${({ theme }) => theme.colors.honey};
  border-radius: 5px;
  font-weight: bold;
  font-family: "Open Sans", sans-serif;
  transition: all 120ms linear;

  @media (max-width: 1366px) {
    height: 40px;
    border-radius: 5px;
    font-size: 1.2rem;
  }
`;

export const FlexBox = styled.div`
  width: 100%;
  min-height: 100px;
  display: flex;
`;

export const ButtonSaveUpload = styled.button`
  width: 112px;
  height: 47px;
  margin-top: 2rem;
  margin-bottom: 3rem;
  margin-left: auto;
  background: ${({ theme }) => theme.colors.honey};
  border-radius: 5px;
  font-weight: bold;
`;

export const FlexEmpty = styled.div`
  width: 100%;
  height: 45vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

/*
*this file contains the chart as named by
"Total articles read" chart and loading all data values,
also exporting to excel data values and user be filter it
*/
import React, { useCallback, useEffect, useState } from "react";
import InputDefault from "../../PanelControl/components/InputDefault";
import iconArrow from "../../../assets/option-arrow.svg";
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import excelButtonIcon from "../../../assets/downloadf-icon-excel.svg";
import { format } from "date-fns-tz";
import api from "../../../../../services/api";
import IconSvg from "../../../../login/components/IconSvg";
import checkIcon from "../../../assets/icon-check.svg";
import { ExportToExcel } from "../ExportToExcel";
import DateFromPieAge from "./components/DateFrom/DateFromPieAge";
import DateToPieAge from "./components/DateTo/DateToPieAge";

//set up styles
import {
  Button,
  Flex,
  FlexChart,
  FlexFilters,
  FlexHeader,
  Select,
  TextHead,
  TextInput,
  VerticalStackButton,
  VerticalStackDate,
  VerticalStackPolitical,
  VerticalStackRegion,
  VerticalStackTopic,
} from "./styles";

//set up interfaces
interface DataNumericApiProps {
  age_17: number;
  age_18_25: number;
  age_26_30: number;
  age_31_35: number;
  age_36_40: number;
  age_41_50: number;
  age_51_60: number;
  age_61_69: number;
  age_70: number;
}

interface ModelChartProps {
  title: string;
}

interface QueryProps {
  initial_date: string | null;
  final_date: string | null;
  topic: string | null;
  initial_political_view: number | null;
  final_political_view: number | null;
  region: string | null;
}

const labelsNumeric = [
  { numeric: "1" },
  { numeric: "2" },
  { numeric: "3" },
  { numeric: "4" },
  { numeric: "5" },
  { numeric: "6" },
  { numeric: "7+" },
];

// here are options for
// react chart js 2 by definition in docs:
// Options are resolved from top to bottom,
// using a context dependent route.
// read more on https://react-chartjs-2.js.org/components/

// in this caseit was also necessary to add the percentage
// of each slice, so there is a percentage calculation and
// I only change the label that the library shows to the user
export const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "top" as const,
      display: true,
    },
    tooltip: {
      //change tooltip for pencentage calculation
      callbacks: {
        label: function (tooltipItem?: any, data?: any) {
          let sumOfDatas = tooltipItem?.dataset;
          let numbers = sumOfDatas?.data;
          let total = numbers.reduce(function (
            previousValue: number,
            currentValue: number
          ) {
            return previousValue + currentValue;
          });
          //total is 100%;
          const rawLabel = tooltipItem?.label;
          const rawValue = tooltipItem?.raw;
          const currentData = rawValue;
          //generate porcentage
          let percentage = parseFloat(((currentData / total) * 100).toFixed(1));
          //then show on tooltip the result
          return `${rawLabel}: ${percentage}% `;
        },
        title: function (tooltipItem?: any, data?: any) {
          // return data.labels[tooltipItem[0].index];
          //here contains raw title just
          const rawTitle = tooltipItem[0].raw;

          return `${rawTitle} `;
        },
      },
    },
  },
};

const PieAge: React.FC<ModelChartProps> = ({ title }) => {
  //register components for chart js 2
  ChartJS.register(ArcElement, Tooltip, Legend);

  //set up states
  const [query, setQuery] = useState<QueryProps>({
    initial_date: null,
    final_date: null,
    topic: null,
    initial_political_view: null,
    final_political_view: null,
    region: null,
  });

  const [numericChartData, setnumericChartData] = useState<DataNumericApiProps>(
    {} as DataNumericApiProps
  );
  const [arrFilteredValues, setArrFilteredValues] = useState<
    DataNumericApiProps[]
  >([]);
  //state of react date component
  const [value, onChange] = useState(new Date());
  const [initialPolitical, setInitialPolitical] = useState<number>(0);
  const [finalPolitical, setFinalPolitical] = useState<number>(0);
  const [dateFrom, setDateFrom] = useState<string>("");
  const [dateTo, setDateTo] = useState<string>("");
  const [topics, setTopics] = useState<string>("");
  const [initialAge, setInitialAge] = useState<number>(0);
  const [finalAge, setFinalAge] = useState<number>(0);
  const [regionOption, setRegionOption] = useState("");
  const [areaOption, setAreaOption] = useState("");
  const [currentFilters, setCurrentFilters] = useState<QueryProps[]>([]);
  const [selectedDate, setSelectedDate] = React.useState<Date>();
  const [selected, setSelected] = React.useState<Date>();
  const [isDateFrom, setIsDateFrom] = useState(false);
  const [isDateTo, setIsDateTo] = useState(false);

  // RANGE DATE FINAL
  //below function will be close dropdown final date
  const closeDropdownDateTo = () => {
    setIsDateTo(false);
  };

  //below function will be toggle for show
  //date component about final date
  const toggleDateTo = useCallback(
    (e) => {
      e.preventDefault();
      setIsDateTo((prevState) => !prevState);
    },
    [isDateTo]
  );

  // RANGE DATE INITIAL
  //below function will be close dropdown initial date
  const closeDropdownDateFrom = () => {
    setIsDateFrom(false);
  };

  //below function will be toggle for show
  //date component about initial date
  const toggleDateFrom = useCallback(
    (e) => {
      e.preventDefault();
      setIsDateFrom((prevState) => !prevState);
    },
    [isDateFrom]
  );

  const labelsAge = [
    "-17",
    "18-25",
    "26-30",
    "31-35",
    "36-40",
    "41-50",
    "51-60",
    "61-69",
    "70+",
  ];

  // data of labels for react chart js 2
  // datasets is an array of objects where each object represents
  // a set of data to display corresponding to the labels above.
  // for brevity, we'll keep it at one object
  const data = {
    labels: labelsAge,
    datasets: [
      {
        label: "# of Votes",
        data: [
          numericChartData.age_17,
          numericChartData.age_18_25,
          numericChartData.age_26_30,
          numericChartData.age_31_35,
          numericChartData.age_36_40,
          numericChartData.age_41_50,
          numericChartData.age_51_60,
          numericChartData.age_61_69,
          numericChartData.age_70,
        ],
        backgroundColor: [
          "#0D6EFD",
          "#D63384",
          "#FFC107",
          "#6610F2",
          "#DC3545",
          "#198754",
          "#FD7E14",
          "#6F42C1",
          "#20C997",
        ],
        borderColor: [
          "#0D6EFD",
          "#D63384",
          "#FFC107",
          "#6610F2",
          "#DC3545",
          "#198754",
          "#FD7E14",
          "#6F42C1",
          "#20C997",
        ],
        borderWidth: 1,
      },
    ],
  };

  //just fetch data fot the api beehive
  //and update the state main
  //in array of dependencies query for filter data
  //if necessary
  useEffect(() => {
    const autoLoad = async () => {
      try {
        const responseCharts = await api.get(
          "dashboard-graphics/age-distribution",
          {
            params: query,
          }
        );

        setnumericChartData(responseCharts.data);
      } catch (err) {
        console.log(err, "erro?");
      }
    };

    autoLoad();
  }, [query]);

  //set up this state for excel exporting
  useEffect(() => {
    setArrFilteredValues((state) => {
      let cloneState = [];
      cloneState.push(numericChartData);
      return cloneState;
    });
  }, [numericChartData]);

  //choose topic filter handler
  const handleTopicFilter = useCallback(
    (event) => {
      setTopics(event.target.value);
    },
    [topics]
  );

  //choose dateFrom filter handler
  const onChangeDateFrom = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setDateFrom(event.target.value);
    },
    [dateFrom]
  );

  //choose date to filter handler
  const onChangeDateTo = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setDateTo(event.target.value);
    },
    [dateTo]
  );

  //choose initial political position filter handler
  const onChangeInitialPolitical = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setInitialPolitical(Number(event.target.value));
    },
    [initialPolitical]
  );

  //choose final political position filter handler
  const handleChangeFinalPolitical = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setFinalPolitical(Number(event.target.value));
    },
    [finalPolitical]
  );

  const handleChangeRegion = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      setRegionOption(event.target.value);
    },
    [regionOption]
  );

  const changeAreaOption = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      setAreaOption(event.target.value);
    },
    [areaOption]
  );

  const handleButtonSubmitChart = useCallback(() => {
    setQuery((state: QueryProps) => {
      let cloneState = {
        ...state,
        topic: topics === "" ? null : topics,
        initial_date: dateFrom === "" ? null : dateFrom,
        final_date: dateTo === "" ? null : dateTo,
        initial_political_view:
          initialPolitical === 0 ? null : initialPolitical,
        final_political_view: finalPolitical === 0 ? null : finalPolitical,
        region: !regionOption
          ? null
          : regionOption !== "" && areaOption === ""
          ? `${regionOption}#@@#all`
          : `${regionOption}#@@#${areaOption}`,
      };

      return cloneState;
    });
  }, [
    query,
    topics,
    dateFrom,
    dateTo,
    initialPolitical,
    finalPolitical,
    initialAge,
    finalAge,
    regionOption,
    areaOption,
  ]);

  //this useEffect for reset state of area
  //when region is null
  useEffect(() => {
    if (!regionOption) {
      setAreaOption("");
    }
  }, [regionOption]);

  useEffect(() => {
    //Array of query filters for export excel
    setCurrentFilters((state) => {
      let cloneState = [];
      cloneState.push({
        ...query,
        region: query?.region?.replace("#@@#", " - "),
      });

      return cloneState;
    });
  }, [query]);

  useEffect(() => {
    if (selectedDate) {
      setDateFrom(format(selectedDate, "yyyy/MM/dd").replaceAll("/", "-"));
    }

    if (selected) {
      setDateTo(format(selected, "yyyy/MM/dd").replaceAll("/", "-"));
    }
  }, [selectedDate, selected]);

  return (
    <Flex>
      <FlexHeader>
        <TextHead> {title} </TextHead>
        <ExportToExcel
          dataFilters={currentFilters}
          valuesFiltered={arrFilteredValues}
          nameFile="total-articles-read-per-user"
        >
          <IconSvg image={excelButtonIcon} width={"40px"} height={"40px"} />
        </ExportToExcel>
      </FlexHeader>
      <FlexChart>
        <Doughnut data={data} options={options} />
      </FlexChart>
      <FlexFilters>
        <VerticalStackDate>
          <TextInput>Date</TextInput>
          <div className="wrapper-date-from">
            <InputDefault
              placeholder="from"
              type="text"
              value={dateFrom}
              onChange={onChangeDateFrom}
              onClick={toggleDateFrom}
            />

            {isDateFrom && (
              <DateFromPieAge
                closeToggle={closeDropdownDateFrom}
                selected={selectedDate}
                setSelected={setSelectedDate}
              />
            )}
          </div>

          <div className="wrapper-date-from">
            <InputDefault
              placeholder="to"
              type="text"
              value={dateTo}
              onChange={onChangeDateTo}
              onClick={toggleDateTo}
            />

            {isDateTo && (
              <DateToPieAge
                closeToggle={closeDropdownDateTo}
                selected={selected}
                setSelected={setSelected}
              />
            )}
          </div>
        </VerticalStackDate>
        <VerticalStackTopic>
          <TextInput style={{ marginBottom: "1rem" }}>Topic</TextInput>
          <div className="arrow-wrapper">
            <Select
              value={topics}
              onChange={handleTopicFilter}
              style={{ height: "42px" }}
              className="force-margin-select"
            >
              <option value={""} hidden>
                {"Topic"}
              </option>
              <option value="Global Affairs">Global Affairs</option>
              <option value="Business">Business</option>
              <option value="Opinion">Opinion</option>
              <option value="Sport">Sport</option>
              <option value="Entertainment">Entertainment</option>
              <option value="Wellness">Wellness</option>
              <option value="Politics">Politics</option>
              <option value="Science">Science</option>
              <option value="Tech">Tech</option>
              <option value="UK Affairs">Uk Affairs</option>
            </Select>
            {topics === "" && (
              <img src={iconArrow} className="image-position" alt="" />
            )}
          </div>
        </VerticalStackTopic>
        <VerticalStackPolitical>
          <TextInput>Political...</TextInput>
          <InputDefault
            placeholder="from"
            type="number"
            value={initialPolitical}
            onChange={onChangeInitialPolitical}
          />
          <InputDefault
            placeholder="to"
            type="number"
            value={finalPolitical}
            onChange={handleChangeFinalPolitical}
          />
        </VerticalStackPolitical>

        <VerticalStackRegion>
          <TextInput>Region</TextInput>
          <div className="arrow-wrapper">
            <Select
              value={regionOption}
              onChange={handleChangeRegion}
              style={{ height: "45px" }}
            >
              <option value={""} hidden>
                {"Region"}
              </option>
              <option value="England - Greater London">Greater London</option>
              <option value="East of England">East of England</option>
              <option value="East Midlands">East Midlands</option>
              <option value="North East">North East</option>
              <option value="North West">North West</option>
              <option value="South East">South East</option>
              <option value="South West">South West</option>
              <option value="West Midlands">West Midlands</option>
              <option value="Yorkshire & The Humber">
                Yorkshire The Humber
              </option>
              <option value="Scotland">Scotland</option>
              <option value="Wales">Wales</option>
              <option value="North Ireland">Northern Ireland</option>
              <option value="Other">Other</option>
            </Select>
            {regionOption === "" && (
              <img src={iconArrow} className="image-position" />
            )}
          </div>
          <div className="arrow-wrapper">
            <Select
              value={areaOption}
              onChange={changeAreaOption}
              style={{ height: "45px" }}
            >
              <option value={""} hidden>
                {"Area"}
              </option>
              {regionOption === "" ? (
                // <option value="">Select region</option>
                <></>
              ) : regionOption === "England - Greater London" ? (
                <>
                  <option value="East Central (EC1, EC2, E1-E20)">
                    East Central (EC1, EC2, E1-E20)
                  </option>
                  <option value="East Other">East Other</option>
                  <option value="North Central (N1-N22)">
                    North Central (N1-N22)
                  </option>
                  <option value="North Other">North Other</option>
                  <option value="North West Central (NW1-NW11)">
                    North West Central (NW1-NW11)
                  </option>
                  <option value="North West Other">North West Other</option>
                  <option value="South East Central (SE1-SE28)">
                    {" "}
                    South East Central (SE1-SE28)
                  </option>
                  <option value="South East Other">South East Other</option>
                  <option value="South West Central (SW1-SW20)">
                    South West Central (SW1-SW20)
                  </option>
                  <option value="South West Other">South West Other</option>
                  <option value="West Central (WC1, WC2, W1-W14)">
                    {" "}
                    West Central (WC1, WC2, W1-W14)
                  </option>
                  <option value="West Other">West Other</option>
                </>
              ) : regionOption === "East of England" ? (
                <>
                  <option value="Cambridge area">Cambridge area</option>
                  <option value="Other">Other</option>
                </>
              ) : regionOption === "East Midlands" ? (
                <>
                  <option value="Leicester area">Leicester area</option>
                  <option value="Nottingham area">Nottingham area</option>
                  <option value="Other">Other</option>
                </>
              ) : regionOption === "North East" ? (
                <>
                  <option value="Newcastle / Tyneside area">
                    Newcastle / Tyneside area
                  </option>
                  <option value="Other">Other</option>
                </>
              ) : regionOption === "North West" ? (
                <>
                  <option value="Liverpool area">Liverpool area</option>
                  <option value="Manchester area">Manchester area</option>
                  <option value="Other">Other</option>
                </>
              ) : regionOption === "South East" ? (
                <>
                  <option value="Brighton area">Brighton area</option>
                  <option value="Oxford area">Oxford area</option>
                  <option value="Portsmouth / Southampton area">
                    Portsmouth / Southampton area
                  </option>
                  <option value="Milton Keynes area">Milton Keynes area</option>
                  <option value="Other">Other</option>
                </>
              ) : regionOption === "South West" ? (
                <>
                  <option value="Bournemouth /Poole area">
                    Bournemouth /Poole area
                  </option>
                  <option value="Bristol area">Bristol area</option>
                  <option value="Other">Other</option>
                </>
              ) : regionOption === "West Midlands" ? (
                <>
                  <option value="Birmingham area">Birmingham area</option>
                  <option value="Coventry area">Coventry area</option>
                  <option value="Warwick area">Warwick area</option>
                  <option value="Other">Other</option>
                </>
              ) : regionOption === "Yorkshire & The Humber" ? (
                <>
                  <option value="Leeds area">Leeds area</option>
                  <option value="Sheffield area">Sheffield area</option>
                  <option value="Bradford area">Bradford area</option>
                  <option value="Other">Other</option>
                </>
              ) : regionOption === "Scotland" ? (
                <>
                  <option value="Glasgow area">Glasgow area</option>
                  <option value="Edinburgh area">Edinburgh area</option>
                  <option value="Other">Other</option>
                </>
              ) : regionOption === "Wales" ? (
                <>
                  <option value="Cardiff area">Cardiff area</option>
                  <option value="Other">Other</option>
                </>
              ) : regionOption === "North Ireland" ? (
                <>
                  <option value="Cardiff area">Cardiff area</option>
                  <option value="Other">Other</option>
                </>
              ) : (
                regionOption === "Other" && (
                  <>
                    <option value="Other">Other</option>
                  </>
                )
              )}
            </Select>
            {areaOption === "" && (
              <img src={iconArrow} className="image-position" alt="" />
            )}
          </div>
        </VerticalStackRegion>
        <VerticalStackButton>
          <Button className="button-excel" onClick={handleButtonSubmitChart}>
            <img src={checkIcon} />
          </Button>
        </VerticalStackButton>
      </FlexFilters>
    </Flex>
  );
};

export default PieAge;

import React from "react";
import { DayPicker } from "react-day-picker";

import { useDetectClickOutside } from "react-detect-click-outside";
import { BoxDate } from "../../../../NewsScreen/components/DateComponentFrom/styles";

// import { Container } from './styles';

interface DateToComponentProps {
  closeToggle: (e) => void;
  selected: Date;
  setSelected: React.Dispatch<React.SetStateAction<Date>>;
}

const DateFromQualityWriting: React.FC<DateToComponentProps> = ({
  closeToggle,
  selected,
  setSelected,
}) => {
  const ref2 = useDetectClickOutside({ onTriggered: closeToggle });
  return (
    <BoxDate className="boxDates" ref={ref2}>
      <DayPicker
        mode="single"
        selected={selected}
        onSelect={setSelected}
        // footer={footer}
      />
    </BoxDate>
  );
};

export default DateFromQualityWriting;

import styled from "styled-components";
import { lighten } from "polished";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  display: flex;
  margin: -1rem auto 0 auto;
  background-color: #ebedef;
  padding: 2.5rem;
  flex-direction: column;

  div.sc-kHneYr.YOmvL {
    width: 100% !important;
    max-width: 100%;
  }

  .last-grid {
    width: 98% !important;
  }

  @media (max-width: 1600px) {
    .last-grid {
      width: 1210px !important;
    }
  }

  @media (max-width: 1366px) {
    .last-grid {
      width: 990px !important;
    }
  }
`;

export const ButtonsExcelContainer = styled.div`
  width: 100%;
  height: 90px;
  background-color: #ebedef;
  display: flex;
  align-items: center;
  padding: 1rem 4.5rem 2rem 0;

  @media (max-width: 1366px) {
    height: 84px;
  }
`;

export const WrapperRight = styled.div`
  width: 40%;
  height: 100%;
  background-color: #ebedef;
  display: flex;
  margin-left: auto;
  padding: 0.5rem 0;
  justify-content: flex-end;
`;

export const GridContainer = styled.div`
  display: grid;
  margin: -2.4rem auto 0 auto;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2.5rem;
`;

export const GridItem = styled.div<{ maxWidth?: string }>`
  min-width: 300px;
  width: 100%;
  display: grid;

  @media (max-width: 1600px) {
    max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : "600px")};
  }

  /* height: auto; */
  :last-child {
    grid-column: 1 / -1;
  }
`;

export const ChartContainer = styled.div`
  height: 500px;
  background: white;
  margin-bottom: 10px;
`;

export const ButtonRatingExcel = styled.button`
  width: 150px;
  height: 45px;
  display: flex;
  background-color: ${({ theme }) => theme.colors.honey};
  color: black;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  border-radius: 5px;
  font-weight: bold;

  transition: background 120ms linear;

  :hover {
    background: ${({ theme }) => lighten(0.1, theme.colors.honey)};
    -webkit-box-shadow: 0px 0px 8px -5px #000000;
    box-shadow: 0px 0px 8px -5px #000000;
  }

  :disabled {
    cursor: "not-allowed";
    opacity: 0.2;
  }

  @media (max-width: 1366px) {
    font-size: 1.3rem;
    width: 133px;
    height: 42px;
  }
`;

export const ButtonUserExcel = styled.button`
  width: 150px;
  height: 45px;
  display: flex;
  margin-left: 1.5rem;
  background-color: ${({ theme }) => theme.colors.honey};
  color: black;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  border-radius: 5px;
  font-weight: bold;

  transition: background 120ms linear;

  :hover {
    background: ${({ theme }) => lighten(0.1, theme.colors.honey)};
    -webkit-box-shadow: 0px 0px 8px -5px #000000;
    box-shadow: 0px 0px 8px -5px #000000;
  }

  :disabled {
    cursor: "not-allowed";
    opacity: 0.2;
  }

  @media (max-width: 1366px) {
    font-size: 1.3rem;
    width: 133px;
    height: 42px;
  }
`;

export const ChartContainer2 = styled.div`
  height: 500px;
  background: white;
`;

export const FullChart = styled.div`
  display: flex;
  width: 100%;
  height: 498px;
  margin-top: 0;
  flex-direction: column;
`;

import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.gray200};
`;

export const LoginBox = styled.form`
  width: 566px;
  height: 486px;
  height: auto;
  display: flex;
  border-radius: 5px;
  padding: 4.2rem;
  background-color: ${({ theme }) => theme.colors.white};
  flex-direction: column;

  & .img-test {
    width: 100px;
    height: 100px;
  }
`;

export const Heading = styled.h1`
  margin-top: 2.9rem;
  font-family: "Open Sans";
  font-size: 5rem;
  font-weight: normal;

  @media (max-width: 1366px) {
    font-size: 3.6rem;
  }
`;

export const Text = styled.p`
  font-family: "Open Sans";
  font-size: 2rem;
  color: #9d9d9d;
  font-weight: normal;
  margin-top: 0.2rem;

  @media (max-width: 1366px) {
    font-size: 1.6rem;
  }
`;

export const Flex = styled.div`
  width: 100%;
  max-width: 515px;
  display: flex;
  height: 47px;
  border: 1px solid #9d9d9d;
  border-radius: 5px;
  margin-top: 23px;
`;

export const Box = styled.div`
  width: 52px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.gray200};
  border-radius: 5px 0px 0px 5px;
`;

export const Input = styled.input`
  width: 100%;
  height: 100%;
  border-radius: 5px;
  border: none;
  font-size: 1.8rem;
  text-align: left;
  padding-left: 1rem;
  min-height: 42px;

  &::placeholder {
    font-size: 1.8rem;
    text-align: left;
  }

  &:focus {
    border: 2px solid ${({ theme }) => theme.colors.honey};
    border-radius: 0px 5px 5px 0;
  }
`;

export const FlexButtons = styled.div`
  display: flex;
  margin-top: 32px;
  justify-content: space-between;
  align-items: center;
`;

export const ButtonLogin = styled.button`
  width: 112px;
  height: 47px;
  background: ${({ theme }) => theme.colors.honey};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.4rem;
  font-weight: bold;
  border-radius: 5px;

  transition: all 120ms linear;

  @media (max-width: 1366px) {
    height: 42px;
    width: 92px;
    font-size: 1.3rem;
  }
`;

export const ButtonForgotPassword = styled.p`
  font-size: 1.4rem;
  color: #3250e2;
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
  padding-right: 0rem;
  font-style: italic;
`;

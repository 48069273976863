import React from "react";
import {
  IArticle,
  Newspaper,
} from "../../../modules/dash/screen/NewsCsvImport/NewsCsvImport";
import { ImportListItem } from "./ImportListItem/ImportListItem";
import { CustomButton, ButtonContainer, CustomForm } from "./styles";
import * as yup from "yup";
import { FieldArray, Formik } from "formik";
import { format } from "date-fns";

export type IImportListProps = {
  onSubmit: (data: IArticle[]) => void;
};

const ImportList: React.FC<IImportListProps> = ({ onSubmit }) => {
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        articles: [
          {
            title: undefined,
            description: undefined,
            image: undefined,
            date: format(new Date(), "yyyy/MM/dd HH:mm"),
            link: undefined,
            article_text: undefined,
            key_words: undefined,
            newspaper: Newspaper.BathMagazine,
          },
        ],
      }}
      onSubmit={(values, { resetForm }) => {
        onSubmit(values.articles);
      }}
      validationSchema={fieldsSchema}
    >
      {({
        values,
        setFieldValue,
        errors,
        touched,
        setFieldTouched,
        handleSubmit,
        setValues,
        validateForm,
      }) => (
        <CustomForm>
          <FieldArray name="articles">
            {({ push, remove }) => (
              <>
                {values.articles.map((article, index) => {
                  return (
                    <ImportListItem
                      data={article}
                      key={index}
                      index={index}
                      remove={remove}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      errors={errors.articles && errors.articles[index]}
                      touched={touched.articles && touched.articles[index]}
                    />
                  );
                })}
                <ButtonContainer>
                  <CustomButton
                    onClick={() => {
                      push({
                        title: undefined,
                        description: undefined,
                        image: undefined,
                        date: format(new Date(), "yyyy/MM/dd HH:mm"),
                        link: undefined,
                        article_text: undefined,
                        key_words: undefined,
                        newspaper: Newspaper.BathMagazine,
                      });
                    }}
                  >
                    Add new
                  </CustomButton>
                  <CustomButton type="submit">Submit articles</CustomButton>
                </ButtonContainer>
              </>
            )}
          </FieldArray>
        </CustomForm>
      )}
    </Formik>
  );
};

const articleSchema = yup.object({
  title: yup.string().required(`Title is required`),
  description: yup.string().required(`Description is required`),
  image: yup.string().required(`Image link is required`),
  link: yup.string().required(`Article link is required`),
  date: yup.string(),
  key_words: yup.string(),
  article_text: yup.string(),
  newspaper: yup.string(),
});

const fieldsSchema = yup.object().shape({
  articles: yup
    .array()
    .of(articleSchema)
    .required("Must have fields")
    .min(1, "Minimum of 1 field"),
});

export { ImportList };

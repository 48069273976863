import styled from "styled-components";

interface ContainerProps {
  isExpanded?: boolean;
  collapsedHeight?: string;
}

export const AccordionWrapper = styled.div<ContainerProps>`
  width: 100%;
  height: auto;
  background: white;
  display: flex;
  flex-direction: column;
  border: 0.5px solid #dadada;
  border-radius: 5px;
  margin-bottom: 2rem;

  .button-header-collapsed {
    width: 100%;
    height: 51px;
    background-color: #666666;
    color: white;
    display: flex;
    align-items: center;
    padding: 0 1.5rem;
    justify-content: space-between;
    border-radius: 5px 5px 0 0;
  }

  .title-head {
    display: flex;

    span {
      font-size: 1.3rem;
      font-weight: bold;
      font-family: "Open Sans", sans serif;
    }

    @media (max-width: 1366px) {
      span {
        font-size: 1.2rem;
      }
    }
  }

  .content-accordion {
    width: 100%;
    height: auto;
    border-radius: 0px 0px 15px 15px;
  }

  .reflect-icon {
    transition: transform 0 ease;
    transform: scaleY(-1);
  }
`;

export const ContentAccordion = styled.div<ContainerProps>`
  display: flex;
  width: 100%;
  min-height: ${({ collapsedHeight }) => (collapsedHeight ? "auto" : "auto")};
  height: 100%;
  background: white;
  border-radius: 0px 0px 15px 15px;
  padding: 1.5rem;
`;

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Container, RootContainer, UploadCsvFile } from "./styles";

export type IDropzoneProps = {
  onUploadPress: (files: File[]) => void;
};

const Dropzone: React.FC<IDropzoneProps> = ({ onUploadPress }) => {
  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone();

  const [files, setFiles] = useState<File[]>([...acceptedFiles]);

  useEffect(() => {
    setFiles([...acceptedFiles]);
  }, [acceptedFiles]);

  const removeFile = (file: File) => {
    setFiles((oldFiles) =>
      oldFiles.filter((oldFile) => oldFile.name !== file.name)
    );
  };

  return (
    <RootContainer>
      <div className="container">
        <Container {...getRootProps({ isFocused, isDragAccept, isDragReject })}>
          <input {...getInputProps()} />
          <p>Drag 'n' drop articles file here, or click to select file</p>
        </Container>
        <aside>
          <h4>Files</h4>
          <ul>
            {files.map((file: any) => (
              <li
                key={file.path}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "50%",
                }}
              >
                <div>
                  {file.name} - {file.size} bytes
                </div>
                <div
                  style={{ marginLeft: 20 }}
                  onClick={() => {
                    removeFile(file);
                  }}
                >
                  x
                </div>
              </li>
            ))}
          </ul>
        </aside>
        <UploadCsvFile
          onClick={() => onUploadPress(files)}
          disabled={files.length === 0}
        >
          Upload file
        </UploadCsvFile>
      </div>
    </RootContainer>
  );
};

export { Dropzone };

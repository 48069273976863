/*
  in this component you will find the page that creates the functionality to add
  random images for a type of newspaper and a category, both fields will be
  mandatory and we will load images for the backend to display in
   the application one of these images when the algorithm does not recognize
  images of that newspaper by category
*/

import React, { useCallback, useEffect, useRef, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import IconSvg from "../../../login/components/IconSvg";
import ButtonNavigator from "../../components/ButtonNavigator";
import CollapseNews from "../../components/CollapseNews";
import ImageUploading from "react-images-uploading";
import removeImgIcon from "../../assets/icon-remove-photos.svg";
import Compressor from "compressorjs";

//import styled of reused components
import {
  AsideControls,
  Box,
  Header,
  MasterContainer,
  NavLeft,
  NavRight,
  TextHeader,
} from "../../Master/styles";

import {
  Button,
  ButtonAddNewPhoto,
  ButtonAddNewPhotoDisable,
  ButtonRemoveImg,
  ButtonSeeMore,
  ButtonSubmit,
  ContainerImages,
  FlexBox,
  FlexEmpty,
  FormBox,
  FormLeft,
  FormRight,
  Grid,
  GridItem,
  MainBox,
  Text,
  UploadsArea,
  UploadsMainContainer,
} from "./styles";

//logo
import logoBeehive from "../../assets/logo-dash.svg";

//icons ----------------------------------------------------- //
import dashIcon from "../../assets/icon-dash.svg";
import feedbackIcon from "../../assets/icon-dash-feedback.svg";
import subsIcon from "../../assets/icon-dash-subs.svg";
import usersIcon from "../../assets/icon-dash-users.svg";
import checkIcon from "../../assets/check-white-icon.svg";
import iconExit from "../../../dash/assets/icon-exit.svg";
import { Select } from "../UserScreen/screens/RelatedNewsMain/styles";
import api from "../../../../services/api";
import { useAuth } from "../../../../hooks/auth";
import CollapseControlPanel from "../../components/CollapseControlPanel";
import { toast } from "react-toastify";
import { LoadingContainer } from "../FeedbackEditScreen/styles";
import { LoadingSpinner } from "../../../../shared/components/LoadingSpinner";
import DotComponent from "../../components/DotComponent";
import iconArrow from "../../assets/option-arrow.svg";
//icons ----------------------------------------------------- //

//set up interfaces
interface ImagesPropsApi {
  category: string;
  created_at: string;
  id: string;
  image: string;
  image_url: string;
  newspaper: string;
  updated_at: string;
}

interface ParamsImagesProps {
  category: string;
  newspaper: string;
  offset: number | null;
}

interface ResultImagesProps {
  id: string;
  category: string;
  newspaper: string;
  image: string;
  created_at: string;
  updated_at: string;
  image_url: string;
}

interface ImageListType {
  dataURL?: string;
  file?: File;
  [key: string]: any;
}

//buttons for sidebar menu navigation import only
const buttonsVector = [
  { name: "Feedback", icon: feedbackIcon, type: "Feedback" },
  { name: "Subscription", icon: subsIcon, type: "Subs" },
  { name: "Subscribers", icon: usersIcon, type: "Users" },
];

const UploadImagesScreen: React.FC = () => {
  const navigator = useNavigate();
  const { signOut } = useAuth();

  //set up all states
  const [indexMenu, setIndexMenu] = useState(0);
  const [screen, setScreen] = useState<
    "Dashboard" | "Panel" | "Feedback" | "Subs" | "Users"
  >("Dashboard");

  const [active, setActive] = useState({
    index: 0,
    visible: false,
  });
  const [images, setImages] = React.useState<any[]>([]);
  const [paramsImages, setParamsImages] = useState<ParamsImagesProps>({
    category: "",
    newspaper: "",
    offset: 0,
  });
  const [isShowButtonAddPhoto, setIsShowButtonAddPhoto] = useState(false);
  const [toggleGenerateNewList, setToggleGenerateNewList] = useState(false);
  const [resultImages, setResultImages] = useState<ResultImagesProps[]>([]);
  const [loadImage, setLoadImage] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [fileImg, setFileImg] = useState<any>();
  const [newPageImages, setNewPageImages] = useState([]);
  const [hasNewPage, setHasNewPage] = useState([]);
  const navigate = useNavigate();

  //then force it which screen user will be
  //controlling this part with routes path
  //just keep the state
  useEffect(() => {
    setScreen("Subs");
  }, []);
  //which type user be
  const handleType = useCallback(
    (value: any, index: number) => {
      switch (value) {
        case "Dashboard":
          setScreen("Dashboard");
          break;
        case "Panel":
          setScreen("Panel");
          break;
        case "Feedback":
          setScreen("Feedback");
          break;
        case "Subs":
          setScreen("Subs");
          break;
        case "Users":
          setScreen("Users");
          break;

        default:
          break;
      }

      setIndexMenu(index);
      active.visible = true;
    },
    [screen]
  );

  // just limit for upload but not necessary all
  //because send just one for time
  const maxNumber = 69;

  // this one captures through the
  // select event which newspaper was chosen
  // to send random image and load exists images
  const handleChangeNewspaper = useCallback(
    (event) => {
      setParamsImages({ ...paramsImages, newspaper: event.target.value });
    },
    [paramsImages]
  );

  // this one captures through the
  // select event which category/topic was chosen
  // to send random image and load exists images
  const handleChangeTopic = useCallback(
    (event) => {
      setParamsImages({ ...paramsImages, category: event.target.value });
    },
    [paramsImages]
  );

  /*
  when administrator clicks on the delete an image button, then we will
  send the id to the endpoint responsible for deleting the selected photo
  through this id
  */
  const handleRemoveImg = useCallback(
    async (id: string) => {
      try {
        const responseRemoveImageApi = await api.delete(`upload-images/${id}`);
        setCurrentPage(0);
        setToggleGenerateNewList(!toggleGenerateNewList);
        setImages([...images]);

        toast.success("Image deleted with success!");
      } catch (err) {
        console.log(err, "erro!!!!");

        alert("Erro");
      }
    },
    [images, toggleGenerateNewList, currentPage]
  );

  //this function has the objective of sending the data to the api with
  // the filters to then load these images through the selected filter
  // of newspaper and category
  const handleLoadImages = useCallback(async () => {
    try {
      setCurrentPage((prevState) => (prevState = 0));
      const responseData = await api.get("upload-images", {
        params: {
          ...paramsImages,
          newspaper:
            paramsImages.newspaper === "" ? null : paramsImages.newspaper,
          category: paramsImages.category === "" ? null : paramsImages.category,
        },
      });
      setResultImages(responseData.data);
    } catch (err) {
      console.log(err, "error when get upload-images");
    }
  }, [paramsImages]);

  /*
  this useEffect controls whether or not we should show the button to add 
  image, because if it has not chosen the filters we cannot show the button
  then if exists image in result, then show button 
  */
  useEffect(() => {
    if (resultImages.length > 0) return setIsShowButtonAddPhoto(true);
  }, [resultImages]);

  /*
  here this useEffect event will monitor if there is a next page,
  to show the next page button to load more images if there is
  */
  useEffect(() => {
    const autoQueryApi = async () => {
      try {
        const response = await api.get("upload-images", {
          params: { ...paramsImages, offset: currentPage + 1 },
        });
        setHasNewPage(response.data);
      } catch (err) {
        console.log(err, "ERROR when fetch if has new page");
      }
    };

    autoQueryApi();
  }, [currentPage, images, currentPage, toggleGenerateNewList, paramsImages]);

  /*
  so now we can fetch images from the api, respecting the filters and pagination
  offset to add them to our main state of images, and if we update the pagination
  or images it will render the result
  */
  useEffect(() => {
    const autoLoad = async () => {
      try {
        const responseData = await api.get("upload-images", {
          params: { ...paramsImages, offset: currentPage },
        });
        if (currentPage === 0) {
          setResultImages(responseData.data);
        } else {
          setResultImages((prevState) => {
            let cloneState = [...prevState];
            let newArray = [...responseData.data];
            cloneState = [...cloneState, ...newArray];
            return cloneState;
          });
        }
      } catch (err) {
        console.log(err, "ERROR when fetch images");
      }
    };

    autoLoad();
  }, [images, currentPage]);

  /*Warning: the backend has a size limit, so we need to
  compress the image to ensure that up to a certain size,
  I can reduce it enough for the api to accept the file */

  //set up some states necessary to store compressed data
  const compressedFileRef = useRef<any>(null);
  const [compressed, setCompressed] = useState<any>();
  const [imageUrl, setImageUrl] = useState<any>();

  //onchange for upload images:
  //a callback that detects and processes
  // the image that the user wants to change from the article
  const onChange = async (
    imageList: ImageListType[],
    addUpdateIndex: number[] | undefined
  ) => {
    //add loading..
    setLoadImage(true);
    //reset current page
    setCurrentPage(0);
    // data for submit
    let myListOfImages: ImagesPropsApi[] = [];
    let fileToCompress = imageList[0].file;
    let myDataUrl = imageList[0].dataURL;

    setImageUrl(imageList[0].dataURL);
    //preparing to try to reduce and maintain the quality
    // for the backend not to reject by size, because the
    // backend limit is really very low
    new Compressor(fileToCompress, {
      quality: 0.1, // 0.6 can also be used, but its not recommended to go below.
      success: (compressedResult) => {
        // compressedResult has the compressed file.
        // Use the compressed file to upload the images to your server.
        compressedFileRef.current = compressedResult;
        setCompressed((state) => {
          let cloneState = state;
          cloneState = compressedResult;
          return cloneState;
        });
      },
    });
  };

  /*
  now with the image loaded in our state and file ready and compressed,
  we need to fetch the information of the object that contains the id that
  is returned by sending the filters, so we load the result and the new image
  in the main state and send this new file to the api and redisplays a new image
  */
  useEffect(() => {
    const autoLoad = async () => {
      const fetchNewImage = await api.post("upload-images", {
        newspaper: paramsImages.newspaper,
        category: paramsImages.category,
      });

      let myListOfImages: ImageListType[];

      try {
        const {
          id,
          created_at,
          image_url,
          image,
          updated_at,
          category,
          newspaper,
        } = fetchNewImage.data as ImagesPropsApi;

        const myFile = new File([compressed], "image.jpeg", {
          type: compressed?.type,
        });

        let formData = new FormData();
        formData.append("image", myFile);

        const fetchUploadPicture = await api.patch(
          `upload-images/${id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        myListOfImages = [
          {
            created_at: created_at,
            id: id,
            image_url: imageUrl,
            image: image,
            updated_at: updated_at,
            category: category,
            newspaper: newspaper,
          },
        ];

        setImages((prevState) => {
          return [...prevState, ...myListOfImages];
        });

        setLoadImage(false);
        toast.success("Image added with success!");
      } catch (error) {
        console.log("error on uploading images", error.message);
        setLoadImage(false);
      }
    };

    autoLoad();
  }, [compressed]);

  /*
  this useEffect manipulate whether or not we should show the button to add 
  image, because if it has not chosen the filters we cannot show the button
  then if exists the fields requireds then show button, case not hidden button 
  */
  useEffect(() => {
    if (paramsImages.category === "" || paramsImages.newspaper === "") {
      setIsShowButtonAddPhoto(false);
    } else {
      setIsShowButtonAddPhoto(true);
    }
  }, [paramsImages]);

  return (
    <>
      <MasterContainer>
        <AsideControls>
          <NavLink to={"/"}>
            <Box>
              <IconSvg
                className="image-testing"
                image={logoBeehive}
                width="208px"
                height="43px"
              />
            </Box>
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              isActive ? "active-link" : "inactive-link"
            }
            to={`/dashboard`}
            end
          >
            <ButtonNavigator
              onClick={() => handleType("Dashboard", 0)}
              name={"Dashboard"}
              icon={dashIcon}
              index={0}
              indexMenu={indexMenu}
              isActive={active.visible}
            />
          </NavLink>

          <CollapseControlPanel />
          <CollapseNews />
          {buttonsVector.map((button, index) => (
            <NavLink
              className={({ isActive }) =>
                isActive ? "active-link" : "inactive-link"
              }
              to={`/${button.type.toLocaleLowerCase()}`}
              end
            >
              <ButtonNavigator
                key={button.name}
                onClick={() => handleType(button.type, index + 1)}
                name={button.name}
                icon={button.icon}
                index={index + 1}
                indexMenu={indexMenu}
                isActive={active.visible}
              />
            </NavLink>
          ))}
          <div
            className="hover-exit-button"
            style={{
              width: "100%",
              height: "60px",
              paddingLeft: "2rem",
            }}
          >
            <ButtonNavigator
              onClick={() => signOut()}
              name={"Exit"}
              icon={iconExit}
              indexMenu={indexMenu}
              isActive={active.visible}
            />
          </div>
        </AsideControls>
        <Header>
          <NavLeft>
            <TextHeader>
              <i>
                <strong>
                  <u
                    style={{ cursor: "pointer" }}
                    onClick={() => navigate("/dashboard")}
                  >
                    Home{" "}
                  </u>
                </strong>
                /{" "}
              </i>

              <i>
                <strong>
                  <u
                    style={{ cursor: "pointer" }}
                    onClick={() => navigate("/news/list")}
                  >
                    Articles /
                  </u>
                </strong>
              </i>
            </TextHeader>
            <TextHeader style={{ marginLeft: "0.6rem" }} color="#666666">
              Edit image catalogue
            </TextHeader>
          </NavLeft>
          <NavRight>
            <DotComponent />
          </NavRight>
        </Header>

        <UploadsMainContainer>
          <MainBox>
            <FormBox>
              <FormLeft>
                <Select
                  value={paramsImages.newspaper}
                  onChange={handleChangeNewspaper}
                >
                  <option hidden value="">
                    Newspaper
                  </option>
                  <option value="aljazeera">Al jazeera</option>
                  <option value="bbc">BBC</option>
                  <option value="independent">Independent</option>
                  <option value="theGuardian">The Guardian</option>
                  <option value="haaretz">Haaretz</option>
                  <option value="sixthtone">Sixthtone</option>
                  <option value="sky">Sky News</option>
                  <option value="theHindu">The Hindu</option>
                  <option value="whashingtonpost">Whashington Post</option>
                  <option value="santiagotimes">Santiago Times</option>
                </Select>

                <img src={iconArrow} className="image-position" />
              </FormLeft>
              <FormRight>
                <Select
                  value={paramsImages.category}
                  onChange={handleChangeTopic}
                >
                  <option value="" hidden>
                    Topic/Category
                    {/* {paramsImages?.category !== ""
                      ? paramsImages?.category
                      : "Topic/Category"} */}
                  </option>

                  <option value="business">Business</option>
                  <option value="opinion">Opinion</option>
                  <option value="sport">Sport</option>
                  <option value="entertainment">Entertainment</option>
                  <option value="wellness">Wellness</option>
                  <option value="politics">Politics</option>
                  <option value="science">Science</option>
                  <option value="tech">Tech</option>
                  <option value="ukAffairs">UK Affairs</option>
                  <option value="globalAffairs">Global Affairs</option>
                </Select>
                <img src={iconArrow} className="image-position" />
              </FormRight>
              <ButtonSubmit>
                <Button className="button-excel" onClick={handleLoadImages}>
                  <img src={checkIcon} alt="" />
                </Button>
              </ButtonSubmit>
            </FormBox>
            <ContainerImages>
              <UploadsArea>
                <ImageUploading
                  multiple={false}
                  value={images}
                  onChange={onChange}
                  maxNumber={maxNumber}
                >
                  {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps,
                  }) => (
                    // write your building UI
                    <div
                      onClick={() => {
                        if (!isShowButtonAddPhoto) {
                          return;
                        } else {
                          onImageUpload();
                        }
                      }}
                      className="upload__image-wrapper"
                      style={isDragging ? { color: "purple" } : undefined}
                      {...dragProps}
                    >
                      {isShowButtonAddPhoto ? (
                        <ButtonAddNewPhoto className="button-excel">
                          {loadImage ? (
                            <LoadingContainer>
                              <LoadingSpinner />
                            </LoadingContainer>
                          ) : (
                            "Add photo"
                          )}
                        </ButtonAddNewPhoto>
                      ) : (
                        <ButtonAddNewPhotoDisable>
                          {loadImage ? (
                            <LoadingContainer>
                              <LoadingSpinner />
                            </LoadingContainer>
                          ) : (
                            "Add photo"
                          )}
                        </ButtonAddNewPhotoDisable>
                      )}
                    </div>
                  )}
                </ImageUploading>

                {resultImages.length > 0 ? (
                  <Grid>
                    {resultImages.map((image, index) => (
                      <GridItem src={image.image_url}>
                        <ButtonRemoveImg
                          onClick={() => handleRemoveImg(image.id)}
                        >
                          <img className="image-close" src={removeImgIcon} />
                        </ButtonRemoveImg>
                      </GridItem>
                    ))}
                  </Grid>
                ) : (
                  <FlexEmpty>
                    <Text
                      style={{
                        margin: "0 auto",
                        textAlign: "center",
                        alignItems: "center",
                        color: "#9D9D9D",
                        fontSize: "1.5rem",
                      }}
                    >
                      Nothing to show.
                    </Text>
                  </FlexEmpty>
                )}

                {hasNewPage.length > 0 &&
                  paramsImages.category !== "" &&
                  paramsImages.newspaper !== "" &&
                  resultImages.length > 0 && (
                    <ButtonSeeMore
                      className="button-excel"
                      onClick={() => {
                        setCurrentPage((prevState) => prevState + 1);
                      }}
                    >
                      See more
                    </ButtonSeeMore>
                  )}
              </UploadsArea>
            </ContainerImages>
          </MainBox>
          <FlexBox>
            {<>{/* <ButtonSaveUpload>Save</ButtonSaveUpload> */}</>}
          </FlexBox>
        </UploadsMainContainer>
      </MasterContainer>
    </>
  );
};

export default UploadImagesScreen;

import styled from "styled-components";

export const DotAvatar = styled.span`
  height: 50px;
  width: 50px;
  background: ${({ theme }) => theme.colors.honey};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4rem;

  span {
    font-family: "Open Sans", sans-serif;
    font-size: 1.6rem;
    color: white;
    font-weight: normal;
    margin-top: 0.2rem;
  }

  @media (max-width: 1366px) {
    height: 38px;
    width: 38px;
    margin-right: 2rem;
    span {
      font-size: 1.3rem;
      margin-top: 0rem;
    }
  }
`;

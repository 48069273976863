import styled from "styled-components";

export const FlexContainerFull = styled.div`
  display: flex;
  width: 100%;
  padding: 2rem;
  background: #ebedef;
  overflow-y: auto;
`;

export const WhiteBox = styled.div`
  width: 100%;
  height: 100%;
  min-height: 800px;
  background: white;
  padding: 1rem 2rem;
  border-radius: 5px;
  border: 1px solid #dadada;
  padding-bottom: 2rem;

  @media (max-width: 1366px) {
    padding-bottom: 3rem;
  }

  @media (max-width: 1366px) {
    min-height: 730px;
  }
`;

export const FlexHeader = styled.div`
  height: 67px;
  width: 100%;
  display: flex;
  margin-bottom: 2rem;
`;

export const WriteWords = styled.div`
  height: 100%;
  padding: 0 0 0 0;
  width: 94%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Button = styled.button`
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.honey};
  border-radius: 5px;
  margin-left: 1.1rem;
  transition: all 120ms linear;

  @media (max-width: 1366px) {
    width: 40px;
    height: 40px;
  }
`;

export const ButtonSendWords = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
`;

export const FlexBody = styled.div`
  width: 100%;
  height: 80%;
  background: #f9fafa;
  padding: 0.4rem;
`;

export const StackWords = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 1.4rem;
`;

export const TitleWords = styled.div`
  width: 100%;
  height: 50px;
  border-bottom: 1px solid #dadada;
  display: flex;
  align-items: center;
  justify-content: space-between;

  :last-child {
    border-bottom: none;
  }
`;

export const Heading = styled.span`
  font-size: 1.6rem;
  font-weight: bold;
  margin-left: 1rem;

  @media (max-width: 1600px) {
    font-size: 1.5rem;
  }
`;

export const Text = styled.p`
  font-size: 1.5rem;
  font-weight: normal;
  margin-left: 1rem;

  @media (max-width: 1600px) {
    font-size: 1.4rem;
  }
`;

export const ButtonSpam = styled.button`
  background: transparent;
`;

export const FlexPagination = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  margin: 1rem 0;
  @media (max-width: 1366px) {
    margin-top: 1rem !important;
    margin: 0rem 0;
  }
`;

import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../hooks/auth";
import LoadingScreen from "../../../../shared/components/LoadingScreen";

// import { Container } from './styles';

const LoadingHome: React.FC = () => {
  const { isAuthenticated, authorized, setAuthorized } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (authorized) {
      navigate("/dashboard");
    } else {
      navigate("/");
    }
  }, [authorized]);

  return (
    <>
      <LoadingScreen />
    </>
  );
};

export default LoadingHome;

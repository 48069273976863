import React, { ReactNode, useCallback, useRef, useState } from "react";
import {
  ButtonCollapse,
  CollapseContainer,
  CollapseContent,
  HorizontalStack,
  Text,
} from "./styles";

import iconArrow from "../../../../assets/icon-feedback-arrowup.svg";

// import { Container } from './styles';

interface CollapseBoxProps {
  answer?: string;
  children: ReactNode;
}

const CollapseBox: React.FC<CollapseBoxProps> = ({ answer, children }) => {
  const [isOpenCollapse, setIsOpenCollapse] = useState(false);
  const parentRef = useRef();
  const handleClickCollapseButton = useCallback(() => {
    setIsOpenCollapse(!isOpenCollapse);
  }, [isOpenCollapse]);

  return (
    <CollapseContainer>
      <ButtonCollapse onClick={handleClickCollapseButton}>
        <HorizontalStack isOpen={isOpenCollapse}>
          <Text> {answer} </Text>
          <img className={"arrow-rotate"} src={iconArrow} />
        </HorizontalStack>
      </ButtonCollapse>
      <CollapseContent isOpen={isOpenCollapse}> {children} </CollapseContent>
    </CollapseContainer>
  );
};

export default CollapseBox;

import * as FileSaver from "file-saver";
import { ReactNode } from "react";
import * as XLSX from "xlsx";

interface DataNumericApiProps {
  one: number;
  two: number;
  three: number;
  four: number;
  five: number;
  six: number;
  seven: number;
}

interface QueryProps {
  initial_date: string | null;
  final_date: string | null;
  topic: string | null;
  initial_political_view?: number | null;
  final_political_view?: number | null;
  initial_age?: number | null;
  final_age?: number | null;
  region?: string | null;
}

interface DataChartsProps {
  all: number | null;
  all_jazeera: number | null;
  bbc: number | null;
  haaretz: number | null;
  independent: number | null;
  sixth_tone: number | null;
  sky_news: number | null;
  the_guardian: number | null;
  the_hindu: number | null;
  whashington_post: number | null;
}

interface ExportToExcelProps {
  children?: ReactNode;
  dataFilters?: any;
  valuesFiltered?: DataChartsProps[] | DataNumericApiProps[] | any;
  nameFile?: string;
}

export const ExportToExcelUnique: React.FC<ExportToExcelProps> = ({
  children,
  dataFilters,
  valuesFiltered,
  nameFile,
}) => {
  const fileType = "xlsx";
  const exportToCSV = () => {
    const filters = XLSX.utils.json_to_sheet(dataFilters);
    // const filteredValues = XLSX.utils.json_to_sheet(valuesFiltered);
    const wb = {
      Sheets: { main: filters },
      SheetNames: ["main"],
    };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, `${nameFile}.xlsx`);
  };

  return (
    <>
      <button onClick={() => exportToCSV()} style={{ cursor: "pointer" }}>
        {children}
      </button>
    </>
  );
};

/*
this screen contains the component that controls the functionality
of editing the payment properties of the plan that users use in the
beehive application, such as changing numbers related to the plan.
*/

import React, { useCallback, useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import IconSvg from "../../../login/components/IconSvg";
import ButtonNavigator from "../../components/ButtonNavigator";
import CollapseNews from "../../components/CollapseNews";
import { Box } from "../../Master/styles";
//logo
import logoBeehive from "../../assets/logo-dash.svg";

//icons ----------------------------------------------------- //
import dashIcon from "../../assets/icon-dash.svg";
import feedbackIcon from "../../assets/icon-dash-feedback.svg";
import subsIcon from "../../assets/icon-dash-subs.svg";
import usersIcon from "../../assets/icon-dash-users.svg";
import iconExit from "../../../dash/assets/icon-exit.svg";
import { useAuth } from "../../../../hooks/auth";
import CollapseControlPanel from "../../components/CollapseControlPanel";
import api from "../../../../services/api";
import SubscribePage from "../NewsScreen/ListNews/screens/SubscribePage";
import DotComponent from "../../components/DotComponent";
//icons ----------------------------------------------------- //

//set up styles
import {
  AsideControls,
  Header,
  MasterContainer,
  NavLeft,
  NavRight,
  TextHeader,
} from "../../Master/styles";

//buttons for show it to user on sidebar
const buttonsVector = [
  { name: "Feedback", icon: feedbackIcon, type: "Feedback" },
  { name: "Subscription", icon: subsIcon, type: "Subs" },
  { name: "Subscribers", icon: usersIcon, type: "Users" },
];

const SubscribeScreen: React.FC = () => {
  const { signOut } = useAuth();
  const navigator = useNavigate();

  //set up states
  const [indexMenu, setIndexMenu] = useState<any>(0);
  const [idPlan, setIdPlan] = useState(0);
  const [screen, setScreen] = useState<
    "Dashboard" | "Panel" | "Feedback" | "Subs" | "Users" | "IrrelevantWords"
  >("Dashboard");
  const [active, setActive] = useState({
    index: 0,
    visible: false,
  });

  const navigate = useNavigate();

  /*
  force state here:
  since the user is navigating through the navigator,
  update my state by forcing it
  */
  useEffect(() => {
    setScreen("Subs");
  }, []);

  //then handle which screen user will be
  //but it was already set anyway
  // via useEffect forcing it
  const handleType = useCallback(
    (value: any, index: number) => {
      switch (value) {
        case "Dashboard":
          setScreen("Dashboard");
          break;
        case "Panel":
          setScreen("Panel");
          break;
        case "Feedback":
          setScreen("Feedback");
          break;
        case "Subs":
          setScreen("Subs");
          break;
        case "Users":
          setScreen("Users");
          break;

        case "IrrelevantWords":
          setScreen("IrrelevantWords");
          break;

        default:
          break;
      }

      setIndexMenu(index);
      active.visible = true;
    },
    [screen]
  );

  /*
  looking for the properties through a request to the backend that talks to
  the bluesnap api and controls the plan's issues, this route will return the
  plan's id to make the modifications.
  */
  useEffect(() => {
    const autoLoadPlan = async () => {
      const responseListPlan = await api.get("plans");
      setIdPlan(Number(responseListPlan.data.plan_id));
    };
    autoLoadPlan();
  }, []);

  return (
    <>
      <MasterContainer>
        <AsideControls>
          <NavLink to={"/"}>
            <Box>
              <IconSvg
                className="image-testing"
                image={logoBeehive}
                width="208px"
                height="43px"
              />
            </Box>
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              isActive ? "active-link" : "inactive-link"
            }
            to={`/dashboard`}
            end
          >
            <ButtonNavigator
              onClick={() => handleType("Dashboard", 0)}
              name={"Dashboard"}
              icon={dashIcon}
              index={0}
              indexMenu={indexMenu}
              isActive={active.visible}
            />
          </NavLink>

          <CollapseControlPanel />
          <CollapseNews />
          {buttonsVector.map((button, index) => (
            <NavLink
              className={({ isActive }) =>
                isActive ? "active-link" : "inactive-link"
              }
              to={`/${button.type.toLocaleLowerCase()}`}
              end
            >
              <ButtonNavigator
                key={button.name}
                onClick={() => handleType(button.type, index + 1)}
                name={button.name}
                icon={button.icon}
                index={index + 1}
                indexMenu={indexMenu}
                isActive={active.visible}
              />
            </NavLink>
          ))}
          <div
            className="hover-exit-button"
            style={{
              width: "100%",
              height: "60px",
              paddingLeft: "2rem",
            }}
          >
            <ButtonNavigator
              onClick={() => signOut()}
              name={"Exit"}
              icon={iconExit}
              indexMenu={indexMenu}
              isActive={active.visible}
            />
          </div>
        </AsideControls>
        <Header>
          <NavLeft>
            <TextHeader
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/dashboard")}
            >
              <u>
                <b>
                  <i> Home </i>
                </b>
              </u>
              /
            </TextHeader>
            <TextHeader style={{ marginLeft: "0.6rem" }} color="#666666">
              {screen === "Panel"
                ? `Painel de Controle`
                : screen === "Subs"
                ? `Subscription`
                : screen === "Users"
                ? `Usuários`
                : screen}
            </TextHeader>
          </NavLeft>
          <NavRight>
            <DotComponent />
          </NavRight>
        </Header>
        {/* here is the main component */}
        <SubscribePage idPlan={idPlan} />
      </MasterContainer>
    </>
  );
};

export default SubscribeScreen;

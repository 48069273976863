/*
this screen contains the component that controls the functionality
of editing the payment properties of the plan that users use in the
beehive application, such as changing numbers related to the plan.

warning: if the admin put initial charge amount for zero then
the bluesnap api will throw error because cant possible give update
without initial charge amount value
*/
import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import api from "../../../../../../../services/api";
import { LoadingSpinner } from "../../../../../../../shared/components/LoadingSpinner";
import { LoadingContainer } from "../../../../FeedbackEditScreen/styles";
import InputDefault from "../../../../PanelControl/components/InputDefault";

//set up styles
import {
  Background,
  Box,
  Button,
  Container,
  Content,
  Flex,
  HorizontalStack,
  Text,
  VerticalStack,
} from "./styles";

//set up interfaces
interface SubscribePageProps {
  idPlan?: number;
}

interface ObjectPlanProps {
  planId: number;
  name: string;
  currency: string;
  recurringChargeAmount: number;
  status: string;
  chargeFrequency: string;
  gracePeriodDays: string;
  initialChargeAmount: number;
  chargeOnPlanSwitch: string;
  trialPeriodDays?: number;
}

const SubscribePage: React.FC<SubscribePageProps> = ({ idPlan }) => {
  //set up states
  const [objectPlan, setObjectPlan] = useState<ObjectPlanProps>({
    planId: idPlan,
    name: "",
    recurringChargeAmount: 0,
    currency: "",
    chargeFrequency: "",
    initialChargeAmount: 0,
    chargeOnPlanSwitch: "",
    gracePeriodDays: "",
    status: "",
    trialPeriodDays: 0,
  });

  const [loading, setLoading] = useState(false);
  const [objectEditPlan, setObjectEditPlan] = useState({
    name: "",
    trial_period_days: 0,
    grace_period_days: 0,
    initial_charge_amount: 0,
  });

  //fetching current plan information via id to endpoint
  useEffect(() => {
    const autoLoadBlueSnapPlan = async () => {
      try {
        const responsePlan = await api.get(`bluesnap/plan-specific`);

        setObjectEditPlan((state) => {
          return {
            ...state,
            name: responsePlan.data.name,
            trial_period_days: responsePlan.data.trialPeriodDays,
            grace_period_days: responsePlan.data.gracePeriodDays,
            initial_charge_amount: responsePlan.data.initialChargeAmount,
          };
        });
      } catch (err) {
        console.log(
          err.response.data.message,
          "error when bluesnap api get plan specific"
        );
      }
    };

    autoLoadBlueSnapPlan();
  }, []);

  //this function changes the data requested by the administrator
  const handleSubmitSubscription = useCallback(async () => {
    setLoading(true);
    if (
      objectEditPlan.name !== "" &&
      objectEditPlan.initial_charge_amount !== 0
    ) {
      if (
        objectEditPlan.trial_period_days !== 0 ||
        objectEditPlan.grace_period_days < 0
      )
        // toast.error("The number should be different of 0");
        setLoading(false);
      try {
        setLoading(true);
        const responsePutSubscription = await api.put(
          "bluesnap/plan-specific",
          objectEditPlan
        );
        toast.success("Subscription changed with success!");
        setLoading(false);
      } catch (err) {
        setLoading(false);
        toast.error("All fields are required");
      }
    } else {
      toast.error("All fields are required");
      setLoading(false);
    }
  }, [objectEditPlan]);

  return (
    <Flex>
      <Container>
        <Box>
          <Text>Change Subscription</Text>
        </Box>
        <Content>
          <Background>
            <VerticalStack>
              <span>Name</span>
              <InputDefault
                style={{ marginBottom: "1rem" }}
                placeholder={"Title"}
                type="text"
                value={objectEditPlan.name}
                className="margin-top-input"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setObjectEditPlan({ ...objectEditPlan, name: e.target.value })
                }
              />
            </VerticalStack>
            <HorizontalStack>
              <VerticalStack PaddingTop="0">
                <span>Trial period days</span>
                <InputDefault
                  style={{ marginBottom: "1rem", marginRight: "0.5rem" }}
                  type="text"
                  placeholder={"Trial Period Days"}
                  value={objectEditPlan.trial_period_days}
                  className="margin-top-input"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setObjectEditPlan({
                      ...objectEditPlan,
                      trial_period_days: Number(e.target.value),
                    })
                  }
                />
              </VerticalStack>

              <VerticalStack
                PaddingTop="0"
                style={{ marginLeft: "2.5rem", paddingRight: "0.3rem" }}
              >
                <span style={{ transform: "translateX(5px)" }}>
                  Grace period days
                </span>
                <InputDefault
                  style={{ marginBottom: "1rem", marginLeft: "0.5rem" }}
                  placeholder=" "
                  value={objectEditPlan.grace_period_days}
                  type="number"
                  className="margin-top-input"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setObjectEditPlan({
                      ...objectEditPlan,
                      grace_period_days: Number(e.target.value),
                    })
                  }
                />
              </VerticalStack>
            </HorizontalStack>
            <HorizontalStack>
              <VerticalStack PaddingTop="0">
                <span>Initial charge amount</span>
                <InputDefault
                  style={{
                    marginBottom: "1rem",
                    marginRight: "0.5rem",
                    maxWidth: "49.7%",
                  }}
                  placeholder="Initial charge amount"
                  type="number"
                  value={objectEditPlan.initial_charge_amount}
                  className="margin-top-input"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setObjectEditPlan({
                      ...objectEditPlan,
                      initial_charge_amount: Number(e.target.value),
                    })
                  }
                />
              </VerticalStack>
            </HorizontalStack>
          </Background>
        </Content>
      </Container>
      <Button
        onClick={() => handleSubmitSubscription()}
        className="button-excel"
      >
        {loading ? (
          <LoadingContainer>
            <LoadingSpinner />
          </LoadingContainer>
        ) : (
          `Save`
        )}
      </Button>
    </Flex>
  );
};

export default SubscribePage;

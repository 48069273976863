/*
this screen contains the functionality to display the feedback listing with
pagination, in addition to filtering the data, it is also possible to navigate
to the specific feedback and view the values that the user chose when voting
within the application so that the administrator can receive feedback from
customers and Beehive app users

the admin will also be able to navigate to the screen to change the feedback
questions that are presented to users in the app.
*/

import { css } from "@emotion/react";
import { format, utcToZonedTime } from "date-fns-tz";
import ButtonNavigator from "../../components/ButtonNavigator";
import CollapseNews from "../../components/CollapseNews";
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { NavLink, useNavigate } from "react-router-dom";
import InputDefault from "../PanelControl/components/InputDefault";
import MoonLoader from "react-spinners/MoonLoader";
import { useAuth } from "../../../../hooks/auth";
import api from "../../../../services/api";
import iconExit from "../../../dash/assets/icon-exit.svg";
import logoBeehive from "../../assets/logo-dash.svg";
import Pagination from "../../components/Pagination";
import { FlexPagination } from "../NewsScreen/components/NewsContainer/styles";
import CollapseControlPanel from "../../components/CollapseControlPanel";
import DateComponent from "./components/DateComponent";
import DateToComponent from "./components/DateToComponent";
import DotComponent from "../../components/DotComponent";

//icons imports
import IconSvg from "../../../login/components/IconSvg";
import checkIcon from "../../assets/icon-check.svg";
import feedbackIcon from "../../assets/icon-dash-feedback.svg";
import subsIcon from "../../assets/icon-dash-subs.svg";
import usersIcon from "../../assets/icon-dash-users.svg";
import dashIcon from "../../assets/icon-dash.svg";
import iconView from "../../assets/icon-view.svg";

//set up styles shared
import {
  AsideControls,
  Box,
  Header,
  MasterContainer,
  NavLeft,
  NavRight,
  TextHeader,
} from "../../Master/styles";

//set up styles for feedback
import {
  Button,
  ButtonBox,
  ButtonCheck,
  ButtonViewFeedback,
  ButtonWrapper,
  Container,
  FormBox,
  HeaderContent,
  InputsWrapper,
  Main,
  PaginationContainer,
  Select,
  StyledTable,
  TableItem,
  TableRow,
  THead,
} from "./styles";

//interfaces
interface FeedbackDataPropsObject {
  initial_date: null | string | number;
  final_date: null | string | number;
  initial_political_position: null | string | number;
  final_political_position: null | string | number;
  initial_born_at?: string | number | null;
  final_born_at?: string | number | null;
  country?: string | number | null;
  state?: string | null;
  offset?: number | null | undefined;
}

interface ResponseFeedbacksData {
  born_at: string;
  created_at: string;
  email: string;
  feedback_id: string;
  first_name: string;
  last_name: string;
  political_position: string;
  region: string;
  updated_at: string;
}
//buttons for sidebar imports
const buttonsVector = [
  { name: "Feedback", icon: feedbackIcon, type: "Feedback" },
  { name: "Subscription", icon: subsIcon, type: "Subs" },
  { name: "Subscribers", icon: usersIcon, type: "Users" },
];

const FeedbackScreen: React.FC = () => {
  //just some styles for loading component
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

  const navigator = useNavigate();
  const [indexMenu, setIndexMenu] = useState<any>(0);
  const [screen, setScreen] = useState<
    "Dashboard" | "Panel" | "Feedback" | "Subs" | "Users" | "IrrelevantWords"
  >("Dashboard");
  const [isLoading, setIsLoading] = useState(true);
  const [color, setColor] = useState("#FFC600");
  const [active, setActive] = useState({
    index: 0,
    visible: false,
  });

  const navigate = useNavigate();

  /*
  force state here:
  since the user is navigating through the navigator,
  update my state by forcing it
  */
  useEffect(() => {
    setScreen("Feedback");
  }, []);

  //then handle which screen user will be
  //but it was already set anyway
  // via useEffect forcing it
  const handleType = useCallback(
    (value: any, index: number) => {
      switch (value) {
        case "Dashboard":
          setScreen("Dashboard");
          break;
        case "Panel":
          setScreen("Panel");
          break;
        case "Feedback":
          setScreen("Feedback");
          break;
        case "Subs":
          setScreen("Subs");
          break;
        case "Users":
          setScreen("Users");
          break;
        case "IrrelevantWords":
          setScreen("IrrelevantWords");
          break;

        default:
          break;
      }

      setIndexMenu(index);
      active.visible = true;
    },
    [screen]
  );

  //this function only navigates the user
  const handleClickButtonView = useCallback((url: string) => {
    navigator(url);
  }, []);

  const { signOut } = useAuth();

  //set up states
  const [feedbackData, setFeedbackData] = useState<FeedbackDataPropsObject>({
    initial_date: null,
    final_date: null,
    initial_political_position: null,
    final_political_position: null,
    offset: 0,
    initial_born_at: null,
    final_born_at: null,
    country: null,
    state: null,
  });

  const [paramsFeedback, setParamsFeedback] = useState<FeedbackDataPropsObject>(
    {
      initial_date: null,
      final_date: null,
      initial_political_position: null,
      final_political_position: null,
      offset: 0,
      initial_born_at: null,
      final_born_at: null,
      country: null,
      state: null,
    }
  );

  const [dataFeedbacks, setDataFeedbacks] = useState<ResponseFeedbacksData[]>(
    []
  );
  const [totalPages, setTotalPages] = useState(0);
  const [regionOption, setRegionOption] = useState("");
  const [areaOption, setAreaOption] = useState("");
  const [selected, setSelected] = React.useState<Date>();
  const [selectedDate, setSelectedDate] = React.useState<Date>();
  const dateFromRef = useRef<any>(null);
  const dateToRef = useRef<any>(null);
  const [rangeDateFrom, setRangeDateFrom] = useState("");
  const [rangeDateTo, setRangeDateTo] = useState("");
  const [initialPoliticalPos, setInitialPoliticalPos] = useState("");
  const [toggleDropdown, setToggleDropdown] = useState(false);
  const [finalPoliticalPos, setFinalPoliticalPos] = useState("");
  const [activeDateBox, setActiveDateBox] = useState<
    "false" | "dateFrom" | "dateTo"
  >("false");

  const [activeDate, setActiveDate] = useState(false);
  const [displayDropdown, setDisplayDropdown] = useState(false);

  //here just close the UI date component
  //for initial filter
  const closeDropdownDateTo = () => {
    setActiveDate(false);
  };

  //handle toggle for receive date value
  const toggleDateTo = useCallback(
    (e) => {
      e.preventDefault();
      setActiveDate((prevState) => !prevState);
    },
    [activeDate]
  );

  /*
  this is the function that manages when the user
  clicks on input which refers to the date component,
  just to open the date component
  */
  const handleToggle = (event) => {
    event.preventDefault();
    setToggleDropdown((prevState) => !prevState);
  };

  /*
  this is the function that manages when the user
  clicks outside the date component, just to close the date
  */
  const closeToggle = () => {
    setToggleDropdown(false);
  };

  //only update state with region which user have chosen
  const handleChangeRegion = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      setRegionOption(event.target.value);
    },
    [regionOption]
  );

  //only update state with area which user have chosen
  const changeAreaOption = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      setAreaOption(event.target.value);
    },
    [areaOption]
  );

  //in this function we send the filters chosen by the administrator to the main
  //state so that as soon as we change, call the api to return the filtered data
  // and then display them
  const handleChangeParamsFeedback = useCallback(
    (event) => {
      event.preventDefault();
      setFeedbackData((state) => {
        return {
          ...state,
          initial_born_at: paramsFeedback.initial_born_at
            ? paramsFeedback.initial_born_at
            : null,
          final_born_at: paramsFeedback.final_born_at
            ? paramsFeedback.final_born_at
            : null,
          country: regionOption ? regionOption : null,
          state: areaOption ? areaOption : null,
          initial_date: rangeDateFrom ? rangeDateFrom : null,
          final_date: rangeDateTo ? rangeDateTo : null,
          initial_political_position: initialPoliticalPos
            ? initialPoliticalPos
            : null,
          final_political_position: finalPoliticalPos
            ? finalPoliticalPos
            : null,
          offset: 0,
        };
      });
    },
    [
      paramsFeedback,
      feedbackData,
      areaOption,
      regionOption,
      rangeDateFrom,
      rangeDateTo,
      initialPoliticalPos,
      finalPoliticalPos,
    ]
  );

  // calling the api and updating the feedback listing states
  // and also pages for pagination
  useEffect(() => {
    const autoLoad = async () => {
      try {
        const responseFeedbacks = await api.get("feedback", {
          params: feedbackData,
        });
        setDataFeedbacks(responseFeedbacks.data.feedback);
        setTotalPages(responseFeedbacks.data.pagination);
        setIsLoading(false);
      } catch (err) {
        console.log(err, "error when get feedbacks values!");
        // setIsLoading(true);
      }
    };

    autoLoad();
  }, [feedbackData, setDataFeedbacks]);

  /* 
  just formatting the values with this useEffect to be right in the
  format that sends to api
  */
  useEffect(() => {
    if (selected) {
      setRangeDateFrom(format(selected, "yyyy/MM/dd").replaceAll("/", "-"));
    }

    if (selectedDate) {
      setRangeDateTo(format(selectedDate, "yyyy/MM/dd").replaceAll("/", "-"));
    }
  }, [selectedDate, selected]);

  /*
    leaving the dashboard times in the same utc
    as the server and formatting the data to display in the
  */
  const formatDate = (date: string) => {
    let myObject = date;
    const nyTimeZone = "UTC";
    const myDate = utcToZonedTime(myObject, nyTimeZone);
    const niceDate = format(myDate, "yyyy/MM/dd - HH:mm", {
      timeZone: "UTC",
    });
    return `${niceDate}`;
  };

  return (
    <>
      <MasterContainer>
        <AsideControls>
          <NavLink to={"/"}>
            <Box>
              <IconSvg
                className="image-testing"
                image={logoBeehive}
                width="208px"
                height="43px"
              />
            </Box>
          </NavLink>

          <NavLink
            className={({ isActive }) =>
              isActive ? "active-link" : "inactive-link"
            }
            to={`/dashboard`}
            end
          >
            <ButtonNavigator
              onClick={() => handleType("Dashboard", 0)}
              name={"Dashboard"}
              icon={dashIcon}
              index={0}
              indexMenu={indexMenu}
              isActive={active.visible}
            />
          </NavLink>

          <CollapseControlPanel />
          <CollapseNews />
          {buttonsVector.map((button, index) => (
            <NavLink
              className={({ isActive }) =>
                isActive ? "active-link" : "inactive-link"
              }
              to={`/${button.type.toLocaleLowerCase()}`}
              end
            >
              <ButtonNavigator
                key={button.name}
                onClick={() => handleType(button.type, index + 1)}
                name={button.name}
                icon={button.icon}
                index={index + 1}
                indexMenu={indexMenu}
                isActive={active.visible}
              />
            </NavLink>
          ))}
          <div
            className="hover-exit-button"
            style={{
              width: "100%",
              height: "60px",
              paddingLeft: "2rem",
            }}
          >
            <ButtonNavigator
              onClick={() => signOut()}
              name={"Exit"}
              icon={iconExit}
              indexMenu={indexMenu}
              isActive={active.visible}
            />
          </div>
        </AsideControls>
        <Header>
          <NavLeft>
            <TextHeader>
              <strong>
                <i>
                  <u
                    style={{ cursor: "pointer" }}
                    onClick={() => navigate("/dashboard")}
                  >
                    Home{" "}
                  </u>
                </i>
              </strong>
              /
            </TextHeader>
            <TextHeader style={{ marginLeft: "0.6rem" }} color="#666666">
              {screen === "Panel"
                ? `Painel de Controle`
                : screen === "Subs"
                ? `Assinatura`
                : screen === "Users"
                ? `Usuários`
                : screen}
            </TextHeader>
          </NavLeft>
          <NavRight>
            <DotComponent />
          </NavRight>
        </Header>

        <Container>
          <ButtonBox>
            <Button
              className="button-excel"
              onClick={() => navigate("/feedback/edit")}
            >
              Change questions
            </Button>
          </ButtonBox>
          {!isLoading ? (
            <Main>
              <HeaderContent>
                <InputsWrapper>
                  <InputDefault
                    style={{ margin: "0 0.5rem" }}
                    type="text"
                    placeholder="Age from"
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setParamsFeedback({
                        ...paramsFeedback,
                        initial_born_at: e.target.value,
                      });
                    }}
                  />
                  <InputDefault
                    style={{ margin: "0 3rem 0 0.5rem" }}
                    type="text"
                    placeholder="Age to"
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setParamsFeedback({
                        ...paramsFeedback,
                        final_born_at: e.target.value,
                      });
                    }}
                  />

                  <Select value={regionOption} onChange={handleChangeRegion}>
                    <option value={""} hidden>
                      {"Region"}
                    </option>
                    <option value="England - Greater London">
                      England - Greater London
                    </option>
                    <option value="East of England">East of England</option>
                    <option value="East Midlands">East Midlands</option>
                    <option value="North East">North East</option>
                    <option value="North West">North West</option>
                    <option value="South East">South East</option>
                    <option value="South West">South West</option>
                    <option value="West Midlands">West Midlands</option>
                    <option value="Yorkshire & The Humber">
                      Yorkshire The Humber
                    </option>
                    <option value="Scotland">Scotland</option>
                    <option value="Wales">Wales</option>
                    <option value="North Ireland">North Ireland</option>
                    <option value="Other">Other</option>
                  </Select>

                  <Select
                    value={areaOption}
                    onChange={changeAreaOption}
                    style={{ marginRight: "3rem" }}
                  >
                    <option value={""} hidden>
                      {"Area"}
                    </option>
                    {regionOption === "" ? (
                      // <option value="">Select region</option>
                      <></>
                    ) : regionOption === "England - Greater London" ? (
                      <>
                        <option value="East Central (EC1, EC2, E1-E20)">
                          East Central (EC1, EC2, E1-E20)
                        </option>
                        <option value="East Other">East Other</option>
                        <option value="North Central (N1-N22)">
                          North Central (N1-N22)
                        </option>
                        <option value="North Other">North Other</option>
                        <option value="North West Central (NW1-NW11)">
                          North West Central (NW1-NW11)
                        </option>
                        <option value="North West Other">
                          North West Other
                        </option>
                        <option value="South East Central (SE1-SE28)">
                          South East Central (SE1-SE28)
                        </option>
                        <option value="South East Other">
                          South East Other
                        </option>
                        <option value="South West Central (SW1-SW20)">
                          South West Central (SW1-SW20)
                        </option>
                        <option value="South West Other">
                          South West Other
                        </option>
                        <option value="West Central (WC1, WC2, W1-W14)">
                          West Central (WC1, WC2, W1-W14)
                        </option>
                        <option value="West Other">West Other</option>
                      </>
                    ) : regionOption === "East of England" ? (
                      <>
                        <option value="Cambridge area">Cambridge area</option>
                        <option value="Other">Other</option>
                      </>
                    ) : regionOption === "East Midlands" ? (
                      <>
                        <option value="Leicester area">Leicester area</option>
                        <option value="Nottingham area">Nottingham area</option>
                        <option value="Other">Other</option>
                      </>
                    ) : regionOption === "North East" ? (
                      <>
                        <option value="Newcastle / Tyneside area">
                          Newcastle / Tyneside area
                        </option>
                        <option value="Other">Other</option>
                      </>
                    ) : regionOption === "North West" ? (
                      <>
                        <option value="Liverpool area">Liverpool area</option>
                        <option value="Manchester area">Manchester area</option>
                        <option value="Other">Other</option>
                      </>
                    ) : regionOption === "South East" ? (
                      <>
                        <option value="Brighton area">Brighton area</option>
                        <option value="Oxford area">Oxford area</option>
                        <option value="Portsmouth / Southampton area">
                          Portsmouth / Southampton area
                        </option>
                        <option value="Milton Keynes area">
                          Milton Keynes area
                        </option>
                        <option value="Other">Other</option>
                      </>
                    ) : regionOption === "South West" ? (
                      <>
                        <option value="Bournemouth /Poole area">
                          Bournemouth /Poole area
                        </option>
                        <option value="Bristol area">Bristol area</option>
                        <option value="Other">Other</option>
                      </>
                    ) : regionOption === "West Midlands" ? (
                      <>
                        <option value="Birmingham area">Birmingham area</option>
                        <option value="Coventry area">Coventry area</option>
                        <option value="Warwick area">Warwick area</option>
                        <option value="Other">Other</option>
                      </>
                    ) : regionOption === "Yorkshire & The Humber" ? (
                      <>
                        <option value="Leeds area">Leeds area</option>
                        <option value="Sheffield area">Sheffield area</option>
                        <option value="Bradford area">Bradford area</option>
                        <option value="Other">Other</option>
                      </>
                    ) : regionOption === "Scotland" ? (
                      <>
                        <option value="Glasgow area">Glasgow area</option>
                        <option value="Edinburgh area">Edinburgh area</option>
                        <option value="Other">Other</option>
                      </>
                    ) : regionOption === "Wales" ? (
                      <>
                        <option value="Cardiff area">Cardiff area</option>
                        <option value="Other">Other</option>
                      </>
                    ) : regionOption === "North Ireland" ? (
                      <>
                        <option value="Cardiff area">Cardiff area</option>
                        <option value="Other">Other</option>
                      </>
                    ) : (
                      regionOption === "Other" && (
                        <>
                          <option value="Other">Other</option>
                        </>
                      )
                    )}
                  </Select>

                  <div className="wrapper-date-from">
                    <InputDefault
                      style={{ margin: "0 0.5rem", marginRight: "0.5rem" }}
                      type="text"
                      onClick={handleToggle}
                      onFocus={(e) => {
                        setTimeout(() => {
                          setToggleDropdown(true);
                        }, 100);
                      }}
                      placeholder="Date from"
                      value={rangeDateFrom}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setRangeDateFrom(e.target.value)
                      }
                    />
                    {toggleDropdown && (
                      <DateComponent
                        closeToggle={closeToggle}
                        selected={selected}
                        setSelected={setSelected}
                      />
                    )}
                  </div>

                  <div className="wrapper-date-from">
                    <InputDefault
                      style={{ margin: "0 0.5rem" }}
                      type="text"
                      placeholder="Date to"
                      onClick={toggleDateTo}
                      onFocus={(e) => {
                        setTimeout(() => {
                          setActiveDate(true);
                        }, 100);
                      }}
                      value={rangeDateTo}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setRangeDateTo(e.target.value)
                      }
                    />

                    {activeDate && (
                      <DateToComponent
                        closeToggle={closeDropdownDateTo}
                        selected={selectedDate}
                        setSelected={setSelectedDate}
                      />
                    )}
                  </div>

                  <div
                    style={{
                      marginLeft: "1.5rem",
                      display: "flex",
                      width: "100%",
                      minWidth: "260px",
                    }}
                  >
                    <InputDefault
                      style={{ margin: "0 0.5rem", marginLeft: "1.5rem" }}
                      type="text"
                      placeholder="Political position"
                      value={initialPoliticalPos}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setInitialPoliticalPos(e.target.value)
                      }
                    />
                    <InputDefault
                      style={{ margin: "0 0.5rem", marginRight: "1.5rem" }}
                      type="text"
                      placeholder="Political position"
                      value={finalPoliticalPos}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFinalPoliticalPos(e.target.value)
                      }
                    />
                  </div>
                </InputsWrapper>

                <ButtonWrapper>
                  <ButtonCheck
                    className="button-excel"
                    type="button"
                    onClick={handleChangeParamsFeedback}
                  >
                    <img src={checkIcon} alt="Icone de check" />
                  </ButtonCheck>
                </ButtonWrapper>
              </HeaderContent>
              <FormBox>
                <StyledTable>
                  <TableRow>
                    <THead>Name</THead>
                    <THead style={{ marginLeft: "-6rem" }}>
                      Political position
                    </THead>
                    <THead>Date</THead>
                  </TableRow>
                  {dataFeedbacks.length > 0 &&
                    dataFeedbacks?.map((feedback, index) => (
                      <TableRow key={feedback.feedback_id}>
                        <TableItem>
                          {`${feedback?.first_name} ${feedback?.last_name}`}
                        </TableItem>
                        <TableItem>
                          {Number(feedback?.political_position).toFixed(2)}
                        </TableItem>
                        <TableItem>{formatDate(feedback.created_at)}</TableItem>
                        <ButtonViewFeedback
                          onClick={() =>
                            handleClickButtonView(
                              `/feedback/${feedback?.feedback_id}`
                            )
                          }
                        >
                          <img src={iconView} alt="" />
                        </ButtonViewFeedback>
                      </TableRow>
                    ))}

                  {dataFeedbacks.length === 0 && (
                    <div
                      style={{
                        width: "100%",
                        minHeight: "300px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      Empty data for your search.
                    </div>
                  )}
                </StyledTable>
              </FormBox>
              <div className="adjust-pagination-container">
                <PaginationContainer>
                  <FlexPagination>
                    {dataFeedbacks?.length >= 1 && (
                      <Pagination
                        limit={10}
                        total={totalPages}
                        offset={feedbackData.offset ? feedbackData.offset : 0}
                        dataNews={feedbackData}
                        setDataNews={setFeedbackData}
                      />
                    )}
                  </FlexPagination>
                </PaginationContainer>
              </div>
            </Main>
          ) : (
            <div
              style={{
                height: "80vh",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <MoonLoader
                color={color}
                loading={isLoading}
                css={override}
                size={65}
                speedMultiplier={1.2}
              />
            </div>
          )}
        </Container>
      </MasterContainer>
    </>
  );
};

export default FeedbackScreen;

/*
 * this page the user will be the inputs for
 * change some parameters mainly that control the app's api
 */

import React, { useRef, ChangeEvent, useState, useCallback } from "react";
import { css } from "@emotion/react";
import { useEffect } from "react";
import api from "../../../../../services/api";
import NotificationArea from "../components/NotificationArea";
import {
  Button,
  Container,
  GrayWrapper,
  HorizontalBox,
  HorizontalWeights,
  InputHorizontal,
  LargeBox,
  LegendInput,
  VerticalBox,
} from "./styles";

import { useForm } from "react-hook-form";
import ClipLoader from "react-spinners/ClipLoader";

import * as yup from "yup";
import CollapseMenu from "../components/CollapseMenu";
import InputDefault from "../components/InputDefault";
import WeightOfUserTable from "../components/WeightOfUserTable";
import OthersCPanel from "../components/OthersCPanel";
import { toast } from "react-toastify";

interface IDataPropsApi {
  data: IDataInputsApi;
}

interface IDataInputsApi {
  created_at: string | number;
  delete_news_day: number | number;
  delete_news_number_related: number | number;
  delete_news_number_times_read: number | number;
  delete_news_raitng: string | number;
  final_rating_weight_division: string | number;
  final_rating_weight_economic: string | number;
  final_rating_weight_social: string | number;
  final_rating_weight_vote: string | number;
  id: string | number;
  minimum_number_votes_weight_rating: number;
  minimum_percentage_among_votes: string | number;
  news_political_position_centre_left_less_than_3a: string | number;
  news_political_position_centre_right_less_than_3a: string | number;
  news_political_position_left_less_than_3a: string | number;
  news_political_position_right_less_than_3a: string | number;
  news_political_rating_centre_less_than_4: string | number;
  news_political_rating_left_less_than_4: string | number;
  news_political_rating_right_less_than_4: string | number;
  news_related_percentage_equal_words: string | number;
  opposing_view_equal_number_words: string | number;
  period_read: number;
  political_weight_economic_centre_left: string | number;
  political_weight_economic_centre_right: string | number;
  political_weight_economic_left: string | number;
  political_weight_economic_right: string | number;
  political_weight_social_centre_left: string | number;
  political_weight_social_centre_right: string | number;
  political_weight_social_left: string | number;
  political_weight_social_right: string | number;
  political_weight_vote_centre_left: string | number;
  political_weight_vote_centre_right: string | number;
  political_weight_vote_left: string | number;
  political_weight_vote_right: string | number;
  read_interval_five_weight: string | number;
  read_interval_four_weight: string | number;
  read_interval_less_than_four: number;
  read_interval_less_than_one: number;
  read_interval_less_than_three: number;
  read_interval_less_than_two: number;
  read_interval_one_weight: string | number;
  read_interval_three_weight: string | number;
  read_interval_two_weight: string | number;
  updated_at: string | number;
  user_political_position_centre_left_less_than_3a: string | number;
  user_political_position_centre_less_than_3a: string | number;
  user_political_position_centre_right_less_than_3a: string | number;
  user_political_position_left_less_than_3a: string | number;
  user_political_position_left_less_than_4: string | number;
  user_political_position_right_greater_than_4: string | number;
  user_political_position_right_less_than_3a: string | number;
  weight_news_centre_left_user_centre_3a: string | number;
  weight_news_centre_left_user_centre_left_3a: string | number;
  weight_news_centre_left_user_centre_right_3a: string | number;
  weight_news_centre_left_user_left_3a: string | number;
  weight_news_centre_left_user_right_3a: string | number;
  weight_news_centre_right_user_centre_3a: string | number;
  weight_news_centre_right_user_centre_left_3a: string | number;
  weight_news_centre_right_user_centre_right_3a: string | number;
  weight_news_centre_right_user_left_3a: string | number;
  weight_news_centre_right_user_right_3a: string | number;
  weight_news_left_user_centre_3a: string | number;
  weight_news_left_user_centre_left_3a: string | number;
  weight_news_left_user_centre_right_3a: string | number;
  weight_news_left_user_left_3a: string | number;
  weight_news_left_user_right_3a: string | number;
  weight_news_rating_centre_user_centre_4: string | number;
  weight_news_rating_centre_user_left_4: string | number;
  weight_news_rating_centre_user_right_4: string | number;
  weight_news_rating_left_user_centre_4: string | number;
  weight_news_rating_left_user_left_4: string | number;
  weight_news_rating_left_user_right_4: string | number;
  weight_news_rating_right_user_centre_4: string | number;
  weight_news_rating_right_user_left_4: string | number;
  weight_news_rating_right_user_right_4: string | number;
  weight_news_right_user_centre_3a: string | number;
  weight_news_right_user_centre_left_3a: string | number;
  weight_news_right_user_centre_right_3a: string | number;
  weight_news_right_user_left_3a: string | number;
  weight_news_right_user_right_3a: string | number;
}

interface IDataNotificationsApi {
  created_at: string;
  hour: number;
  id: string;
  is_active: boolean;
  minute: number;
  text: string;
  title: string;
  updated_at: string;
}

const PanelControlMain: React.FC = () => {
  //just styles complement for
  //loading component

  const override = css`
    display: block;
    margin: 0 auto;
  `;

  // const schema = yup.object().shape({
  //   //... this schema for config yup if necessary
  // });

  //set up states of feedbacks
  const [dataApi, setDataApi] = useState<IDataPropsApi>();
  const [data, setData] = useState({} as IDataInputsApi);
  const [dataNotifications, setDataNotifications] =
    useState<IDataNotificationsApi>({} as IDataNotificationsApi);
  let [color, setColor] = useState("#000");
  const [isActiveNotification, setIsActiveNotification] = useState(false);
  const [notificationId, setNotificationId] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  const [hour, setHour] = useState(0);
  const [minute, setMinute] = useState(0);
  const checkBoxRef = useRef(null);

  // object for edit only area of notifications
  // then
  // this division by the api separately this part

  const [formData, setFormData] = useState({
    notification_control_panel_id: "d1dec0fe-f9f4-4454-804c-4b4dac3e5c92",
    title: "Beehive News",
    text: "",
    hour: 0,
    minute: 0,
    is_active: false,
  });

  // loaded our objects of notifications
  useEffect(() => {
    if (dataNotifications) {
      setNotificationId(dataNotifications?.id);
      setIsActiveNotification(dataNotifications.is_active);
      setTitle(dataNotifications?.title);
      setText(dataNotifications?.text);
      setHour(dataNotifications?.hour);
      setMinute(dataNotifications?.minute);
    }
  }, [dataNotifications]);

  //edit if notifications active or not yet
  const handleActiveNotification = () => {
    setIsActiveNotification(!isActiveNotification);
    setFormData({ ...formData, is_active: !isActiveNotification });
  };

  //fetch some data what api brings us
  // and formatted to arrays
  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const myData = await api.get("control-panel");
        const dataValues = myData.data as IDataInputsApi;
        const myDataNotifications = await api.get("notification-control-panel");

        setDataNotifications(myDataNotifications.data);
        setData(dataValues);
        setDataApi(myData);
        let formatted = [];
        let result = Object.entries(data);

        result.forEach((item, index) => {
          return formatted.push(item);
        });
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    })();
  }, []);

  /*
   *here is the function that sends the new data to the api
   *if it is changed by the user. There is more than one
   *request because the data here is divided into a few different
   *endpoints
   */

  const handleSubmitState = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      setLoadingSave(true);

      //then prepare the object
      //to session of notifications

      let notificationsControlPanel = {
        notification_control_panel_id: dataNotifications.id,
        title: formData.title,
        text: formData.text,
        hour: formData.hour,
        minute: formData.minute,
        is_active: formData.is_active,
      };

      //then prepare the object
      //to session of some variables of api

      let objectControlPanel = {
        control_panel_id: String(data.id),
        period_read: Number(data.period_read),
        read_interval_less_than_one: Number(data.read_interval_less_than_one),
        read_interval_less_than_two: Number(data.read_interval_less_than_two),
        read_interval_less_than_three: Number(
          data.read_interval_less_than_three
        ),
        read_interval_less_than_four: Number(data.read_interval_less_than_four),
        read_interval_one_weight: Number(data.read_interval_one_weight),
        read_interval_two_weight: Number(data.read_interval_two_weight),
        read_interval_three_weight: Number(data.read_interval_three_weight),
        read_interval_four_weight: Number(data.read_interval_four_weight),
        read_interval_five_weight: Number(data.read_interval_five_weight),
        minimum_number_votes_weight_rating: Number(
          data.minimum_number_votes_weight_rating
        ),
        news_political_position_left_less_than_3a: Number(
          data.news_political_position_left_less_than_3a
        ),
        news_political_position_centre_left_less_than_3a: Number(
          data.news_political_position_centre_left_less_than_3a
        ),
        news_political_position_centre_right_less_than_3a: Number(
          data.news_political_position_centre_right_less_than_3a
        ),
        news_political_position_right_less_than_3a: Number(
          data.news_political_position_right_less_than_3a
        ),
        user_political_position_left_less_than_3a: Number(
          data.user_political_position_left_less_than_3a
        ),
        user_political_position_centre_left_less_than_3a: Number(
          data.user_political_position_centre_left_less_than_3a
        ),
        user_political_position_centre_less_than_3a: Number(
          data.user_political_position_centre_less_than_3a
        ),
        user_political_position_centre_right_less_than_3a: Number(
          data.user_political_position_centre_right_less_than_3a
        ),
        user_political_position_right_less_than_3a: Number(
          data.user_political_position_right_less_than_3a
        ),
        weight_news_left_user_left_3a: Number(
          data.weight_news_left_user_left_3a
        ),
        weight_news_left_user_centre_left_3a: Number(
          data.weight_news_left_user_centre_left_3a
        ),
        weight_news_left_user_centre_3a: Number(
          data.weight_news_left_user_centre_3a
        ),
        weight_news_left_user_centre_right_3a: Number(
          data.weight_news_left_user_centre_right_3a
        ),
        weight_news_left_user_right_3a: Number(
          data.weight_news_left_user_right_3a
        ),
        weight_news_centre_left_user_left_3a: Number(
          data.weight_news_centre_left_user_left_3a
        ),
        weight_news_centre_left_user_centre_left_3a: Number(
          data.weight_news_centre_left_user_centre_left_3a
        ),
        weight_news_centre_left_user_centre_3a: Number(
          data.weight_news_centre_left_user_centre_3a
        ),
        weight_news_centre_left_user_centre_right_3a: Number(
          data.weight_news_centre_left_user_centre_right_3a
        ),
        weight_news_centre_left_user_right_3a: Number(
          data.weight_news_centre_left_user_right_3a
        ),
        weight_news_centre_right_user_left_3a: Number(
          data.weight_news_centre_right_user_left_3a
        ),
        weight_news_centre_right_user_centre_left_3a: Number(
          data.weight_news_centre_right_user_centre_left_3a
        ),
        weight_news_centre_right_user_centre_3a: Number(
          data.weight_news_centre_right_user_centre_3a
        ),
        weight_news_centre_right_user_centre_right_3a: Number(
          data.weight_news_centre_right_user_centre_right_3a
        ),
        weight_news_centre_right_user_right_3a: Number(
          data.weight_news_centre_right_user_right_3a
        ),
        weight_news_right_user_left_3a: Number(
          data.weight_news_right_user_left_3a
        ),
        weight_news_right_user_centre_left_3a: Number(
          data.weight_news_right_user_centre_left_3a
        ),
        weight_news_right_user_centre_3a: Number(
          data.weight_news_right_user_centre_3a
        ),
        weight_news_right_user_centre_right_3a: Number(
          data.weight_news_right_user_centre_right_3a
        ),
        weight_news_right_user_right_3a: Number(
          data.weight_news_right_user_right_3a
        ),
        news_political_rating_left_less_than_4: Number(
          data.news_political_rating_left_less_than_4
        ),
        news_political_rating_centre_less_than_4: Number(
          data.news_political_rating_centre_less_than_4
        ),
        news_political_rating_right_less_than_4: Number(
          data.news_political_rating_right_less_than_4
        ),
        user_political_position_left_less_than_4: Number(
          data.user_political_position_left_less_than_4
        ),
        user_political_position_right_greater_than_4: Number(
          data.user_political_position_right_greater_than_4
        ),
        weight_news_rating_left_user_left_4: Number(
          data.weight_news_rating_left_user_left_4
        ),
        weight_news_rating_left_user_centre_4: Number(
          data.weight_news_rating_left_user_centre_4
        ),
        weight_news_rating_left_user_right_4: Number(
          data.weight_news_rating_left_user_right_4
        ),
        weight_news_rating_centre_user_left_4: Number(
          data.weight_news_rating_centre_user_left_4
        ),
        weight_news_rating_centre_user_centre_4: Number(
          data.weight_news_rating_centre_user_centre_4
        ),
        weight_news_rating_centre_user_right_4: Number(
          data.weight_news_rating_centre_user_right_4
        ),
        weight_news_rating_right_user_left_4: Number(
          data.weight_news_rating_right_user_left_4
        ),
        weight_news_rating_right_user_centre_4: Number(
          data.weight_news_rating_right_user_centre_4
        ),
        weight_news_rating_right_user_right_4: Number(
          data.weight_news_rating_right_user_right_4
        ),
        political_weight_vote_left: Number(data.political_weight_vote_left),
        political_weight_vote_centre_left: Number(
          data.political_weight_vote_centre_left
        ),
        political_weight_vote_centre_right: Number(
          data.political_weight_vote_centre_right
        ),
        political_weight_vote_right: Number(data.political_weight_vote_right),
        political_weight_economic_left: Number(
          data.political_weight_economic_left
        ),
        political_weight_economic_centre_left: Number(
          data.political_weight_economic_centre_left
        ),
        political_weight_economic_centre_right: Number(
          data.political_weight_economic_centre_right
        ),
        political_weight_economic_right: Number(
          data.political_weight_economic_right
        ),
        political_weight_social_left: Number(data.political_weight_social_left),
        political_weight_social_centre_left: Number(
          data.political_weight_social_centre_left
        ),
        political_weight_social_centre_right: Number(
          data.political_weight_social_centre_right
        ),
        political_weight_social_right: Number(
          data.political_weight_social_right
        ),
        final_rating_weight_vote: Number(data.final_rating_weight_vote),
        final_rating_weight_economic: Number(data.final_rating_weight_economic),
        final_rating_weight_social: Number(data.final_rating_weight_social),
        final_rating_weight_division: Number(data.final_rating_weight_division),
        opposing_view_equal_number_words: Number(
          data.opposing_view_equal_number_words
        ),
        news_related_percentage_equal_words: Number(
          data.news_related_percentage_equal_words
        ),
        delete_news_number_times_read: Number(
          data.delete_news_number_times_read
        ),
        delete_news_number_related: Number(data.delete_news_number_related),
        delete_news_raitng: Number(data.delete_news_raitng),
        delete_news_day: Number(data.delete_news_day),
        minimum_percentage_among_votes: Number(
          data.minimum_percentage_among_votes
        ),
      };
      let sucessInfos = false;
      let sucessNotifications = false;
      let errorMessage = "";

      // send patchs with our objects
      //brings by event of submit
      //relate with notifications of questions

      try {
        const requestUpdateRoute = await api.put(
          "notification-control-panel",
          notificationsControlPanel,
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
            },
          }
        );
        setLoadingSave(false);
        sucessNotifications = true;
      } catch (err) {
        console.log(err, "error when update notifications control panel");
        setLoadingSave(false);
      }

      //now send for others fields
      // to api beehive

      try {
        const responseUpdateMainControlPanel = await api.put(
          "control-panel",
          objectControlPanel
        );
        setLoadingSave(false);
        sucessInfos = true;
      } catch (err) {
        console.log(err, "error when post data inputs main control panel");
        setLoadingSave(false);
      }

      if (sucessInfos && sucessNotifications) {
        toast.success("Updated with success!");
      } else {
        toast.error(errorMessage + "Bad Request! Try again later.");
      }
    },

    [formData, data, loadingSave]
  );

  // load the notification information
  //if has changed too

  useEffect(() => {
    if (loading) {
    } else {
      setFormData({
        ...formData,
        title: title,
        text: text,
        hour: hour,
        minute: minute,
        is_active: isActiveNotification,
      });
    }
  }, [loading]);

  return (
    <Container>
      <div className="content-box">
        <CollapseMenu title="Weight by read count" collapseHeight="900px">
          <GrayWrapper>
            <VerticalBox>
              <span>Read count period (in days)</span>
              <InputDefault
                type="number"
                value={data?.period_read}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setData({ ...data, period_read: Number(e.target.value) })
                }
                className="input-panel"
              />
            </VerticalBox>

            <HorizontalBox>
              <span>Brackets for read count in assigned period</span>
              <InputHorizontal>
                <LegendInput>
                  <span className="legend-input"> {`Very low  > Low `}</span>
                </LegendInput>
                <InputDefault
                  className="input-panel"
                  type="number"
                  value={data?.read_interval_less_than_one}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setData({
                      ...data,
                      read_interval_less_than_one: Number(e.target.value),
                    })
                  }
                />
              </InputHorizontal>

              <InputHorizontal>
                <LegendInput>
                  <span className="legend-input"> {`Low > Intermediary`}</span>
                </LegendInput>
                <InputDefault
                  className="input-panel"
                  type="number"
                  value={data?.read_interval_less_than_two}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setData({
                      ...data,
                      read_interval_less_than_two: Number(e.target.value),
                    })
                  }
                />
              </InputHorizontal>

              <InputHorizontal>
                <LegendInput>
                  <span className="legend-input">
                    {" "}
                    {`Intermediary > Very high `}
                  </span>
                </LegendInput>
                <InputDefault
                  className="input-panel"
                  title={"Read interval less than three"}
                  type="number"
                  value={data?.read_interval_less_than_three}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setData({
                      ...data,
                      read_interval_less_than_three: Number(e.target.value),
                    })
                  }
                />
              </InputHorizontal>

              <InputHorizontal>
                <LegendInput>
                  <span className="legend-input"> {`High > Very high`}</span>
                </LegendInput>
                <InputDefault
                  className="input-panel"
                  type="number"
                  value={data?.read_interval_less_than_four}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setData({
                      ...data,
                      read_interval_less_than_four: Number(e.target.value),
                    })
                  }
                />
              </InputHorizontal>
            </HorizontalBox>

            <HorizontalWeights style={{ marginTop: "3.5rem" }}>
              <span>Weights</span>
              <InputHorizontal>
                <LegendInput>
                  <span className="legend-input"> {`Very low`}</span>
                </LegendInput>
                <InputDefault
                  className="input-panel"
                  type="number"
                  value={data?.read_interval_one_weight}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setData({
                      ...data,
                      read_interval_one_weight: Number(e.target.value),
                    })
                  }
                />
              </InputHorizontal>

              <InputHorizontal>
                <LegendInput>
                  <span className="legend-input"> {`Low`}</span>
                </LegendInput>
                <InputDefault
                  className="input-panel"
                  type="number"
                  value={data?.read_interval_two_weight}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setData({
                      ...data,
                      read_interval_two_weight: Number(e.target.value),
                    })
                  }
                />
              </InputHorizontal>

              <InputHorizontal>
                <LegendInput>
                  <span className="legend-input">{`Intermediary`}</span>
                </LegendInput>
                <InputDefault
                  className="input-panel"
                  type="number"
                  value={data?.read_interval_three_weight}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setData({
                      ...data,
                      read_interval_three_weight: Number(e.target.value),
                    })
                  }
                />
              </InputHorizontal>

              <InputHorizontal>
                <LegendInput>
                  <span className="legend-input"> {`High`}</span>
                </LegendInput>
                <InputDefault
                  className="input-panel"
                  type="number"
                  value={data?.read_interval_four_weight}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setData({
                      ...data,
                      read_interval_four_weight: Number(e.target.value),
                    })
                  }
                />
              </InputHorizontal>

              <InputHorizontal>
                <LegendInput>
                  <span className="legend-input"> {`Very high`}</span>
                </LegendInput>
                <InputDefault
                  className="input-panel"
                  type="number"
                  value={data?.read_interval_five_weight}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setData({
                      ...data,
                      read_interval_five_weight: Number(e.target.value),
                    })
                  }
                />
              </InputHorizontal>
            </HorizontalWeights>
          </GrayWrapper>
        </CollapseMenu>

        <CollapseMenu
          title="Weight of user for their quality ratings (completeness, trustworthiness, quality of writing"
          collapseHeight="200px"
        >
          <GrayWrapper>
            <WeightOfUserTable
              title="Article political position brackets (Left to Right)"
              legend="1 > 2"
              type="number"
              value={data?.news_political_position_left_less_than_3a}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setData({
                  ...data,
                  news_political_position_left_less_than_3a: Number(
                    e.target.value
                  ),
                })
              }
            />
            <WeightOfUserTable
              title=""
              legend="2 > 3"
              type="number"
              value={data?.news_political_position_centre_left_less_than_3a}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setData({
                  ...data,
                  news_political_position_centre_left_less_than_3a: Number(
                    e.target.value
                  ),
                })
              }
            />
            <WeightOfUserTable
              title=""
              legend="3 > 4"
              type="number"
              value={data?.news_political_position_centre_right_less_than_3a}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setData({
                  ...data,
                  news_political_position_centre_right_less_than_3a: Number(
                    e.target.value
                  ),
                })
              }
            />
            <WeightOfUserTable
              title=""
              legend="4 > 5"
              type="number"
              value={data?.news_political_position_right_less_than_3a}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setData({
                  ...data,
                  news_political_position_right_less_than_3a: Number(
                    e.target.value
                  ),
                })
              }
            />

            <WeightOfUserTable
              title="User political position brackets"
              legend="1 > 2"
              type="number"
              value={data?.user_political_position_left_less_than_3a}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setData({
                  ...data,
                  user_political_position_left_less_than_3a: Number(
                    e.target.value
                  ),
                })
              }
            />
            <WeightOfUserTable
              title=""
              legend="2 > 3"
              type="number"
              value={data?.user_political_position_centre_left_less_than_3a}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setData({
                  ...data,
                  user_political_position_centre_left_less_than_3a: Number(
                    e.target.value
                  ),
                })
              }
            />
            <WeightOfUserTable
              title=""
              legend="3 > 4"
              type="number"
              value={data?.user_political_position_centre_less_than_3a}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setData({
                  ...data,
                  user_political_position_centre_less_than_3a: Number(
                    e.target.value
                  ),
                })
              }
            />
            <WeightOfUserTable
              title=""
              legend="4 > 5"
              type="number"
              value={data?.user_political_position_centre_right_less_than_3a}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setData({
                  ...data,
                  user_political_position_centre_right_less_than_3a: Number(
                    e.target.value
                  ),
                })
              }
            />

            <WeightOfUserTable
              title=""
              legend="5 > 6"
              type="number"
              value={data?.user_political_position_right_less_than_3a}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setData({
                  ...data,
                  user_political_position_right_less_than_3a: Number(
                    e.target.value
                  ),
                })
              }
            />

            <WeightOfUserTable
              title="Weights - Article position 1 (A1)"
              legend="A1 + U1"
              type="number"
              value={data?.weight_news_left_user_left_3a}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setData({
                  ...data,
                  weight_news_left_user_left_3a: Number(e.target.value),
                })
              }
            />
            <WeightOfUserTable
              title=""
              legend="A1 + U2"
              type="number"
              value={data?.weight_news_left_user_centre_left_3a}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setData({
                  ...data,
                  weight_news_left_user_centre_left_3a: Number(e.target.value),
                })
              }
            />
            <WeightOfUserTable
              title=""
              legend="A1 + U3"
              type="number"
              value={data?.weight_news_left_user_centre_3a}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setData({
                  ...data,
                  weight_news_left_user_centre_3a: Number(e.target.value),
                })
              }
            />
            <WeightOfUserTable
              title=""
              legend="A1 + U4"
              type="number"
              value={data?.weight_news_left_user_centre_right_3a}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setData({
                  ...data,
                  weight_news_left_user_centre_right_3a: Number(e.target.value),
                })
              }
            />
            <WeightOfUserTable
              title=""
              legend="A1 + U5"
              type="number"
              value={data?.weight_news_left_user_right_3a}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setData({
                  ...data,
                  weight_news_left_user_right_3a: Number(e.target.value),
                })
              }
            />

            <WeightOfUserTable
              title="Weights - Article position 2 (A2)"
              legend="A2 + U1"
              type="number"
              value={data?.weight_news_left_user_left_3a}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setData({
                  ...data,
                  weight_news_centre_left_user_left_3a: Number(e.target.value),
                })
              }
            />
            <WeightOfUserTable
              title=""
              legend="A2 + U2"
              type="number"
              value={data?.weight_news_centre_left_user_centre_left_3a}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setData({
                  ...data,
                  weight_news_centre_left_user_centre_left_3a: Number(
                    e.target.value
                  ),
                })
              }
            />
            <WeightOfUserTable
              title=""
              legend="A2 + U3"
              type="number"
              value={data?.weight_news_centre_left_user_centre_3a}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setData({
                  ...data,
                  weight_news_centre_left_user_centre_3a: Number(
                    e.target.value
                  ),
                })
              }
            />
            <WeightOfUserTable
              title=""
              legend="A2 + U4"
              type="number"
              value={data?.weight_news_centre_left_user_centre_right_3a}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setData({
                  ...data,
                  weight_news_centre_left_user_centre_right_3a: Number(
                    e.target.value
                  ),
                })
              }
            />
            <WeightOfUserTable
              title=""
              legend="A2 + U5"
              type="number"
              value={data?.weight_news_centre_left_user_right_3a}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setData({
                  ...data,
                  weight_news_centre_left_user_right_3a: Number(e.target.value),
                })
              }
            />

            <WeightOfUserTable
              title="Weights - Article position 3 (A3)"
              legend="A3 + U1"
              type="number"
              value={data?.weight_news_centre_right_user_left_3a}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setData({
                  ...data,
                  weight_news_centre_right_user_left_3a: Number(e.target.value),
                })
              }
            />
            <WeightOfUserTable
              title=""
              legend="A3 + U2"
              type="number"
              value={data?.weight_news_centre_right_user_centre_left_3a}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setData({
                  ...data,
                  weight_news_centre_right_user_centre_left_3a: Number(
                    e.target.value
                  ),
                })
              }
            />
            <WeightOfUserTable
              title=""
              legend="A3 + U3"
              type="number"
              value={data?.weight_news_centre_right_user_centre_3a}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setData({
                  ...data,
                  weight_news_centre_right_user_centre_3a: Number(
                    e.target.value
                  ),
                })
              }
            />
            <WeightOfUserTable
              title=""
              legend="A3 + U4"
              type="number"
              value={data?.weight_news_centre_right_user_centre_right_3a}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setData({
                  ...data,
                  weight_news_centre_right_user_centre_right_3a: Number(
                    e.target.value
                  ),
                })
              }
            />
            <WeightOfUserTable
              title=""
              legend="A3 + U5"
              type="number"
              value={data?.weight_news_centre_right_user_right_3a}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setData({
                  ...data,
                  weight_news_centre_right_user_right_3a: Number(
                    e.target.value
                  ),
                })
              }
            />

            <WeightOfUserTable
              title="Weights - Article position 4 (A4)"
              legend="A4 + U1"
              type="number"
              value={data?.weight_news_right_user_left_3a}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setData({
                  ...data,
                  weight_news_right_user_left_3a: Number(e.target.value),
                })
              }
            />
            <WeightOfUserTable
              title=""
              legend="A4 + U2"
              type="number"
              value={data?.weight_news_right_user_centre_left_3a}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setData({
                  ...data,
                  weight_news_right_user_centre_left_3a: Number(e.target.value),
                })
              }
            />
            <WeightOfUserTable
              title=""
              legend="A4 + U3"
              type="number"
              value={data?.weight_news_right_user_centre_3a}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setData({
                  ...data,
                  weight_news_right_user_centre_3a: Number(e.target.value),
                })
              }
            />
            <WeightOfUserTable
              title=""
              legend="A4 + U4"
              type="number"
              value={data?.weight_news_right_user_centre_right_3a}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setData({
                  ...data,
                  weight_news_right_user_centre_right_3a: Number(
                    e.target.value
                  ),
                })
              }
            />
            <WeightOfUserTable
              title=""
              legend="A4 + U5"
              type="number"
              value={data?.weight_news_right_user_right_3a}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setData({
                  ...data,
                  weight_news_right_user_right_3a: Number(e.target.value),
                })
              }
            />
          </GrayWrapper>
        </CollapseMenu>

        <CollapseMenu
          title="Weight of user for their political positioning assessment"
          collapseHeight="200px"
        >
          <GrayWrapper>
            <WeightOfUserTable
              title="Article positioning brackets"
              legend="0 > Left "
              type="number"
              value={data?.news_political_rating_left_less_than_4}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setData({
                  ...data,
                  news_political_rating_left_less_than_4: Number(
                    e.target.value
                  ),
                })
              }
            />
            <WeightOfUserTable
              title=""
              legend="Left > Centre"
              type="number"
              value={data?.news_political_rating_centre_less_than_4}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setData({
                  ...data,
                  news_political_rating_centre_less_than_4: Number(
                    e.target.value
                  ),
                })
              }
            />
            <WeightOfUserTable
              title=""
              legend="Center > Right"
              type="number"
              value={data?.news_political_rating_right_less_than_4}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setData({
                  ...data,
                  news_political_rating_right_less_than_4: Number(
                    e.target.value
                  ),
                })
              }
            />

            <WeightOfUserTable
              title="User positioning brackets"
              legend="Left > Centre"
              type="number"
              value={data?.user_political_position_left_less_than_4}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setData({
                  ...data,
                  user_political_position_left_less_than_4: Number(
                    e.target.value
                  ),
                })
              }
            />
            <WeightOfUserTable
              title=""
              legend="Centre > Right"
              type="number"
              value={data?.user_political_position_right_greater_than_4}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setData({
                  ...data,
                  user_political_position_right_greater_than_4: Number(
                    e.target.value
                  ),
                })
              }
            />

            <WeightOfUserTable
              title="Weights - Article position 'LEFT' "
              legend="A Left + U Left"
              type="number"
              value={data?.weight_news_rating_left_user_left_4}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setData({
                  ...data,
                  weight_news_rating_left_user_left_4: Number(e.target.value),
                })
              }
            />
            <WeightOfUserTable
              title=""
              legend="A Left + U Centre"
              type="number"
              value={data?.weight_news_rating_left_user_centre_4}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setData({
                  ...data,
                  weight_news_rating_left_user_centre_4: Number(e.target.value),
                })
              }
            />
            <WeightOfUserTable
              title=""
              legend="A Left + U Right"
              type="number"
              value={data?.weight_news_rating_left_user_right_4}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setData({
                  ...data,
                  weight_news_rating_left_user_right_4: Number(e.target.value),
                })
              }
            />

            <WeightOfUserTable
              title="Weights - Article position 'CENTER' "
              legend="A Left + U Left"
              type="number"
              value={data?.weight_news_rating_centre_user_left_4}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setData({
                  ...data,
                  weight_news_rating_centre_user_left_4: Number(e.target.value),
                })
              }
            />
            <WeightOfUserTable
              title=""
              legend="A Left + U Centre"
              type="number"
              value={data?.weight_news_rating_centre_user_centre_4}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setData({
                  ...data,
                  weight_news_rating_centre_user_centre_4: Number(
                    e.target.value
                  ),
                })
              }
            />
            <WeightOfUserTable
              title=""
              legend="A Left + U Right"
              type="number"
              value={data?.weight_news_rating_centre_user_right_4}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setData({
                  ...data,
                  weight_news_rating_centre_user_right_4: Number(
                    e.target.value
                  ),
                })
              }
            />

            <WeightOfUserTable
              title="Weights - Article position 'Right'"
              legend="A Right + U Left"
              type="number"
              value={data?.weight_news_rating_right_user_left_4}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setData({
                  ...data,
                  weight_news_rating_right_user_left_4: Number(e.target.value),
                })
              }
            />
            <WeightOfUserTable
              title=""
              legend="A Right + U Centre"
              type="number"
              value={data?.weight_news_rating_right_user_centre_4}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setData({
                  ...data,
                  weight_news_rating_right_user_centre_4: Number(
                    e.target.value
                  ),
                })
              }
            />
            <WeightOfUserTable
              title=""
              legend="A Right + U Right"
              type="number"
              value={data?.weight_news_rating_right_user_right_4}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setData({
                  ...data,
                  weight_news_rating_right_user_right_4: Number(e.target.value),
                })
              }
            />
          </GrayWrapper>
        </CollapseMenu>

        <CollapseMenu
          title="Formula for user’s political positioning"
          collapseHeight="200px"
        >
          <GrayWrapper>
            <WeightOfUserTable
              title="points for Electoral preferences"
              legend="Left"
              type="number"
              value={data?.political_weight_vote_left}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setData({
                  ...data,
                  political_weight_vote_left: Number(e.target.value),
                })
              }
            />
            <WeightOfUserTable
              title=""
              legend="Centre-left"
              type="number"
              value={data?.political_weight_vote_centre_left}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setData({
                  ...data,
                  political_weight_vote_centre_left: Number(e.target.value),
                })
              }
            />
            <WeightOfUserTable
              title=""
              legend="Centre-right"
              type="number"
              value={data?.political_weight_vote_centre_right}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setData({
                  ...data,
                  political_weight_vote_centre_right: Number(e.target.value),
                })
              }
            />
            <WeightOfUserTable
              title=""
              legend="Right"
              type="number"
              value={data?.political_weight_vote_right}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setData({
                  ...data,
                  political_weight_vote_right: Number(e.target.value),
                })
              }
            />

            <WeightOfUserTable
              title="points for Economic views"
              legend="Left"
              type="number"
              value={data?.political_weight_economic_left}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setData({
                  ...data,
                  political_weight_economic_left: Number(e.target.value),
                })
              }
            />
            <WeightOfUserTable
              title=""
              legend="Centre-left"
              type="number"
              value={data?.political_weight_economic_centre_left}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setData({
                  ...data,
                  political_weight_economic_centre_left: Number(e.target.value),
                })
              }
            />
            <WeightOfUserTable
              title=""
              legend="Centre-right"
              type="number"
              value={data?.political_weight_economic_centre_right}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setData({
                  ...data,
                  political_weight_economic_centre_right: Number(
                    e.target.value
                  ),
                })
              }
            />
            <WeightOfUserTable
              title=""
              legend="Right"
              type="number"
              value={data?.political_weight_economic_right}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setData({
                  ...data,
                  political_weight_economic_right: Number(e.target.value),
                })
              }
            />

            <WeightOfUserTable
              title="points for Social views"
              legend="Left"
              type="number"
              value={data?.political_weight_social_left}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setData({
                  ...data,
                  political_weight_social_left: Number(e.target.value),
                })
              }
            />
            <WeightOfUserTable
              title=""
              legend="Centre-left"
              type="number"
              value={data?.political_weight_social_centre_right}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setData({
                  ...data,
                  political_weight_social_centre_left: Number(e.target.value),
                })
              }
            />
            <WeightOfUserTable
              title=""
              legend="Centre-right"
              type="number"
              value={data?.political_weight_social_centre_right}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setData({
                  ...data,
                  political_weight_social_centre_right: Number(e.target.value),
                })
              }
            />
            <WeightOfUserTable
              title=""
              legend="Right"
              type="number"
              value={data?.political_weight_social_right}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setData({
                  ...data,
                  political_weight_social_right: Number(e.target.value),
                })
              }
            />

            <WeightOfUserTable
              title="Formula for final positioning"
              legend="Weight Electoral"
              type="number"
              value={data?.final_rating_weight_vote}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setData({
                  ...data,
                  final_rating_weight_vote: Number(e.target.value),
                })
              }
            />
            <WeightOfUserTable
              title=""
              legend="Weight Economic"
              type="number"
              value={data?.final_rating_weight_economic}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setData({
                  ...data,
                  final_rating_weight_economic: Number(e.target.value),
                })
              }
            />
            <WeightOfUserTable
              title=""
              legend="Weight Social"
              type="number"
              value={data?.final_rating_weight_social}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setData({
                  ...data,
                  final_rating_weight_social: Number(e.target.value),
                })
              }
            />

            <WeightOfUserTable
              title=""
              legend="Denominator for division (sum of weights)"
              type="number"
              value={data?.final_rating_weight_division}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setData({
                  ...data,
                  final_rating_weight_division: Number(e.target.value),
                })
              }
            />
          </GrayWrapper>
        </CollapseMenu>

        <CollapseMenu title="Other" collapseHeight="200px">
          <GrayWrapper>
            <OthersCPanel data={data} setData={setData} />
          </GrayWrapper>
        </CollapseMenu>

        <CollapseMenu title="notifications" collapseHeight="200px">
          <NotificationArea
            title={title}
            setTitle={setTitle}
            hour={hour}
            setHour={setHour}
            setText={setText}
            text={text}
            minute={minute}
            isActiveNotification={isActiveNotification}
            setMinute={setMinute}
            dataNotifications={dataNotifications}
            checkBoxRef={checkBoxRef}
            formData={formData}
            setFormData={setFormData}
            handleActiveNotification={handleActiveNotification}
          />
        </CollapseMenu>
      </div>

      <LargeBox>
        <Button className="button-excel" onClick={handleSubmitState}>
          {loadingSave ? (
            <ClipLoader
              loading={loadingSave}
              color={color}
              css={override}
              size={15}
              speedMultiplier={1.2}
            />
          ) : (
            "Save"
          )}
        </Button>
      </LargeBox>
    </Container>
  );
};

export default PanelControlMain;

import styled from "styled-components";
import { lighten } from "polished";
import { Form } from "formik";

export const CustomForm = styled(Form)`
  width: 100%;
  height: 100%;
  position: relative;
  padding: 0 50px 0 50px;
  max-height: 85vh;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const CustomButton = styled.button`
  width: 150px;
  height: 65px;
  display: flex;
  background-color: ${({ theme, ...props }) =>
    props.color || theme.colors.honey};
  color: black;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  border-radius: 5px;
  font-weight: bold;

  :hover {
    background: ${({ theme, ...props }) =>
      lighten(0.1, props.color || theme.colors.honey)};
    box-shadow: 0px 0px 8px -5px #000000;
  }

  :disabled {
    cursor: "not-allowed";
    opacity: 0.2;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 5px;
`;

import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  background: #ebedef;
  width: 100%;
  height: auto;
  overflow: auto;
  flex-direction: column;
  padding: 0 2rem;
`;

export const Main = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  background: white;
  flex-direction: column;
  padding: 1rem 2rem 1rem 2rem;
  margin-top: 3rem;
  margin-bottom: 5rem;
  border-radius: 5px;
  border: 1px solid #dadada;

  @media (max-width: 1366px) {
    padding: 1rem 2rem 0rem 2rem;
  }
`;

export const BoxList = styled.div`
  width: 100%;
  padding: 1.8rem 1.8rem 1rem 1.8rem;
  margin-top: 1.5rem;
  background: ${({ theme }) => theme.colors.gray100};

  @media (max-width: 1366px) {
    margin-top: 0.5rem;
  }
`;

export const FlexControl = styled.div`
  display: flex;
  width: 100%;
  min-height: 6rem;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  padding-top: 1rem;

  & .input-default-list-news {
    font-size: 1.3rem;
    margin: 0.2rem;
    padding: 0 1rem;

    &::placeholder {
      font-size: 1.3rem;
    }
  }

  #forcemargin {
    transform: translateY(-1px);
  }

  @media (max-width: 1366px) {
    #forcemargin {
      transform: translateY(-2px);
    }
  }
`;

export const FlexList = styled.div`
  display: flex;
  flex: 0.1666;
  height: 100%;
  position: relative;

  .image-position {
    position: absolute;
    right: 15px;
    top: 20px;
  }
`;

export const FlexDateFrom = styled.div`
  display: flex;
  flex: 0.105;
  height: 100%;

  .wrapper-date-from {
    display: flex;

    min-width: 160px;
    position: relative;

    align-items: center;

    @media (max-width: 1600px) {
      min-width: 115px;
    }
  }
`;

export const FlexDateTo = styled.div`
  display: flex;
  flex: 0.105;
  height: 100%;

  .wrapper-date-from {
    display: flex;
    width: 100%;
    min-width: 160px;
    position: relative;
    height: 100%;
    align-items: center;

    @media (max-width: 1600px) {
      min-width: 115px;
    }
  }
`;

export const FlexTopic = styled.div`
  display: flex;
  flex: 0.1666;
  height: 100%;
  position: relative;

  .image-position {
    position: absolute;
    right: 15px;
    top: 20px;
  }
`;

export const FlexPoliticalViewsFrom = styled.div`
  display: flex;
  flex: 0.105;
  height: 100%;
`;

export const FlexPoliticalViewsTo = styled.div`
  display: flex;
  flex: 0.105;
  height: 100%;
`;

export const FlexButton = styled.div`
  display: flex;
  flex: 0.05;
  height: 100%;
`;

export const FlexNewspapper = styled.div`
  display: flex;
  flex: 0.1666;
  height: 100%;
  margin-right: 0.3rem;
  position: relative;

  .image-position {
    position: absolute;
    right: 15px;
    top: 20px;
  }
`;

export const ButtonCheck = styled.div`
  width: 45px;
  height: 45px;
  display: flex;
  transition: all 120ms linear;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin-top: 0.2rem;
  margin-left: auto;
  background: ${({ theme }) => theme.colors.honey};

  & .check-icon {
    display: flex;
    width: 22px;
    height: 22px;

    @media (max-width: 1366px) {
      .check-icon {
        width: 1px;
        height: 1px;
      }
    }
  }

  @media (max-width: 1366px) {
    width: 42px;
    height: 42px;
  }
`;

export const TableNews = styled.table`
  display: flex;
  flex-direction: column;
  width: 100%;
  table-layout: auto;
  .force-transform-title {
    transform: translateX(100px);
  }

  @media (max-width: 1366px) {
    .force-transform-title {
      transform: translateX(60px);
    }
  }

  .force-transform-rating {
    transform: translateX(10px) !important;
  }

  .date-transform {
    transform: translateX(60px);
  }

  @media (max-width: 1690px) {
    .date-transform {
      transform: translateX(0px);
    }
  }

  @media (max-width: 1600px) {
    .date-transform {
      transform: translateX(80px) !important;
    }

    .date-transform2 {
      transform: translateX(30px) !important;
    }

    .field-political-position {
      transform: translateX(80px) !important;
    }
  }

  @media (max-width: 1366px) {
    .force-transform-rating {
      transform: translateX(50px) !important;
    }
  }

  .date-transform {
    .column-date {
      transform: translateX(20px);
    }
  }

  @media (max-width: 1366px) {
    .date-transform {
      transform: translateX(60px) !important;
    }

    .date-transform2 {
      transform: translateX(10px) !important;
    }
  }
`;

export const THeadNews = styled.thead`
  width: 100%;
  display: flex;

  .force-transform-rating {
    transform: translateX(40px) !important;
  }

  .force-transform-rating2 {
    transform: translateX(140px) !important;
  }

  .field-political-position {
    width: 16.6%;
    display: flex;
    justify-content: flex-end;
    transform: translateX(40px);
  }

  .field-political-position2 {
    width: 16.6%;
    display: flex;
    justify-content: flex-end;
    transform: translateX(120px);
  }

  @media (max-width: 1690px) {
    .force-transform-rating {
      transform: translateX(70px) !important;
    }

    .force-transform-rating2 {
      transform: translateX(110px) !important;
    }
  }

  @media (max-width: 1366px) {
    .field-political-position {
      width: 16.6%;
      display: flex;
      justify-content: flex-end;
      transform: translateX(90px);
    }
  }
`;

export const TrNews = styled.tr`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding: 0rem 0rem;
  font-size: 1.3rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray200};
  color: #333333 !important;
  table-layout: auto;

  :first-child {
    border-top: 1px solid ${({ theme }) => theme.colors.gray200};
  }

  :last-child {
    border: none;
  }

  .column-date {
    transform: translateX(20px);
  }

  @media (max-width: 1720px) {
    .column-date {
      transform: translateX(-10px);
    }
  }

  @media (max-width: 1366px) {
    .column-date {
      transform: translateX(-20px);
    }
  }
`;

export const FlexPagination = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  margin: 1rem 0;
  @media (max-width: 1366px) {
    margin-top: 0rem !important;
    margin: 0rem 0;
  }
`;

export const ContentPag = styled.div`
  transform: translateY(10px);

  @media (max-width: 1366px) {
    transform: translateY(0px);
  }
`;

export const EmptyField = styled.div`
  width: 100%;
  min-height: 50rem;
  padding-bottom: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TBodyNews = styled.tbody`
  .column-political-position {
    width: 60px;
    margin-right: 5rem;
    transform: translateX(45px);
  }

  @media (max-width: 1366px) {
    .column-political-position {
      transform: translateX(30px);
    }
  }
`;

export const TdNews = styled.td`
  width: 100%;
  max-width: 300px;
  padding: 2rem 0rem;
  display: flex;
  text-overflow: ellipsis;
`;

export const ThNews = styled.th`
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 1rem;
`;

export const ButtonEditNews = styled.button`
  background: transparent;

  .check-icon {
    width: 18px;
    height: 18px;
  }
`;

export const FlexSearch = styled.div`
  width: 100%;
  min-height: 45px;
  display: flex;
  margin: 1rem 0 0.2rem 0.2rem;
  border: 1px solid #9d9d9d;
  border-radius: 5px;
  padding-left: 1rem;

  @media (max-width: 1366px) {
    min-height: 42px;
  }
`;

export const FlexMain = styled.div`
  width: 95%;
  height: 100%;
  border-radius: 5px;
  display: flex;
  align-items: center;

  & input {
    width: 100%;
    height: 100%;
    border-left: none;
    border-right: none;
    border: none;
    font-size: 1.3rem;
  }
`;

export const FlexIcon = styled.div`
  width: 5%;
  height: 100%;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Select = styled.select`
  width: 100%;
  height: 45px;
  border: 1px solid #9d9d9d;
  color: gray;
  border-radius: 5px;
  font-size: 1.3rem;
  padding: 0 0rem;

  option {
    color: black;
    background: white;
    display: flex;
    white-space: pre;
    min-height: 20px;
    padding: 0px 2px 1px;
  }

  @media (max-width: 1366px) {
    height: 42px;
  }
`;

export const TextTable = styled.p`
  max-width: 300px; // Limite maximo do texto
  white-space: nowrap; // Removendo quebra de linha
  overflow: hidden; // Removendo a barra de rolagem
  text-overflow: ellipsis; // Adicionando "..." ao final do texto
`;
